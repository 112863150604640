import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { ConfirmModal } from 'src/providers/Confirm/ConfirmModal';
import ConfirmContextProvider from 'src/providers/Confirm/ConfirmContextProvider';
import moment from 'moment-timezone';
import { history } from './utils/history';
import Routes from './routes';
import './styles/index.css';

import 'react-toastify/dist/ReactToastify.css';
import { notifyInfo } from './utils/notification';

const App = () => {
  // Setting default timezone to Europe/London
  // moment.tz.setDefault('Europe/London');
  moment.tz.setDefault();

  // When the app is loaded, checks if the version of the app is different from the one stored in the local storage.
  // If it is different, the user is redirected to the login page.
  if (localStorage.getItem('version') !== process.env.REACT_APP_VERSION) {
    notifyInfo('A new version of the app is available. Please, log in again.');
    persistor.purge().then(() => {
      localStorage.clear();
      localStorage.setItem('version', process.env.REACT_APP_VERSION!);
      history.push('/login');
    });
  }

  return (
    <React.StrictMode>
      <Router history={history}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfirmContextProvider>
              <ConfirmModal />

              <ToastContainer />
              <Routes />
            </ConfirmContextProvider>
          </PersistGate>
        </Provider>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
