/* eslint-disable indent */
import { TIconsType } from 'src/components/Icon/constants';
import React from 'react';

import Plot from 'src/components/plotly';
import { Icon } from 'src/components/Icon';
import { Typography } from 'src/components/Typography';
import { If } from 'src/components/If';
import { convertIntervalDateDays } from 'src/utils/helpers';
import { Tooltip } from 'src/components/Tooltip';
import { useSelector } from 'react-redux';

type Params = {
  data: any[];
  title: string;
  value: string;
  icon: TIconsType;
  percent: string;
  tooltip?: string;
};

const IndicatorWithPlot = ({ data, title, value, percent, icon, tooltip }: Params) => {
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const isShowDoD = percent !== '0%';

  const layout = {
    margin: {
      t: 0,
      r: 0,
      b: 0,
      l: 10,
    },
    height: 70,
    width: 200,
    showlegend: false,
    xaxis: {
      autotick: false,
      fixedrange: true,
      showgrid: true,
      showline: false,
      showticklabels: false,
      zeroline: false,
      visible: (data[0].y && data[0].y?.length) || false,
    },
    yaxis: {
      visible: false,
      autotick: true,
      fixedrange: false,
      showgrid: false,
      showline: false,
      // showticklabels: false,
      zeroline: false,
    },
    annotations:
      data[0].y && data[0].y?.length < 1
        ? [
          {
            text: 'No matching data found',
            xref: 'paper',
            yref: 'paper',
            showarrow: false,
            font: { size: 14, color: '#3F404F' },
          },
        ]
        : [],
  };

  const config = {
    displayModeBar: false,
  };

  return (
    <div className="flex items-end p-4">
      <div className="flex-col">
        <div className="text-sm leading-4 flex justify-start">
          <Typography variant="body-small" className="mr-1" style={{ fontSize: '0.75rem', whiteSpace: 'pre' }}>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip tooltipText={tooltip} tooltipPlacement="top" width={250}>
              <Icon icon="circle-warning" className="mt-1 m-0 text-gray-500 text-xs" />
            </Tooltip>
          )}
        </div>
        <Typography variant="large-h1">{value}</Typography>
        {data[0].y && data[0].y.length > 0 && (
          <div className="flex items-center gap-2">
            {isShowDoD && (
              <If condition={icon}>
                <Icon icon={icon} color="primary" />
              </If>
            )}

            <div className="text-base w-[80px]">
              <span className="">{`${isShowDoD ? percent : 0}`}</span>
              {isShowDoD && (
                <span className="ml-1 text-neutral-400">{convertIntervalDateDays(startDate, endDate)}</span>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full" style={{ maxWidth: '100%', flex: 2 }}>
        <Plot data={data} layout={layout} config={config} />
      </div>
    </div>
  );
};

export default IndicatorWithPlot;
