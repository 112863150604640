/* eslint-disable no-unused-vars */
import bugFixImg from 'src/assets/illustrations/bug-fix.png';
import bugFixImg2x from 'src/assets/illustrations/bug-fix@2x.png';

import addingPiecesImg from 'src/assets/illustrations/adding-pieces.png';
import addingPiecesImg2x from 'src/assets/illustrations/adding-pieces@2x.png';

import fixingErrorsImg from 'src/assets/illustrations/fixing-errors.png';
import fixingErrorsImg2x from 'src/assets/illustrations/fixing-errors@2x.png';

import serverUpdatesImg from 'src/assets/illustrations/server-updates.png';
import serverUpdatesImg2x from 'src/assets/illustrations/server-updates@2x.png';

import pageNotFoundImg from 'src/assets/illustrations/page-not-found.png';
import pageNotFoundImg2x from 'src/assets/illustrations/page-not-found@2x.png';

import deleteImg from 'src/assets/illustrations/delete.png';
import deleteImg2x from 'src/assets/illustrations/delete@2x.png';

export const ILLUSTRATIONS_NAME = [
  'bug-fix',
  'adding-pieces',
  'fixing-errors',
  'server-updates',
  'page-not-found',
  'delete',
] as const;

export type AvailableIllustrations = typeof ILLUSTRATIONS_NAME[number];

export const ILLUSTRATIONS: {
  [key in AvailableIllustrations]: {
    x1: string;
    x2: string;
    alt?: string;
  };
} = {
  'bug-fix': {
    x1: bugFixImg,
    x2: bugFixImg2x,
    alt: 'Bugfix illustration',
  },
  'adding-pieces': {
    x1: addingPiecesImg,
    x2: addingPiecesImg2x,
    alt: 'Adding pieces illustration',
  },
  'fixing-errors': {
    x1: fixingErrorsImg,
    x2: fixingErrorsImg2x,
    alt: 'Fixing errors illustration',
  },
  'server-updates': {
    x1: serverUpdatesImg,
    x2: serverUpdatesImg2x,
    alt: 'Server updates illustration',
  },
  'page-not-found': {
    x1: pageNotFoundImg,
    x2: pageNotFoundImg2x,
    alt: 'Page not found illustration',
  },
  delete: {
    x1: deleteImg,
    x2: deleteImg2x,
    alt: 'Delete illustration',
  },
};
