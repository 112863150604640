/**
 * Use this application to clean SVGs and put theirs named paths here.
 *
 * trash: {
 *   path: (
 *     <>
 *       <path
          stroke="currentColor"
 *  fill="#000000" d="M3 7h26" />
 *       <path
          stroke="currentColor"
 *  fill="#000000" d="M11 7v-6h10v6" />
 *       <path
          stroke="currentColor"
 *  fill="#000000" d="M11 25l10-10" />
 *       <path
          stroke="currentColor"
 *  fill="current-color" d="M21 25l-10-10" />
 *     </>
 *   ),
 * },
 */

const ICONS = {
  empty: {
    path: (
      <path />
    ),
  },
  'arrow-up': {
    path: (
      <path
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M7 19l9-9 9 9"
      />
    ),
  },
  'arrow-down': {
    path: (
      <path
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M7 13l9 9 9-9"
      />
    ),
  },
  'arrow-left': {
    path: (
      <path
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M19 7l-9 9 9 9"
      />
    ),
  },
  'arrow-right': {
    path: (
      <path
        stroke="currentColor"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M13 7l9 9-9 9"
      />
    ),
  },
  'circle-warning': {
    path: (
      <path
        stroke="currentColor"
        d="M-0 16c0 8.8 7.2 16 16 16s16-7.2 16-16c0-8.8-7.2-16-16-16s-16 7.2-16 16zM2.667 16c0-7.333 6-13.333 13.333-13.333s13.333 6 13.333 13.333c0 7.333-6 13.333-13.333 13.333s-13.333-6-13.333-13.333zM16 20.8c-0.88 0-1.6-0.72-1.6-1.6v-11.2c0-0.88 0.72-1.6 1.6-1.6s1.6 0.72 1.6 1.6v11.2c0 0.88-0.72 1.6-1.6 1.6zM17.6 24c0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6c0-0.884 0.716-1.6 1.6-1.6s1.6 0.716 1.6 1.6z"
      />
    ),
  },
  'circle-check': {
    path: (
      <>
        <path
          stroke="currentColor"
          d="M16 4.889c-6.136 0-11.111 4.975-11.111 11.111s4.975 11.111 11.111 11.111c6.137 0 11.111-4.975 11.111-11.111s-4.975-11.111-11.111-11.111zM2.667 16c0-7.364 5.97-13.333 13.333-13.333s13.333 5.97 13.333 13.333c0 7.364-5.97 13.333-13.333 13.333s-13.333-5.97-13.333-13.333z"
        />
        <path
          stroke="currentColor"
          d="M22.563 12.325c0.434 0.434 0.434 1.137 0 1.571l-7.452 7.452-4.119-4.119c-0.434-0.434-0.434-1.137 0-1.571s1.137-0.434 1.571 0l2.548 2.548 5.881-5.881c0.434-0.434 1.137-0.434 1.571 0z"
        />
      </>
    ),
  },
  close: {
    path: (
      <path
        stroke="currentColor"
        d="M6.676 23.553c-0.474 0.474-0.501 1.268-0.061 1.773s1.181 0.531 1.655 0.057l7.678-7.678 7.678 7.678c0.474 0.474 1.242 0.474 1.716 0s0.474-1.242 0-1.716l-7.678-7.678 7.556-7.556c0.474-0.474 0.501-1.268 0.061-1.773s-1.181-0.531-1.655-0.057l-7.674 7.674-7.561-7.561c-0.474-0.474-1.242-0.474-1.716 0s-0.474 1.242 0 1.716l7.561 7.561-7.561 7.561z"
      />
    ),
  },
  warning: {
    path: (
      <>
        <path
          stroke="currentColor"
          d="M14.547 11.965v7.264c0 0.807 0.646 1.453 1.453 1.453s1.453-0.646 1.453-1.453v-7.264c0-0.807-0.646-1.453-1.453-1.453s-1.453 0.807-1.453 1.453z"
        />
        <path
          stroke="currentColor"
          d="M16 24.878c0.802 0 1.453-0.65 1.453-1.453s-0.651-1.453-1.453-1.453c-0.802 0-1.453 0.65-1.453 1.453s0.65 1.453 1.453 1.453z"
        />
        <path
          stroke="currentColor"
          d="M17.13 0.989c-0.484-0.807-1.776-0.807-2.26 0l-14.689 27.117c-0.161 0.323-0.161 0.807 0 1.291s0.646 0.646 1.13 0.646h29.377c0.484 0 0.807-0.161 1.13-0.646s0.323-0.807 0-1.291l-14.689-27.117zM3.41 27.461l12.59-23.082 12.59 23.082h-25.18z"
        />
      </>
    ),
  },

  trash: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M5 11v16c0 1.061 0.421 2.078 1.172 2.828s1.768 1.172 2.828 1.172h14c1.061 0 2.078-0.421 2.828-1.172s1.172-1.768 1.172-2.828v-16"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M3 7h26"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M11 7v-6h10v6"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M11 25l10-10"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M21 25l-10-10"
        />
      </>
    ),
  },
  store: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2.6667"
          d="M30.667 12h-29.333l5.333-10.667h18.667l5.333 10.667z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2.6667"
          d="M28 17.333v13.333h-24v-13.333"
        />
        <path
          stroke="currentColor"
          fill="none"
          d="M18.667 21.333h-5.333v8h5.333v-8z"
        />
      </>
    ),
  },
  delete: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M5 11v16c0 1.061 0.421 2.078 1.172 2.828s1.768 1.172 2.828 1.172h14c1.061 0 2.078-0.421 2.828-1.172s1.172-1.768 1.172-2.828v-16"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M3 7h26"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          fill="none"
          strokeWidth="2"
          d="M11 7v-6h10v6"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M11 25l10-10"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M21 25l-10-10"
        />
      </>
    ),
  },
  edit: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M23.5 4.75l3.75 3.75-12 12-5.25 1.5 1.5-5.25 12-12z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M24.25 18.25v7.5c0 0.828-0.672 1.5-1.5 1.5h-16.5c-0.828 0-1.5-0.672-1.5-1.5v-16.5c0-0.828 0.672-1.5 1.5-1.5h7.5"
        />
      </>
    ),
  },
  move: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 22l-5-5 5-5"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26 22l5-5-5-5"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 17h-10"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 17h-10"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8l5-5 5 5"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 26l5 5 5-5"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 13v-10"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 31v-10"
        />
      </>
    ),
  },
  'lock-filled': {
    path: (
      <path
        stroke="currentColor"
        d="M24 10.667h-1.333v-2.667c0-3.68-2.987-6.667-6.667-6.667s-6.667 2.987-6.667 6.667v2.667h-1.333c-1.467 0-2.667 1.2-2.667 2.667v13.333c0 1.467 1.2 2.667 2.667 2.667h16c1.467 0 2.667-1.2 2.667-2.667v-13.333c0-1.467-1.2-2.667-2.667-2.667zM16 22.667c-1.467 0-2.667-1.2-2.667-2.667s1.2-2.667 2.667-2.667c1.467 0 2.667 1.2 2.667 2.667s-1.2 2.667-2.667 2.667zM20.133 10.667h-8.267v-2.667c0-2.28 1.853-4.133 4.133-4.133s4.133 1.853 4.133 4.133v2.667z"
      />
    ),
  },
  'bottom-to-top': {
    path: (
      <path
        stroke="currentColor"
        d="M7.218 15.218l6.115-6.115v20.229c0 0.707 0.281 1.386 0.781 1.886s1.178 0.781 1.886 0.781c0.707 0 1.386-0.281 1.886-0.781s0.781-1.178 0.781-1.886v-20.229l6.115 6.115c0.503 0.486 1.177 0.755 1.876 0.748s1.368-0.287 1.862-0.781 0.775-1.163 0.781-1.862c0.006-0.699-0.263-1.373-0.749-1.876l-10.667-10.667c-0.5-0.5-1.178-0.781-1.885-0.781s-1.385 0.281-1.885 0.781l-10.667 10.667c-0.486 0.503-0.755 1.177-0.748 1.876s0.287 1.368 0.781 1.862c0.494 0.494 1.163 0.775 1.862 0.781s1.373-0.263 1.876-0.748z"
      />
    ),
  },
  'top-to-bottom': {
    path: (
      <path
        stroke="currentColor"
        d="M14.112 31.216c0.248 0.249 0.544 0.446 0.869 0.579 0.322 0.135 0.669 0.205 1.019 0.205s0.696-0.070 1.019-0.205c0.325-0.133 0.621-0.33 0.869-0.579l10.667-10.667c0.255-0.246 0.458-0.541 0.597-0.866s0.213-0.675 0.216-1.029c0.003-0.354-0.065-0.705-0.199-1.033s-0.333-0.625-0.583-0.875-0.549-0.448-0.876-0.582c-0.328-0.134-0.679-0.201-1.033-0.198s-0.704 0.077-1.029 0.217-0.619 0.343-0.865 0.598l-6.115 6.115v-20.229c0-0.707-0.281-1.386-0.781-1.886s-1.178-0.781-1.886-0.781c-0.707 0-1.386 0.281-1.886 0.781s-0.781 1.178-0.781 1.886v20.229l-6.115-6.115c-0.503-0.486-1.177-0.755-1.876-0.748s-1.368 0.287-1.862 0.781c-0.494 0.494-0.775 1.163-0.781 1.862s0.263 1.373 0.748 1.876l10.664 10.664z"
      />
    ),
  },
  'left-to-right': {
    path: (
      <path
        stroke="currentColor"
        d="M2.667 18.667h20.229l-6.115 6.115c-0.255 0.246-0.458 0.54-0.598 0.866s-0.213 0.675-0.216 1.029c-0.003 0.354 0.064 0.705 0.198 1.033s0.332 0.626 0.582 0.876c0.25 0.25 0.548 0.448 0.876 0.582s0.679 0.202 1.033 0.198c0.354-0.003 0.704-0.077 1.029-0.216s0.62-0.343 0.866-0.598l10.667-10.667c0.248-0.248 0.445-0.542 0.58-0.866s0.204-0.671 0.204-1.022c0-0.351-0.069-0.698-0.204-1.022s-0.331-0.618-0.58-0.866l-10.667-10.667c-0.501-0.5-1.18-0.781-1.887-0.78s-1.386 0.282-1.886 0.783c-0.5 0.501-0.781 1.18-0.78 1.887s0.282 1.386 0.783 1.886l6.115 6.115h-20.229c-0.707 0-1.386 0.281-1.886 0.781s-0.781 1.178-0.781 1.886c0 0.707 0.281 1.386 0.781 1.886s1.178 0.781 1.886 0.781z"
      />
    ),
  },
  'right-to-left': {
    path: (
      <path
        stroke="currentColor"
        d="M29.333 13.333h-20.229l6.115-6.115c0.486-0.503 0.755-1.177 0.748-1.876s-0.287-1.368-0.781-1.862c-0.494-0.494-1.163-0.775-1.862-0.781s-1.373 0.263-1.876 0.748l-10.667 10.667c-0.248 0.248-0.445 0.542-0.58 0.866s-0.204 0.671-0.204 1.022c0 0.351 0.069 0.698 0.204 1.022s0.331 0.618 0.58 0.866l10.667 10.667c0.248 0.248 0.542 0.444 0.866 0.578s0.671 0.203 1.021 0.202c0.35-0 0.697-0.070 1.021-0.204s0.618-0.331 0.865-0.579 0.444-0.542 0.578-0.866c0.134-0.324 0.203-0.671 0.202-1.021s-0.070-0.697-0.204-1.021-0.331-0.618-0.579-0.865l-6.115-6.115h20.229c0.707 0 1.386-0.281 1.886-0.781s0.781-1.178 0.781-1.886-0.281-1.386-0.781-1.886c-0.5-0.5-1.178-0.781-1.886-0.781z"
      />
    ),
  },
  'woman-up-front': {
    path: (
      <>
        <path
          fill="none"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M21.165 2.667c-0.295 1.146-0.962 2.162-1.897 2.887s-2.085 1.119-3.268 1.119-2.333-0.394-3.268-1.119c-0.935-0.725-1.603-1.741-1.897-2.887"
        />
        <path
          strokeLinejoin="round"
          fill="none"
          strokeLinecap="round"
          stroke="currentColor"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M26.666 1.333l1.66 4.98c0.267 0.802 0.34 1.656 0.213 2.491s-0.452 1.629-0.946 2.314c-0.494 0.686-1.144 1.244-1.896 1.629s-1.585 0.586-2.431 0.586h-14.533c-0.845 0-1.678-0.201-2.43-0.586s-1.402-0.943-1.896-1.629c-0.494-0.686-0.818-1.479-0.946-2.314s-0.055-1.689 0.213-2.491l1.66-4.98"
        />
        <path
          fill="none"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M8 13.334c1.167 8.251-2.667 17.333-2.667 17.333"
        />
        <path
          strokeLinejoin="round"
          fill="none"
          strokeLinecap="round"
          stroke="currentColor"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M24 13.334c-1.167 8.251 2.667 17.333 2.667 17.333"
        />
        <path
          stroke="currentColor"
          d="M16 26.667c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
        />
      </>
    ),
  },
  'eye-recognition': {
    path: (
      <>
        <path
          stroke="currentColor"
          d="M4 9.334h-2.667v-6.667c0-0.354 0.14-0.693 0.391-0.943s0.589-0.391 0.943-0.391h6.667v2.667h-5.333v5.333z"
        />
        <path
          stroke="currentColor"
          d="M30.667 9.334h-2.667v-5.333h-5.333v-2.667h6.667c0.354 0 0.693 0.14 0.943 0.391s0.391 0.589 0.391 0.943v6.667z"
        />
        <path
          stroke="currentColor"
          d="M9.333 30.667h-6.667c-0.354 0-0.693-0.14-0.943-0.391s-0.391-0.589-0.391-0.943v-6.667h2.667v5.333h5.333v2.667z"
        />
        <path
          stroke="currentColor"
          d="M29.333 30.667h-6.667v-2.667h5.333v-5.333h2.667v6.667c0 0.354-0.141 0.693-0.391 0.943s-0.589 0.391-0.943 0.391z"
        />
        <path
          stroke="currentColor"
          d="M30.443 15.26c-0.233-0.349-5.828-8.593-14.443-8.593s-14.209 8.244-14.443 8.593c-0.146 0.219-0.224 0.477-0.224 0.74s0.078 0.521 0.224 0.74c0.233 0.349 5.828 8.593 14.443 8.593s14.209-8.244 14.443-8.593c0.146-0.219 0.224-0.477 0.224-0.74s-0.078-0.521-0.224-0.74zM16 20.667c-0.923 0-1.825-0.274-2.593-0.786s-1.366-1.242-1.719-2.094-0.446-1.791-0.266-2.696c0.18-0.905 0.625-1.737 1.277-2.389s1.484-1.097 2.389-1.277c0.905-0.18 1.844-0.088 2.696 0.266s1.582 0.951 2.094 1.719c0.513 0.767 0.787 1.67 0.787 2.593 0 1.238-0.492 2.425-1.367 3.3s-2.062 1.367-3.3 1.367z"
        />
      </>
    ),
  },
  lock: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27 31h-22c-0.53 0-1.039-0.211-1.414-0.586s-0.586-0.884-0.586-1.414v-14c0-0.53 0.211-1.039 0.586-1.414s0.884-0.586 1.414-0.586h22c0.53 0 1.039 0.211 1.414 0.586s0.586 0.884 0.586 1.414v14c0 0.53-0.211 1.039-0.586 1.414s-0.884 0.586-1.414 0.586z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 13v-5c0-1.857 0.738-3.637 2.050-4.95s3.093-2.050 4.95-2.050v0c1.857 0 3.637 0.738 4.95 2.050s2.050 3.093 2.050 4.95v5"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 25c1.657 0 3-1.343 3-3s-1.343-3-3-3c-1.657 0-3 1.343-3 3s1.343 3 3 3z"
        />
      </>
    ),
  },
  rotation: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.757 4.687l-7.071 7.071c-0.781 0.781-0.781 2.047 0 2.828l12.728 12.728c0.781 0.781 2.047 0.781 2.828 0l7.071-7.071c0.781-0.781 0.781-2.047 0-2.828l-12.728-12.728c-0.781-0.781-2.047-0.781-2.828 0z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 21c0 2.652 1.054 5.196 2.929 7.071s4.419 2.929 7.071 2.929"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 1c2.652 0 5.196 1.054 7.071 2.929s2.929 4.419 2.929 7.071"
        />
      </>
    ),
  },
  crop: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 1v6"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23 27v4"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M1 7h22v14"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
          d="M9 13v8h22"
        />
      </>
    ),
  },
  area: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
          d="M11 29h-8c-1.104 0-2-0.896-2-2v-24c0-1.104 0.896-2 2-2h24c1.104 0 2 0.896 2 2v8"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 15l14 3-5 3 7 7-3 3-7-7-3 5-3-14z"
        />
      </>
    ),
  },
  link: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
          d="M21 5c0 0-4.334-1.666-7 1l-8 8c-2.666 2.666-1 7-1 7"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27 11c0 0 1.666 4.334-1 7l-8 8c-2.666 2.666-7 1-7 1"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 19l-10 10"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M29 3l-10 10"
        />
      </>
    ),
  },
  cctv: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 17h-24l-6-16h22c2.122 0 4.157 0.843 5.657 2.343s2.343 3.535 2.343 5.657v8z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 31h-10v-8"
        />
      </>
    ),
  },
  phoneButton: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M25 1h-18c-1.105 0-2 0.895-2 2v26c0 1.105 0.895 2 2 2h18c1.105 0 2-0.895 2-2v-26c0-1.105-0.895-2-2-2z"
        />
        <path
          stroke="currentColor"
          d="M16 26c1.105 0 2-0.895 2-2s-0.895-2-2-2c-1.105 0-2 0.895-2 2s0.895 2 2 2z"
        />
      </>
    ),
  },
  mail: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M29 29h-26c-1.104 0-2-0.896-2-2v-22c0-1.104 0.896-2 2-2h26c1.104 0 2 0.896 2 2v22c0 1.104-0.896 2-2 2z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 9l11 9 11-9"
        />
      </>
    ),
  },
  location: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27 12c0 9-11 19-11 19s-11-10-11-19c0-2.917 1.159-5.715 3.222-7.778s4.861-3.222 7.778-3.222c2.917 0 5.715 1.159 7.778 3.222s3.222 4.861 3.222 7.778v0z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 17c2.761 0 5-2.239 5-5s-2.239-5-5-5c-2.761 0-5 2.239-5 5s2.239 5 5 5z"
        />
      </>
    ),
  },
  user: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
          d="M8.636 18c-1.69 0.82-3.115 2.098-4.111 3.69s-1.525 3.432-1.525 5.31v0c0 0 4 4 13 4s13-4 13-4c0-1.878-0.528-3.719-1.526-5.31s-2.422-2.871-4.112-3.69"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23 8c0 3.866-3.134 9-7 9s-7-5.134-7-9c0-1.857 0.738-3.637 2.050-4.95s3.093-2.050 4.95-2.050c1.857 0 3.637 0.738 4.95 2.050s2.050 3.093 2.050 4.95v0z"
        />
      </>
    ),
  },
  add: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 31c7.732 0 14-6.268 14-14s-6.268-14-14-14c-7.732 0-14 6.268-14 14s6.268 14 14 14z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 9v16"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 17h16"
        />
      </>
    ),
  },
  menu: {
    path: (
      <>
        <path
          stroke="currentColor"
          d="M16 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4z"
        />
        <path
          stroke="currentColor"
          d="M4 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4z"
        />
        <path
          stroke="currentColor"
          d="M28 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4z"
        />
      </>
    ),
  },
  sort: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 13l4.5-12h1l4.5 12"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.5 9h7"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 19h10l-10 12h10"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23 1v30"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 23l8 8 8-8"
        />
      </>
    ),
  },
  sortTool: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 5h30"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 13h22"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 21h14"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 29h6"
        />
      </>
    ),
  },
  country: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M15 13h6"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M15 23h6"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          d="M3 29v-22l4-6 4 6v22h-8z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 29v-22l4-6 4 6v22h-8z"
        />
      </>
    ),
  },
  settings: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 21c2.761 0 5-2.239 5-5s-2.239-5-5-5c-2.761 0-5 2.239-5 5s2.239 5 5 5z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M27 16c0-0.93-0.128-1.826-0.344-2.688l3.834-2.214-3-5.196-3.83 2.212c-1.288-1.252-2.882-2.186-4.66-2.688v-4.426h-6v4.424c-1.776 0.504-3.37 1.438-4.66 2.69l-3.83-2.212-3 5.196 3.834 2.214c-0.216 0.862-0.344 1.758-0.344 2.688 0 0.928 0.128 1.826 0.344 2.688l-3.834 2.214 3 5.196 3.832-2.212c1.288 1.252 2.882 2.186 4.66 2.688v4.426h6v-4.424c1.778-0.504 3.37-1.438 4.66-2.688l3.832 2.212 3-5.196-3.834-2.214c0.212-0.864 0.34-1.762 0.34-2.69z"
        />
      </>
    ),
  },
  fullScreen: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.533 9.467h-13.067v13.067h13.067v-13.067z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2 10.4v-6.533c0-1.030 0.836-1.867 1.867-1.867h6.533"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.6 2h6.533c1.030 0 1.867 0.836 1.867 1.867v6.533"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M30 21.6v6.533c0 1.030-0.836 1.867-1.867 1.867h-6.533"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.4 30h-6.533c-1.030 0-1.867-0.836-1.867-1.867v-6.533"
        />
      </>
    ),
  },
  calendarBusy: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 16v8"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 20h8"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28 4h-24c-1.104 0-2 0.896-2 2v22c0 1.104 0.896 2 2 2h24c1.104 0 2-0.896 2-2v-22c0-1.104-0.896-2-2-2z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2 10h28"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 0v4"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22 0v4"
        />
      </>
    ),
  },
  calendarDate: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 11h28"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M29 5h-24c-1.104 0-2 0.896-2 2v22c0 1.104 0.896 2 2 2h24c1.104 0 2-0.896 2-2v-22c0-1.104-0.896-2-2-2z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 1v4"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M25 1v4"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 1v4"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 15v12"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 15v12"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 21h20"
        />
      </>
    ),
  },
  calendar: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="1"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2 11.333h28"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28 5.733h-24c-1.104 0-2 0.836-2 1.867v20.533c0 1.030 0.896 1.867 2 1.867h24c1.104 0 2-0.836 2-1.867v-20.533c0-1.030-0.896-1.867-2-1.867z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 2v3.733"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M24 2v3.733"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 2v3.733"
        />
      </>
    ),
  },
  timeMachine: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 30c7.732 0 14-6.268 14-14s-6.268-14-14-14c-7.732 0-14 6.268-14 14s6.268 14 14 14z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 8v8h8"
        />
      </>
    ),
  },
  hourglass: {
    path: (
      <path
        stroke="currentColor"
        d="M6 0v9.6h0.016l-0.016 0.016 6.4 6.384-6.4 6.4 0.016 0.016h-0.016v9.584h19.2v-9.584h-0.016l0.016-0.016-6.4-6.4 6.4-6.384-0.016-0.016h0.016v-9.6h-19.2zM22 23.2v5.6h-12.8v-5.6l6.4-6.4 6.4 6.4zM15.6 15.2l-6.4-6.4v-5.6h12.8v5.6l-6.4 6.4z"
      />
    ),
  },
  meanVisitTime: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 32c6.627 0 12-5.373 12-12s-5.373-12-12-12c-6.627 0-12 5.373-12 12s5.373 12 12 12z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28 20v12h-24v-12"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 8v-6"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 2h12"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 20h6"
        />
      </>
    ),
  },
  time: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 8v6h6"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28 14c0 10-12 18-12 18s-12-8-12-18c0-3.183 1.264-6.235 3.515-8.485s5.303-3.515 8.485-3.515c3.183 0 6.235 1.264 8.485 3.515s3.515 5.303 3.515 8.485v0z"
        />
      </>
    ),
  },
  footfall: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.304 13.304l4.696 4.696 4-2"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 14l-4 2v4"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 22l2 2v8"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 32l6.344-22.358"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 10c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4z"
        />
      </>
    ),
  },
  down: {
    path: (
      <>
        <path
          stroke="#eb5757"
          fill="#eb5757"
          d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z"
        />
        <path
          fill="#FFF"
          d="M16.496 23.779c-0.064 0.067-0.141 0.12-0.226 0.156s-0.177 0.055-0.27 0.055-0.184-0.019-0.27-0.055c-0.085-0.036-0.162-0.089-0.226-0.156l-6-6.667c-0.086-0.096-0.142-0.215-0.162-0.342s-0.003-0.258 0.050-0.375c0.052-0.118 0.138-0.218 0.246-0.288s0.234-0.107 0.363-0.107h4.667v-6.667c0-0.354 0.14-0.693 0.391-0.943s0.589-0.391 0.943-0.391c0.354 0 0.693 0.14 0.943 0.391s0.391 0.589 0.391 0.943v6.667h4.667c0.129 0 0.255 0.037 0.363 0.107s0.193 0.17 0.246 0.288c0.052 0.118 0.070 0.248 0.050 0.375s-0.076 0.246-0.162 0.342l-6 6.667z"
        />
      </>
    ),
  },
  up: {
    path: (
      <>
        <path
          stroke="#27ae60"
          fill="#27ae60"
          d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z"
        />
        <path
          stroke="fff"
          fill="#fff"
          d="M16.496 8.221c-0.064-0.067-0.141-0.12-0.226-0.156s-0.177-0.055-0.27-0.055c-0.093 0-0.184 0.019-0.27 0.055s-0.162 0.089-0.226 0.156l-6 6.667c-0.086 0.096-0.142 0.215-0.162 0.342s-0.003 0.258 0.050 0.375c0.052 0.118 0.138 0.218 0.246 0.288s0.234 0.107 0.363 0.107h4.667v6.667c0 0.354 0.14 0.693 0.391 0.943s0.589 0.39 0.943 0.39 0.693-0.14 0.943-0.39c0.25-0.25 0.391-0.589 0.391-0.943v-6.667h4.667c0.129-0 0.255-0.037 0.363-0.107s0.193-0.17 0.246-0.288c0.052-0.118 0.070-0.248 0.050-0.375s-0.076-0.246-0.162-0.342l-6-6.667z"
        />
      </>
    ),
  },
  circle: {
    path: (
      <path
        fill="none"
        stroke="currentColor"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2.6667"
        d="M29.333 16v-0.001c-0.003-3.535-1.409-6.924-3.909-9.423s-5.888-3.905-9.423-3.909h-0.001c-2.637 0-5.215 0.782-7.408 2.247s-3.902 3.547-4.911 5.984c-1.009 2.436-1.273 5.117-0.759 7.704s1.784 4.962 3.649 6.827c1.865 1.865 4.24 3.135 6.827 3.649s5.267 0.25 7.704-0.759 4.519-2.718 5.984-4.911c1.465-2.193 2.247-4.77 2.247-7.408zM15.998 30.667c-2.9-0-5.735-0.861-8.147-2.472s-4.292-3.902-5.402-6.582-1.401-5.629-0.835-8.474c0.566-2.845 1.963-5.458 4.014-7.51s4.664-3.448 7.51-4.014c2.845-0.566 5.794-0.275 8.474 0.835s4.971 2.99 6.582 5.402c1.611 2.412 2.471 5.247 2.472 8.147-0.004 3.889-1.551 7.617-4.301 10.367s-6.478 4.297-10.367 4.301z"
      />
    ),
  },
  'circle-check-filled': {
    path: (
      <path
        stroke="currentColor"
        d="M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c8.8 0 16-7.2 16-16s-7.2-16-16-16zM14 22.8l-6.8-6.8 2.8-2.8 4 4 8-8 2.8 2.8-10.8 10.8z"
      />
    ),
  },
  dashboard: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 1h-12v16h12v-16z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 23h-12v8h12v-8z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 1h-12v10h12v-10z"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 17h-12v14h12v-14z"
        />
      </>
    ),
  },
  cameraVideo: {
    path: (
      <>
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 19l10 4v-14l-10 4"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19 27h-16c-1.104 0-2-0.896-2-2v-18c0-1.104 0.896-2 2-2h16c1.104 0 2 0.896 2 2v18c0 1.104-0.896 2-2 2z"
        />
      </>
    ),
  },
  arrowRight: {
    path: (
      <path
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 7l9 9-9 9"
      />
    ),
  },
  arrowDown: {
    path: (
      <path
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeMiterlimit="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 13l9 9 9-9"
      />
    ),
  },
  arrowLeft: {
    path: (
      <path
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeMiterlimit="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19 7l-9 9 9 9"
      />
    ),
  },
  arrowUp: {
    path: (
      <path
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeMiterlimit="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 19l9-9 9 9"
      />
    ),
  },
  logout: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11 17h20"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23 9l8 8-8 8"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 31h-14c-1.104 0-2-0.896-2-2v-24c0-1.104 0.896-2 2-2h14"
        />
      </>
    ),
  },
  acc: {
    path: (
      <>
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 21c3.866 0 7-3.134 7-7s-3.134-7-7-7c-3.866 0-7 3.134-7 7s3.134 7 7 7z"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 20.324c-0.024 0.808-0.301 1.588-0.79 2.232s-1.168 1.117-1.94 1.356l-4.534 2.176c-0.191 0.080-0.376 0.176-0.552 0.286"
        />
        <path
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26.816 26.376c-0.176-0.11-0.361-0.206-0.552-0.286l-4.53-2.176c-1.664-0.876-2.73-1.866-2.73-3.588"
        />
        <path
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 31c8.284 0 15-6.716 15-15s-6.716-15-15-15c-8.284 0-15 6.716-15 15s6.716 15 15 15z"
        />
      </>
    ),
  },
  download: {
    path: (
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M1,17 L1,23 L23,23 L23,17 M12,2 L12,19 M5,12 L12,19 L19,12"
      />
    ),
  },
  info: {
    path: (
      <>
        <path
          d="M6 4C6.27614 4 6.5 3.77614 6.5 3.5C6.5 3.22386 6.27614 3 6 3C5.72386 3 5.5 3.22386 5.5 3.5C5.5 3.77614 5.72386 4 6 4Z"
          fill="#3F404F"
        />
        <path
          d="M5.99951 8.5V5.5"
          stroke="#3F404F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 11C3.23858 11 1 8.76142 1 6H-1C-1 9.86599 2.13401 13 6 13V11ZM11 6C11 8.76142 8.76142 11 6 11V13C9.86599 13 13 9.86599 13 6H11ZM6 1C8.76142 1 11 3.23858 11 6H13C13 2.13401 9.86599 -1 6 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
          fill="#3F404F"
        />
      </>
    ),
  },
  back: {
    path: (
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="M8,3 L3,8 L8,13 M12,20 L15,20 C18.3137085,20 21,17.3137085 21,14 C21,10.6862915 18.3137085,8 15,8 L4,8"
      />
    ),
  },
};

export type TIconsType = keyof typeof ICONS;

export { ICONS };
