import React from 'react';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import AuthLayout from 'src/components/layout/auth';
import { Icon } from 'src/components/Icon';

const ResetPasswordConfirm = () => {
  return (
    <AuthLayout title="Recover Password" subtitle="Enter email and we will send you recovery link.">
      <div className="flex flex-col w-96 gap-2 m-auto py-5">
        <Icon icon="circle-check-filled" color="primary" size="lg" className="mx-auto" />
        <Button className="h-[50px] mt-5" type="submit">
          <Typography variant="standard-button" color="white">
            Back to login page

          </Typography>
          <Icon icon="left-to-right" color="white" size="sm" className="mt-1 ml-3" />
        </Button>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordConfirm;
