import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { getTimestampOf } from 'src/utils/helpers';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getStoreAreas({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreAreas,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getStoreAreas.success(data));
  } catch (e) {
    yield put(ActionTypes.getStoreAreas.failure(e));
  }
}

function* getStoreAreasSaga() {
  yield takeLatest(ActionTypes.GET_STORE_AREAS, getStoreAreas);
}

function* getDepartmentAreas({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDepartmentAreas,
      payload.storeId,
      payload.departmentId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getDepartmentAreas.success(data));
  } catch (e) {
    yield put(ActionTypes.getDepartmentAreas.failure(e));
  }
}

function* getDepartmentAreasSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENT_AREAS, getDepartmentAreas);
}

function* getCameraAreas({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCameraAreas,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getCameraAreas.success(data));
  } catch (e) {
    yield put(ActionTypes.getCameraAreas.failure(e));
  }
}

function* getCameraAreasSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_AREAS, getCameraAreas);
}

function* getDwellAnalysis({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDwellAnalysis,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
      payload.thresholdFilter,
    );
    yield put(ActionTypes.getDwellAnalysis.success(data));
  } catch (e) {
    yield put(ActionTypes.getDwellAnalysis.failure(e));
  }
}

function* getDwellAnalysisSaga() {
  yield takeLatest(ActionTypes.GET_DWELL_ANALYSIS, getDwellAnalysis);
}

function* getCameraAreasOfInterest({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAreasOfInterest,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
      payload.thresholdFilter,
    );
    yield put(ActionTypes.getCameraAreasOfInterest.success(data));
  } catch (e) {
    yield put(ActionTypes.getCameraAreasOfInterest.failure(e));
  }
}

function* getCameraAreasOfInterestSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_AREAS_OF_INTEREST, getCameraAreasOfInterest);
}

function* getOutsideTraffic({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getOutsideTraffic,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getOutsideTraffic.success(data));
  } catch (e) {
    yield put(ActionTypes.getOutsideTraffic.failure(e));
  }
}

function* getOutsideTrafficSaga() {
  yield takeLatest(ActionTypes.GET_OUTSIDE_TRAFFIC, getOutsideTraffic);
}

function* postAreas({ payload }: any) {
  try {
    const { data } = yield call(
      Api.postAreas,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.data,
    );
    yield put(ActionTypes.postAreas.success(data));
    notifyInfo('Areas Saved!');
  } catch (e) {
    yield put(ActionTypes.postAreas.failure(e));
    notifyError(e as string);
  }
}

function* postAreasSaga() {
  yield takeLatest(ActionTypes.POST_AREAS, postAreas);
}

function* deleteArea({ payload }: any) {
  try {
    const { data } = yield call(Api.deleteArea, payload.areaId);
    yield put(ActionTypes.deleteArea.success(data));
    notifyInfo('Areas Deleted!');
  } catch (e) {
    yield put(ActionTypes.deleteArea.failure(e));
    notifyError(e as string);
  }
}

function* deleteAreaSaga() {
  yield takeLatest(ActionTypes.DELETE_AREA, deleteArea);
}

function* archiveArea({ payload }: any) {
  try {
    const { data } = yield call(
      Api.archiveArea,
      payload.brandId,
      payload.areaId,
    );

    yield put(ActionTypes.archiveArea.success(data));
    notifyInfo('Areas Deleted!');
  } catch (e) {
    yield put(ActionTypes.archiveArea.failure(e));
    notifyError(e as string);
  }
}

function* archiveAreaSaga() {
  yield takeLatest(ActionTypes.ARCHIVE_AREA, archiveArea);
}

function* getArchivedAreas({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getArchivedAreas,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
    );
    yield put(ActionTypes.getArchivedAreas.success(data));
  } catch (e) {
    yield put(ActionTypes.getArchivedAreas.failure(e));
  }
}

function* getArchivedAreasSaga() {
  yield takeLatest(ActionTypes.GET_ARCHIVED_AREAS, getArchivedAreas);
}

function* getUnarchivedAreas({ payload }: any) {
  // receives entire area, but in the future will receive only the id

  try {
    // const { data } = yield call(
    //   Api.getArchivedAreas,
    //   payload.storeId,
    //   payload.departmentId,
    //   payload.cameraId,
    //   payload.startDate,
    //   payload.endDate,
    // );
    yield put(ActionTypes.getUnArchivedAreas.success(payload.areas));
  } catch (e) {
    yield put(ActionTypes.getUnArchivedAreas.failure(e));
  }
}

function* getUnarchivedAreasSaga() {
  yield takeLatest(ActionTypes.GET_UNARCHIVED_AREAS, getUnarchivedAreas);
}

export default function* rootSaga() {
  yield all([
    fork(getStoreAreasSaga), fork(getDepartmentAreasSaga),
    fork(getCameraAreasSaga), fork(getDwellAnalysisSaga),
    fork(getOutsideTrafficSaga), fork(postAreasSaga),
    fork(deleteAreaSaga), fork(archiveAreaSaga),
    fork(getArchivedAreasSaga), fork(getUnarchivedAreasSaga),
    fork(getCameraAreasOfInterestSaga),
  ]);
}
