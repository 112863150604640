import React from 'react';
import { Card } from 'src/components/Card';
import { Typography } from 'src/components/Typography';
import UserMenu from './UserMenu';

interface Params {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  loadingHeader?: boolean;
}

const Header = ({ title, subtitle, loadingHeader, children }: Params) => {
  return (
    <header>
      <Card className="flex items-center justify-between w-full py-2 px-4">
        {loadingHeader ? (
          <div className="animate-pulse w-full bg-gray-200 my-[3px] p-4 rounded-lg shadow" />
        ) : (
          <>
            <div className="flex flex-col justify-center min-w-[240px]">
              <Typography variant="standard-h5" color="neutral-800">
                {title ? title.replace('- cam', '- cam ') : ''}
              </Typography>

              <Typography variant="caption-3" color="neutral-600">
                {subtitle || ''}
              </Typography>
            </div>

            <div className="flex items-center justify-center w-full">{children}</div>
          </>
        )}
        <UserMenu />
      </Card>
    </header>
  );
};
export default Header;
