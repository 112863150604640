import React from 'react';

import cx from 'classnames';
import type { ThemeColors } from 'src/types/theme';

export const VARIANTS = [
  'large-h1',
  'large-h2',
  'subtitle',
  'subtitle-underline',
  'standard-h1',
  'standard-h2',
  'standard-h3',
  'standard-h4',
  'standard-h5',
  'standard-h6',
  'standard-h2',
  'body-standard',
  'body-small',
  'large-button',
  'standard-button',
  'small-button',
  'input-label',
  'input-text',
  'caption-1',
  'caption-1-underline',
  'caption-2',
  'caption-2-underline',
  'caption-3',
  'caption-3-underline',
] as const;

type VariantType = typeof VARIANTS[number];

export type TypographyProps = React.HTMLAttributes<HTMLSpanElement> & {
  variant?: VariantType;
  tag?: string;
  color?: ThemeColors;
};

const DEFAULT_VARIANT = 'body-standard';
const DEFAULT_COLOR = 'neutral-800';

export const Typography = ({
  children,
  className,
  variant = DEFAULT_VARIANT,
  tag,
  color = DEFAULT_COLOR,
  ...props
}: TypographyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = React.useMemo(() => {
    if (tag) {
      return tag;
    }

    const [, possibleTag] = variant?.split?.('-') || [];

    if (possibleTag?.startsWith?.('h')) {
      return possibleTag;
    }

    return 'span';
  }, [tag, variant]);

  const isFontWeight700 = (
    [
      'standard-h1',
      'standard-h2',
      'standard-h3',
      'standard-h5',
    ] as VariantType[]
  ).includes(variant);

  const isFontWeight600 = (
    [
      'large-h1',
      'large-h2',
      'subtitle',
      'standard-h4',
      'caption-1-underline',
      'caption-2-underline',
      'large-button',
      'standard-button',
    ] as VariantType[]
  ).includes(variant);

  const isFontWeight500 = (
    ['body-standard', 'subtitle-underline', 'standard-h6'] as VariantType[]
  ).includes(variant);

  const isFontWeight400 = (
    [
      'caption-1',
      'body-small',
      'input-label',
      'input-text',
      'caption-2',
      'caption-3',
      'caption-3-underline',
      'small-button',
    ] as VariantType[]
  ).includes(variant);

  return (
    <Component
      className={cx(
        {
          [`text-${color}`]: !!color,
          'font-bold': isFontWeight700,
          'font-semibold': isFontWeight600,
          'font-medium': isFontWeight500,
          'font-normal': isFontWeight400,
          'text-[32px] leading-[38px]': variant === 'large-h1',
          'text-3xl': variant === 'large-h2',
          'text-lg leading-7': variant === 'subtitle',
          'text-lg leading-7 underline': variant === 'subtitle-underline',
          'text-2xl leading-7': variant === 'standard-h1',
          'text-[22px] leading-[26px]': variant === 'standard-h2',
          'text-xl': variant === 'standard-h3',
          'text-xl leading-[22px]': variant === 'standard-h4',
          'text-lg leading-[22px]': variant === 'standard-h5',
          'text-base leading-5': variant === 'standard-h6',
          'text-base': (
            ['body-standard', 'standard-button', 'input-text'] as VariantType[]
          ).includes(variant),
          'text-sm leading-5': variant === 'body-small',
          'text-sm leading-6': variant === 'small-button',
          'text-lg': variant === 'large-button',
          'text-base leading-[18px]': (
            ['caption-1', 'caption-1-underline'] as VariantType[]
          ).includes(variant),
          'text-sm': (
            ['caption-2', 'caption-2-underline'] as VariantType[]
          ).includes(variant),
          'text-xs': (
            ['caption-3', 'caption-3-underline', 'input-label'] as VariantType[]
          ).includes(variant),
        },
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
