import { axiosJsonInstance, axiosBlobInstance } from 'src/store/api';

export default {
  async getDepartments(startDate: any, endDate: any, storeId: any) {
    return axiosJsonInstance.get('departments/', {
      params: {
        start_date: startDate, // july 1
        end_date: endDate, // October 7
        store_id: storeId,
      },
    });
  },

  async getDepartmentStats(departmentId: any, startDate: any, endDate: any) {
    return axiosJsonInstance.get(`department/${departmentId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  async postDepartment(name: any, url: any, notifyUrl: any, storeId: any) {
    return axiosJsonInstance.post('departments/', {
      name,
      url,
      notify_url: notifyUrl,
      store: storeId,
    });
  },

  async updateDepartment(departmentId: any, dptName: any, dptUrl: any, dptNotifyUrl: any, storeId: any) {
    return axiosJsonInstance.put(`department/${departmentId}/`, {
      name: dptName,
      url: dptUrl,
      notify_url: dptNotifyUrl,
      store: storeId,
    });
  },

  async getDepartmentFloorplan(departmentId: any) {
    return axiosBlobInstance.get(`/e_search/department_floorplan/${departmentId}/`);
  },

  async updateDepartmentFloorplan(departmentId: any, departmentFloorplan: any) {
    return axiosJsonInstance.post(`/e_search/department_floorplan/${departmentId}/`, {
      floorplan: departmentFloorplan,
    });
  },
};
