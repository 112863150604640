import { getIntervalByDate, getTimestampOf } from './helpers';

export const getParametersAndPersistObject = (log = false) => {
  const root = JSON.parse(localStorage.getItem('persist:root') as string);
  if (root) {
    const { startDate, endDate } = JSON.parse(root.dateTime);
    const startDateTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
    const endDateTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));
    const persistObject = {} as any;
    const rootkeys = Object.keys(root);
    rootkeys.forEach((key) => {
      persistObject[key] = JSON.parse(root[key]);
    });
    const { genderFilter = '', ageFilter = '' } = persistObject.filter;
    const { interval = '' } = persistObject.graphs;
    const intervalAuto = getIntervalByDate(startDateTimestamp, endDateTimestamp);
    const { store, stores } = persistObject.stores;
    const storesIds = stores.map((store: any) => store.id);
    const { id: storeId = null } = store || null;
    const { id: cameraId = null } = persistObject.cameras.cameraStats;
    const objPersist = {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      genderFilter,
      ageFilter,
      interval,
      intervalAuto,
      storeId,
      storesIds,
      persistObject,
      cameraId,
    };
    if (log) {
      console.log(objPersist);
    }
    return objPersist;
  }
  return {
    startDate: '',
    endDate: '',
    genderFilter: '',
    ageFilter: '',
    interval: '',
    intervalAuto: '',
    storeId: null,
    storesIds: [],
    persistObject: {},
    cameraId: null,
  };
};
