export const UPDATE_GENDER = 'UPDATE_GENDER';
export const UPDATE_AGE = 'UPDATE_AGE';

export const updateGender = (data: any) => {
  return {
    type: UPDATE_GENDER,
    payload: data,
  };
};

export const updateAge = (data: any) => {
  return {
    type: UPDATE_AGE,
    payload: data,
  };
};
