import React from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { Dropdown } from 'src/components/Dropdown';
import { AREA_TYPES } from 'src/constants/areas';

export type Form = {
  name: string;
  type: string;
};

export type ModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

export const ModalFormArea = ({ onSave, isOpen, onClose }: ModalFormProps) => {
  const onSubmit = (data: any) => {
    data.min_point = [0.1, 0.2];
    data.max_point = [0.4, 0.6];
    onSave(data);
    onClose();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>();

  return (
    <Modal
      hideCloseButton
      title="Area"
      subTitle="Add a new area"
      isOpen={isOpen}
      onClose={onClose}
      className={cx('px-5 py-6')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
        <FormField label="Name" error={errors.name?.message}>
          <TextInput {...register('name')} icon="area" placeholder="e.g. Store" />
        </FormField>
        <FormField label="Setting">
          <Dropdown
            {...register('type')}
            options={AREA_TYPES}
            placeholder="Select Area Type"
          />
        </FormField>
        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={onClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              Add
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
