import React, { useEffect } from 'react';
import { Card } from 'src/components/Card';
import { CardIndicator, CardIndicatorProps } from 'src/components/CardIndicator';
import { Col } from 'src/components/Col';
import { Row } from 'src/components/Row';
import { getSimpleMinutesAndSeconds } from 'src/utils/helpers';
import { useSelector } from 'react-redux';
import { DoD } from 'src/interfaces/graph.interface';
import { PlotLineArea } from '../../PlotLineArea';

type IndicatorType = Omit<CardIndicatorProps, 'className'> & {
  data: any[];
};

const CameraIndicators: React.FC = () => {
  const [indicators, setIndicators] = React.useState<IndicatorType[]>([]);
  const { cameraStats, cameraStatsLoading } = useSelector((state: any) => state.cameras);

  const { allCameraGraphs: { all_customers_avg, graph_data, day_over_day_data } = [], allCameraGraphsLoading } =
    useSelector((state: any) => state.graphs);

  useEffect(() => {
    const { footfall_percentage } = cameraStats;
    const { data: totalFootfallGraph = [] } =
      graph_data && graph_data.length > 0
        ? graph_data.find((graph: any) => graph.name === 'cam_total_footfall_graph')
        : [];
    const { data: avgTimeGraph = [] } =
      graph_data && graph_data.length > 0
        ? graph_data.find((graph: any) => graph.name === 'cam_average_time_graph')
        : [];

    const camDoD = ((): DoD => {
      const values: DoD = {
        footfallDoD: {
          percentage: '0',
          icon: 'empty',
        },
        avgTimeDoD: {
          percentage: '0',
          icon: 'empty',
        },
      };
      if (day_over_day_data && day_over_day_data.length > 0) {
        const dataFootfall =
          day_over_day_data.find((graph: any) => graph[0] === 'store_day_over_day_footfall_graph') || [];
        const dataAvgTime =
          day_over_day_data.find((graph: any) => graph[0] === 'store_day_over_day_average_time_graph') || [];
        return {
          footfallDoD:
            dataFootfall.length && typeof dataFootfall[1].percentage === 'number'
              ? {
                percentage: dataFootfall[1].percentage.toFixed(0),
                icon: dataFootfall[1].percentage > 0 ? 'up' : 'down',
              }
              : values.footfallDoD,
          avgTimeDoD:
            dataAvgTime.length && typeof dataFootfall[1].percentage === 'number'
              ? {
                percentage: dataAvgTime[1].percentage.toFixed(0),
                icon: dataAvgTime[1].percentage > 0 ? 'up' : 'down',
              }
              : values.avgTimeDoD,
        };
      }
      return values;
    })();
    const newIndicators = [
      {
        label: 'Shoppers By Camera',
        tooltip:
          "Percentage of shoppers seen in this camera out of the Total Footfall in Store by user's selected dates and hours.",
        bottom: {},
        // Shows 100% for canary wharf camera 25 (cam. 3).
        value: cameraStats.store.id === 4 && cameraStats.id === 25 ? '100%' : footfall_percentage,
        icon: camDoD.footfallDoD.icon,
        percent: camDoD.footfallDoD.percentage,
        data: [
          {
            name: 'shoppers_by_camera',
            y: totalFootfallGraph.length === 1 ? [...totalFootfallGraph, 0] : totalFootfallGraph,
            hoverinfo: 'none',
            fill: 'tozeroy',
            fillcolor: 'rgba(23, 153, 66, 0.1)',
            line: { color: '#27AE60' },
            mode: 'lines',
          },
        ],
      },
      {
        label: 'Average Visit Time',
        tooltip: "Average Time people have spent in this camera's view angle at user selected dates and hours.",
        icon: camDoD.avgTimeDoD.icon,
        percent: camDoD.avgTimeDoD.percentage,
        bottom: {},
        value: all_customers_avg ? getSimpleMinutesAndSeconds(all_customers_avg) : '0',
        data: [
          {
            name: 'average_visit_time',
            y: avgTimeGraph?.length === 1 ? [...avgTimeGraph, 0] : avgTimeGraph,
            hoverinfo: 'none',
            fill: 'tozeroy',
            fillcolor: 'rgba(23, 153, 66, 0.1)',
            line: { color: '#27AE60' },
            mode: 'lines',
          },
        ],
      },
    ];
    setIndicators(newIndicators);
  }, [all_customers_avg, cameraStats, day_over_day_data, graph_data]);

  if (allCameraGraphsLoading || cameraStatsLoading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 my-3">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
          <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
          <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      {indicators.map((item, idx) => (
        <Col size="6" key={`indicator-${item.value}-${idx + 1}`}>
          <Card className="flex-1 justify-between">
            <CardIndicator {...item} toggle={item.toggle}>
              <PlotLineArea data={item.data} />
            </CardIndicator>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CameraIndicators;
