/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import cx from 'classnames';

import { AvailableIllustrations, ILLUSTRATIONS } from './constants';

export type IllustrationProps = {
  name: AvailableIllustrations;
  className?: string;
};

export const Illustration = React.memo(
  ({ name, className }: IllustrationProps) => {
    const imageProps = React.useMemo(() => {
      const { x1, x2, alt } = ILLUSTRATIONS[name];

      return {
        src: x1,
        srcSet: `${x1} 1x, ${x2} 2x`,
        alt,
      };
    }, [name]);

    return (
      <picture>
        <img {...imageProps} className={cx(className)} />
      </picture>
    );
  },
);
