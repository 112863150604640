import React from 'react';

type Option = {
  title: string;
  key: string;
};

type UseSelectFilterProps = {
  defaultSelected?: string;
  options: Option[];
  // eslint-disable-next-line no-unused-vars
  onSelect?: (value: string) => void;
};

export function useSelectFilter({
  options,
  defaultSelected,
  onSelect,
}: UseSelectFilterProps) {
  const [activeFilter, setActiveFilter] = React.useState(defaultSelected);

  const handleActive = React.useCallback(
    (filter: string) => {
      setActiveFilter(filter);
      onSelect?.(filter);
    },
    [setActiveFilter, onSelect],
  );

  const filters = React.useMemo(() => {
    return options.map((item) => {
      return {
        ...item,
        active: item.key === activeFilter,
        onclick: () => handleActive(item.key),
      };
    });
  }, [options, handleActive, activeFilter]);

  return [filters];
}
