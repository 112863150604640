import React from 'react';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';

import { Button } from 'src/components/Button';
import { FormField } from 'src/components/FormField';
import { TextInput } from 'src/components/TextInput';
import { Typography } from 'src/components/Typography';
import AuthLayout from 'src/components/layout/auth';
import { Icon } from 'src/components/Icon';

import { history } from 'src/utils/history';

import { MdOutlineArrowBack } from 'react-icons/md';

import styles from './styles.module.css';

type Form = {
  email: string;
};

type AuthProps = {
  auth: {
    loading: boolean;
  };
};

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();
  const dispatch = useDispatch();

  const onSubmit = (data: Form) => {
    dispatch(ActionTypes.postPasswordReset.request(data.email));
  };
  const loading = useSelector((state: AuthProps) => state.auth.loading);

  return (
    <AuthLayout title="Recover Password" subtitle="Enter email and we will send you recovery link.">
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        <div className={styles.form}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" onClick={() => history.push('/login')} className={styles.buttonBack}>
            <MdOutlineArrowBack />
          </button>
          <FormField label="Email" error={errors.email?.message}>
            <TextInput
              placeholder="e.g. name@mail.com"
              error={errors.email}
              {...register('email', {
                required: 'Email is required',
                minLength: {
                  message: 'Min 6 characters',
                  value: 6,
                },
              })}
            />
          </FormField>

          <Button className="h-[50px] mt-5" type="submit" disabled={loading}>
            <Typography variant="standard-button" color="white">
              {!loading ? 'Recover' : 'Recovering...'}
            </Typography>
            <Icon icon="left-to-right" color="white" size="sm" className="mt-1 ml-3" />
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
