import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

type SizeCol =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

export type ColProps = {
  className?: string;
  size?: SizeCol;
  horizontalAlign?: 'start' | 'center' | 'end';
  verticalAlign?: 'start' | 'center' | 'end';
  children?: React.ReactNode;
};

export const Col = React.forwardRef<any, ColProps>(
  (
    { size, children, className, verticalAlign, horizontalAlign, ...props },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cx(
          '',
          styles.col,
          {
            [styles[`col-${size}`]]: size,
            [`justify-${verticalAlign}`]: verticalAlign,
            [`items-${horizontalAlign}`]: horizontalAlign,
          },
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);
