import React from 'react';
import cx from 'classnames';
import { Typography } from 'src/components/Typography';
import { If } from 'src/components/If';
import { Icon } from 'src/components/Icon';

import styles from './styles.module.css';
import { Tooltip } from '../Tooltip';

interface ActionParams {
  title: string;
  onclick: () => void;
  active?: boolean;
  disabled?: boolean;
  disabledHint?: string;
}

export interface CardProps {
  children?: React.ReactNode;
  className?: string;
  title?: string | React.ReactElement;
  subTitle?: string;
  actionsGroup?: ActionParams[];
  iconsGroup?: React.ReactNode;
  goBack?: boolean;
  padding?: boolean;
}

export const Card = ({
  children,
  className,
  title,
  subTitle,
  actionsGroup,
  iconsGroup,
  goBack,
  padding,
}: CardProps) => {
  const showCardHeader = title || subTitle || actionsGroup || iconsGroup || goBack;
  //  ;
  return (
    <div className={cx('bg-white rounded-lg shadow', { 'p-4': padding }, className)}>
      {showCardHeader && (
        <div className={cx('flex justify-between', { 'p-4 pb-0': !padding })}>
          <div className="mb-5">
            <Typography variant="standard-h5" color="neutral-900">
              {title}
            </Typography>
            <Typography variant="caption-3" color="neutral-600" className="mt-2">
              {subTitle}
            </Typography>
          </div>
          <div className="flex h-10 gap-3">
            <If condition={iconsGroup}>
              <div className="justify-end self-center">{iconsGroup}</div>
            </If>
            <If condition={actionsGroup}>
              <div className="flex gap-2 flex-row center bg-gray-100 rounded-lg pt-[1px] h-[27px]">
                {actionsGroup &&
                  actionsGroup.map((action) => (
                    <Tooltip
                      key={JSON.stringify(action)}
                      tooltipText={action.disabled ? action.disabledHint ?? '' : ''}
                      tooltipPlacement="top"
                      width={100}
                    >
                      <button
                        type="button"
                        onClick={action.onclick}
                        className={cx('px-2', {
                          'bg-white rounded-lg px-2': action.active,
                          'cursor-not-allowed': action.disabled,
                        })}
                        disabled={action.disabled}
                      >
                        <Typography variant="caption-3">{action.title}</Typography>
                      </button>
                    </Tooltip>
                  ))}
              </div>
            </If>
            <If condition={goBack}>
              <Icon icon="close" className="text-gray-500 text-lg" />
            </If>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
