import { createApiAction } from 'src/utils/helpers';

export const GET_AREAS_OF_INTEREST = 'GET_AREAS_OF_INTEREST';
export const GET_AREAS_OF_INTEREST_SUCCESS = 'GET_AREAS_OF_INTEREST_SUCCESS';
export const GET_AREAS_OF_INTEREST_FAILURE = 'GET_AREAS_OF_INTEREST_FAILURE';

export const POST_AREAS_OF_INTEREST = 'POST_AREAS_OF_INTEREST';
export const POST_AREAS_OF_INTEREST_SUCCESS = 'POST_AREAS_OF_INTEREST_SUCCESS';
export const POST_AREAS_OF_INTEREST_FAILURE = 'POST_AREAS_OF_INTEREST_FAILURE';

export const DELETE_AREA_OF_INTEREST = 'DELETE_AREA_OF_INTEREST';
export const DELETE_AREA_OF_INTEREST_SUCCESS =
  'DELETE_AREA_OF_INTEREST_SUCCESS';
export const DELETE_AREA_OF_INTEREST_FAILURE =
  'DELETE_AREA_OF_INTEREST_FAILURE';

export const getAreasOfInterest = createApiAction(GET_AREAS_OF_INTEREST);
export const postAreasOfInterest = createApiAction(POST_AREAS_OF_INTEREST);
export const deleteAreaOfInterest = createApiAction(DELETE_AREA_OF_INTEREST);
