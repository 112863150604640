import React from 'react';
import cx from 'classnames';

export type BreadcrumbItem = {
  label: string;
  href?: string;
};

export type BreadcrumbProps = {
  list: BreadcrumbItem[];
  className?: string;
};

export const Breadcrumb = ({ list, className }: BreadcrumbProps) => {
  return (
    <div className={cx('flex gap-1 flex-wrap', className)}>
      {list.map((item, idx) => {
        const key = `breadcrumb-${item.label}-${idx}`;

        const isLastItem = idx === list.length - 1;

        return (
          <div key={key}>
            <a
              className={cx('text-neutral-400 text-sm-2', {
                'text-neutral-900': isLastItem,
                'hover:text-accent': !isLastItem,
              })}
              href={item.href}
            >
              {item.label}
            </a>
            {!isLastItem && <span className={cx('text-neutral-900')}>{' > '}</span>}
          </div>
        );
      })}
    </div>
  );
};

Breadcrumb.defaultProps = {
  list: [],
} as BreadcrumbProps;
