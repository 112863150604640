export function groupBy<T = any>(
  objectArray: T[],
  property: string,
): { [key: string]: T[] } {
  return (objectArray as unknown as any[]).reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);
    return acc;
  }, {});
}
