import { axiosJsonInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async getStoreTills(storeId: any, startDate: any, endDate: any, genderFilter?: any, ageFilter?: any) {
    return axiosJsonInstance.get('tills/', {
      params: {
        store_id: storeId,
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getDepartmentTills(storeId: any, departmentId: any, startDate: any, endDate: any, genderFilter?: any, ageFilter?: any) {
    return axiosJsonInstance.get('tills/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getCameraTills(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('tills/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async postTills(storeId: any, departmentId: any, cameraId: any, data: any) {
    return axiosJsonInstance.post('tills/', {
      store_id: storeId,
      department_id: departmentId,
      camera_id: cameraId,
      data,
    });
  },

  async deleteTill(tillId: any) {
    return axiosJsonInstance.delete(`till/${tillId}/`);
  },
};
