import React from 'react';

import cx from 'classnames';
import { Icon, TIcon } from 'src/components/Icon';
import { If } from 'src/components/If';
import { Typography, TypographyProps } from 'src/components/Typography';
import { PopUp, PopUpProps } from 'src/components/PopUp';
import { Card } from 'src/components/Card';
import { useModal } from 'src/hooks/useModal';

export type TagOption = {
  icon: TIcon;
  label: string;
  value: string;
};

export type TagProps = Partial<TypographyProps> & {
  className?: string;
  icon?: TIcon;
  active?: boolean;
  onClickIcon?: () => void;
  dual?: {
    options: TagOption[];
    title: string;
    subTitle?: string;
    selected?: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string) => void;
    placement?: PopUpProps['placement'];
  };
};

export const Tag: React.FC<TagProps> = ({ children, className, icon, active, onClickIcon, dual, ...props }) => {
  const [isOpen, onClose, onOpen] = useModal();
  const hasIcon = !!icon;

  const selectedOption = React.useMemo(() => dual?.options.find((item) => item.value === dual.selected), [dual]);

  const CardOptions = (
    <Card title={dual?.title} subTitle={dual?.subTitle} padding>
      <div className={cx('flex flex-col gap-1')}>
        {dual?.options.map((item) => {
          return (
            <button
              className={cx('flex w-full gap-2 items-center px-2 rounded', 'hover:bg-primary-super-light')}
              key={item.value}
              onClick={() => {
                dual?.onChange?.(item.value);
                onClose();
              }}
              type="button"
            >
              <Icon icon={item.icon} color="primary" />
              <Typography variant="body-standard">{item.label}</Typography>
            </button>
          );
        })}
      </div>
    </Card>
  );

  return (
    <Typography
      className={cx(
        'flex items-center gap-2 border rounded-full w-fit px-3',
        'hover:bg-primary-super-light hover:border-primary',
        {
          'bg-primary-super-light border-primary': active,
          'bg-neutral-50 border-neutral-100': !active,
          'py-1': hasIcon,
          'py-2': !hasIcon,
        },
        className,
      )}
      variant={hasIcon ? 'caption-2' : 'caption-3'}
      color={active ? 'primary-dark' : 'neutral-800'}
      {...props}
    >
      {children}

      <If condition={dual}>
        <PopUp isOpen={isOpen} onClose={onClose} element={CardOptions} placement={dual?.placement || 'top-start'}>
          <button
            type="button"
            className={cx(
              'flex items-center gap-2 border rounded-full w-fit px-3',
              'bg-neutral-100 border-neutral-600 hover:bg-primary-super-light hover:border-primary',
              'min-h-[1.75rem]',
            )}
            onClick={onOpen}
          >
            {selectedOption?.icon && <Icon icon={selectedOption.icon} color="neutral-800" />}

            <Typography>{selectedOption?.label}</Typography>

            <Icon icon="arrow-down" />
          </button>
        </PopUp>
      </If>

      <If condition={hasIcon}>
        <Icon
          tag="button"
          onClick={() => onClickIcon?.()}
          icon={icon as TIcon}
          color={active ? 'primary-dark' : 'neutral-400'}
        />
      </If>
    </Typography>
  );
};
