/* eslint-disable indent */
import { TIconsType } from 'src/components/Icon/constants';
import React from 'react';
import { Toggle } from 'src/components/Toggle';
import Plot from 'src/components/plotly';
import { Icon } from 'src/components/Icon';
import { Typography } from 'src/components/Typography';
import { If } from 'src/components/If';
import { convertIntervalDateDays } from 'src/utils/helpers';
import { Tooltip } from 'src/components/Tooltip';

import { useDispatch, useSelector } from 'react-redux';

import * as ActionTypes from 'src/store/actions';

type Params = {
  data: any[];
  title: string;
  value: string;
  icon: TIconsType;
  percent: string;
  name?: string;
  tooltip?: string;
};

const IndicatorWithPlot = ({ data, name, title, value, percent, icon, tooltip }: Params) => {
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { graphPerformance } = useSelector((state: any) => state.graphs);
  const dispatch = useDispatch();

  const layout = {
    margin: {
      t: 0,
      r: 0,
      b: 0,
      l: 10,
    },
    height: 70,
    width: 300,
    showlegend: false,
    xaxis: {
      autotick: false,
      fixedrange: true,
      showgrid: true,
      showline: false,
      showticklabels: false,
      zeroline: false,
      visible: (data[0].y && data[0].y?.length) || false,
    },
    yaxis: {
      visible: false,
      autotick: true,
      fixedrange: false,
      showgrid: false,
      showline: false,
      // showticklabels: false,
      zeroline: false,
    },
    annotations:
      data[0].y && data[0].y?.length < 1
        ? [
          {
            text: 'No matching data found',
            xref: 'paper',
            yref: 'paper',
            showarrow: false,
            font: { size: 14, color: '#3F404F' },
          },
        ]
        : [],
  };

  const config = {
    displayModeBar: false,
  };

  function handleChangeLine(e: any) {
    dispatch(
      ActionTypes.toggleLineGraph({
        axis: name === 'footfall' ? 'xaxis' : 'yaxis',
        checked: e,
      }),
    );
  }

  const isShowDoD = Number(percent) !== 0;

  return (
    <div className="flex items-end gap-4 p-4">
      <div className="flex flex-col gap-1">
        <div className="text-sm leading-4 flex justify-between">
          <Typography variant="body-small">{title}</Typography>
          {tooltip && (
            <Tooltip tooltipText={tooltip} tooltipPlacement="top" width={250}>
              <Icon icon="circle-warning" className="mt-1 m-0 text-gray-500 text-xs" />
            </Tooltip>
          )}
        </div>
        <Typography variant="large-h1" className="text-xl">
          {value}
        </Typography>

        {data[0].y && data[0].y.length > 0 && (
          <div className="flex items-center gap-2">
            <If condition={icon}>
              <Icon icon={icon} color="primary" />
            </If>
            <div className="text-base">
              <span className="">
                {`${percent}`}
                %
              </span>
              <span className="ml-1 text-neutral-400">{convertIntervalDateDays(startDate, endDate)}</span>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-end w-full">
        <Plot data={data} layout={layout} config={config} />
        <Toggle
          justifyEnd
          checked={graphPerformance[name === 'footfall' ? 'xaxis' : 'yaxis'].visible}
          label="Plot at chart"
          className="text-xs my-2"
          data-name="avg"
          onChange={(e) => handleChangeLine(e)}
        />
      </div>
    </div>
  );
};

export default IndicatorWithPlot;
