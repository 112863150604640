import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getDepartments({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDepartments,
      payload.startDate,
      payload.endDate,
      payload.shopId,
    );
    yield put(ActionTypes.getDepartments.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getDepartments.failure(e));
  }
}

function* getDepartmentSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENTS, getDepartments);
}

function* getDepartmentStats({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDepartmentStats,
      payload.departmentId,
      payload.startDate,
      payload.endDate,
    );
    yield put(ActionTypes.getDepartmentStats.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getDepartmentStats.failure(e));
  }
}

function* getDepartmentStatsSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENT_STATS, getDepartmentStats);
}

function* postDepartment({ payload }: any) {
  try {
    const { data } = yield call(
      Api.postDepartment,
      payload.name,
      payload.url,
      payload.notifyUrl,
      payload.storeId,
    );
    yield put(ActionTypes.postDepartment.success(data));
    notifyInfo('Department created');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postDepartment.failure(e));
  }
}

function* postDepartmentSaga() {
  yield takeLatest(ActionTypes.POST_DEPARTMENT, postDepartment);
}

function* updateDepartment({ payload }: any) {
  try {
    const { data } = yield call(
      Api.updateDepartment,
      payload.departmentId,
      payload.dptName,
      payload.dptUrl,
      payload.dptNotifyUrl,
      payload.storeId,
    );
    yield put(ActionTypes.updateDepartment.success(data));
    notifyInfo('Department updated!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateDepartment.failure(e));
  }
}

function* updateDepartmentSaga() {
  yield takeLatest(ActionTypes.UPDATE_DEPARTMENT, updateDepartment);
}

function* getDepartmentFloorplan({ payload }: any) {
  try {
    const { data } = yield call(Api.getDepartmentFloorplan, payload.departmentId);
    const imageUrl = URL.createObjectURL(data);
    yield put(ActionTypes.getDepartmentFloorplan.success(imageUrl));
  } catch (e) {
    yield put(ActionTypes.getDepartmentFloorplan.failure(e));
  }
}

function* getDepartmentFloorplanSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENT_FLOORPLAN, getDepartmentFloorplan);
}

function* updateDepartmentFloorplan({ payload }: any) {
  try {
    const { data } = yield call(Api.updateDepartmentFloorplan, payload.departmentId, payload.departmentFloorplan);
    yield put(ActionTypes.updateDepartmentFloorplan.success(data));
    notifyInfo('Floorplan updated!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateDepartmentFloorplan.failure(e));
  }
}

function* updateDepartmentFloorplanSaga() {
  yield takeLatest(ActionTypes.UPDATE_DEPARTMENT_FLOORPLAN, updateDepartmentFloorplan);
}

export default function* rootSaga() {
  yield all([
    fork(getDepartmentSaga),
    fork(getDepartmentStatsSaga),
    fork(postDepartmentSaga),
    fork(updateDepartmentSaga),
    fork(getDepartmentFloorplanSaga),
    fork(updateDepartmentFloorplanSaga),
  ]);
}
