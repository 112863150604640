import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getLocations({ payload }: any) {
  try {
    const { data } = yield call(Api.getLocations, payload);
    yield put(ActionTypes.getLocations.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getLocations.failure(e));
  }
}

function* getLocationsSaga() {
  yield takeLatest(ActionTypes.GET_LOCATION, getLocations);
}

function* postLocation({ payload }: any) {
  try {
    const { data } = yield call(Api.postLocation, payload);
    yield put(ActionTypes.postLocation.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postLocation.failure(e));
  }
}

function* postLocationSaga() {
  yield takeLatest(ActionTypes.POST_LOCATION, postLocation);
}

export default function* rootSaga() {
  yield all([fork(getLocationsSaga), fork(postLocationSaga)]);
}
