import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getAreasOfInterest({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAreasOfInterest,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
    );
    yield put(ActionTypes.getAreasOfInterest.success(data));
  } catch (e) {
    yield put(ActionTypes.getAreasOfInterest.failure(e));
  }
}

function* getAreasOfInterestSaga() {
  yield takeLatest(ActionTypes.GET_AREAS_OF_INTEREST, getAreasOfInterest);
}

function* postAreasOfInterest({ payload }: any) {
  try {
    const { data } = yield call(
      Api.postAreasOfInterest,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.data,
    );
    yield put(ActionTypes.postAreasOfInterest.success(data));
    notifyInfo('Areas Of Interest created!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postAreasOfInterest.failure(e));
  }
}

function* postAreasOfInterestSaga() {
  yield takeLatest(ActionTypes.POST_AREAS_OF_INTEREST, postAreasOfInterest);
}

function* deleteAreaOfInterest({ payload }: any) {
  try {
    const { data } = yield call(Api.deleteAreaOfInterest, payload.areaOfInterestId);
    yield put(ActionTypes.deleteAreaOfInterest.success(data));
    notifyInfo('Area Of Interest deleted!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.deleteAreaOfInterest.failure(e));
  }
}

function* deleteAreaOfInterestSaga() {
  yield takeLatest(ActionTypes.DELETE_AREA_OF_INTEREST, deleteAreaOfInterest);
}

export default function* rootSaga() {
  yield all([
    fork(getAreasOfInterestSaga),
    fork(postAreasOfInterestSaga),
    fork(deleteAreaOfInterestSaga),
  ]);
}
