import { createApiAction } from 'src/utils/helpers';

export const GET_STORE_TILLS = 'GET_STORE_TILLS';
export const GET_STORE_TILLS_SUCCESS = 'GET_STORE_TILLS_SUCCESS';
export const GET_STORE_TILLS_FAILURE = 'GET_STORE_TILLS_FAILURE';

export const GET_DEPARTMENT_TILLS = 'GET_DEPARTMENT_TILLS';
export const GET_DEPARTMENT_TILLS_SUCCESS = 'GET_DEPARTMENT_TILLS_SUCCESS';
export const GET_DEPARTMENT_TILLS_FAILURE = 'GET_DEPARTMENT_TILLS_FAILURE';

export const GET_CAMERA_TILLS = 'GET_CAMERA_TILLS';
export const GET_CAMERA_TILLS_SUCCESS = 'GET_CAMERA_TILLS_SUCCESS';
export const GET_CAMERA_TILLS_FAILURE = 'GET_CAMERA_TILLS_FAILURE';

export const POST_TILLS = 'POST_TILLS';
export const POST_TILLS_SUCCESS = 'POST_TILLS_SUCCESS';
export const POST_TILLS_FAILURE = 'POST_TILLS_FAILURE';

export const DELETE_TILL = 'DELETE_TILL';
export const DELETE_TILL_SUCCESS = 'DELETE_TILL_SUCCESS';
export const DELETE_TILL_FAILURE = 'DELETE_TILL_FAILURE';

export const getStoreTills = createApiAction(GET_STORE_TILLS);
export const getDepartmentTills = createApiAction(GET_DEPARTMENT_TILLS);
export const getCameraTills = createApiAction(GET_CAMERA_TILLS);
export const postTills = createApiAction(POST_TILLS);
export const deleteTill = createApiAction(DELETE_TILL);
