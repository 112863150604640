import React from 'react';
import cx from 'classnames';
import { Icon, TIcon, IconProps } from '../Icon';

export interface TextInputProps
  extends Partial<React.InputHTMLAttributes<HTMLInputElement>> {
  sizeModifier?: 'small' | 'large';
  error?: unknown;
  icon?: TIcon;
  iconColor?: IconProps['color'];
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, sizeModifier, icon, ...props }, ref) => {
    const { isLarge, isSmall, sizeIcon } = React.useMemo(
      () => ({
        isSmall: sizeModifier === 'small',
        isLarge: sizeModifier === 'large',
        sizeIcon: sizeModifier === 'large' ? 'lg' : ('md' as IconProps['size']),
      }),
      [sizeModifier],
    );

    const hasIcon = !!icon;

    return (
      <div className="relative flex w-full flex-1 items-center">
        <input
          ref={ref}
          className={cx(
            'shadow-sm outline-none',
            'transition duration-500',
            'border-b border-b-neutral-100',
            'p-2 w-full',
            'text-neutral-400',
            {
              'text-sm': isSmall,
              'text-base': isLarge,
              'hover:border-b-neutral-200 focus-within:text-neutral-900 focus-within:border-b-neutral-400':
                !props.error && !props.disabled,
              'text-red border-red': props.error,
              'bg-neutral-100': props.disabled,
              'bg-neutral-50': !props.disabled,
              'pl-8': hasIcon,
            },
            className,
          )}
          {...props}
        />
        {!!icon && (
          <Icon
            className={cx('absolute left-1')}
            icon={icon}
            size={sizeIcon}
            color="primary"
          />
        )}
      </div>
    );
  },
);

TextInput.defaultProps = {
  sizeModifier: 'small',
  icon: 'edit',
} as TextInputProps;
