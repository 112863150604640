import cx from 'classnames';
import React, { useMemo } from 'react';
import { Card } from 'src/components/Card';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import Plot from 'src/components/plotly';
import { Tooltip } from 'src/components/Tooltip';
import { Typography } from 'src/components/Typography';
import { modeBarButtonsToRemove } from 'src/utils/constants';

import style from './style.module.css';

type Params = {
  data: number[];
  categories: string[];
  intervalLabel?: string;
  indicator?: any;
  labelDoD?: string;
};

export default function TimeSpent({ data, categories, intervalLabel, indicator, labelDoD }: Params) {
  const mappedTimespent = useMemo(() => {
    const timespentData = [] as any;
    const timespentCategories = [] as any;
    if (data.length && categories.length) {
      data.forEach((item: number, i: number) => {
        if (item === 0) {
          return;
        }
        timespentData.push(data[i]);
        timespentCategories.push(categories[i]);
      });
      return {
        data: timespentData,
        categories: timespentCategories,
      };
    }
    return {
      data: [],
      categories: [],
    };
  }, [data, categories]);

  const timespentGraphDownloadCSV = useMemo(() => {
    const timespentData = [] as any;
    const { data, categories } = mappedTimespent;
    timespentData.push(['Time Spent', 'Percentage']);
    if (data) {
      categories.forEach((cat: number, i: number) => {
        timespentData.push([cat, `${data[i]}%`]);
      });
    }
    return timespentData;
  }, [mappedTimespent]);

  const infoIndicator = indicator && (
    <>
      <Typography variant="large-h1" className="mb-2">
        {indicator.value}
      </Typography>
      <div className="flex gap-2">
        <Icon icon={indicator.icon} />
        <Typography variant="caption-2">
          {indicator.percent}
          %
        </Typography>
        <Typography variant="caption-2" className="text-neutral-200">
          {labelDoD}
        </Typography>
      </div>
      <hr className="bg-neutral-600 mt-5" />
    </>
  );

  return (
    <Card
      className="w-full relative"
      title="Time Spent In Store"
      subTitle="The feature shows you time spent in store."
      iconsGroup={(
        <Tooltip tooltipText="Download" tooltipPlacement="top">
          {timespentGraphDownloadCSV && (
            <DownloadCSV filename={`Time Spent In Store - ${intervalLabel}`} data={timespentGraphDownloadCSV}>
              <Icon icon="download" className="text-xl" />
            </DownloadCSV>
          )}
        </Tooltip>
      )}
    >
      <div className="flex items-center gap-3 px-5">
        <Plot
          data={[
            {
              values: mappedTimespent.data.map((val: number) => val.toFixed()),
              labels: mappedTimespent.categories,
              type: 'pie',
              hoverinfo: 'label+percent',
              marker: {
                colors: ['#53D2C5', '#665BFF', '#DDF6F3', '#FDC132', '#1F77B4'],
              },
              // hovertemplate: `${isHeatmapTotal ? 'Total footfall' : 'Average Time Spent'}: %{z}<extra></extra>`,
            },
          ]}
          layout={{
            width: mappedTimespent.data.length > 0 ? 400 : 'auto',
            height: 360,
            showlegend: false,
            legend: { y: 0.3, x: 1, font: { size: 14 } },
            margin: { l: 0, r: mappedTimespent.data.length > 0 ? 200 : 0, t: 70, b: 70 },
            annotations:
              mappedTimespent.data.length <= 0
                ? [
                  {
                    text: 'No matching data found',
                    xref: 'paper',
                    yref: 'paper',
                    showarrow: false,
                    font: { size: 14, color: '#3F404F' },
                  },
                ]
                : [],
          }}
          config={{
            displaylogo: false,
            modeBarButtonsToRemove,
          }}
        />
        {mappedTimespent.data.length > 0 && (
          <div className={`${cx(style.customLegend)} absolute right-[30px] top-[240px]`}>
            {/* {infoIndicator} */}
            {mappedTimespent.data.length > 0 && (
              <div className={style.customLegendValues}>
                {mappedTimespent.data.map((time: number, index: number) => (
                  <div className={style.legendItem} key={Math.random() * 1000}>
                    <span className={style.legendText}>
                      <span>{mappedTimespent.categories[index]}</span>
                    </span>
                    <span className={style.legendPercent}>
                      {`${(
                        (time / mappedTimespent.data.reduce((prev: number, current: number) => prev + current)) *
                        100
                      ).toFixed()}%`}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
