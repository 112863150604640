import React, { useEffect, useState } from 'react';
import { Card } from 'src/components/Card';
import { Typography } from 'src/components/Typography';
import cx from 'classnames';
import moment from 'moment-timezone';
import { BsCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { getLastAlive } from './api';

const APIStatusCard: React.FC = () => {
  const [storeData, setData] = useState<
    {
      store: string;
      status: boolean;
      lastAlive: string;
    }[]
  >([]);

  const fetchData = async () => {
    getLastAlive()
      .then((response) => {
        const dataObject = [
          {
            store: 'Canary Wharf',
            status: response?.data?.canary?.alive === 'true',
            lastAlive: moment.unix(Number(response.data.canary.last_alive)).format('MM/DD/YYYY HH:mm:ss'),
          },
          {
            store: 'Sloane Square',
            status: response.data.sloane.alive === 'true',
            lastAlive: moment.unix(Number(response.data.sloane.last_alive)).format('MM/DD/YYYY HH:mm:ss'),
          },
        ];
        setData(dataObject);
      })
      .catch((err) => {
        setData([]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cols = [
    {
      title: 'Store',
      align: '',
    },
    {
      title: 'Status',
      align: '',
    },
    {
      title: 'Last seen Online',
      align: '',
    },
  ];

  return (
    <Card title="Status" subTitle="View all stores status" padding>
      <div className="flex flex-col">
        <table className="w-full">
          <thead className="border-b-2 border-b-neutral-100 ">
            <tr>
              {cols.map((col) => (
                <Typography
                  variant="caption-3"
                  color="neutral-600"
                  tag="td"
                  className={cx('px-2 py-1', {
                    'text-left': true,
                    'text-right': col.align === 'right',
                    'text-center': col.align === 'center',
                  })}
                  key={col.title}
                >
                  {col.title}
                </Typography>
              ))}
            </tr>
          </thead>
          <tbody>
            {storeData.length <= 0 && (
              <Typography
                variant="caption-2"
                tag="td"
                className={cx('p-2', {
                  'text-neutral-800': true,
                })}
              >
                No data
              </Typography>
            )}

            {storeData.length > 0 &&
              storeData.map((store) => (
                <tr
                  className={cx('w-full transition-colors duration-100', {
                    'hover:bg-primary-super-light': true,
                    'border-l-4 border-l-green': store.status === true,
                    'border-l-4 border-l-red': store.status === false,
                  })}
                  key={store.store}
                >
                  <Typography
                    variant="caption-2"
                    tag="td"
                    className={cx('p-2', {
                      'text-neutral-800': true,
                    })}
                  >
                    {store.store}
                  </Typography>

                  <Typography variant="caption-2" tag="td" className="p-2">
                    <span
                      className="mb-2"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {store.status === true ? (
                        <>
                          <BsCheckCircleFill className="mr-1" color="rgb(34 197 94)" />
                          Operational
                        </>
                      ) : (
                        <>
                          <BsFillExclamationCircleFill className="mr-1" color="rgb(153 27 27)" />
                          Offline
                        </>
                      )}
                    </span>
                  </Typography>

                  <Typography
                    variant="caption-2"
                    tag="td"
                    className={cx('p-2', {
                      'text-neutral-800': true,
                    })}
                  >
                    {store.lastAlive}
                  </Typography>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default APIStatusCard;
