import React from 'react';
import { TIconsType } from 'src/components/Icon/constants';
import { Icon } from 'src/components/Icon';
import { If } from 'src/components/If';
import { Typography } from 'src/components/Typography';

interface Params {
  icon: TIconsType;
  title: string;
  description: string;
}

const SummaryIndicators = ({ icon, title, description }: Params) => (
  <div className="flex">
    <If condition={icon}>
      <Icon icon={icon} size="sm" color="primary" className="mt-1 mr-2" />
    </If>
    <div className="flex flex-col">
      <Typography variant="caption-3" className="text-neutral-600">
        {title}
      </Typography>
      <Typography variant="standard-h6" className="text-neutral-600 mt-1">
        {description}
      </Typography>
    </div>
  </div>
);

export default SummaryIndicators;
