import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;

/**
 * DRF returns data that passed through a serializer as a dict which can be
 * transformed directly into JSON
 */
const axiosJsonInstance = Axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
});

/**
 * we only store our images in the backend, we do not host them, this is
 * important because this means we need to pass
 * the image itself to the ui for it to be displayed. This can not be done
 *  with json because we are passing a raw
 * binary string so we use arraybuffer.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ArrayBuffer
 */
const axiosArrayBufferInstance = Axios.create({
  baseURL: BASE_URL,
  responseType: 'arraybuffer',
});

const axiosBlobInstance = Axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
});

/**
 * configuring how we handle requests. we need this because we are using
 * token based auth and this token will need to be
 * referenced every time we make a request of behalf of a user. We also
 * send a UUID if the user have decided to hijack
 * another user
 */
const requestHandler = (req: AxiosRequestConfig<any>) => {
  if (req.headers === undefined) {
    req.headers = {};
  }

  const token = localStorage.getItem('token');
  if (token) {
    req.headers.Authorization = `Token ${token}`;
  }

  const uuid = localStorage.getItem('uuid');
  if (uuid) {
    req.headers.common['Hijacked-User-Uuid'] = `${uuid}`;
  }

  return req;
};

axiosJsonInstance.interceptors.request.use((req) => requestHandler(req));
axiosArrayBufferInstance.interceptors.request.use((req) => requestHandler(req));
axiosBlobInstance.interceptors.request.use((req) => requestHandler(req));

const errorHandler = async (error: { response: { status: number; statusText: string } }) => {
  const statusCode = error.response.status;
  if (statusCode >= 400 && statusCode !== 401) {
    await Promise.reject(error.response.statusText);
  }
  if (statusCode === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('uuid');
    window.location.href = '/';
  }
};

const successHandler = (response: AxiosResponse<any, any>) => {
  return response;
};

axiosJsonInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

axiosArrayBufferInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

axiosBlobInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export { axiosJsonInstance, axiosArrayBufferInstance, axiosBlobInstance };
