import React from 'react';
import cx from 'classnames';

import { TIcon, Icon } from 'src/components/Icon';
import { ThemeColors } from 'src/types/theme';
import { Typography } from 'src/components/Typography';

export type ButtonSelectProps = {
  onClick?: () => void;
  active?: boolean;
  label: string;
  icon?: TIcon;
  className?: string;
};

type ActiveColors = {
  fontColor: ThemeColors;
  iconColor: ThemeColors;
  borderColor: ThemeColors;
  bgColor?: ThemeColors;
};

export const ButtonSelect = ({
  label,
  icon,
  active,
  onClick,
  className,
}: ButtonSelectProps) => {
  const { fontColor, borderColor, bgColor, iconColor }: ActiveColors =
    React.useMemo(() => {
      return {
        fontColor: active ? 'primary-dark' : 'neutral-800',
        iconColor: active ? 'primary-dark' : 'neutral-600',
        borderColor: active ? 'primary' : 'neutral-600',
        bgColor: active ? 'primary-super-light' : undefined,
      } as ActiveColors;
    }, [active]);

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'flex gap-2 py-1 px-2 border rounded-lg',
        {
          [`border-${borderColor}`]: !!borderColor,
          [`bg-${bgColor}`]: bgColor,
        },
        className,
      )}
    >
      {icon ? (
        <Icon icon={icon} color={iconColor} />
      ) : (
        <Icon
          icon={active ? 'circle-check-filled' : 'circle'}
          color={iconColor}
        />
      )}
      <Typography variant="caption-3" color={fontColor}>
        {label}
      </Typography>
    </button>
  );
};
