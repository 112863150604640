import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifySuccess } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* createUserEmailPreferences({ payload }: any) {
  try {
    const { data } = yield call(Api.createUserEmailPreferences, payload);
    yield put(ActionTypes.createUserEmailPreferences.success(data));
    notifySuccess('Email preferences updated successfully!');
  } catch (e) {
    yield put(ActionTypes.createUserEmailPreferences.failure(e));
    notifyError('Error updating email preferences. Please, try again later!');
  }
}

function* createUserEmailPreferencesSaga() {
  yield takeLatest(ActionTypes.CREATE_USER_EMAIL_PREFERENCES, createUserEmailPreferences);
}

function* updateUserEmailPreferences({ payload }: any) {
  try {
    const { data } = yield call(Api.updateUserEmailPreferences, payload);
    yield put(ActionTypes.updateUserEmailPreferences.success(data));
    notifySuccess('Email preferences updated successfully!');
  } catch (e) {
    yield put(ActionTypes.updateUserEmailPreferences.failure(e));
    notifyError('Error updating email preferences. Please, try again later!');
  }
}

function* updateUserEmailPreferencesSaga() {
  yield takeLatest(ActionTypes.UPDATE_USER_EMAIL_PREFERENCES, updateUserEmailPreferences);
}

export default function* rootSaga() {
  yield all([
    fork(updateUserEmailPreferencesSaga),
    fork(createUserEmailPreferencesSaga),
  ]);
}
