import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Tooltip } from 'src/components/Tooltip';
import { useSelector } from 'react-redux';
import { IIndicator, IStore } from 'src/interfaces/store.interface';
import { IStores } from 'src/interfaces/storeList.interface';
import {
  convertOneHourPercentageToMinutesAndSeconds,
  getSimpleMinutesAndSeconds,
  getHoursMinutesAndSeconds,
} from 'src/utils/helpers';
import { IDateTime } from 'src/interfaces/common.interface';
import { history } from 'src/utils/history';
import IndicatorWithPlot from '../../indicatorWithPlot';

const DashboardIndicator: React.FC = () => {
  const [indicator, setIndicator] = useState<IIndicator[]>([]);
  const { startDate, endDate } = useSelector((state: IDateTime) => state.dateTime);
  const { stores, loading } = useSelector((state: IStores) => state.stores);
  const {
    allStoresGraphs: { shop_data, categories },
    allStoresGraphs_loading,
    allStoresAvgTimeGraphs: { shop_data: shop_data_avg },
    allStoresFootfallDoDGraphs,
    allStoresFootfallDoDGraphsLoading,
    allStoresAvgTimeDoDGraphs,
    allStoresAvgTimeDoDGraphsLoading,
  } = useSelector((state: any) => state.graphs);

  const handleDoDFromStoreGraph = (data: any, storeId: any) => {
    const defaultValue = {
      icon: 'empty',
      percentage: '0%',
      color: 'green',
    };
    const storeData = data.length > 0 ? data.find((graph: any) => graph.store.id === storeId) : null;
    if (!storeData || !storeData.result) {
      return defaultValue;
    }
    const { result: { percentage = null } = null } = storeData || { result: { percentage: null } };
    if (!percentage || typeof percentage !== 'number') {
      return defaultValue;
    }
    return {
      icon: percentage > 0 ? 'up' : 'down',
      percentage: `${percentage.toFixed()}%`,
      color: percentage > 0 ? 'green' : 'red',
    };
  };

  useEffect(() => {
    const newIndicators = stores.map((store: IStore) => {
      const { icon, percentage } = handleDoDFromStoreGraph(allStoresFootfallDoDGraphs, store.id);
      const { icon: icon_avg, percentage: percentage_avg } = handleDoDFromStoreGraph(
        allStoresAvgTimeDoDGraphs,
        store.id,
      );
      const graph = shop_data ? shop_data.find((storeGraph: any) => storeGraph.name === store.name)?.data : [];
      const graph_avg = shop_data_avg
        ? shop_data_avg.find((storeGraph: any) => storeGraph.name === store.name)?.data
        : [];
      const { indoor_footfall_metrics, indoor_time_metrics } = store;
      const avgTimeGraphFormatted = graph_avg?.length
        ? graph_avg.map((t: number) => convertOneHourPercentageToMinutesAndSeconds(t))
        : [];

      return {
        store: store.name,
        storeId: store.id,
        type: store.es_store_id.toLowerCase(),
        indicators: [
          {
            title: 'Total Footfall',
            tooltip: 'Aggregated number of people in store at user selected dates and hours.',
            value: indoor_footfall_metrics || 0,
            perc: percentage,
            icon: icon as any,
            data: [
              {
                type: 'footfall',
                y: graph?.length === 1 ? [...graph, 0] : graph || [],
                hoverinfo: 'none',
                // hovertemplate: 'Footfall: %{y}<extra></extra>',
                fill: 'tozeroy',
                fillcolor: 'rgba(23, 153, 66, 0.1)',
                line: { color: '#27AE60' },
                mode: 'lines',
              },
            ],
          },
          {
            title: 'Avg. Time Spent',
            tooltip: 'Average Time people have spent in store at user selected dates and hours.',
            value:
              (indoor_time_metrics?.average_time && getSimpleMinutesAndSeconds(indoor_time_metrics.average_time)) || 0,
            perc: percentage_avg,
            icon: icon_avg as any,
            data: [
              {
                type: 'timespent',
                y: graph_avg?.length === 1 ? [...graph_avg, 0] : graph_avg || [],
                formatted: avgTimeGraphFormatted,
                // hoverinfo: 'text',
                // hovertext: tooltipAvgTime,
                hoverinfo: 'none',
                fill: 'tozeroy',
                fillcolor: 'rgba(23, 153, 66, 0.1)',
                line: { color: '#27AE60' },
                mode: 'lines',
              },
            ],
          },
        ],
      };
    });
    setIndicator(newIndicators);
  }, [allStoresAvgTimeDoDGraphs, allStoresFootfallDoDGraphs, shop_data, shop_data_avg, stores]);

  const dataPlotDownloadCSV = useMemo(() => {
    if (shop_data && shop_data_avg) {
      const headers = [
        { label: 'Date/Time', key: 'categories' },
        { label: 'Total Footfall', key: 'total_footfall' },
        { label: 'Avg. Time Spent', key: 'avg_time' },
      ];
      const parsedCategories = categories.map((cat: number) => moment(cat * 1000).format('DD/MM/YY HH:mm'));
      const graph = {};
      shop_data.forEach((store: any, i: number) => {
        graph[store.name] = {
          name: store.name,
          headers,
          data: parsedCategories.map((cat: any, j: number) => {
            return {
              categories: cat,
              total_footfall: store.data.length > 0 ? store.data[j] : 0,
              avg_time:
                shop_data_avg[i].data.length > 0 && shop_data_avg[i].data[j]
                  ? getHoursMinutesAndSeconds(shop_data_avg[i].data[j])
                  : 0,
            };
          }),
        };
      });
      return graph;
    }
    return '';
  }, [categories, shop_data, shop_data_avg]);

  if (
    loading ||
    allStoresGraphs_loading ||
    allStoresFootfallDoDGraphsLoading ||
    allStoresAvgTimeDoDGraphsLoading
  ) {
    return (
      <>
        <div className="animate-pulse flex flex-row gap-3 my-3">
          <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
            <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
            <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
          </div>
          <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
            <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
            <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
          </div>
        </div>
        <div className="animate-pulse flex flex-row gap-3 my-3">
          <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
            <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
            <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
          </div>
          <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
            <div className="h-24 w-32 bg-gray-200 rounded-lg br-5 mb-2.5" />
            <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {indicator.map((item) => (
        <Row key={item.store}>
          <Col>
            <Card
              title={item.store}
              iconsGroup={(
                <Tooltip tooltipText="Download" tooltipPlacement="top">
                  {dataPlotDownloadCSV && (
                    <DownloadCSV
                      filename={`${item.store} - ${moment(startDate).format('DD/MM/YYYY HH:mm')} - ${moment(
                        endDate,
                      ).format('DD/MM/YYYY HH:mm')}`}
                      headers={dataPlotDownloadCSV[item.store]?.headers}
                      data={dataPlotDownloadCSV[item.store]?.data}
                    >
                      <Icon icon="download" className="text-xl" />
                    </DownloadCSV>
                  )}
                </Tooltip>
              )}
              actionsGroup={[{ title: 'Go to Store', onclick: () => history.push(`store/${item.storeId}`) }]}
            >
              <div className="" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0' }}>
                {item.indicators.map((ind) => {
                  return (
                    <div key={ind.title} style={{ width: '100%' }}>
                      {ind.icon && (
                        <IndicatorWithPlot
                          title={ind.title}
                          data={ind.data}
                          percent={ind.perc}
                          value={String(ind.value)}
                          icon={ind.icon}
                          tooltip={ind.tooltip}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Card>
            {/* </div> */}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default DashboardIndicator;
