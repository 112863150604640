import * as Action from './actions';

const initialState = {
  storeAreas: [],
  storeAreasLoading: false,
  storeAreasError: null,

  departmentAreas: [],
  departmentAreasLoading: false,
  departmentAreasError: null,

  cameraAreas: [],
  cameraAreasLoading: false,
  cameraAreasError: null,

  dwellAnalysis: [],
  dwellAnalysisLoading: false,
  dwellAnalysisError: null,

  outsideTraffic: [],
  outsideTrafficLoading: false,
  outsideTrafficError: null,

  createAreasLoading: false,
  createAreasError: null,

  deleteAreasLoading: false,
  deleteAreasError: null,

  archiveAreaLoading: false,
  archiveAreaError: null,

  archivedAreas: [],
  archivedAreasLoading: false,
  archivedAreasError: null,

  unarchivedAreas: [],
  unarchivedAreasLoading: false,
  unarchivedAreasError: null,

  areasOfInterest: [],
  areasOfInterestLoading: false,
  areasOfInterestError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_STORE_AREAS: {
      return {
        ...state,
        storeAreasLoading: true,
      };
    }
    case Action.GET_STORE_AREAS_SUCCESS: {
      return {
        ...state,
        storeAreas: action.payload,
        storeAreasLoading: false,
      };
    }
    case Action.GET_STORE_AREAS_FAILURE: {
      return {
        ...state,
        storeAreasLoading: false,
        storeAreasError: action.error,
      };
    }

    case Action.GET_DEPARTMENT_AREAS: {
      return {
        ...state,
        departmentAreasLoading: true,
      };
    }
    case Action.GET_DEPARTMENT_AREAS_SUCCESS: {
      return {
        ...state,
        departmentAreas: action.payload,
        departmentAreasLoading: false,
      };
    }
    case Action.GET_DEPARTMENT_AREAS_FAILURE: {
      return {
        ...state,
        departmentAreasLoading: false,
        departmentAreasError: action.error,
      };
    }

    case Action.GET_CAMERA_AREAS: {
      return {
        ...state,
        cameraAreasLoading: true,
      };
    }
    case Action.GET_CAMERA_AREAS_SUCCESS: {
      return {
        ...state,
        cameraAreas: action.payload,
        cameraAreasLoading: false,
      };
    }
    case Action.GET_CAMERA_AREAS_FAILURE: {
      return {
        ...state,
        cameraAreasLoading: false,
        cameraAreasError: action.error,
      };
    }

    case Action.GET_DWELL_ANALYSIS: {
      return {
        ...state,
        dwellAnalysisLoading: true,
      };
    }
    case Action.GET_DWELL_ANALYSIS_SUCCESS: {
      return {
        ...state,
        dwellAnalysis: action.payload,
        dwellAnalysisLoading: false,
      };
    }
    case Action.GET_DWELL_ANALYSIS_FAILURE: {
      return {
        ...state,
        dwellAnalysisLoading: false,
        dwellAnalysisError: action.error,
      };
    }

    case Action.GET_OUTSIDE_TRAFFIC: {
      return {
        ...state,
        outsideTrafficLoading: true,
      };
    }
    case Action.GET_OUTSIDE_TRAFFIC_SUCCESS: {
      return {
        ...state,
        outsideTraffic: action.payload,
        outsideTrafficLoading: false,
      };
    }
    case Action.GET_OUTSIDE_TRAFFIC_FAILURE: {
      return {
        ...state,
        outsideTrafficLoading: false,
        outsideTrafficError: action.error,
      };
    }

    case Action.POST_AREAS: {
      return {
        ...state,
        createAreasLoading: true,
      };
    }
    case Action.POST_AREAS_SUCCESS: {
      return {
        ...state,
        createAreasLoading: false,
      };
    }
    case Action.POST_AREAS_FAILURE: {
      return {
        ...state,
        createAreasLoading: false,
        createAreasError: action.error,
      };
    }

    case Action.DELETE_AREA: {
      return {
        ...state,
        storeAreas: state.storeAreas.filter((area: any) => area.id !== action.payload.areaId),
        departmentAreas: state.departmentAreas.filter((area: any) => area.id !== action.payload.areaId),
        cameraAreas: state.cameraAreas.filter((area: any) => area.id !== action.payload.areaId),
        dwellAnalysis: state.dwellAnalysis.filter((area: any) => area.id !== action.payload.areaId),
        outsideTraffic: state.outsideTraffic.filter((area: any) => area.id !== action.payload.areaId),
        deleteAreasLoading: true,
      };
    }
    case Action.DELETE_AREA_SUCCESS: {
      return {
        ...state,
        deleteAreasLoading: false,
      };
    }
    case Action.DELETE_AREA_FAILURE: {
      return {
        ...state,
        deleteAreasLoading: false,
        deleteAreasError: action.error,
      };
    }

    case Action.ARCHIVE_AREA: {
      return {
        ...state,
        storeAreas: state.storeAreas.filter((area: any) => area.id !== action.payload.areaId),
        departmentAreas: state.departmentAreas.filter((area: any) => area.id !== action.payload.areaId),
        cameraAreas: state.cameraAreas.filter((area: any) => area.id !== action.payload.areaId),
        dwellAnalysis: state.dwellAnalysis.filter((area: any) => area.id !== action.payload.areaId),
        outsideTraffic: state.outsideTraffic.filter((area: any) => area.id !== action.payload.areaId),
        deleteAreasLoading: true,
      };
    }

    case Action.ARCHIVE_AREA_SUCCESS: {
      return {
        ...state,
        archiveAreaLoading: false,
      };
    }

    case Action.ARCHIVE_AREA_FAILURE: {
      return {
        ...state,
        archiveAreaLoading: false,
        archiveAreaError: action.error,
      };
    }

    case Action.GET_ARCHIVED_AREAS: {
      return {
        ...state,
        archivedAreasLoading: true,
      };
    }

    case Action.GET_ARCHIVED_AREAS_SUCCESS: {
      return {
        ...state,
        archivedAreas: action.payload,
        archivedAreasLoading: false,
        archivedAreasError: null,
      };
    }

    case Action.GET_ARCHIVED_AREAS_FAILURE: {
      return {
        ...state,
        archivedAreasLoading: false,
        archivedAreasError: action.error,
      };
    }

    case Action.GET_UNARCHIVED_AREAS: {
      return {
        ...state,
        unarchivedAreasLoading: true,
      };
    }

    case Action.GET_UNARCHIVED_AREAS_SUCCESS: {
      return {
        ...state,
        unarchivedAreas: action.payload,
        unarchivedAreasLoading: false,
        unarchivedAreasError: null,
      };
    }

    case Action.GET_UNARCHIVED_AREAS_FAILURE: {
      return {
        ...state,
        unarchivedAreasLoading: false,
        unarchivedAreasError: action.error,
      };
    }

    case Action.GET_CAMERA_AREAS_OF_INTEREST: {
      return {
        ...state,
        areasOfInterestLoading: true,
        areasOfInterestError: null,
      };
    }
    case Action.GET_CAMERA_AREAS_OF_INTEREST_SUCCESS: {
      return {
        ...state,
        areasOfInterest: action.payload,
        areasOfInterestLoading: false,
        areasOfInterestError: null,
      };
    }

    case Action.GET_CAMERA_AREAS_OF_INTEREST_FAILURE: {
      return {
        ...state,
        areasOfInterestLoading: false,
        areasOfInterestError: 'Could not fetch areas of interest',
      };
    }

    default:
      return state;
  }
};
