/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import cx from 'classnames';
import { ButtonSelect } from 'src/components/ButtonSelect';
import { Modal, ModalProps } from 'src/components/Modal';
import { TabGroup } from 'src/components/TabGroup';
import { useDispatch, useSelector } from 'react-redux';

import * as ActionTypes from 'src/store/actions';
import { getTimestampOf } from 'src/utils/helpers';
import { useParams } from 'react-router-dom';

const TAB_GROUP_OPTIONS = [
  { label: 'Heatmap', key: 'heatmap' },
  { label: 'Paths', key: 'paths' },
];

export const HeaderItemsHeatmapModal = () => {
  const [activeOption, setActiveOption] = React.useState('heatmap');

  return <TabGroup options={TAB_GROUP_OPTIONS} onClick={setActiveOption} active={activeOption} />;
};

type FiltersHeatmapModalProps = {
  className?: string;
  activeOption: string;
  setActiveOption: React.Dispatch<React.SetStateAction<string>>;
  activeOptionViewer: string;
  setActiveOptionViewer: React.Dispatch<React.SetStateAction<string>>;
};

export const FiltersHeatmapModal = ({
  className,
  activeOption,
  setActiveOption,
  activeOptionViewer,
  setActiveOptionViewer,
}: FiltersHeatmapModalProps) => {
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const { isCameraPaths } = useSelector((state: any) => state.cameras);
  const dispatch = useDispatch();
  const { cameraId } = useParams<any>();

  function handleActiveOptionViewer(type: string) {
    dispatch(
      ActionTypes.getHeatmap.request({
        cameraId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
        region: 'head_top',
        // region: type === 'eyelevel' ? 'head_top' : 'center',
      }),
    );
  }

  return (
    <>
      <div className={cx('flex justify-between', className)}>
        <div className="flex gap-2">
          <ButtonSelect
            label="Footfall"
            active={activeOption === 'footfall'}
            onClick={() => setActiveOption('footfall')}
          />
          {/* <ButtonSelect */}
          {/*  label="Total Time" */}
          {/*  active={activeOption === 'totaltime'} */}
          {/*  onClick={() => setActiveOption('totaltime')} */}
          {/* /> */}
          <ButtonSelect
            label="Avg. time"
            active={activeOption === 'avgtime'}
            onClick={() => setActiveOption('avgtime')}
          />
        </div>

        <div className="flex gap-2">
          <ButtonSelect
            icon="woman-up-front"
            label="Torso"
            active={activeOptionViewer === 'torso'}
            onClick={() => {
              setActiveOptionViewer('torso');
              handleActiveOptionViewer('torso');
            }}
          />
          <ButtonSelect
            icon="eye-recognition"
            label="Eye level"
            active={activeOptionViewer === 'eyelevel'}
            onClick={() => {
              setActiveOptionViewer('eyelevel');
              handleActiveOptionViewer('eyelevel');
            }}
          />
        </div>
      </div>
    </>
  );
};

export type HeatmapModalProps = ModalProps;

export const HeatmapModal = ({ children, ...props }: HeatmapModalProps) => {
  return (
    <Modal className={cx('p-6')} {...props} size="lg">
      {children}
    </Modal>
  );
};
