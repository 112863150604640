import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Tooltip } from 'src/components/Tooltip';
import { Typography } from 'src/components/Typography';
import { IStore } from 'src/interfaces/store.interface';
import { IStores, ITraffic } from 'src/interfaces/storeList.interface';
import cx from 'classnames';
import { history } from 'src/utils/history';
import { HIGHLIGHTED_ROW_NAME, colDef } from '../../utils';

type Props = {
  intervalLabel: string;
};

const DashboardInsideTraffic: React.FC<Props> = ({ intervalLabel }) => {
  const [insideTraffic, setInsideTraffic] = useState<ITraffic[]>([]);
  const { stores, loading } = useSelector((state: IStores) => state.stores);

  useEffect(() => {
    setInsideTraffic(
      stores.map((store: IStore) => ({
        storeId: store.id,
        store: store.name,
        totalFootfall: store.indoor_footfall_metrics,
        busiestMonth: store.indoor_busiest_month,
        busiestWeek: store.indoor_busiest_week,
        busiestDay: store.indoor_busiest_day,
        busiestHour: store.indoor_busiest_hour,
      })),
    );
  }, [stores]);

  if (loading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 my-3">
        <div role="status" className="w-full bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="animate-bounce h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
          <div className="animate-bounce h-4 bg-gray-200 rounded-lg br-5 w-[100%]" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <Card
          title="Inside Traffic"
          iconsGroup={(
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV
                filename={`Inside Traffic - ${intervalLabel}`}
                data={insideTraffic.map((ot) => ({
                  store: ot.store,
                  totalFootfall: ot.totalFootfall,
                  busiestMonth: ot.busiestMonth,
                  busiestWeek: ot.busiestWeek,
                  busiestDay: ot.busiestDay,
                  busiestHour: ot.busiestHour,
                }))}
              >
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          )}
        >
          <div className="py-2 px-4">
            <table className={cx('w-full')}>
              <thead className="border-b-2 border-b-neutral-100 ">
                <tr>
                  {colDef.map((col) => (
                    <Typography
                      variant="caption-3"
                      color="neutral-600"
                      tag="td"
                      className={cx('px-2 py-1', {
                        'text-left': 'left',
                      })}
                      key={col.title}
                    >
                      {col.title}
                    </Typography>
                  ))}
                </tr>
              </thead>
              <tbody>
                {insideTraffic.map((row) => {
                  const isHighlightedRow = !!row[HIGHLIGHTED_ROW_NAME];

                  return (
                    <tr
                      className={cx('w-full transition-colors duration-100 cursor-pointer', {
                        'bg-transparent': !isHighlightedRow,
                        'hover:bg-primary-super-light': true,
                        'bg-extra-super-light': isHighlightedRow,
                      })}
                      key={JSON.stringify(row)}
                      onClick={() => history.push(`/store/${row.storeId}`)}
                    >
                      {colDef.map((col) => {
                        const item = row[col.field];

                        return (
                          <Typography
                            variant="caption-2"
                            tag="td"
                            className={cx('p-2', {
                              'font-bold text-extra-dark': isHighlightedRow,
                              'text-neutral-800': !isHighlightedRow,
                              'hover:bg-primary-super-light': false,
                            })}
                            key={JSON.stringify({ ...item, ...col })}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardInsideTraffic;
