/* eslint-disable */
// @ts-nocheck
import React from 'react';
import Plotly from 'plotly.js-dist';
import plotComponentFactory from './factory';
import { PlotProps } from './types';
import { useRefWidth } from 'src/hooks/useRefWidth';

const PlotComponent = plotComponentFactory(Plotly);

const Plot = ({ data, layout, config, ...rest }: PlotProps) => {
  const { widthValue, widthRef } = useRefWidth();

  return (
    <div ref={widthRef} className="flex flex-col gap-10 w-full">
      <PlotComponent data={data} layout={{ ...layout, width: widthValue }} config={config} {...rest} />
    </div>
  );
};

export default Plot;
