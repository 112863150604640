import { createApiAction } from 'src/utils/helpers';

export const GET_CAMERAS = 'GET_CAMERAS';
export const GET_CAMERAS_SUCCESS = 'GET_CAMERAS_SUCCESS';
export const GET_CAMERAS_FAILURE = 'GET_CAMERAS_FAILURE';

export const GET_CAMERAS_DISTRIBUTION = 'GET_CAMERAS_DISTRIBUTION';
export const GET_CAMERAS_DISTRIBUTION_SUCCESS = 'GET_CAMERAS_DISTRIBUTION_SUCCESS';
export const GET_CAMERAS_DISTRIBUTION_FAILURE = 'GET_CAMERAS_DISTRIBUTION_FAILURE';

export const UPDATE_CAMERAS = 'UPDATE_CAMERAS';
export const UPDATE_CAMERAS_SUCCESS = 'UPDATE_CAMERAS_SUCCESS';
export const UPDATE_CAMERAS_FAILURE = 'UPDATE_CAMERAS_FAILURE';

export const GET_CAMERA_IMAGE = 'GET_CAMERA_IMAGE';
export const GET_CAMERA_IMAGE_SUCCESS = 'GET_CAMERA_IMAGE_SUCCESS';
export const GET_CAMERA_IMAGE_FAILURE = 'GET_CAMERA_IMAGE_FAILURE';

export const GET_CAMERA_IMAGE_SNAPSHOT = 'GET_CAMERA_IMAGE_SNAPSHOT';
export const GET_CAMERA_IMAGE_SNAPSHOT_SUCCESS = 'GET_CAMERA_IMAGE_SNAPSHOT_SUCCESS';
export const GET_CAMERA_IMAGE_SNAPSHOT_FAILURE = 'GET_CAMERA_IMAGE_SNAPSHOT_FAILURE';

export const GET_CAMERAS_WITH_INFO = 'GET_CAMERAS_WITH_INFO';
export const GET_CAMERAS_WITH_INFO_SUCCESS = 'GET_CAMERAS_WITH_INFO_SUCCESS';
export const GET_CAMERAS_WITH_INFO_FAILURE = 'GET_CAMERAS_WITH_INFO_FAILURE';

export const GET_CAMERA_STATS = 'GET_CAMERA_STATS';
export const GET_CAMERA_STATS_SUCCESS = 'GET_CAMERA_STATS_SUCCESS';
export const GET_CAMERA_STATS_FAILURE = 'GET_CAMERA_STATS_FAILURE';

export const GET_CAMERA_STATS_TODAY = 'GET_CAMERA_STATS_TODAY';
export const GET_CAMERA_STATS_TODAY_SUCCESS = 'GET_CAMERA_STATS_TODAY_SUCCESS';
export const GET_CAMERA_STATS_TODAY_FAILURE = 'GET_CAMERA_STATS_TODAY_FAILURE';

export const GET_CAMERA_PATHS = 'GET_CAMERA_PATHS';
export const GET_CAMERA_PATHS_SUCCESS = 'GET_CAMERA_PATHS_SUCCESS';
export const GET_CAMERA_PATHS_FAILURE = 'GET_CAMERA_PATHS_FAILURE';
export const SET_CAMERA_PATHS = 'SET_CAMERA_PATHS';

export const getCameras = createApiAction(GET_CAMERAS);
export const updateCameras = createApiAction(UPDATE_CAMERAS);
export const getCameraImage = createApiAction(GET_CAMERA_IMAGE);
export const getCameraImageSnapshot = createApiAction(GET_CAMERA_IMAGE_SNAPSHOT);
export const getCamerasWithInfo = createApiAction(GET_CAMERAS_WITH_INFO);
export const getCameraStats = createApiAction(GET_CAMERA_STATS);
export const getCameraStatsToday = createApiAction(GET_CAMERA_STATS_TODAY);
export const getCameraPaths = createApiAction(GET_CAMERA_PATHS);
export const getCamerasDistribution = createApiAction(GET_CAMERAS_DISTRIBUTION);
