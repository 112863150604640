/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'src/components/Breadcrumb';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Filters } from 'src/components/Filters';
import { Icon } from 'src/components/Icon';
import { If } from 'src/components/If';
import Layout from 'src/components/layout';
import { Row } from 'src/components/Row';
import { Table } from 'src/components/Table';
import { Typography } from 'src/components/Typography';
import { useModal } from 'src/hooks/useModal';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import { STORE_FILTERS } from 'src/pages/SuperAdmin/Common/constants';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'src/components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import { ModalFormUser, FormUser } from './ModalFormUser';
import { ModalFormUserEmail } from './ModalFormUserEmail';

const BREADCRUMB_LIST: BreadcrumbItem[] = [
  {
    label: 'Dashboard',
    href: '/super-admin/admin-dashboard',
  },
  {
    label: 'Users',
    href: '',
  },
];

export const StoresUsers = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const dispatch = useDispatch();
  const { brandUsers, brandUsersLoading } = useSelector((state: any) => state.brands);
  // const [users, setUsers] = useState<FormUser[]>([]);
  const { isConfirmed } = useConfirm();

  const [isOpenModalUser, onCloseModalUser, onOpenModalUser] = useModal(false);
  const [isOpenModalEmail, onCloseModalEmail, onOpenModalEmail] = useModal(false);
  const [selectedUser, setSelectedUser] = React.useState<FormUser | undefined>();

  const handleCloseModalUser = () => {
    setSelectedUser(undefined);
    onCloseModalUser();
  };

  const handleSubmitUser = async (values: FormUser) => {
    try {
      const body: any = {
        brand: brandId,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        username: values.username,
        is_superuser: values.status === 'super_admin',
        is_brand_admin: values.status === 'brand_admin',
        storedManaged: values.storedManaged,
      };

      if (!selectedUser) {
        body.password = values.password;
        dispatch({
          type: ActionTypes.POST_BRAND_USERS,
          payload: body,
        });
        handleCloseModalUser();
        return;
      }

      dispatch({
        type: ActionTypes.UPDATE_BRAND_USERS,
        payload: { ...body, userId: selectedUser?.id },
      });

      handleCloseModalUser();
    } catch (err: any) {
      toast.error(err.data.error[0]);
    }
  };

  const handleEditUser = (user: FormUser) => {
    if (user.is_superuser) user.status = 'super_admin';
    else if (user.is_brand_admin) user.status = 'brand_admin';
    else user.status = 'member';

    setSelectedUser(user);
    onOpenModalUser();
  };

  const handleDelete = async (rowData: any) => {
    const willDelete = await isConfirmed(
      'Are You Sure You Want To Delete This Brand?',
      'You wont be able to recover this brand or its settings',
      'delete',
    );

    if (willDelete) {
      dispatch({
        type: ActionTypes.DELETE_BRAND_USERS,
        payload: { userId: rowData?.id },
      });
    }
  };

  const handleEmailOpen = (user: FormUser) => {
    setSelectedUser(user);
    onOpenModalEmail();
  };

  async function loadUsers() {
    dispatch(
      ActionTypes.getBrandUsers.request({
        brandId,
      }),
    );
  }

  useEffect(() => {
    loadUsers();
  }, []);

  const breadcrumb = useMemo(() => {
    return <Breadcrumb list={BREADCRUMB_LIST} />;
  }, []);

  return (
    <Layout title="Apple">
      <If condition={isOpenModalUser}>
        <ModalFormUser
          isOpen={isOpenModalUser}
          onClose={handleCloseModalUser}
          defaultValues={selectedUser}
          onSubmit={handleSubmitUser}
        />
      </If>
      <If condition={isOpenModalEmail}>
        <ModalFormUserEmail
          handleClose={onCloseModalEmail}
          isOpen={isOpenModalEmail}
          brandId={brandId}
          selectedUser={selectedUser}
        />
      </If>
      <Row>
        <Col>{breadcrumb}</Col>
      </Row>

      <Row>
        <Col>
          <Filters
            filters={STORE_FILTERS}
            title="Filters"
            onApplyFilters={(values) => console.log('values::', values)}
          />
        </Col>

        <Col>
          <div className="flex justify-end gap-4">
            <Button
              onClick={onOpenModalUser}
              variant="dropdown-button"
              iconProps={{
                icon: 'add',
                side: 'left',
                size: 'sm',
              }}
            >
              <Typography variant="small-button">Add New User</Typography>
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="h-[calc(100% - 5.5rem)]">
        <Col className="h-auto">
          <Card
            title="Users"
            subTitle="Users by store"
            iconsGroup={(
              <div className="flex gap-2">
                <Tooltip tooltipText="Download" tooltipPlacement="top">
                  <Icon icon="download" className="text-xl" />
                </Tooltip>
              </div>
            )}
            padding
          >
            {brandUsersLoading ? (
              <div className="text-center">Loading...</div>
            ) : (
              <Table
                cols={[
                  {
                    field: 'firstName',
                    title: 'Name',
                    order: true,
                  },
                  {
                    field: 'email',
                    title: 'Email',
                    order: true,
                  },
                  {
                    field: 'actions',
                    title: 'Actions',
                    order: true,
                    alignment: 'end',
                    customCol: (rowData: any) => (
                      <div className="flex gap-2 items-center justify-end py-2">
                        <Icon icon="mail" tag="button" onClick={() => handleEmailOpen(rowData)} />
                        <Icon icon="edit" tag="button" onClick={() => handleEditUser(rowData)} />
                        <Icon icon="delete" tag="button" onClick={() => handleDelete(rowData)} />
                      </div>
                    ),
                  },
                ]}
                rows={
                  brandUsers?.length
                    ? brandUsers?.map((user: FormUser) => ({
                      brandId: user.brand,
                      id: user.id,
                      firstName: `${user.first_name} ${user.last_name}`,
                      email: user.email,
                      username: user.username,
                      first_name: user.first_name,
                      last_name: user.last_name,
                      is_superuser: user.is_superuser,
                      is_brand_admin: user.is_brand_admin,
                      email_subscription: user.email_subscription,
                    }))
                    : [
                      {
                        firstName: 'No Data',
                      },
                    ]
                }
              />
            )}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
