import React from 'react';

import { Text, Rect, Transformer } from 'react-konva';

type Coors = {
  x: number;
  y: number;
};

export type RectangleProps = {
  shapeProps: any;
  name: string;
  isSelected?: boolean;
  onSelect: (values: any) => void;
  onChange: (values: any) => void;
  isDraggable?: boolean;
  cameraSelected?: number;
  onDeleteElement: () => void;
  withTooltip?: boolean;
  fillColor?: string;
  setIsTransforming?: any;
  allowRotation?: boolean;
};

export const Rectangle = ({
  shapeProps,
  name,
  isSelected,
  onSelect,
  onChange,
  isDraggable,
  cameraSelected,
  onDeleteElement,
  withTooltip,
  fillColor: color,
  setIsTransforming,
  allowRotation,
}: RectangleProps) => {
  const shapeRef = React.useRef<any>();
  const trRef = React.useRef<any>();
  const toolTipRef = React.useRef<any>();

  // for tooltip
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
  const [tooltipCoords, setTooltipCoords] = React.useState({
    x: shapeProps.x + shapeProps.width / 2,
    y: shapeProps.y + shapeProps.height / 2,
  });
  const [minPoints, setMinPoints] = React.useState({});
  const [maxPoints, setMaxPoints] = React.useState({});

  const handleUpdateTooltipCoords = (newCoords: Coors) => {
    setTooltipCoords((prevState) => ({
      ...prevState,
      x: newCoords.x,
      y: newCoords.y,
    }));
  };

  React.useEffect(() => {
    if (withTooltip) {
      setMinPoints({
        ...minPoints,
        x: shapeRef.current.attrs.x,
        y: shapeRef.current.attrs.y,
      });
      setMaxPoints({
        ...maxPoints,
        x: shapeRef.current.attrs.x + shapeRef.current.attrs.width,
        y: shapeRef.current.attrs.y + shapeRef.current.attrs.height,
      });
    }
  }, []);

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current?.nodes?.([shapeRef.current]);
      trRef.current?.getLayer?.().batchDraw?.();
    }
  }, [isSelected]);

  const PRIMARY_COLOR = '#003e80';
  const SECONDARY_COLOR = '#00d2c6';

  const { strokeColor, fillColor } = React.useMemo(() => {
    let strokeColor = SECONDARY_COLOR;
    let fillColor = SECONDARY_COLOR;

    if (cameraSelected === shapeProps.key) {
      strokeColor = PRIMARY_COLOR;
      fillColor = PRIMARY_COLOR;
    }

    return { strokeColor, fillColor };
  }, [cameraSelected, shapeProps.key]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        fill={shapeProps.fillPatternImage ? '' : color ?? '#ffffff47'}
        fillPatternImage={shapeProps.fillPatternImage}
        fillPatternScaleY={shapeProps?.fillPatternImage ? shapeProps.height / shapeProps.fillPatternImage.height : 1}
        fillPatternScaleX={shapeProps?.fillPatternImage ? shapeProps.width / shapeProps.fillPatternImage.width : 1}
        stroke={strokeColor}
        strokeWidth={3}
        width={shapeProps.width}
        height={shapeProps.height}
        fillPatternRepeat="no-repeat"
        draggable={isDraggable}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            rotation: e.target.rotation(),
          });
        }}
        onMouseEnter={(e: any) => {
          e.target.getStage().container().style.cursor = isDraggable
            ? 'move'
            : 'default';
        }}
        onMouseLeave={(e: any) => {
          e.target.getStage().container().style.cursor = 'default';
        }}
        onTransformStart={() => setIsTransforming?.(true)}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),

            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });

          setIsTransforming?.(false);
        }}
        onMouseMove={(e) => {
          setIsTooltipVisible(true);
          const stage: any = e.target.getStage();
          const pointerCoords = stage.getPointerPosition();
          handleUpdateTooltipCoords(pointerCoords);
        }}
        onMouseOut={() => {
          setIsTooltipVisible(false);
        }}
      />
      {/* </Transformer> */}
      <Text
        text={name}
        x={shapeProps.x}
        y={(function calculateY() {
          if (shapeProps.rotation === 0) {
            return shapeProps.y < 20 ? shapeProps.height + 15 : shapeProps.y - 15;
          }

          return shapeProps.y - 20;
        }())}
        fontSize={15}
        fillAfterStrokeEnabled
        // strokeScaleEnabled={false}
        // fill={strokeColor}
        // stroke="white"
        fill={strokeColor}
        // stroke={strokeColor}
        // strokeWidth={0.5}
        // hitStrokeWidth={1}
        cursor="pointer"
        rotation={shapeProps.rotation}
      />

      <Text
        text="✘"
        x={shapeProps.x + shapeProps.width + 5}
        y={shapeProps.y + shapeProps.height + 5}
        fontSize={15}
        fill="red"
        visible={false}
        // visible={isSelected}
        onMouseEnter={(e: any) => {
          e.target.getStage().container().style.cursor = 'pointer';
        }}
        onMouseLeave={(e: any) => {
          e.target.getStage().container().style.cursor = 'default';
        }}
        onClick={onDeleteElement}
      />

      {/* box for tooltip */}
      {withTooltip && (
        <Rect
          ref={toolTipRef}
          // x={tooltipCoords.x + 30}
          // y={tooltipCoords.y + 30}
          x={10}
          y={30}
          width={122}
          height={60}
          fill="gray"
          stroke="gray"
          strokeWidth={0}
          visible={isTooltipVisible}
          cornerRadius={3}
          shadowBlur={0}
        />
      )}

      {/* text for tooltip */}
      {withTooltip && (
        <Text
          text={`${name}\n\nTotal Footfall: ${shapeProps.data.totalFootfall}\nAverage Time: ${shapeProps.data.averageTime}`}
          // x={tooltipCoords.x + 30}
          // y={tooltipCoords.y + 30}
          x={15}
          y={35}
          width={130}
          fill="white"
          visible={isTooltipVisible}
          fontFamily="Arial"
        />
      )}

      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={allowRotation ?? false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
