/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { If } from '../If';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export type FormFieldProps = {
  children?: React.ReactNode;
  label?: string;
  error?: string | string[];
  charactersRemaining?: number;
  className?: string;
};

export const FormField = ({ children, label, error, charactersRemaining, className }: FormFieldProps) => {
  const errorAsArray = React.useMemo(() => {
    if (Array.isArray(error)) {
      return error;
    }

    if (typeof error === 'string') {
      return [error];
    }

    return [];
  }, [error]);

  return (
    <fieldset className={className}>
      {label && (
        <Typography tag="label" variant="input-label">
          {label}
        </Typography>
      )}

      {children}
      <div className="flex justify-between items-center mt-1">
        <If condition={errorAsArray.length}>
          <span className="flex items-center">
            <Icon icon="circle-warning" size="sm" color="red" className="mr-1" />
            {errorAsArray.map((err) => (
              <span className="text-xs text-red" key={err}>
                {err}
              </span>
            ))}
          </span>
        </If>

        <If condition={typeof charactersRemaining === 'number'}>
          <span className="text-xs ml-auto">{charactersRemaining}</span>
        </If>
      </div>
    </fieldset>
  );
};
