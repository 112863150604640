import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import { history } from 'src/utils/history';
import { lastWeekTime, todayLastTime } from 'src/utils/helpers';
import * as ActionTypes from './actions';
import Api from './api';

function* postLogin({ payload }: any) {
  try {
    const { data } = yield call(Api.postLogin, payload);

    yield put(ActionTypes.postLogin.success(data));
    localStorage.setItem('token', data.key);
    localStorage.setItem('welcomeMessage', 'true');
    localStorage.setItem('startDate', lastWeekTime.toString());
    localStorage.setItem('endDate', todayLastTime.toString());
    localStorage.setItem('genderFilter', '');
    localStorage.setItem('ageFilter', '');
    // history.push('/');
    window.location.href = '/'; // quick fix for history bug
  } catch (e) {
    console.log('DEBUG erro:', e);
    notifyError(e as string);
    yield put(ActionTypes.postLogin.failure(e));
  }
}

function* postLoginSaga() {
  yield takeLatest(ActionTypes.POST_LOGIN, postLogin);
}

function* postLogout({ payload }: any) {
  try {
    const { data } = yield call(Api.postLogout, payload);
    yield put(ActionTypes.postLogout.success(data));
    // history.push('login');
    window.location.href = '/'; // quick fix for history bug
    localStorage.removeItem('token');
    localStorage.removeItem('uuid');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postLogout.failure(e));
  }
}

function* postLogoutSaga() {
  yield takeLatest(ActionTypes.POST_LOGOUT, postLogout);
}

function* postRegisterUser({ payload }: any) {
  try {
    const { data } = yield call(Api.postRegisterUser, payload);
    yield put(ActionTypes.postRegisterUser.success(data));
    history.push('login');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postRegisterUser.failure(e));
  }
}

function* postRegisterUserSaga() {
  yield takeLatest(ActionTypes.POST_REGISTER_USER, postRegisterUser);
}

function* postPasswordReset({ payload }: any) {
  try {
    const { data } = yield call(Api.postPasswordReset, payload);
    notifyInfo('Please, check your email!');
    yield put(ActionTypes.postPasswordReset.success(data));
    history.push('login');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postPasswordReset.failure(e));
  }
}

function* postPasswordResetSaga() {
  yield takeLatest(ActionTypes.POST_PASSWORD_RESET, postPasswordReset);
}

function* postPasswordResetConfirm({ payload }: any) {
  try {
    const { data } = yield call(Api.postPasswordResetConfirm, payload);
    notifyInfo('Password changed!');
    yield put(ActionTypes.postPasswordResetConfirm.success(data));
    history.push('login');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postPasswordResetConfirm.failure(e));
  }
}

function* postPasswordResetConfirmSaga() {
  yield takeLatest(ActionTypes.POST_PASSWORD_RESET_CONFIRM, postPasswordResetConfirm);
}

function* getUser() {
  try {
    const { data } = yield call(Api.getUser);
    yield put(ActionTypes.getUser.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getUser.failure(e));
  }
}

function* getUserSaga() {
  yield takeLatest(ActionTypes.GET_USER, getUser);
}

export default function* rootSaga() {
  yield all([
    fork(postLoginSaga),
    fork(postLogoutSaga),
    fork(postRegisterUserSaga),
    fork(postPasswordResetSaga),
    fork(postPasswordResetConfirmSaga),
    fork(getUserSaga),
  ]);
}
