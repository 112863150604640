import React, { useMemo } from 'react';
import { If } from 'src/components/If';
import Plot from 'src/components/plotly';
import PlotProps from 'src/components/plotly/types';
import { modeBarButtonsToRemove } from 'src/utils/constants';
import { getHoursMinutesAndSeconds } from 'src/utils/helpers';
import Loader from '../Loader';

import styles from './styles.module.css';

export type PlotHeatmapProps = {
  data: any[];
  imageSrc?: string;
  type?: string;
  maxValue?: number;
  loading?: boolean;
  activeOptionViewer?: string;
};

export const PlotHeatmap = ({
  data,
  imageSrc = 'https://i.ytimg.com/vi/hyhR_NoJyr8/maxresdefault.jpg',
  maxValue = 1,
  type,
  loading,
  activeOptionViewer,
}: PlotHeatmapProps) => {
  const filler = useMemo(() => {
    const size = activeOptionViewer === 'torso' ? 30 : 15;
    const filler: number[][] = [];
    for (let i = 0; i < size; i += 1) {
      filler.push([]);
      for (let j = 0; j < 51; j += 1) {
        filler[i].push(0);
      }
    }
    return filler;
  }, [activeOptionViewer]);

  const layout: any = {
    margin: {
      t: 0,
      r: 0,
      b: 0,
      l: 0,
    },
    showlegend: false,
    autosize: true,
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',

    xaxis: {
      showgrid: false,
      zeroline: false,
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
    },
  };

  const config: Partial<PlotProps['config']> = {
    // displayModeBar: false,
    displaylogo: false,
    responsive: false,
    modeBarButtonsToRemove,
  };

  const colors = ['#ffffff73', '#92ff57', '#59ff00', '#ffff57', '#fafa00', '#ffd65c', '#ffbf00', '#ff5c5c', '#ff0000'];

  const colorScaleValues = [];
  for (let i = 0; i < colors.length; i += 1) {
    const color = colors[i];
    const colorValue = i + 1 === colors.length ? 1 : (1 / colors.length) * i;
    colorScaleValues.push([colorValue, color]);
  }

  let textType: string;
  if (type === 'totaltime') {
    textType = 'Time';
  } else if (type === 'avgtime') {
    textType = 'Avg. Time';
  } else {
    textType = 'Footfall';
  }

  if (loading) {
    return (
      <div className="h-[450px] pt-[25%] pr-[12%]">
        <Loader />
      </div>
    );
  }
  const formatHoverTemplate = (type: string) => {
    if (type === 'totaltime' || type === 'avgtime') {
      return `Total ${textType}: %{customdata}<extra></extra>`;
    }
    return `Total ${textType}: %{z}<extra></extra>`;
  };

  const customData = data.map((row) => row.map((value: number) => getHoursMinutesAndSeconds(value)));
  const combinedData = [...data, ...filler];
  const combinedCustomData = [...customData, ...filler.map((row) => row.map(() => '0 seconds'))];

  return (
    <div className={styles.heatmapContainer}>
      <div className="opacity-70">
        <If condition={imageSrc}>
          <img className={styles.heatmapBgImage} src={imageSrc} alt="" />
        </If>
      </div>
      <Plot
        data={[
          {
            // z: [...data, ...filler],
            // x: xaxis,
            // y: yaxis.reverse(),
            z: combinedData,
            customdata: combinedCustomData,
            xgap: 0.1,
            ygap: 0.1,
            type: 'heatmap',
            mode: 'markers',
            colorscale: colorScaleValues,
            showscale: false,
            opacity: 0.45,
            hovertemplate: type && formatHoverTemplate(type),
          },
        ]}
        layout={layout}
        config={config}
      />
    </div>
  );
};
