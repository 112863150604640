import React from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { uniqueId } from 'lodash';

export type Form = {
  name: string;
};

export type ModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

export const ModalFormAreaOfInterest = ({ onSave, isOpen, onClose }: ModalFormProps) => {
  const onSubmit = (data: any) => {
    data.tempId = uniqueId();
    onSave(data);
    onClose();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>();

  return (
    <Modal
      hideCloseButton
      title="Area of Interest"
      subTitle="Add a new area of interest"
      isOpen={isOpen}
      onClose={onClose}
      className={cx('px-5 py-6')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
        <FormField label="Name" error={errors.name?.message}>
          <TextInput {...register('name')} icon="area" placeholder="e.g. Store" />
        </FormField>
        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={onClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              Add
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
