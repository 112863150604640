import { toast } from 'react-toastify';

const defaultAutoClose = 2000;

export const notifyInfo = (message: string) => {
  toast.info(message, { autoClose: defaultAutoClose, pauseOnFocusLoss: false });
};
export const notifySuccess = (message: string) => {
  toast.success(message, { autoClose: defaultAutoClose, pauseOnFocusLoss: false });
};
export const notifyError = (message: string | null) => {
  console.error({ message });
  // Error messages disabled for demo.
  // toast.error(message || 'Error!', { autoClose: defaultAutoClose, pauseOnFocusLoss: false });
};
