import { createApiAction } from 'src/utils/helpers';

export const GET_HEATMAP = 'GET_HEATMAP';
export const GET_HEATMAP_SUCCESS = 'GET_HEATMAP_SUCCESS';
export const GET_HEATMAP_FAILURE = 'GET_HEATMAP_FAILURE';

export const GET_CALENDAR_HEATMAP = 'GET_CALENDAR_HEATMAP';
export const GET_CALENDAR_HEATMAP_SUCCESS = 'GET_CALENDAR_HEATMAP_SUCCESS';
export const GET_CALENDAR_HEATMAP_FAILURE = 'GET_CALENDAR_HEATMAP_FAILURE';

export const GET_CALENDAR_HEATMAP_CSV = 'GET_CALENDAR_HEATMAP_CSV';
export const GET_CALENDAR_HEATMAP_CSV_SUCCESS = 'GET_CALENDAR_HEATMAP_CSV_SUCCESS';
export const GET_CALENDAR_HEATMAP_CSV_FAILURE = 'GET_CALENDAR_HEATMAP_CSV_FAILURE';

export const GET_DOWNLOAD_CSV = 'GET_DOWNLOAD_CSV';
export const GET_DOWNLOAD_CSV_SUCCESS = 'GET_DOWNLOAD_CSV_SUCCESS';
export const GET_DOWNLOAD_CSV_FAILURE = 'GET_DOWNLOAD_CSV_FAILURE';

export const getHeatmap = createApiAction(GET_HEATMAP);
export const getCalendarHeatmap = createApiAction(GET_CALENDAR_HEATMAP);
export const getCalendarHeatmapCSV = createApiAction(GET_CALENDAR_HEATMAP_CSV);
export const getDownloadCSV = createApiAction(GET_DOWNLOAD_CSV);
