/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Icon, TIcon } from 'src/components/Icon';
import { Typography } from 'src/components/Typography';
import { FormField } from 'src/components/FormField';
import { TextInput } from 'src/components/TextInput';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'src/components/Dropdown';
import { Button } from 'src/components/Button';
import { FaSave, FaTrash } from 'react-icons/fa';
import { Tooltip } from 'src/components/Tooltip';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AiFillWarning } from 'react-icons/ai';

type AreasDirection = 'bottom-to-top' | 'top-to-bottom' | 'left-to-right' | 'right-to-left';

type AreasValue = { up: boolean; down: boolean; left: boolean; right: boolean };

export const AREA_CONFIG_DIRECTION_OPTIONS: {
  // eslint-disable-next-line no-unused-vars
  [key in AreasDirection]: { description: string; icon: TIcon, direction_of_flow: AreasValue };
} = {
  'bottom-to-top': { icon: 'bottom-to-top', description: 'Bottom to top', direction_of_flow: { up: true, down: false, left: false, right: false } },
  'top-to-bottom': { icon: 'top-to-bottom', description: 'Top to Bottom', direction_of_flow: { up: false, down: true, left: false, right: false } },
  'right-to-left': { icon: 'right-to-left', description: 'Right to Left', direction_of_flow: { up: false, down: false, left: true, right: false } },
  'left-to-right': { icon: 'left-to-right', description: 'Left to Right', direction_of_flow: { up: false, down: false, left: false, right: true } },
};

type AreasProps = {
  id: number;
  title: string;
  setting?: string;
  area: any;
  direction?: AreasDirection;
  active?: boolean;
  configActive?: boolean;
  areasOfInterestList?: any[];
  onClick?: (forceSwitch?: boolean) => void;
  onConfig?: (close?: boolean) => void;
  onAdd: (data: any) => void;
  onUpdate: (data: any, area: any) => void;
  onDelete: (id: number, tillArea?: boolean) => void;
  unarchivedArea?: boolean;
};

const areaTypes = [
  { value: 'DA', label: 'Dwell Analysis' },
  { value: 'OT', label: 'Outside Traffic' },
  { value: 'AI', label: 'Areas Of Interest' },
  { value: 'SE', label: 'Store Entrance' },
  { value: 'QM', label: 'Queue Monitoring' },
];

export const areasDirectionOfFlow = [
  { label: 'Bottom to top', value: 'bottom-to-top', direction_of_flow: { up: true, down: false, left: false, right: false } },
  { label: 'Top to Bottom', value: 'top-to-bottom', direction_of_flow: { up: false, down: true, left: false, right: false } },
  { label: 'Right to Left', value: 'right-to-left', direction_of_flow: { up: false, down: false, left: true, right: false } },
  { label: 'Left to Right', value: 'left-to-right', direction_of_flow: { up: false, down: false, left: false, right: true } },
];

export const Areas = ({
  id,
  setting,
  title,
  area,
  direction,
  active,
  configActive,
  areasOfInterestList = [],
  onClick,
  onConfig,
  onAdd,
  onUpdate,
  onDelete,
  unarchivedArea,
}: AreasProps) => {
  const dispatch = useDispatch();
  const { storeId } = useParams<any>();
  const isEditingArea = configActive && active;
  const [isHovering, setIsHovering] = useState(false);
  const [isAreaOfInterest, setIsAreaOfInterest] = useState(false);
  const { isConfirmed } = useConfirm();

  const {
    register,
    handleSubmit,
    watch,
  } = useForm();

  const watchAllFields = watch();

  const areaType = watch('areaType');

  useEffect(() => {
    if (areasOfInterestList.length === 0) {
      setIsAreaOfInterest(false);
      return;
    }

    areasOfInterestList.forEach((area: any) => {
      const interestArea =
        area.entry_areas?.filter((entryArea: any) => entryArea.id === id).length > 0 ||
        area.exit_areas?.filter((exitArea: any) => exitArea.id === id).length > 0;

      setIsAreaOfInterest(interestArea);
    });
  }, [areasOfInterestList, id]);

  const onSubmit = (data: any) => {
    onAdd(data);
  };

  // const onArchiveArea = async () => {
  //   const willArchive = await isConfirmed(
  //     'Confirmation',
  //     'Archive this Area?',
  //     undefined,
  //     'Yes',
  //   );

  //   const brandId = getBrandId(storeId);

  //   if (brandId === 0) {
  //     dispatch(ActionTypes.getAllBrands.request({}));
  //   }

  //   if (willArchive) {
  //     dispatch(ActionTypes.archiveArea.request({ brandId, areaId: id }));
  //   }
  // };

  const updateArea = (fields: any, area: any) => {
    // If its not an area of interest or Store Entrance, then swe need to remove the direction of flow and direction
    if (areaType !== 'AI' && areaType !== 'SE') {
      fields.direction = null;
      area.direction_of_flow = {
        up: false,
        down: false,
        left: false,
        right: false,
      };
    }

    onUpdate(fields, area);
  };

  return (
    <div
      className={cx('flex items-start gap-3 py-2 pt-3 pr-1 hover:border-b-4 hover:border-b-primary', {
        'border-b-4 border-b-primary': active,
      })}
      onFocus={() => {
        setIsHovering(true);
        if (!active && !configActive) {
          onClick?.();
        }
      }}
      onMouseOver={() => {
        setIsHovering(true);
        if (!active && !configActive) {
          onClick?.();
        }
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      {configActive ? (
        <div className="flex flex-col flex-1">
          <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
            <FormField className="w-3/6">
              <TextInput
                {...register('areaName')}
                defaultValue={title}
                className="focus-within:border-b-2 focus-within:border-b-primary hover:border-b-2 hover:border-b-primary-light"
                icon="area"
              />
            </FormField>
            <FormField label="Setting" className="w-3/6">
              <Dropdown
                {...register('areaType')}
                selectedValue={setting}
                options={areaTypes}
                placeholder="Select Area Type"
              />
              {areaType === 'OT' && (
                <span className="flex flex-row text-xs text-amber-500">
                  <AiFillWarning className="mt-0.5 mr-0.5 " />
                  Considering &ldquo;Head top&ldquo; of costumer
                </span>
              )}
            </FormField>
            {(areaType === 'SE' || areaType === 'AI') && (
              <FormField label="Direction" className="w-3/6">
                <Dropdown
                  {...register('direction')}
                  selectedValue={direction}
                  options={areasDirectionOfFlow}
                  placeholder="Select Direction"
                />
              </FormField>
            )}
            <div className="flex gap-3">
              <Button className="mt-3 bg-green w-16 flex gap-1" onClick={() => updateArea(watchAllFields, area)}>
                <FaSave className="mt-0.5" />
                Save
              </Button>
              <Tooltip
                tooltipPlacement="top"
                tooltipText={isAreaOfInterest ? "You can't delete an area that is set as an area of interest." : ''}
                width={200}
              >
                <Button
                  className="mt-3 bg-red w-20 flex gap-1 disabled:bg-neutral-50"
                  onClick={() => onDelete(id, areaType === 'QM')}
                  disabled={isAreaOfInterest}
                >
                  <FaTrash className="mt-0.5" />
                  Remove
                </Button>
              </Tooltip>
            </div>
          </form>
        </div>
      ) : (
        <>
          <Icon className="mt-2" icon="area" color={active ? 'primary' : 'neutral-600'} />

          <div className="flex flex-col items-start flex-1">
            <Typography variant="input-text" color="neutral-900">
              {title}
            </Typography>
            <div className="flex gap-6 mt-2">
              {setting && (
                <Typography variant="caption-3" color="neutral-600">
                  Setting:
                  <Typography variant="caption-3" className="ml-1" color="neutral-800">
                    {setting}
                  </Typography>
                </Typography>
              )}

              <Typography variant="caption-3" color="neutral-600">
                Direction:
                {direction ? (
                  <Typography variant="caption-3" color="neutral-800">
                    <Icon icon={AREA_CONFIG_DIRECTION_OPTIONS[direction].icon} color="primary" className="mx-1.5" />
                    {AREA_CONFIG_DIRECTION_OPTIONS[direction].description}
                  </Typography>
                ) : (
                  ' -'
                )}
              </Typography>
            </div>
          </div>
        </>
      )}
      {(configActive || active || isHovering) && !unarchivedArea && (
        <div className="flex gap-5">
          {/* <Tooltip tooltipPlacement="top" tooltipText="Archive" width={65}>
            <IoArchiveOutline size={19} color="#6A6B76" className="mt-1.5" onClick={() => onArchiveArea()} />
          </Tooltip> */}
          <Tooltip
            tooltipText={configActive ? 'Close' : 'Edit this area'}
            tooltipPlacement="top"
            width={configActive ? 50 : 100}
          >
            <Icon
              tag="button"
              onClick={() => {
                if (isHovering && !isEditingArea) {
                  onClick?.(true);
                  onConfig?.(true);
                  onConfig?.();
                } else if (isEditingArea) {
                  onConfig?.(true);
                } else {
                  onConfig?.();
                }
              }}
              className="mt-2 hover:text-primary transition"
              icon={configActive ? 'close' : 'settings'}
              color="neutral-600"
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
