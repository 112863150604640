import * as Action from './actions';

const initialState = {
  loading: false,
  isAuthenticated: false,
  error: null,

  user: {},
  loading_user: false,
  error_user: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.POST_LOGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_LOGIN_SUCCESS: {
      console.log('DEBUG', state, action);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };
    }
    case Action.POST_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.error,
      };
    }

    case Action.POST_LOGOUT: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    }
    case Action.POST_LOGOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.error,
      };
    }

    case Action.POST_REGISTER_USER: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_REGISTER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_REGISTER_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.POST_PASSWORD_RESET: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.POST_PASSWORD_RESET_CONFIRM: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_PASSWORD_RESET_CONFIRM_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_PASSWORD_RESET_CONFIRM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.POST_PASSWORD_CHANGE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.POST_PASSWORD_CHANGE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_PASSWORD_CHANGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.GET_USER: {
      return {
        ...state,
        loading_user: true,
        error_user: null,
      };
    }
    case Action.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading_user: false,
      };
    }
    case Action.GET_USER_FAILURE: {
      return {
        ...state,
        loading_user: false,
        error_user: action.error,
      };
    }

    default:
      return state;
  }
};
