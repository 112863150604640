import * as Action from './actions';

const initialState = {
  departments: [],
  loading: false,
  error: null,

  departmentStats: {},
  departmentStatsLoading: false,
  departmentStatsError: null,

  departmentFloorplan: '',
  departmentFloorplanLoading: false,
  departmentFloorplanError: null,

};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_DEPARTMENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.payload,
        loading: false,
      };
    }
    case Action.GET_DEPARTMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.GET_DEPARTMENT_STATS: {
      return {
        ...state,
        departmentStatsLoading: true,
      };
    }
    case Action.GET_DEPARTMENT_STATS_SUCCESS: {
      return {
        ...state,
        departmentStats: action.payload,
        departmentStatsLoading: false,
      };
    }
    case Action.GET_DEPARTMENT_STATS_FAILURE: {
      return {
        ...state,
        departmentStatsLoading: false,
        departmentStatsError: action.error,
      };
    }

    case Action.POST_DEPARTMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.POST_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_DEPARTMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.UPDATE_DEPARTMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.UPDATE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.UPDATE_DEPARTMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.GET_DEPARTMENT_FLOORPLAN: {
      return {
        ...state,
        departmentFloorplanLoading: true,
      };
    }
    case Action.GET_DEPARTMENT_FLOORPLAN_SUCCESS: {
      return {
        ...state,
        departmentFloorplan: action.payload,
        departmentFloorplanLoading: false,
      };
    }
    case Action.GET_DEPARTMENT_FLOORPLAN_FAILURE: {
      return {
        ...state,
        departmentFloorplanLoading: false,
        departmentFloorplanError: action.error,
      };
    }

    case Action.UPDATE_DEPARTMENT_FLOORPLAN: {
      return {
        ...state,
        departmentFloorplanLoading: true,
      };
    }
    case Action.UPDATE_DEPARTMENT_FLOORPLAN_SUCCESS: {
      return {
        ...state,
        departmentFloorplanLoading: false,
      };
    }
    case Action.UPDATE_DEPARTMENT_FLOORPLAN_FAILURE: {
      return {
        ...state,
        departmentFloorplanLoading: false,
        departmentFloorplanError: action.error,
      };
    }

    default:
      return state;
  }
};
