import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { Dropdown } from 'src/components/Dropdown';
import { toast } from 'react-toastify';
import { axiosJsonInstance } from 'src/store/api';

export type FormStore = {
  brand: string;
  store?: string;
  address?: string;
  numberOfCameras?: number;
};

type BrandsProps = {
  id: number;
  name: string;
  address?: string;
  icon_url?: string;
  employees?: any[];
  stores?: any[];
  icon?: string;
  phone_number?: string;
};

export type ModalFormStoreProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: FormStore;
  onSubmit?: (values: FormStore) => void;
};

export const ModalFormStore = ({ isOpen, onClose, defaultValues, onSubmit }: ModalFormStoreProps) => {
  const [brands, setBrands] = useState<BrandsProps[]>([]);

  const DROPDOWN_OPTIONS =
    brands?.map((brand) => {
      return {
        value: String(brand.id),
        label: brand.name,
      };
    }) || [];

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
  } = useForm<FormStore>({
    defaultValues,
  });

  const { title, subTitle, buttonLabel } = React.useMemo(() => {
    const hasDefaultValues = !!defaultValues;

    return {
      buttonLabel: hasDefaultValues ? 'Save' : 'Add',
      title: hasDefaultValues ? 'Edit Store' : 'Add Store',
      subTitle: hasDefaultValues ? 'Edit store to the brand' : 'Add store to the brand',
    };
  }, [defaultValues]);

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  async function loadBrands() {
    try {
      const response = await axiosJsonInstance.get('/brands/');
      setBrands(response.data);
    } catch (err: any) {
      toast.error(err?.error[0]);
    }
  }

  useEffect(() => {
    loadBrands();
  }, []);

  const onSubmitForm = handleSubmit(async (data) => {
    try {
      const response = await axiosJsonInstance.post('/store/', {
        brand_id: data.brand,
        name: data.store,
        address_1: data.address,
        numberOfCameras: Number(data.numberOfCameras),
      });

      if (response.data) {
        toast.success('New Store Create With Success.');
      }

      onClose();
    } catch (err) {
      toast.error('Error to Insert New Store');
    }
  });

  return (
    <Modal
      hideCloseButton
      title={title}
      subTitle={subTitle}
      isOpen={isOpen}
      onClose={handleClose}
      className={cx('px-5 py-6')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={onSubmitForm}>
        <FormField label="Brand" error={errors.brand?.message}>
          <Dropdown
            {...register('brand')}
            options={brands.map((brand) => ({
              value: String(brand.id),
              label: brand.name,
            }))}
          />
        </FormField>

        <FormField label="Store" error={errors.store?.message}>
          <TextInput {...register('store')} icon="store" placeholder="e.g. Store" />
        </FormField>

        <FormField label="Address" error={errors.address?.message}>
          <TextInput {...register('address')} icon="location" placeholder="e.g. Main street 7" />
        </FormField>

        <FormField label="No. of cameras" error={errors.numberOfCameras?.message}>
          <TextInput {...register('numberOfCameras')} type="number" icon="cameraVideo" placeholder="e.g. 10" />
        </FormField>

        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={handleClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              {buttonLabel}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
