import React from 'react';
import { Icon } from 'src/components/Icon';

interface Params {
  footFall: string;
  avgTime?: string;
  timeSpent?: string;
}

const ExtraHeaders = ({ footFall, avgTime, timeSpent }: Params) => (
  <div className="flex items-center justify-between p-1">
    <div className="flex items-center">
      <Icon icon="footfall" color="primary" />
      <span className="mx-2 text-xs">Total Footfall Today</span>
      <span className="font-bold text-sm leading-5">{footFall}</span>
    </div>
    {avgTime !== undefined && (
      <div className="flex items-center ml-5">
        <Icon icon="time" color="primary" />
        <span className="mx-2 text-xs">Avg. Time Spent Today</span>
        <span className="font-bold text-sm leading-5">{avgTime}</span>
      </div>
    )}

    {timeSpent !== undefined && (
      <div className="flex items-center ml-5">
        <Icon icon="time" color="primary" />
        <span className="mx-2 text-xs">Avg Time Spent Today</span>
        <span className="font-bold text-sm leading-5">{timeSpent}</span>
      </div>
    )}
  </div>
);

export default ExtraHeaders;
