import React from 'react';
import cx from 'classnames';
import { IconProps, Icon } from 'src/components/Icon';
import { If } from 'src/components/If';
import type { ThemeColors } from 'src/types/theme';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  color?: ThemeColors;
  variant?: '' | 'solid' | 'outlined' | 'ghost' | 'dropdown-button';
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  iconProps?: IconProps & {
    side: 'left' | 'right';
  };
};

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  variant,
  size,
  fullWidth,
  iconProps,
  ...props
}) => {
  const isSolid = variant === 'solid';
  const isOutlined = variant === 'outlined';
  const isGhost = variant === 'ghost';
  const isDropdownButton = variant === 'dropdown-button';

  const isLarge = size === 'large';
  const isMedium = size === 'medium';
  const isSmall = size === 'small';

  const hasSize = Boolean(isLarge || isMedium || isSmall);

  const icon = iconProps && <Icon {...iconProps} className="self-center" />;

  return (
    <button
      type="button"
      className={cx(
        'transition-colors duration-400', // Transition
        'focus-within:outline outline-offset-2 outline-black outline-2', // Outline
        {
          // Variants
          'rounded-full': !isDropdownButton,
          'bg-primary text-white hover:bg-primary-dark': isSolid,
          'bg-white text-primary-dark border-2 border-primary-dark hover:bg-primary-super-light': isOutlined,
          'bg-white text-primary-dark hover:bg-primary-super-light': isGhost,
          'bg-neutral-100 text-neutral-200 hover:bg-neutral-100': props.disabled,
          'bg-white px-4 py-1 rounded shadow-md': isDropdownButton,

          // Sizes
          'font-normal py-1 px-2 text-xs': !hasSize,
          'font-semibold': hasSize,
          'text-lg py-4 px-10': isLarge,
          'text-base py-1.5 px-4': isMedium,
          'text-sm py-1.5 px-4': isSmall,
          'w-full': fullWidth,
          'flex items-center gap-1': !!iconProps,
        },
        className,
      )}
      {...props}
    >
      <If condition={iconProps?.side === 'left'}>{icon}</If>

      {children}

      <If condition={iconProps?.side === 'right'}>{icon}</If>
    </button>
  );
};

Button.defaultProps = {
  variant: 'solid',
  color: 'primary',
} as ButtonProps;
