import { createApiAction } from 'src/utils/helpers';

export const GET_STORE_AREAS = 'GET_STORE_AREAS';
export const GET_STORE_AREAS_SUCCESS = 'GET_STORE_AREAS_SUCCESS';
export const GET_STORE_AREAS_FAILURE = 'GET_STORE_AREAS_FAILURE';

export const GET_DEPARTMENT_AREAS = 'GET_DEPARTMENT_AREAS';
export const GET_DEPARTMENT_AREAS_SUCCESS = 'GET_DEPARTMENT_AREAS_SUCCESS';
export const GET_DEPARTMENT_AREAS_FAILURE = 'GET_DEPARTMENT_AREAS_FAILURE';

export const GET_CAMERA_AREAS = 'GET_CAMERA_AREAS';
export const GET_CAMERA_AREAS_SUCCESS = 'GET_CAMERA_AREAS_SUCCESS';
export const GET_CAMERA_AREAS_FAILURE = 'GET_CAMERA_AREAS_FAILURE';

export const GET_DWELL_ANALYSIS = 'GET_DWELL_ANALYSIS';
export const GET_DWELL_ANALYSIS_SUCCESS = 'GET_DWELL_ANALYSIS_SUCCESS';
export const GET_DWELL_ANALYSIS_FAILURE = 'GET_DWELL_ANALYSIS_FAILURE';

export const GET_OUTSIDE_TRAFFIC = 'GET_OUTSIDE_TRAFFIC';
export const GET_OUTSIDE_TRAFFIC_SUCCESS = 'GET_OUTSIDE_TRAFFIC_SUCCESS';
export const GET_OUTSIDE_TRAFFIC_FAILURE = 'GET_OUTSIDE_TRAFFIC_FAILURE';

export const POST_AREAS = 'POST_AREAS';
export const POST_AREAS_SUCCESS = 'POST_AREAS_SUCCESS';
export const POST_AREAS_FAILURE = 'POST_AREAS_FAILURE';

export const DELETE_AREA = 'DELETE_AREA';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE';

export const ARCHIVE_AREA = 'ARCHIVE_AREA';
export const ARCHIVE_AREA_SUCCESS = 'ARCHIVE_AREA_SUCCESS';
export const ARCHIVE_AREA_FAILURE = 'ARCHIVE_AREA_FAILURE';

export const GET_ARCHIVED_AREAS = 'GET_ARCHIVED_AREAS';
export const GET_ARCHIVED_AREAS_SUCCESS = 'GET_ARCHIVED_AREAS_SUCCESS';
export const GET_ARCHIVED_AREAS_FAILURE = 'GET_ARCHIVED_AREAS_FAILURE';

export const GET_UNARCHIVED_AREAS = 'GET_UNARCHIVED_AREAS';
export const GET_UNARCHIVED_AREAS_SUCCESS = 'GET_UNARCHIVED_AREAS_SUCCESS';
export const GET_UNARCHIVED_AREAS_FAILURE = 'GET_UNARCHIVED_AREAS_FAILURE';

export const GET_CAMERA_AREAS_OF_INTEREST = 'GET_AREAS_OF_INTEREST_AREAS';
export const GET_CAMERA_AREAS_OF_INTEREST_SUCCESS = 'GET_AREAS_OF_INTEREST_AREAS_SUCCESS';
export const GET_CAMERA_AREAS_OF_INTEREST_FAILURE = 'GET_AREAS_OF_INTEREST_AREAS_FAILURE';

export const getStoreAreas = createApiAction(GET_STORE_AREAS);
export const getDepartmentAreas = createApiAction(GET_DEPARTMENT_AREAS);
export const getCameraAreas = createApiAction(GET_CAMERA_AREAS);
export const getDwellAnalysis = createApiAction(GET_DWELL_ANALYSIS);
export const getOutsideTraffic = createApiAction(GET_OUTSIDE_TRAFFIC);
export const postAreas = createApiAction(POST_AREAS);
export const deleteArea = createApiAction(DELETE_AREA);
export const archiveArea = createApiAction(ARCHIVE_AREA);
export const getArchivedAreas = createApiAction(GET_ARCHIVED_AREAS);
export const getUnArchivedAreas = createApiAction(GET_UNARCHIVED_AREAS);
export const getCameraAreasOfInterest = createApiAction(GET_CAMERA_AREAS_OF_INTEREST);
