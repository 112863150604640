import * as Action from './actions';

const initialState = {
  locations: [],
  loading: false,
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_LOCATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_LOCATION_SUCCESS: {
      return {
        ...state,
        locations: action.payload,
        loading: false,
      };
    }
    case Action.GET_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.POST_LOCATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.POST_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};
