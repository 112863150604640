import * as Action from './actions';

const initialState = {
  storeProducts: {
    results: '',
  },
  storeProducts_loading: false,
  storeProducts_error: null,

  departmentProducts: { results: '' },
  departmentProducts_loading: false,
  departmentProducts_error: null,

  updateProduct_loading: false,
  updateProduct_error: null,

  product: [],
  product_loading: false,
  product_error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.POST_PRODUCT: {
      return {
        ...state,
        storeProducts_loading: true,
      };
    }
    case Action.POST_PRODUCT_SUCCESS: {
      return {
        ...state,
        storeProducts_loading: false,
      };
    }
    case Action.POST_PRODUCT_FAILURE: {
      return {
        ...state,
        loading: false,
        storeProducts_error: action.error,
      };
    }
    case Action.GET_STORE_PRODUCTS: {
      return {
        ...state,
        storeProducts_loading: true,
      };
    }
    case Action.GET_STORE_PRODUCTS_SUCCESS: {
      if (action.payload.more) {
        return {
          ...state,
          storeProducts: {
            ...state.storeProducts,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            results: state.storeProducts.results.concat(action.payload.results),
          },
          storeProducts_loading: false,
        };
      }
      return {
        ...state,
        storeProducts: action.payload,
        storeProducts_loading: false,
      };
    }
    case Action.GET_STORE_PRODUCTS_FAILURE: {
      return {
        ...state,
        storeProducts_loading: false,
        storeProducts_error: action.error,
      };
    }
    case Action.GET_DEPARTMENT_PRODUCTS: {
      return {
        ...state,
        departmentProducts_loading: true,
      };
    }
    case Action.GET_DEPARTMENT_PRODUCTS_SUCCESS: {
      if (action.payload.more) {
        return {
          ...state,
          departmentProducts: {
            ...state.departmentProducts,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            results: state.departmentProducts.results.concat(action.payload.results),
          },
          departmentProducts_loading: false,
        };
      }
      return {
        ...state,
        departmentProducts: action.payload,
        departmentProducts_loading: false,
      };
    }
    case Action.GET_DEPARTMENT_PRODUCTS_FAILURE: {
      return {
        ...state,
        departmentProducts_loading: false,
        departmentProducts_error: action.error,
      };
    }

    case Action.UPDATE_PRODUCT: {
      return {
        ...state,
        updateProduct_loading: true,
      };
    }
    case Action.UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        updateProduct_loading: false,
      };
    }
    case Action.UPDATE_PRODUCT_FAILURE: {
      return {
        ...state,
        updateProduct_loading: false,
        updateProduct_error: action.error,
      };
    }
    case Action.GET_PRODUCT: {
      return {
        ...state,
        product_loading: true,
      };
    }
    case Action.GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        product_loading: false,
      };
    }
    case Action.GET_PRODUCT_FAILURE: {
      return {
        ...state,
        product_loading: false,
        product_error: action.error,
      };
    }

    default:
      return state;
  }
};
