/* eslint-disable */
import React, { memo, useEffect, useState } from 'react';

import { Stage, Layer, Arrow, Image } from 'react-konva';
import useImage from 'use-image';

import { Rectangle } from './Rectangle';

let pos = { x: 0, y: 0 }; // initial coords points

export type CanvasProps = {
  path?: boolean;
  withDraw?: boolean;
  withTooltip?: boolean;
  reset?: boolean;
  img: string;
  withDraggable?: boolean;
  annotations?: any[];
  setAnnotations?: (values: any) => void;
  setSelectedElementId?: (values: any) => void;
  selectedElementId?: number;
  cameraSelected?: number;
  elementName: string;
  setCameraSelected?: (values: any) => void;
  canvasWidth: number;
  canvasHeight: number;
  setDataToSave?: (values: any) => void;
  setSelections?: (values: any) => void;
  paths?: any[];
  onDeleteElement?: (value?: any) => void;
  dataAreasToSave?: any;
  imgOpacity?: number;
  scale?: number;
  scaleYDec?: number;
  redAreasAnnotations?: any[];
  allowRotation?: boolean;
};

export const Canvas = memo<CanvasProps>(
  ({
    path,
    withDraw,
    withTooltip,
    img,
    withDraggable,
    annotations = [],
    setAnnotations = () => null,
    setSelectedElementId = () => null,
    selectedElementId,
    cameraSelected,
    elementName,
    setCameraSelected = () => null,
    canvasWidth,
    canvasHeight,
    setDataToSave = () => null,
    setSelections = () => null,
    paths = [],
    onDeleteElement,
    dataAreasToSave,
    imgOpacity,
    scale,
    scaleYDec,
    redAreasAnnotations,
    allowRotation,
  }) => {
    const [newAnnotation, setNewAnnotation] = useState<any>([]);
    const [image] = useImage(img);
    const [isTransforming, setIsTransforming] = useState(false);

    useEffect(() => {
      setAnnotations([...annotations, ...newAnnotation]);
    }, [annotations.length]);

    useEffect(() => {
      if (annotations) {
        setDataToSave(dataAreasToSave);
      }
    }, [annotations]);

    useEffect(() => {
      if (!withDraggable) {
        setSelectedElementId(null);
      }
    }, [withDraggable])

    const handleMouseDown = (event: any) => {
      if (!newAnnotation?.length) {
        const { x, y } = event.target.getStage().getPointerPosition();
        if (
          annotations.some(
            (item) => x >= item.x && x <= item.x + item.width && y >= item.y && y <= item.y + item.height,
          )
        ) {
          return;
        }

        setNewAnnotation([
          {
            x,
            y,
            width: 0,
            height: 0,
            key: annotations?.length + 1,
            name: `${elementName} ${annotations?.length + 1}`,
            id: null,
            updated: true,
          },
        ]);
      }

      if (event.target) {
        setSelectedElementId(null);
      }
    };

    const handleMouseUp = (event: any) => {
      if (newAnnotation?.length === 1) {
        const sx = newAnnotation[0].x;
        const sy = newAnnotation[0].y;
        const { x, y } = event.target.getStage().getPointerPosition();

        const annotationToAdd = {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: annotations?.length + 1,
          name: `${elementName} ${annotations?.length + 1}`,
          id: null,
          updated: true,
        };
        if (Math.abs(annotationToAdd.width) < 10) {
          setNewAnnotation([]);
          return;
        }
        annotations.push(annotationToAdd);
        setNewAnnotation([]);
        setAnnotations(annotations);
      }
    };

    const handleMouseMove = (event: any) => {
      if (newAnnotation?.length === 1) {
        const sx = newAnnotation[0].x;
        const sy = newAnnotation[0].y;
        const { x, y } = event.target.getStage().getPointerPosition();
        setNewAnnotation([
          {
            x: sx,
            y: sy,
            width: x - sx,
            height: y - sy,
            key: annotations?.length + 1,
            name: `${elementName} ${annotations?.length + 1}`,
            id: null,
          },
        ]);
      }
    };

    const handleTransform = () => {
      if (!isTransforming) {
        setSelectedElementId(null);
      }
    }

    const annotationsToDraw = [...annotations, ...newAnnotation];

    const _onMouseMove = (e: any) => {
      const { layerX, layerY } = e.nativeEvent;
      pos = { x: layerX, y: layerY };
      return pos;
    };

    const scaleX = scale || 1;
    const scaleY = 0.9;

    return (
      <div className="canvas__container" onMouseMove={_onMouseMove}>
        <div>
          <Stage
            onMouseDown={withDraw ? handleMouseDown : allowRotation ? handleTransform : undefined}
            onMouseUp={withDraw ? handleMouseUp : undefined}
            onMouseMove={withDraw ? handleMouseMove : undefined}
            width={canvasWidth}
            height={canvasHeight}
            opacity={1}
            className="canvas__stage"
            scaleX={scaleX}
            scaleY={scaleY}
          >
            <Layer>
              {!!image && <Image image={image} width={canvasWidth} height={canvasHeight} opacity={imgOpacity} />}

              {annotationsToDraw?.map((value, i) => (
                <Rectangle
                  key={value.key}
                  name={value.name || `${elementName} ${value.key}`}
                  shapeProps={value}
                  isSelected={value.key === selectedElementId}
                  isDraggable={withDraggable}
                  onSelect={() => {
                    if (withDraggable) {
                      setSelectedElementId(value.key);
                      setCameraSelected(value.key);
                      setSelections((prevState: any) => ({
                        ...prevState,
                        [elementName.toLowerCase()]: true,
                      }));
                    }
                  }}
                  onChange={(newAttrs) => {
                    const rects = annotationsToDraw.slice();
                    rects[i] = {...newAttrs, updated: true};
                    setAnnotations(rects);
                  }}
                  withTooltip={withTooltip && value?.type !== 'SE'}
                  cameraSelected={cameraSelected}
                  onDeleteElement={() => onDeleteElement?.(value)}
                  setIsTransforming={setIsTransforming}
                  allowRotation={allowRotation}
                />
              ))}

              {redAreasAnnotations?.map((value, i) => (
                <Rectangle
                  key={value.key}
                  name={value.name || `${elementName} ${value.key}`}
                  shapeProps={value}
                  isSelected={value.key === selectedElementId}
                  isDraggable={withDraggable}
                  onSelect={() => {
                    if (withDraggable) {
                      setSelectedElementId(value.key);
                      setCameraSelected(value.key);
                      setSelections((prevState: any) => ({
                        ...prevState,
                        [elementName.toLowerCase()]: true,
                      }));
                    }
                  }}
                  onChange={(newAttrs) => {
                    const rects = redAreasAnnotations.slice();
                    rects[i] = {...newAttrs, updated: true};
                    setAnnotations(rects);
                  }}
                  withTooltip={withTooltip}
                  cameraSelected={cameraSelected}
                  onDeleteElement={() => onDeleteElement?.(value)}
                  fillColor="#ff000047"
                />
              ))}

              {path &&
                paths.map((path, index) => (
                  <Arrow key={index} points={path} fill="black" stroke="black" strokeWidth={2} />
                ))}
            </Layer>
          </Stage>
        </div>
      </div>
    );
  },
);
