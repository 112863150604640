/* eslint-disable indent */
import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Plot from 'src/components/plotly';
import { useRefDimensions } from 'src/hooks/useRefDefinitions';
import { Toggle } from 'src/components/Toggle';

import * as ActionTypes from 'src/store/actions';

export type PlotLineAreaProps = {
  data: any;
  className?: string;
};

export const PlotLineArea = ({ data, className }: PlotLineAreaProps) => {
  const { width, ref } = useRefDimensions({});
  const dispatch = useDispatch();
  const { graphCameraPerformance } = useSelector((state: any) => state.graphs);

  const layout = {
    margin: {
      t: 0,
      r: 0,
      b: 0,
      l: 10,
    },
    height: 70,
    width,
    showlegend: false,
    xaxis: {
      autotick: false,
      fixedrange: true,
      showgrid: true,
      showline: false,
      showticklabels: false,
      zeroline: false,
      visible: (data[0].y && data[0].y?.length) || false,
    },
    yaxis: {
      visible: false,
      autotick: true,
      fixedrange: false,
      showgrid: false,
      showline: false,
      // showticklabels: false,
      zeroline: false,
    },
    annotations:
      data[0].y && data[0].y?.length < 1
        ? [
            {
              text: 'No matching data found',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: { size: 14, color: '#3F404F' },
            },
          ]
        : [],
  };

  const config = {
    displayModeBar: false,
  };

  function handleChangeLine(e: any) {
    dispatch(
      ActionTypes.toggleLineCameraGraph({
        axis: data[0].name === 'shoppers_by_camera' ? 'xaxis' : 'yaxis',
        checked: e,
      }),
    );
  }

  return (
    <div ref={ref} className={cx('w-full mb-[-20px]', className)}>
      <Plot data={data} layout={layout} config={config} />
      <Toggle
        justifyEnd
        checked={graphCameraPerformance[data[0].name === 'shoppers_by_camera' ? 'xaxis' : 'yaxis'].visible}
        label="Plot at chart"
        className="text-xs my-2"
        data-name="avg"
        onChange={(e) => handleChangeLine(e)}
      />
    </div>
  );
};
