import { axiosJsonInstance } from 'src/store/api';

export default {
  async getAreasOfInterest(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any) {
    return axiosJsonInstance.get('areas-of-interest/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  async postAreasOfInterest(storeId: any, departmentId: any, cameraId: any, data: any) {
    return axiosJsonInstance.post('areas-of-interest/', {
      store_id: storeId,
      department_id: departmentId,
      camera_id: cameraId,
      data,
    });
  },

  async deleteAreaOfInterest(areaOfInterestId: any) {
    return axiosJsonInstance.delete(`area-of-interest/${areaOfInterestId}/`);
  },
};
