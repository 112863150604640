import * as Action from './actions';

const initialState = {
  areasOfInterest: [],
  areasOfInterestLoading: false,
  areasOfInterestError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_AREAS_OF_INTEREST: {
      return {
        ...state,
        areasOfInterestLoading: true,
      };
    }
    case Action.GET_AREAS_OF_INTEREST_SUCCESS: {
      return {
        ...state,
        areasOfInterest: action.payload,
        areasOfInterestLoading: false,
      };
    }
    case Action.GET_AREAS_OF_INTEREST_FAILURE: {
      return {
        ...state,
        areasOfInterestLoading: false,
        areasOfInterestError: action.error,
      };
    }

    case Action.POST_AREAS_OF_INTEREST: {
      return {
        ...state,
        areasOfInterestLoading: true,
      };
    }
    case Action.POST_AREAS_OF_INTEREST_SUCCESS: {
      return {
        ...state,
        areasOfInterestLoading: false,
      };
    }
    case Action.POST_AREAS_OF_INTEREST_FAILURE: {
      return {
        ...state,
        areasOfInterestLoading: false,
        areasOfInterestError: action.error,
      };
    }

    case Action.DELETE_AREA_OF_INTEREST: {
      return {
        ...state,
        areasOfInterest: state.areasOfInterest.filter((areaOfInterest: any) => areaOfInterest.id !== action.payload.areaOfInterestId),
        areasOfInterestLoading: true,
      };
    }
    case Action.DELETE_AREA_OF_INTEREST_SUCCESS: {
      return {
        ...state,
        areasOfInterestLoading: false,
      };
    }
    case Action.DELETE_AREA_OF_INTEREST_FAILURE: {
      return {
        ...state,
        areasOfInterestLoading: false,
        areasOfInterestError: action.error,
      };
    }

    default:
      return state;
  }
};
