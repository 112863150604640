import Login from 'src/pages/Login';
import ResetPassword from 'src/pages/ResetPassword';
import ResetPasswordConfirm from 'src/pages/ResetPasswordConfirm';

// import RegisterUser from 'src/pages/RegisterUser';
// import ChangePassword from 'src/pages/ChangePassword';

export const PublicRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/reset-password-confirm',
    component: ResetPasswordConfirm,
  },
];
