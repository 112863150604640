/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Icon, TIcon } from 'src/components/Icon';
import Layout from 'src/components/layout';
import { Row } from 'src/components/Row';
import { Typography } from 'src/components/Typography';
import { useForm } from 'react-hook-form';
import { TextInput } from 'src/components/TextInput';
import { FormField } from 'src/components/FormField';
import { useModal } from 'src/hooks/useModal';
import { Canvas } from 'src/components/PlotShapesFigure/Canvas';
import { useParams } from 'react-router-dom';
import { IHeader, IUseParamsDepartmentSettings } from 'src/interfaces/common.interface';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import { getTimestampOf } from 'src/utils/helpers';
import { ICameraList, IArea, ICamera, IDepartmentData } from 'src/interfaces/department.interface';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import { If } from 'src/components/If';
import { ButtonSelect } from 'src/components/ButtonSelect';
import { toast } from 'react-toastify';
import { history } from 'src/utils/history';
import { BsArchive } from 'react-icons/bs';
import { AiOutlineSave } from 'react-icons/ai';
import { Tooltip } from 'src/components/Tooltip';
import { Modal } from 'src/components/Modal';
import { IoWarningOutline } from 'react-icons/io5';
import { notifyError } from 'src/utils/notification';
import { Areas, areasDirectionOfFlow } from './Areas';
import { ModalFormArea } from './ModalFormArea';
import { AreasOfInterest } from './AreasOfInterest';
import { ModalFormAreaOfInterest } from './ModalFormAreaOfInterest';
import ArchivedAreasList from './ArchivedAreasList';
import CamerasDistribution from './CameraDistribution';

const MOCK_IMAGE_FLOORPLANT_URL = 'https://i.imgur.com/mxf7y4Q.png';
const CANVAS_WIDTH = 750;
const CANVAS_HEIGHT = 500;

type LabelWithValueProps = {
  label: string;
  value: string;
  icon?: TIcon;
  onClick?: () => void;
  active?: boolean;
};

type PropsAuth = {
  auth: {
    user: {
      is_superuser: boolean;
    };
  };
}

const LabelWithValue = ({ label, value, icon, onClick, active }: LabelWithValueProps) => (
  <div
    role={onClick ? 'button' : undefined}
    onClick={onClick}
    className={cx('flex flex-col', {
      'gap-3': !!icon,
      'gap-1': !icon,
      'border-b-2 border-b-primary': active,
    })}
  >
    <Typography variant="input-label" color="neutral-600">
      {label}
    </Typography>
    <div className="flex items-center gap-2">
      {icon && <Icon color="primary" icon={icon} />}
      <Typography
        variant={active && !icon ? 'standard-h6' : 'input-text'}
        color={active === false ? 'neutral-600' : 'neutral-900'}
      >
        {value}
      </Typography>
    </div>
  </div>
);

const DepartamentSettings = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { departmentStats, departmentFloorplan, departmentStats_loading } = useSelector(
    (state: any) => state.departments,
  );

  const {
    cameras,
    loading,
    cameraImage,
    cameraImageLoading,
    camerasDistribution,
  } = useSelector((state: any) => state.cameras);
  const { cameraTills, cameraTillsLoading } = useSelector((state: any) => state.tills);
  const { cameraAreas, cameraAreasLoading, createAreasLoading, deleteAreasLoading, unarchivedAreas } = useSelector(
    (state: any) => state.areas,
  );
  const { areasOfInterest, areasOfInterestLoading } = useSelector((state: any) => state.areasOfInterest);
  const isSuperUser = useSelector((state: PropsAuth) => state.auth.user.is_superuser);

  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));
  const [updateFloorplan, setUpdateFloorplan] = useState(false);
  const [departmentData, setDepartmentData] = useState<IDepartmentData>();
  const [cameraList, setCameraList] = useState<ICameraList[]>([]);

  const [headerData, setHeaderData] = useState<IHeader>({
    address: '',
    location: '',
  });
  const [camerasToSave, setCamerasToSave] = useState<any>([]);
  const [areasToSave, setAreasToSave] = useState<any>([]);
  const [camerasAnnotations, setCamerasAnnotations] = useState([]);
  const [cameraSelected, setCameraSelected] = useState(null);
  const [idSelectedCamera, setIdSelectedCamera] = useState('');
  const [selectedAreaId, setSelectedAreaId] = useState<any>(null);
  const [areasAnnotations, setAreasAnnotations] = useState<any>([]);
  const [unarchivedAreasAnnotations, setUnarchivedAreasAnnotations] = useState<any>([]);
  const [selectedCamera, setSelectedCamera] = useState<ICameraList>();
  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const [configSelectedArea, setConfigSelectedArea] = useState<number | null>(null);
  const [cameraAreaList, setCameraAreaList] = useState<IArea[]>([]);
  const [areasOfInterestList, setAreasOfInterestList] = useState<any[]>([]);
  const [isOpenArea, onCloseArea, onOpenArea] = useModal();
  const [isOpenArchiveList, onCloseArchiveList, onOpenArchiveList] = useModal();
  const [isOpenAreaOfInterest, onCloseAreaOfInterest, onOpenAreaOfInterest] = useModal();
  const [dragArea, setDragArea] = useState(false);
  const [drawArea, setDrawArea] = useState(false);

  const [selectedCameraId, setSelectedCameraId] = useState<number>();

  const { storeId, departmentId } = useParams<IUseParamsDepartmentSettings>();

  const {
    register,
    formState: { errors },
  } = useForm();

  const { isConfirmed } = useConfirm();

  useEffect(() => {
    if (departmentId) {
      if (!isSuperUser) {
        history.push('/');
      }
      // dispatch(ActionTypes.getDepartmentFloorplan.request({ departmentId }));

      dispatch(
        ActionTypes.getDepartmentStats.request({
          departmentId,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(ActionTypes.getCameras.request({ storeId, departmentId }));

      dispatch(ActionTypes.getCamerasDistribution.request({}));
    }
  }, [dispatch, departmentId, startTimeTimestamp, endTimeTimestamp, storeId]);

  useEffect(() => {
    if (idSelectedCamera) {
      dispatch(ActionTypes.getCameraImage.request({ cameraId: idSelectedCamera }));

      dispatch(
        ActionTypes.getCameraAreas.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(
        ActionTypes.getCameraTills.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(
        ActionTypes.getAreasOfInterest.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
        }),
      );
    }
  }, [dispatch, idSelectedCamera, startTimeTimestamp, endTimeTimestamp, storeId, departmentId]);

  useEffect(() => {
    if (departmentId && departmentFloorplan && updateFloorplan) {
      // dispatch(ActionTypes.updateDepartmentFloorplan.request({ departmentId, departmentFloorplan }));
      // setUpdateFloorplan(false);
    }
  }, [dispatch, departmentId, departmentFloorplan, updateFloorplan]);

  const loadImg = async (url: string): Promise<HTMLImageElement> => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    if (cameras && departmentId) {
      const mapCameras = async (cameras: any[], departmentId: any) => {
        const mappedCamerasPromises = cameras.map(async (camera: any, id: number) => {
          const img = await loadImg(`${process.env.PUBLIC_URL}/images/top_view_cameras/${departmentId}/${camera.id}.png`);

          return {
            key: camera.id,
            id: camera.id,
            name: camera.es_cam_id,
            x: Number(camera?.position?.x_coord) * CANVAS_WIDTH,
            y: Number(camera?.position?.y_coord) * CANVAS_HEIGHT,
            width: img.width,
            height: img.height,
            fillPatternImage: img,
          };
        });

        return Promise.all(mappedCamerasPromises);
      };

      mapCameras(cameras, departmentId)
        .then((mappedCameras: any) => setCamerasAnnotations(mappedCameras))
        .catch((err) => console.error(err));
    }
  }, [cameras, departmentId]);

  useEffect(() => {
    if (departmentStats) {
      const { store, areas, cameras } = departmentStats;

      setDepartmentData(departmentStats);

      if (store) {
        setHeaderData({
          location: `${store.name || ''}`,
          address: `${store.address_1 || ''} ${store.address_2 || ''}`,
        });
      }
      if (cameras && cameras.length > 0) {
        const cameraData = cameras.map((c: ICamera) => {
          const cameraAareas = areas.filter((area: IArea) => area.camera === c.id);
          return {
            id: c.id,
            name: c.es_cam_id,
            label: c.es_cam_id,
            value: `Areas: ${cameraAareas.length}`,
            defaultValue: `Name of ${c.es_cam_id}`,
            areas: cameraAareas || [],
          };
        });
        setIdSelectedCamera(cameras[0].id);
        setSelectedCamera(cameraData[0]);
        setCameraList(cameraData);
      }
    }
  }, [departmentStats]);

  useEffect(() => {
    if (idSelectedCamera) {
      const camera = cameraList.filter((camera: ICameraList) => camera.id === idSelectedCamera).shift();
      const areas = camera?.areas || [];
      if (areas && areas.length > 0) {
        setCameraAreaList(areas);
      }
    }
  }, [idSelectedCamera, cameraList]);

  useEffect(() => {
    if (cameraAreas && departmentId && idSelectedCamera) {
      const parsedCameraTills = cameraTills?.data?.map((till: any) => ({
        ...till,
        type: 'QM',
      }));

      const allAreas = [...cameraAreas, ...parsedCameraTills];

      const mappedAreas = allAreas.map((area: any, idx: number) => ({
        key: idx,
        id: area.id,
        name: area.name,
        x: Number(area.min_point.x_coord) * CANVAS_WIDTH,
        y: Number(area.min_point.y_coord) * CANVAS_HEIGHT,
        width: (Number(area.max_point.x_coord) - Number(area.min_point.x_coord)) * CANVAS_WIDTH,
        height: (Number(area.max_point.y_coord) - Number(area.min_point.y_coord)) * CANVAS_HEIGHT,
        outside_traffic: area.outside_traffic,
        direction_of_flow: area.direction_of_flow,
        type: area.type,
      }));
      setAreasAnnotations(mappedAreas);

      const mappeddUnarchivedAreas = unarchivedAreas?.map((area: any, idx: number) => ({
        key: idx,
        id: area.id,
        name: area.name,
        x: Number(area.min_point.x_coord) * CANVAS_WIDTH,
        y: Number(area.min_point.y_coord) * CANVAS_HEIGHT,
        width: (Number(area.max_point.x_coord) - Number(area.min_point.x_coord)) * CANVAS_WIDTH,
        height: (Number(area.max_point.y_coord) - Number(area.min_point.y_coord)) * CANVAS_HEIGHT,
        outside_traffic: area.outside_traffic,
        direction_of_flow: area.direction_of_flow,
        type: area.type,
      }));
      setUnarchivedAreasAnnotations(mappeddUnarchivedAreas);
    }
  }, [cameraAreas, idSelectedCamera, departmentId, departmentStats, unarchivedAreas, cameraTills]);

  useEffect(() => {
    setAreasOfInterestList(areasOfInterest);
  }, [areasOfInterest]);

  useEffect(() => {
    const dataAreasToSave =
      areasAnnotations.length > 0
        ? areasAnnotations.map((area: any) => ({
          id: area.id,
          key: area.key,
          name: area.name,
          min_point: [area.x / CANVAS_WIDTH, area.y / CANVAS_HEIGHT],
          max_point: [(area.x + area.width) / CANVAS_WIDTH, (area.y + area.height) / CANVAS_HEIGHT],
          direction_of_flow: area.direction_of_flow,
          outside_traffic: area.outside_traffic,
          type: area.type,
        }))
        : [];
    setAreasToSave(dataAreasToSave);
  }, [areasAnnotations]);

  const SectionGeneral = (
    <Row>
      <Col>
        <Card
          title="General settings"
          subTitle="Adjustments to store camera setup"
          iconsGroup={
            <div className="flex gap-2 items-center">
              {/* <Icon icon="download" color="neutral-600" /> */}
              {/* <Icon icon="edit" color="neutral-600" /> */}
            </div>
          }
          padding
        >
          <Row>
            <Col size="6">
              <LabelWithValue
                icon="link"
                label="Department configuration ID"
                value={departmentData?.es_dept_id || ''}
              />
            </Col>

            <Col size="6">
              <LabelWithValue
                icon="link"
                label="Store configuration ID"
                value={departmentData?.store?.es_store_id || ''}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  const SectionCameraOptions = (
    <Row>
      <Col>
        <Card padding>
          <div className="flex gap-10 flex-wrap">
            {cameraList.map((item) => {
              return (
                <LabelWithValue
                  key={item.id}
                  label={`${item.label} (id: ${item.id})`}
                  value={item.defaultValue}
                  onClick={() => {
                    setIdSelectedCamera(item.id);
                    setSelectedCamera(item);
                    setConfigSelectedArea(null);
                    setSelectedArea(null);
                  }}
                  active={item.id === idSelectedCamera}
                />
              );
            })}
          </div>
        </Card>
      </Col>
    </Row>
  );

  const addCameraArea = (data: any) => {
    dispatch(
      ActionTypes.postAreas.request({
        storeId,
        departmentId,
        cameraId: idSelectedCamera,
        data: [data],
      }),
    );
    setSelectedArea(null);
    setConfigSelectedArea(null);
    dispatch(
      ActionTypes.getCameraAreas.request({
        storeId,
        departmentId,
        cameraId: idSelectedCamera,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
      }),
    );
  };

  const updateCameraArea = (data: any, area: any) => {
    setSelectedArea(null);
    setConfigSelectedArea(null);

    const newDataAreasToSave = areasAnnotations.map((item: any) => {
      const directionToSave = areasDirectionOfFlow.find((item: any) => item.value === data.direction)?.direction_of_flow || {
        up: false,
        down: false,
        left: false,
        right: false,
      };

      if (item.key === area.key) {
        return {
          ...item,
          name: data.areaName,
          type: data.areaType,
          direction_of_flow: directionToSave,
          updated: true,
        };
      }
      if (item.id === null) {
        return {
          ...item,
          name: data.areaName,
          type: data.areaType,
          direction_of_flow: directionToSave,
          updated: true,
        };
      }

      item.updated = true;
      return item;
    });

    setAreasAnnotations(newDataAreasToSave);

    const dataAreasToSave = areasAnnotations.map((area: any) => ({
      id: area.id,
      key: area.key,
      name: area.name,
      min_point: [area.x / CANVAS_WIDTH, area.y / CANVAS_HEIGHT],
      max_point: [
        (area.x + area.width) / CANVAS_WIDTH,
        (area.y + area.height) / CANVAS_HEIGHT,
      ],
      direction_of_flow: area.direction_of_flow,
      outside_traffic: area.outside_traffic,
      type: area.type,
    }));
    setAreasToSave(dataAreasToSave);
  };

  const deleteCameraArea = async (id: number, tillArea?: boolean) => {
    const willDelete = await isConfirmed(
      'Are You Sure You Want To Delete This Area?',
      'You wont be able to recover it or its settings',
      'delete',
    );
    if (willDelete) {
      if (tillArea) {
        dispatch(ActionTypes.deleteTill.request({ tillId: id }));
      } else {
        dispatch(ActionTypes.deleteArea.request({ areaId: id }));
      }

      dispatch(
        ActionTypes.getDepartmentStats.request({
          departmentId,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(
        ActionTypes.getCameraAreas.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(
        ActionTypes.getCameraTills.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
        }),
      );

      dispatch(
        ActionTypes.getAreasOfInterest.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
        }),
      );
      setSelectedArea(null);
      setConfigSelectedArea(null);
    }
  };

  const onSaveEditAreas = async () => {
    const willSave = await isConfirmed(
      'Confirmation',
      'Save Areas Distribution Changes?',
      undefined,
      'Yes',
    );
    if (willSave) {
      const newDataAreasToSave = areasToSave.filter((area: { type: string }) => area.type !== 'QM').map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          min_point: [item.min_point[0], item.min_point[1]],
          max_point: [item.max_point[0], item.max_point[1]],
          direction_of_flow: item.direction_of_flow,
          outside_traffic: item.outside_traffic,
          type: item.type,
        };
      });

      const newTillAreasToSave = areasToSave.filter((area: { type: string }) => area.type === 'QM').map((area: any) => ({
        id: area.id,
        name: area.name,
        min_point: [area.min_point[0], area.min_point[1]],
        max_point: [area.max_point[0], area.max_point[1]],
      }));

      dispatch(
        ActionTypes.postAreas.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          data: newDataAreasToSave,
        }),
      );

      dispatch(
        ActionTypes.postTills.request({
          storeId: Number(storeId),
          departmentId: Number(departmentId),
          cameraId: Number(idSelectedCamera),
          data: newTillAreasToSave,
        }),
      );

      setTimeout(() => {
        dispatch(
          ActionTypes.getCameraAreas.request({
            storeId,
            departmentId,
            cameraId: idSelectedCamera,
            startDate: startTimeTimestamp,
            endDate: endTimeTimestamp,
          }),
        );

        dispatch(
          ActionTypes.getCameraTills.request({
            storeId,
            departmentId,
            cameraId: idSelectedCamera,
            startDate: startTimeTimestamp,
            endDate: endTimeTimestamp,
          }),
        );

        dispatch(
          ActionTypes.getAreasOfInterest.request({
            storeId,
            departmentId,
            cameraId: idSelectedCamera,
          }),
        );
      }, 1000);
    }
  };

  const SectionAreasDistribution = selectedCamera ? (
    <Row>
      <Col>
        <Card
          title={
            <div className="flex flex-row items-end gap-5">
              <span>
                Areas Distribution -
                {' '}
                {selectedCamera.label}
                {' '}
                {selectedCamera.defaultValue}
              </span>
              <Tooltip
                tooltipText="Create and edit areas for each camera in the department."
                tooltipPlacement="top"
                width={250}
              >
                <Icon icon="circle-warning" className="mt-1 m-0 text-gray-500 text-xs" />
              </Tooltip>
            </div>
          }
          subTitle="Camera Areas Adjustments"
          padding
          iconsGroup={
            <div className="flex gap-5 items-center">
              {/* <div className="mt-1.5" onClick={() => onOpenArchiveList()}>
                <Tooltip tooltipPlacement="bottom" tooltipText="Archived Areas" width={111}>
                  <BsArchive size={18} color="#6A6B76" className="mt-[2.2px] cursor-pointer" />
                </Tooltip>
              </div> */}
              {(areasAnnotations.some((area: any) => area.updated)) && (
                <Tooltip tooltipPlacement="top" tooltipText="Save Areas" width={87}>
                  <AiOutlineSave
                    size={20}
                    color="#6A6B76"
                    className="mt-0.5 cursor-pointer"
                    onClick={() => onSaveEditAreas()}
                  />
                </Tooltip>
              )}
              {/* <Icon icon="add" tag="button" color="neutral-600" onClick={onOpenArea} /> */}
            </div>
          }
        >
          <Row>
            <Col>
              <div className="flex gap-2 mb-2">
                <ButtonSelect
                  icon="move"
                  label="Drag"
                  active={dragArea}
                  onClick={() => {
                    setDragArea(!dragArea);
                    setDrawArea(false);
                  }}
                />
                {isSuperUser && (
                  <ButtonSelect
                    icon="add"
                    label="Draw"
                    active={drawArea}
                    onClick={() => {
                      setDrawArea(!drawArea);
                    }}
                  />
                )}
              </div>
              <Canvas
                annotations={areasAnnotations}
                redAreasAnnotations={unarchivedAreasAnnotations}
                canvasHeight={CANVAS_HEIGHT}
                canvasWidth={CANVAS_WIDTH}
                elementName="Area Distribution"
                img={cameraImage}
                imgOpacity={0.6}
                selectedElementId={selectedAreaId}
                setSelectedElementId={setSelectedAreaId}
                setAnnotations={setAreasAnnotations}
                setCameraSelected={setCameraSelected}
                setDataToSave={() => setAreasToSave(areasToSave)}
                withDraggable={dragArea}
                withDraw={drawArea}
              />
            </Col>
            <Col className="gap-4" size="5">
              {areasToSave.length > 0 && areasToSave.map((area: any) => {
                const { value: areaDirection }: any = areasDirectionOfFlow.find((item: any) => {
                  return area.direction_of_flow && item.direction_of_flow.up === area.direction_of_flow.up &&
                    item.direction_of_flow.down === area.direction_of_flow.down &&
                    item.direction_of_flow.left === area.direction_of_flow.left &&
                    item.direction_of_flow.right === area.direction_of_flow.right;
                }) || { value: null };

                return (
                  <div onMouseLeave={() => {
                    if (configSelectedArea === null) {
                      setConfigSelectedArea(null);
                      setSelectedArea(null);
                    }
                  }}
                  >
                    <Areas
                      key={area.key}
                      id={area.id}
                      title={area.name}
                      area={area}
                      setting={area.type || '-'}
                      direction={areaDirection}
                      areasOfInterestList={areasOfInterestList ?? []}
                      active={selectedArea === area.key || false}
                      configActive={(selectedArea === area.key && configSelectedArea === area.key) || false}
                      onClick={(forceSwitch?: boolean) => {
                        if (forceSwitch || (selectedArea === area.key && configSelectedArea === null)) {
                          setSelectedArea(area.key);
                        }
                      }}
                      onConfig={(close?: boolean) => {
                        if (close) {
                          setConfigSelectedArea(null);
                          return;
                        }

                        setConfigSelectedArea(area.key);
                      }}
                      onAdd={addCameraArea}
                      onUpdate={updateCameraArea}
                      onDelete={deleteCameraArea}
                    />
                  </div>
                );
              })}

              {unarchivedAreas?.length > 0 && (
                <h1>Unarchived areas:</h1>
              )}

              {unarchivedAreas?.map((area: any) => {
                const { value: areaDirection }: any = unarchivedAreas.find((item: any) => {
                  return area.direction_of_flow && item.direction_of_flow.up === area.direction_of_flow.up &&
                    item.direction_of_flow.down === area.direction_of_flow.down &&
                    item.direction_of_flow.left === area.direction_of_flow.left &&
                    item.direction_of_flow.right === area.direction_of_flow.right;
                }) || { value: null };

                return (
                  <div onMouseLeave={() => {
                    if (configSelectedArea === null) {
                      setConfigSelectedArea(null);
                      setSelectedArea(null);
                    }
                  }}
                  >
                    <Areas
                      key={area.id}
                      id={area.id}
                      title={area.name}
                      area={area}
                      setting={area.type || '-'}
                      direction={areaDirection}
                      onAdd={addCameraArea}
                      onUpdate={updateCameraArea}
                      onDelete={deleteCameraArea}
                      unarchivedArea
                    />
                  </div>
                );
              })}
            </Col>
          </Row>
        </Card>
      </Col>
      <If condition={isOpenArea && storeId && departmentId && idSelectedCamera}>
        <ModalFormArea isOpen={isOpenArea} onClose={onCloseArea} onSave={addCameraArea} />
      </If>
      <Modal
        isOpen={isOpenArchiveList}
        onClose={onCloseArchiveList}
        title="Archived Areas"
        subTitle="List of Areas Archived"
        className={cx('px-5 py-6 bg-gray-50')}
        size="xl"
      >
        <div className="flex gap-1">
          <ArchivedAreasList
            storeId={Number(storeId ?? 0)}
            cameraId={Number(selectedCamera.id)}
            departmentId={Number(departmentId)}
            onClose={onCloseArchiveList}
          />
        </div>
      </Modal>
    </Row>
  ) : null;

  const addAreaOfInterest = async (data: any) => {
    setAreasOfInterestList([...areasOfInterestList, data]);
  };

  const saveNewAreaOfInterest = async () => {
    const isEmpty = areasOfInterestList.length === 0;
    if (isEmpty) {
      toast.error('Please add at least one area of interest');
      return;
    }

    const isEntryExitEmpty = areasOfInterestList.every((item: any) => {
      return item.entry_areas && item.entry_areas.length > 0 && item.exit_areas && item.exit_areas.length > 0;
    });
    if (!isEntryExitEmpty) {
      toast.error('Please add at least one entry and one exit area');
      return;
    }

    const isEntryExitValid = areasOfInterestList.every((item: any) => {
      return item.entry_areas.length === item.exit_areas.length;
    });
    if (!isEntryExitValid) {
      toast.error('Entry and Exit areas must have the same number of areas');
      return;
    }

    const areasOfInterestToSave = areasOfInterestList.map((item: any) => {
      return {
        name: item.name,
        entry_areas: item.entry_areas,
        exit_areas: item.exit_areas,
      };
    });
    const willSave = await isConfirmed(
      'Confirmation',
      'Save Areas of Interest?',
      undefined,
      'Yes',
    );
    if (willSave) {
      dispatch(
        ActionTypes.postAreasOfInterest.request({
          storeId,
          departmentId,
          cameraId: idSelectedCamera,
          data: areasOfInterestToSave,
        }),
      );
      setTimeout(() => {
        dispatch(
          ActionTypes.getCameraAreasOfInterest.request({
            storeId,
            departmentId,
            cameraId: idSelectedCamera,
          }),
        );
      }, 1000);
    }
  };

  const deleteAreaOfInterest = async (id: number, tempId: any) => {
    if (id) {
      const willDelete = await isConfirmed(
        'Are You Sure You Want To Delete This Area Of Interest?',
        'You wont be able to recover this Area or its settings',
        'delete',
      );
      if (willDelete) {
        dispatch(ActionTypes.deleteAreaOfInterest.request({ areaOfInterestId: id }));
      }
    } else {
      const willDelete = await isConfirmed(
        'Delete This Unsaved Area Of Interest?',
        undefined,
        'delete',
      );
      if (willDelete) {
        setAreasOfInterestList(areasOfInterestList.filter((item: any) => item.tempId !== tempId));
      }
    }
  };

  const SectionAreasOfInterest = (
    <If condition={!areasOfInterestLoading}>
      <AreasOfInterest
        data={areasOfInterestList}
        onAddData={setAreasOfInterestList}
        onSaveData={saveNewAreaOfInterest}
        onOpenAreaOfInterest={onOpenAreaOfInterest}
        areas={areasAnnotations}
        onDelete={deleteAreaOfInterest}
      />
      <If condition={isOpenAreaOfInterest && storeId && departmentId && idSelectedCamera}>
        <ModalFormAreaOfInterest
          isOpen={isOpenAreaOfInterest}
          onClose={onCloseAreaOfInterest}
          onSave={addAreaOfInterest}
        />
      </If>
    </If>
  );

  return (
    <Layout
      title={headerData.location}
      subtitle={headerData.address}
      extraHeader={departmentStats?.name || ''}
      loading={departmentStats_loading || cameraImageLoading || cameraTillsLoading || areasOfInterestLoading}
    >
      {SectionGeneral}
      {departmentId && (
        <CamerasDistribution departmentId={Number(departmentId)} />
      )}
      {SectionCameraOptions}
      {SectionAreasDistribution}
      {SectionAreasOfInterest}
    </Layout>
  );
};

export default DepartamentSettings;
// export default memo(DepartamentSettings);
