import { createApiAction } from 'src/utils/helpers';

export const POST_PRODUCT = 'POST_PRODUCT';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILURE = 'POST_PRODUCT_FAILURE';

export const GET_STORE_PRODUCTS = 'GET_STORE_PRODUCTS';
export const GET_STORE_PRODUCTS_SUCCESS = 'GET_STORE_PRODUCTS_SUCCESS';
export const GET_STORE_PRODUCTS_FAILURE = 'GET_STORE_PRODUCTS_FAILURE';

export const GET_DEPARTMENT_PRODUCTS = 'GET_DEPARTMENT_PRODUCTS';
export const GET_DEPARTMENT_PRODUCTS_SUCCESS =
  'GET_DEPARTMENT_PRODUCTS_SUCCESS';
export const GET_DEPARTMENT_PRODUCTS_FAILURE =
  'GET_DEPARTMENT_PRODUCTS_FAILURE';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const postProduct = createApiAction(POST_PRODUCT);
export const getStoreProducts = createApiAction(GET_STORE_PRODUCTS);
export const getDepartmentProducts = createApiAction(GET_DEPARTMENT_PRODUCTS);
export const updateProduct = createApiAction(UPDATE_PRODUCT);
export const getProduct = createApiAction(GET_PRODUCT);
