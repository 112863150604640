import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Icon } from 'src/components/Icon';
import { PlotHeatmap } from 'src/components/PlotHeatmap';
import { Row } from 'src/components/Row';
import { useSelectFilter } from 'src/hooks/useSelectFilter';
import { useModal } from 'src/hooks/useModal';
import { useRefDimensions } from 'src/hooks/useRefDefinitions';
import { Canvas } from 'src/components/PlotShapesFigure/Canvas';
import { FiltersHeatmapModal, HeatmapModal } from '../../HeatmapModal';
import AreaCanvas from '../../AreaCanvas';

const HEATMAP_PATH_ACTIONS = [
  { title: 'Heatmap', key: 'heatmap' },
  { title: 'Paths', key: 'paths' },
];

const CameraHeatmaps: React.FC = () => {
  const { cameraPaths, cameraPathsLoading, cameraImage, cameraImageLoading } = useSelector(
    (state: any) => state.cameras,
  );

  const { cameraAreas, cameraAreasLoading } = useSelector((state: any) => state.areas);

  const {
    heatmap: { get_heatmap: heatmapData = [] },
    loading: heatMapLoading,
  } = useSelector((state: any) => state.heatmap);

  const [toggleHeatmapPath, setToggleHeatmapPath] = useState<string>('heatmap');
  const [isOpenHeatmap, onCloseHeatmap, onOpenHeatmap] = useModal();
  const [activeOption, setActiveOption] = useState('footfall');
  const [activeOptionViewer, setActiveOptionViewer] = useState('eyelevel');
  const { width, ref } = useRefDimensions({});

  const [heatmapPathFilters] = useSelectFilter({
    options: HEATMAP_PATH_ACTIONS,
    defaultSelected: 'heatmap',
    onSelect: (filter) => setToggleHeatmapPath(filter),
  });

  const [pathsData, setPathsData] = useState<any[]>([]);

  useEffect(() => {
    if (cameraPaths && cameraPaths.get_paths && cameraPaths.get_paths.length > 0) {
      setPathsData(cameraPaths.get_paths);
    }
  }, [cameraPaths]);

  const memoizedHeatmapData = useMemo(() => {
    const { heatmap } = heatmapData;

    if (heatmap) {
      let data;
      if (activeOption === 'totaltime') {
        data = heatmap?.map((item: any) => item.map((heat: any) => heat.total_time));
      } else if (activeOption === 'avgtime') {
        data = heatmap?.map((item: any) => item.map((heat: any) => heat.average_time));
      } else {
        data = heatmap?.map((item: any) => item.map((heat: any) => heat.y));
      }
      return data;
    }

    return [];
  }, [heatmapData, activeOption]);

  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (width) {
      setScale(width / 750);
    }
  }, [width]);

  if (cameraImageLoading || cameraPathsLoading || cameraAreasLoading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 my-3">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="animate-bounce h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="w-[100%] h-[400px] bg-gray-200 p-4 rounded-lg shadow" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="animate-bounce h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="w-[100%] h-[400px] bg-gray-200 p-4 rounded-lg shadow" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col size="6">
          <Card
            title={toggleHeatmapPath === 'heatmap' ? 'Heatmap' : 'Paths'}
            subTitle="This feature displays a heatmap representing the statistics of detected views."
            actionsGroup={heatmapPathFilters}
            iconsGroup={<Icon tag="button" onClick={onOpenHeatmap} icon="fullScreen" />}
          >
            <div className="p-6 h-[540px]">
              {toggleHeatmapPath === 'heatmap' ? (
                <PlotHeatmap
                  data={memoizedHeatmapData}
                  maxValue={heatmapData.max_value}
                  imageSrc={cameraImage}
                  type={activeOption}
                />
              ) : (
                <div ref={ref}>
                  <Canvas
                    canvasHeight={500}
                    canvasWidth={750}
                    elementName="Paths"
                    img={cameraImage}
                    imgOpacity={0.7}
                    path
                    paths={pathsData}
                    scale={scale}
                    withDraggable={false}
                    withDraw={false}
                  />
                </div>
              )}
            </div>
          </Card>
        </Col>
        <AreaCanvas cameraImage={cameraImage} graphData={cameraAreas} />
      </Row>
      <HeatmapModal
        preventScroll={false}
        isOpen={isOpenHeatmap}
        onClose={onCloseHeatmap}
        title="Heatmap"
        subTitle="This feature displays a heatmap representing the statistics of detected views."
      >
        <FiltersHeatmapModal
          className="mb-6"
          activeOption={activeOption}
          setActiveOption={setActiveOption}
          activeOptionViewer={activeOptionViewer}
          setActiveOptionViewer={setActiveOptionViewer}
        />

        <PlotHeatmap
          data={memoizedHeatmapData}
          maxValue={heatmapData.max_value}
          imageSrc={cameraImage}
          type={activeOption}
          loading={heatMapLoading}
          activeOptionViewer={activeOptionViewer}
        />
      </HeatmapModal>
    </>
  );
};

export default CameraHeatmaps;
