import React from 'react';

export type UseModal = [boolean, () => void, () => void, () => void];

export const useModal = (initialState = false): UseModal => {
  const [isOpened, setIsOpened] = React.useState(initialState);

  const handleClose = React.useCallback(
    () => setIsOpened(false),
    [setIsOpened],
  );
  const handleOpen = React.useCallback(() => setIsOpened(true), [setIsOpened]);

  const handleSwitch = React.useCallback(
    () => setIsOpened(!isOpened),
    [setIsOpened, isOpened],
  );

  return [isOpened, handleClose, handleOpen, handleSwitch];
};
