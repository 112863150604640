import { FilterItem } from 'src/components/Filters';

export const GENDER_FILTER: FilterItem = {
  title: 'Gender',
  key: 'gender',
  options: [
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Male',
      value: 'male',
    },
  ],
  defaultValue: '',
};

export const AGE_FILTER: FilterItem = {
  title: 'Age Range',
  key: 'age',
  options: [
    { value: '18-24', label: '18-24' },
    { value: '25-29', label: '25-29' },
    { value: '30-34', label: '30-34' },
    { value: '35-39', label: '35-39' },
    { value: '40-44', label: '40-44' },
    { value: '45-49', label: '45-49' },
    { value: '50-54', label: '50-54' },
    { value: '55-59', label: '55-59' },
    { value: '60-64', label: '60-64' },
    { value: 'over 65', label: 'over 65' },
  ],
  defaultValue: '',
};
