/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';

import { Typography } from 'src/components/Typography';
import { Tag } from 'src/components/Tag';
import { Button } from 'src/components/Button';
import { useDispatch } from 'react-redux';
import * as ActionTypes from 'src/store/actions';

interface Params {
  startDate: Date | null;
  endDate: Date | null;
  startTime?: string;
  endTime?: string;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setIsOpen: (isOpen: boolean) => void;
  className?: string;
}
const CustomActions = ({
  startDate,
  endDate,
  startTime,
  endTime,
  setStartDate,
  setEndDate,
  setIsOpen,
  className,
}: Params) => {
  const dispatch = useDispatch();

  const handleMonth = () => {
    const day = Number(moment().format('D'));
    const thisWeekStart = moment()
      .subtract(day - 1, 'days')
      .toDate();
    const thisWeekEnd = new Date();
    setStartDate(thisWeekStart);
    setEndDate(thisWeekEnd);
  };

  const handleConfirm = () => {
    if (startDate && startTime) {
      const hour = parseInt(startTime.split(':')[0], 10);
      const minutes = parseInt(startTime.split(':')[1], 10);
      const newDate = moment(startDate).hour(hour).minute(minutes);

      setStartDate(newDate.toDate());
      dispatch(ActionTypes.updateStartDate(newDate.toDate()));
    }

    if (endDate && endTime) {
      const hour = parseInt(endTime.split(':')[0], 10);
      const minutes = parseInt(endTime.split(':')[1], 10);
      const newDate = moment(endDate).hour(hour).minute(minutes);

      setEndDate(newDate.toDate());
      dispatch(ActionTypes.updateEndDate(newDate.toDate()));
    }

    setIsOpen(false);
  };

  function handleLastDays(days: number) {
    const startOfMonth = moment().subtract(days, 'days').toDate();
    const endOfMonth = moment().toDate();
    if (days <= 1) {
      setStartDate(startOfMonth);
      setEndDate(startOfMonth);
    } else {
      setStartDate(startOfMonth);
      setEndDate(endOfMonth);
    }
  }

  return (
    <div className={cx('pt-6', className)}>
      <Typography variant="standard-h6">Shortcuts</Typography>
      <hr className="mt-2 mb-4" />

      <div className="flex flex-wrap gap-4">
        <Tag role="button" className="datapicker-action" onClick={() => handleLastDays(0)}>
          Today
        </Tag>
        <Tag role="button" className="datapicker-action" onClick={() => handleLastDays(1)}>
          Yesterday
        </Tag>

        <Tag role="button" className="datapicker-action" onClick={() => handleLastDays(7)}>
          Last 7 days
        </Tag>
        <Tag role="button" className="datapicker-action" onClick={() => handleLastDays(30)}>
          Last 30 days
        </Tag>
        <Tag role="button" className="datapicker-action" onClick={handleMonth}>
          This Month
        </Tag>
      </div>

      <div className="flex justify-end">
        <Button size="medium" className="datapicker-action py-1.5 px-4" onClick={handleConfirm}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CustomActions;
