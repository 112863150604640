import React from 'react';
import { Card } from 'src/components/Card';
import Logo from 'src/assets/images/TrackIn_Logo.svg';
import { Typography } from 'src/components/Typography';

interface Params {
  title: string,
  subtitle: string,
  children: React.ReactNode,
}

const AuthLayout = ({ title, subtitle, children }: Params) => {
  return (
    <div className="flex flex-col h-full w-full bg-gray-50 justify-between p-10">
      <div className="flex flex-1 justify-center items-center bg-red-200">
        <div className="flex flex-col justify-center align-middle items-center">
          <img src={Logo} alt="Trackin" width="100" className="m-auto mb-10" />
          <Typography variant="standard-h1" className="mb-4">
            {title}
          </Typography>
          <Typography>
            {subtitle}
          </Typography>
          <Card className="w-[500px] mt-10">
            {children}
          </Card>
        </div>
      </div>
      <footer className="w-full">
        <hr className="w-full border-gray-200 mb-10 " />
        <div className="flex justify-between w-full ">
          <div className="text-neutral-600 text-xs">info@trackin.com</div>
          <div className="text-neutral-600 text-xs">
            All rights reserved @ Trackin LTD
          </div>
          <div className="flex gap-10 text-neutral-600 text-xs">
            <span>Contacts</span>
            <span>FAQ</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AuthLayout;
