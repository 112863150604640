/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Tooltip } from 'src/components/Tooltip';
import { convertIntervalDateDays } from 'src/utils/helpers';
import { useSelector } from 'react-redux';
import { IIndicatorType } from 'src/interfaces/store.interface';
import TimeSpent from '../../TimeSpent';
import HeatMapCalendarPlot from '../../heatMapCalendarPlot';

type Props = {
  intervalLabel: string;
  startDate: string;
  endDate: string;
  indicators: IIndicatorType[];
};

const TimeAndPopularity: React.FC<Props> = ({ intervalLabel, startDate, endDate, indicators }) => {
  const { storeTimeSpentGraphLoading } = useSelector((state: any) => state.graphs);
  const { loading: heatmap_loading } = useSelector((state: any) => state.heatmap);

  const [isHeatmapTotal, setIsHeatmapTotal] = useState(true);
  const [heatmapDownloadCSV, setHeatmapDownloadCSV] = useState([]);

  const {
    storeTimeSpentGraph: { data: timespent_graph, categories: timespent_categories },
  } = useSelector((state: any) => state.graphs);

  if (heatmap_loading || storeTimeSpentGraphLoading) {
    return (
      <div className="flex flex-row gap-3 my-3 animate-pulse">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-[300px] w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-row gap-2">
          <div className="h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-[300px] w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      <Col size="5">
        <TimeSpent
          data={timespent_graph}
          categories={timespent_categories}
          intervalLabel={intervalLabel}
          indicator={indicators.find((item) => item.name === 'avgtime') || {}}
          labelDoD={convertIntervalDateDays(startDate, endDate)}
        />
      </Col>

      <Col size="7">
        <Card
          className="w-full pr-4 pl-4"
          padding
          title="Popular Days and Hours"
          subTitle="Feature Shows your Most Popular Days and Hours in Store."
          iconsGroup={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              {heatmapDownloadCSV && (
                <DownloadCSV filename={`Popular Days and Hours - ${intervalLabel}`} data={heatmapDownloadCSV}>
                  <Icon icon="download" className="text-xl" />
                </DownloadCSV>
              )}
            </Tooltip>
          }
          actionsGroup={[
            { title: 'Total', active: isHeatmapTotal, onclick: () => setIsHeatmapTotal(true) },
            { title: 'Average', active: !isHeatmapTotal, onclick: () => setIsHeatmapTotal(false) },
          ]}
        >
          <HeatMapCalendarPlot
            isHeatmapTotal={isHeatmapTotal}
            setHeatmapDownloadCSV={setHeatmapDownloadCSV}
            className="custom-heatmap"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TimeAndPopularity;
