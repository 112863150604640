/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import cx from 'classnames';

import styles from './styles.module.css';

export type PopUpProps = {
  className?: string;
  element: React.ReactNode;
  placement?:
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'bottom-center'
    | 'top-center';
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const PopUp = ({
  className,
  isOpen,
  onClose,
  children,
  element,
  placement = 'bottom-start',
}: PopUpProps) => {
  return (
    <OutsideClickHandler display="flex" onOutsideClick={onClose}>
      <div className={cx('relative')}>
        <div
          className={cx('w-max')}
          onClick={() => {
            if (isOpen) {
              onClose?.();
            }
          }}
        >
          {children}
        </div>

        <div
          className={cx(
            styles.popup,
            {
              hidden: !isOpen,
              [styles.topStart]: placement === 'top-start',
              [styles.topEnd]: placement === 'top-end',
              [styles.bottomStart]: placement === 'bottom-start',
              [styles.bottomEnd]: placement === 'bottom-end',
              [styles.bottomCenter]: placement === 'bottom-center',
              [styles.topCenter]: placement === 'top-center',
            },
            className,
          )}
        >
          {element}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
