import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getAllBrands() {
  try {
    const { data } = yield call(Api.getAllBrands);
    yield put(ActionTypes.getAllBrands.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllBrands.failure(e));
  }
}

function* getAllBrandsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_BRANDS, getAllBrands);
}

function* getBrandUsers({ payload }: any) {
  try {
    const { data } = yield call(Api.getBrandUsers, payload);
    yield put(ActionTypes.getBrandUsers.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getBrandUsers.failure(e));
  }
}

function* getBrandUsersSaga() {
  yield takeLatest(ActionTypes.GET_BRAND_USERS, getBrandUsers);
}

function* postBrand({ payload }: any) {
  try {
    const { data } = yield call(Api.postBrand, payload);
    yield put(ActionTypes.postBrand.success(data));
    notifyInfo('Store Created!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postBrand.failure(e));
  }
}

function* postBrandSaga() {
  yield takeLatest(ActionTypes.POST_BRAND, postBrand);
}

function* updateBrand({ payload }: any) {
  try {
    const { data } = yield call(Api.updateBrand, payload);
    yield put(ActionTypes.updateBrand.success(data));
    notifyInfo('Store Updated!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateBrand.failure(e));
  }
}

function* updateBrandSaga() {
  yield takeLatest(ActionTypes.UPDATE_BRAND, updateBrand);
}

function* postBrandUser({ payload }: any) {
  try {
    const { data } = yield call(Api.postBrandUser, payload);
    yield put(ActionTypes.postBrandUser.success(data));
    notifyInfo('User Created!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postBrandUser.failure(e));
  }
}

function* postBrandUsersSaga() {
  yield takeLatest(ActionTypes.POST_BRAND_USERS, postBrandUser);
}

function* updateBrandUser({ payload }: any) {
  try {
    const { data } = yield call(Api.updateBrandUser, payload);
    yield put(ActionTypes.updateBrandUser.success(data));
    notifyInfo('User Updated!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateBrandUser.failure(e));
  }
}

function* updateBrandUsersSaga() {
  yield takeLatest(ActionTypes.UPDATE_BRAND_USERS, updateBrandUser);
}

function* deleteBrandUser({ payload }: any) {
  try {
    yield call(Api.deleteBrandUser, payload);
    yield put(ActionTypes.deleteBrandUser.success(payload));
    notifyInfo('User Deleted!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.deleteBrandUser.failure(e));
  }
}

function* deleteBrandUsersSaga() {
  yield takeLatest(ActionTypes.DELETE_BRAND_USERS, deleteBrandUser);
}

export default function* rootSaga() {
  yield all([
    fork(getAllBrandsSaga),
    fork(getBrandUsersSaga),
    fork(postBrandSaga),
    fork(postBrandUsersSaga),
    fork(updateBrandUsersSaga),
    fork(deleteBrandUsersSaga),
    fork(updateBrandSaga),
  ]);
}
