import * as Action from './actions';

const initialState = {
  emailPreferences: {},
  emailPreferencesLoading: false,
  emailPreferencesError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.CREATE_USER_EMAIL_PREFERENCES: {
      return {
        ...state,
        emailPreferencesLoading: true,
        emailPreferencesError: null,
      };
    }
    case Action.CREATE_USER_EMAIL_PREFERENCES_SUCCESS: {
      return {
        ...state,
        emailPreferences: action.payload,
        emailPreferencesLoading: false,
        emailPreferencesError: null,
      };
    }
    case Action.CREATE_USER_EMAIL_PREFERENCES_FAILURE: {
      return {
        ...state,
        emailPreferencesLoading: false,
        emailPreferencesError: action.error,
      };
    }

    case Action.UPDATE_USER_EMAIL_PREFERENCES: {
      return {
        ...state,
        emailPreferencesLoading: true,
        emailPreferencesError: null,
      };
    }

    case Action.UPDATE_USER_EMAIL_PREFERENCES_SUCCESS: {
      return {
        ...state,
        emailPreferences: action.payload,
        emailPreferencesLoading: false,
        emailPreferencesError: null,
      };
    }

    case Action.UPDATE_USER_EMAIL_PREFERENCES_FAILURE: {
      return {
        ...state,
        emailPreferencesLoading: false,
        emailPreferencesError: action.error,
      };
    }

    default:
      return state;
  }
};
