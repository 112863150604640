import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

const asyncGetStoreTills = async (storeId: any, startDate: any, endDate: any, ageFilter: any = '', genderFilter: any = '') => {
  return Api.getStoreTills(storeId, startDate, endDate, genderFilter, ageFilter);
};

const asyncGetDepartmentTills = async (
  storeId: any,
  departmentId: any,
  startDate: any,
  endDate: any,
  ageFilter: any = '',
  genderFilter: any = '',
) => {
  return Api.getDepartmentTills(storeId, departmentId, startDate, endDate, genderFilter, ageFilter);
};

function* getStoreTills({ payload }: any) {
  try {
    const { data } = yield call(
      asyncGetStoreTills,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.ageFilter,
      payload.genderFilter,
    );
    yield put(ActionTypes.getStoreTills.success(data));
  } catch (e) {
    yield put(ActionTypes.getStoreTills.failure(e));
  }
}

function* getStoreTillsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_TILLS, getStoreTills);
}

function* getDepartmentTills({ payload }: any) {
  try {
    const { data } = yield call(
      asyncGetDepartmentTills,
      payload.storeId,
      payload.departmentId,
      payload.startDate,
      payload.endDate,
      payload.ageFilter,
      payload.genderFilter,
    );
    yield put(ActionTypes.getDepartmentTills.success(data));
  } catch (e) {
    yield put(ActionTypes.getDepartmentTills.failure(e));
  }
}

function* getDepartmentTillsSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENT_TILLS, getDepartmentTills);
}

function* getCameraTills({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCameraTills,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getCameraTills.success(data));
  } catch (e) {
    yield put(ActionTypes.getCameraTills.failure(e));
  }
}

function* getCameraTillsSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_TILLS, getCameraTills);
}

function* postTills({ payload }: any) {
  try {
    const { data } = yield call(
      Api.postTills,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.data,
    );
    yield put(ActionTypes.postTills.success(data));
    notifyInfo('Till created!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postTills.failure(e));
  }
}

function* postTillsSaga() {
  yield takeLatest(ActionTypes.POST_TILLS, postTills);
}

function* deleteTill({ payload }: any) {
  try {
    const { data } = yield call(Api.deleteTill, payload.tillId);
    yield put(ActionTypes.deleteTill.success(data));
    notifyInfo('Till deleted!');
  } catch (e) {
    yield put(ActionTypes.deleteTill.failure(e));
  }
}

function* deleteTillSaga() {
  yield takeLatest(ActionTypes.DELETE_TILL, deleteTill);
}

export default function* rootSaga() {
  yield all([
    fork(getStoreTillsSaga), fork(getDepartmentTillsSaga),
    fork(getCameraTillsSaga), fork(postTillsSaga),
    fork(deleteTillSaga),
  ]);
}
