import * as Action from './actions';

const initialState = {
  cameras: [],
  loading: false,
  error: null,

  cameraImage: '',
  cameraImageLoading: false,
  cameraImageError: null,

  updateCameras_loading: false,
  updateCameras_error: null,

  cameraStats: {},
  cameraStatsLoading: false,
  cameraStatsError: null,

  cameraStatsToday: {},
  cameraStatsTodayLoading: false,
  cameraStatsTodayError: null,

  camerasWithInfo: [],
  camerasWithInfoLoading: false,
  camerasWithInfoError: null,

  cameraPaths: [],
  cameraPathsLoading: false,
  cameraPathsError: null,
  isCameraPaths: false,

  camerasDistribution: [],
  camerasDistributionLoading: false,
  camerasDistributionError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_CAMERAS: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_CAMERAS_SUCCESS: {
      return {
        ...state,
        cameras: action.payload,
        loading: false,
      };
    }
    case Action.GET_CAMERAS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.UPDATE_CAMERAS: {
      return {
        ...state,
        updateCameras_loading: true,
      };
    }
    case Action.UPDATE_CAMERAS_SUCCESS: {
      return {
        ...state,
        updateCameras_loading: false,
      };
    }
    case Action.UPDATE_CAMERAS_FAILURE: {
      return {
        ...state,
        updateCameras_loading: false,
        updateCameras_error: action.error,
      };
    }

    case Action.GET_CAMERA_IMAGE: {
      return {
        ...state,
        cameraImageLoading: true,
      };
    }
    case Action.GET_CAMERA_IMAGE_SUCCESS: {
      return {
        ...state,
        cameraImage: action.payload,
        cameraImageLoading: false,
      };
    }
    case Action.GET_CAMERA_IMAGE_FAILURE: {
      return {
        ...state,
        cameraImageLoading: false,
        cameraImageError: action.error,
      };
    }

    case Action.GET_CAMERA_IMAGE_SNAPSHOT: {
      return {
        ...state,
        cameraImageLoading: true,
      };
    }
    case Action.GET_CAMERA_IMAGE_SNAPSHOT_SUCCESS: {
      return {
        ...state,
        cameraImage: action.payload,
        cameraImageLoading: false,
      };
    }
    case Action.GET_CAMERA_IMAGE_SNAPSHOT_FAILURE: {
      return {
        ...state,
        cameraImageLoading: false,
        cameraImageError: action.error,
      };
    }

    case Action.GET_CAMERAS_WITH_INFO: {
      return {
        ...state,
        camerasWithInfoLoading: true,
      };
    }
    case Action.GET_CAMERAS_WITH_INFO_SUCCESS: {
      return {
        ...state,
        camerasWithInfo: action.payload,
        camerasWithInfoLoading: false,
      };
    }
    case Action.GET_CAMERAS_WITH_INFO_FAILURE: {
      return {
        ...state,
        camerasWithInfoLoading: false,
        camerasWithInfoError: action.error,
      };
    }

    case Action.GET_CAMERA_STATS: {
      return {
        ...state,
        cameraStatsLoading: true,
      };
    }
    case Action.SET_CAMERA_PATHS: {
      return {
        ...state,
        isCameraPaths: false,
      };
    }
    case Action.GET_CAMERA_STATS_SUCCESS: {
      return {
        ...state,
        cameraStats: action.payload,
        cameraStatsLoading: false,
      };
    }
    case Action.GET_CAMERA_STATS_FAILURE: {
      return {
        ...state,
        cameraStatsTodayLoading: false,
        cameraStatsTodayError: action.error,
      };
    }
    case Action.GET_CAMERA_STATS_TODAY: {
      return {
        ...state,
        cameraStatsTodayLoading: true,
      };
    }
    case Action.GET_CAMERA_STATS_TODAY_SUCCESS: {
      return {
        ...state,
        cameraStatsToday: action.payload,
        cameraStatsTodayLoading: false,
      };
    }
    case Action.GET_CAMERA_STATS_TODAY_FAILURE: {
      return {
        ...state,
        cameraStatsTodayLoading: false,
        cameraStatsTodayError: action.error,
      };
    }

    case Action.GET_CAMERA_PATHS: {
      return {
        ...state,
        cameraPathsLoading: true,
        isCameraPaths: true,
      };
    }
    case Action.GET_CAMERA_PATHS_SUCCESS: {
      return {
        ...state,
        cameraPaths: action.payload,
        cameraPathsLoading: false,
      };
    }
    case Action.GET_CAMERA_PATHS_FAILURE: {
      return {
        ...state,
        cameraPathsLoading: false,
        cameraPathsError: action.error,
      };
    }

    case Action.GET_CAMERAS_DISTRIBUTION: {
      return {
        ...state,
        camerasDistributionLoading: true,
      };
    }
    case Action.GET_CAMERAS_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        camerasDistribution: action.payload,
        camerasDistributionLoading: false,
      };
    }
    case Action.GET_CAMERAS_DISTRIBUTION_FAILURE: {
      return {
        ...state,
        camerasDistribution: [],
        camerasDistributionLoading: false,
        camerasDistributionError: action.error,
      };
    }

    default:
      return state;
  }
};
