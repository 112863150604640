import { combineReducers } from 'redux';
import stores from './modules/store/reducer';
import products from './modules/product/reducer';
import departments from './modules/department/reducer';
import locations from './modules/location/reducer';
import collections from './modules/collection/reducer';
import auth from './modules/auth/reducer';
import cameras from './modules/cameras/reducer';
import graphs from './modules/graphs/reducer';
import dateTime from './modules/datetime/reducer';
import heatmap from './modules/heatmap/reducer';
import tills from './modules/tills/reducer';
import areas from './modules/areas/reducer';
import brands from './modules/brands/reducer';
import areasOfInterest from './modules/areasOfInterest/reducer';
import filter from './modules/filter/reducer';
import user from './modules/user/reducer';
import rehydrate from './modules/rehydrate/reducer';

// https://redux.js.org/api/combinereducers
export default combineReducers({
  stores,
  products,
  departments,
  locations,
  collections,
  auth,
  cameras,
  graphs,
  dateTime,
  heatmap,
  tills,
  areas,
  brands,
  areasOfInterest,
  filter,
  user,
  // rehydrate,
});
