import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Filters } from 'src/components/Filters';
import { Icon } from 'src/components/Icon';
import Layout from 'src/components/layout';
import { Row } from 'src/components/Row';
import { Typography } from 'src/components/Typography';
import { useModal } from 'src/hooks/useModal';
import { If } from 'src/components/If';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import { ModalFormBrand, FormBrand } from 'src/pages/SuperAdmin/Common/ModalFormBrand';
import { FormUser, ModalFormUser } from 'src/pages/SuperAdmin/Common/ModalFormUser';
import { ModalFormStore, FormStore } from 'src/pages/SuperAdmin/Common/ModalFormStore';
import { Pagination } from 'src/components/Pagination';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import * as ActionTypes from 'src/store/actions';
import { STORE_FILTERS } from '../Common/constants';
import APIStatusCard from './APIStatusCard/APIStatusCard';

type BrandsProps = {
  id: number;
  name: string;
  address?: string;
  icon_url?: string;
  employees?: any[];
  stores?: any[];
  icon?: string;
  phone_number?: string;
};

const cols = [
  {
    field: 'brand',
    title: 'Brand name',
    order: true,
  },
  {
    field: 'stores',
    title: 'No. of stores',
    order: true,
  },
  {
    field: 'users',
    title: 'Users',
    order: true,
  },
  {
    field: 'actions',
    title: 'Actions',
    order: true,
    align: 'right',
  },
];

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { brands } = useSelector((state: any) => state.brands);
  const { isConfirmed } = useConfirm();

  const [isOpenModalBrand, onCloseModalBrand, onOpenModalBrand] = useModal(false);
  const [selectedBrand, setSelectedBrand] = React.useState<FormBrand | undefined>();

  const [, setBrands] = useState<BrandsProps[]>([]);

  const handleCloseModalBrand = () => {
    setSelectedBrand(undefined);
    onCloseModalBrand();
  };

  const handleSubmitBrand = (values: FormBrand) => {
    alert(JSON.stringify(values));
    handleCloseModalBrand();
  };

  const handleEditBrand = (brand: FormBrand) => {
    setSelectedBrand(brand);
    onOpenModalBrand();
  };

  const [isOpenModalUser, onCloseModalUser, onOpenModalUser] = useModal(false);
  const [selectedUser, setSelectedUser] = React.useState<FormUser | undefined>();

  const handleCloseModalUser = () => {
    setSelectedUser(undefined);
    onCloseModalUser();
  };

  const handleSubmitUser = (values: FormUser) => {
    alert(JSON.stringify(values));
    handleCloseModalUser();
  };

  const handleEditUser = (user: FormUser) => {
    setSelectedUser(user);
    onOpenModalUser();
  };

  const [isOpenModalStore, onCloseModalStore, onOpenModalStore] = useModal(false);
  const [selectedStore, setSelectedStore] = React.useState<FormStore | undefined>();

  const handleCloseModalStore = () => {
    setSelectedStore(undefined);
    onCloseModalStore();
  };

  const handleSubmitStore = (values: FormStore) => {
    alert(JSON.stringify(values));
    handleCloseModalStore();
  };

  const handleDelete = async () => {
    const willDelete = await isConfirmed(
      'Are You Sure You Want To Delete This Brand?',
      'You wont be able to recover this brand or its settings',
      'delete',
    );

    alert(willDelete);
  };

  function loadBrands() {
    dispatch(ActionTypes.getAllBrands.request({}));
  }

  useEffect(() => {
    loadBrands();
  }, []);

  return (
    <Layout title="Admin Dashboard">
      <If condition={isOpenModalBrand}>
        <ModalFormBrand
          isOpen={isOpenModalBrand}
          onClose={handleCloseModalBrand}
          brands={brands}
          setBrands={setBrands}
          defaultValues={selectedBrand}
        />
      </If>

      <If condition={isOpenModalUser}>
        <ModalFormUser isOpen={isOpenModalUser} onClose={handleCloseModalUser} defaultValues={selectedUser} />
      </If>

      <If condition={isOpenModalStore}>
        <ModalFormStore isOpen={isOpenModalStore} onClose={handleCloseModalStore} defaultValues={selectedStore} />
      </If>
      <Row>
        <Col>
          <Filters filters={STORE_FILTERS} title="Filters" onApplyFilters={(values) => alert(JSON.stringify(values))} />
        </Col>

        <Col>
          <div className="flex justify-end gap-4">
            <Button
              onClick={onOpenModalBrand}
              variant="dropdown-button"
              iconProps={{
                icon: 'add',
                side: 'left',
                size: 'sm',
              }}
            >
              <Typography variant="small-button">Add New Brand</Typography>
            </Button>

            <Button
              onClick={onOpenModalStore}
              variant="dropdown-button"
              iconProps={{
                icon: 'add',
                side: 'left',
                size: 'sm',
              }}
            >
              <Typography variant="small-button">Add New Store</Typography>
            </Button>

            <Button
              onClick={onOpenModalUser}
              variant="dropdown-button"
              iconProps={{
                icon: 'add',
                side: 'left',
                size: 'sm',
              }}
            >
              <Typography variant="small-button">Add New User</Typography>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card title="Brands" subTitle="View all brands contracted" padding>
            <div className="flex flex-col">
              <table className="w-full">
                <thead className="border-b-2 border-b-neutral-100 ">
                  <tr>
                    {cols.map((col) => (
                      <Typography
                        variant="caption-3"
                        color="neutral-600"
                        tag="td"
                        className={cx('px-2 py-1', {
                          'text-left': true,
                          'text-right': col.align === 'right',
                        })}
                        key={col.title}
                      >
                        {col.title}
                      </Typography>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {brands.length <= 0 ? (
                    <Typography
                      variant="caption-2"
                      tag="td"
                      className={cx('p-2', {
                        'text-neutral-800': true,
                      })}
                    >
                      No Data
                    </Typography>
                  ) : (
                    brands.map((brand: BrandsProps) => (
                      <tr
                        className={cx('w-full transition-colors duration-100', {
                          'hover:bg-primary-super-light': true,
                        })}
                        key={brand.id}
                      >
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('p-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          {brand.name}
                        </Typography>
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('p-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          <Link to={`stores/${brand.id}`} style={{ color: '#3182ce' }}>
                            {brand.stores?.length}
                          </Link>
                        </Typography>

                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('p-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          <Link to={`users/${brand.id}`} style={{ color: '#3182ce' }}>
                            {brand.employees?.length}
                          </Link>
                        </Typography>

                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('p-2 text-right', {
                            'text-neutral-800': true,
                          })}
                        >
                          <Icon
                            icon="edit"
                            tag="button"
                            onClick={() => handleEditBrand({
                              id: String(brand.id),
                              brand: brand.name,
                              address: brand.address,
                              contactsPhoneNumber: brand.phone_number,
                            })}
                          />
                          <Icon icon="delete" tag="button" onClick={() => handleDelete()} />
                        </Typography>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <Pagination className="ml-auto mt-4" count={5} />
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <APIStatusCard />
        </Col>
      </Row>
    </Layout>
  );
};
