import React from 'react';

import cx from 'classnames';
import { Icon, TIcon } from '../Icon';

export type Option = {
  value: string;
  label: string;
};

export type DropdownProps = {
  options: Option[];
  className?: string;
  icon?: TIcon;
  placeholder?: string;
  selectedValue?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Dropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(
  ({ options, icon, className, selectedValue, ...props }, ref) => {
    const hasIcon = !!icon;

    return (
      <div className={cx('relative', className)}>
        <select
          className={cx('text-base font-normal text-neutral-900 relative', 'py-2 w-full bg-neutral-50', {
            'pl-6': hasIcon,
          })}
          ref={ref}
          defaultValue={selectedValue}
          {...props}
        >
          {options?.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </select>
        {hasIcon && <Icon color="primary" className="absolute left-2 bottom-3 z-10" icon={icon} />}
      </div>
    );
  },
);
