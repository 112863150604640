import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Canvas } from 'src/components/PlotShapesFigure/Canvas';
import React, { useEffect, useState } from 'react';
import { useRefDimensions } from 'src/hooks/useRefDefinitions';
import { getSimpleMinutesAndSeconds } from 'src/utils/helpers';

const CANVAS_HEIGHT = 500;

export default function AreaCanvas({ cameraImage, graphData }: any) {
  const [selectedAreaId, setSelectedAreaId] = useState<any>(null);
  const [areasAnnotations, setAreasAnnotations] = useState<any>([]);
  const { width, ref } = useRefDimensions({});
  const [variableWidth, setVariableWidth] = useState<number>(500);

  useEffect(() => {
    setVariableWidth(width - 55);
  }, [width]);

  useEffect(() => {
    const mappedAreas = graphData.map((area: any, idx: number) => ({
      key: idx,
      id: area.id,
      name: area.name,
      x: Number(area.min_point.x_coord) * variableWidth,
      y: Number(area.min_point.y_coord) * CANVAS_HEIGHT,
      width: (Number(area.max_point.x_coord) - Number(area.min_point.x_coord)) * variableWidth,
      height: (Number(area.max_point.y_coord) - Number(area.min_point.y_coord)) * CANVAS_HEIGHT,
      outside_traffic: area.outside_traffic,
      direction_of_flow: area.direction_of_flow,
      type: area.type,
      data: {
        totalFootfall: area.footfall_metrics,
        averageTime: getSimpleMinutesAndSeconds(area.time_metrics?.average_time),
      },
    }));
    setAreasAnnotations(mappedAreas);
  }, [graphData, variableWidth]);

  return (
    <Col size="6">
      <Card
        title="Till and Areas"
        subTitle="The feature shows you profile view statistics."
      >
        <div className="p-6 h-[540px]" ref={ref}>
          <Canvas
            annotations={areasAnnotations}
            canvasHeight={500}
            canvasWidth={variableWidth}
            elementName="Area Distribution"
            img={cameraImage}
            imgOpacity={0.7}
            selectedElementId={selectedAreaId}
            setAnnotations={setAreasAnnotations}
            setSelectedElementId={setSelectedAreaId}
            withDraggable={false}
            withDraw={false}
            withTooltip
          />
        </div>
      </Card>
    </Col>
  );
}
