import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { RadioButton } from 'src/components/RadioButton';
import { DropdownMulti, Option } from 'src/components/DropdownMulti';
import { axiosJsonInstance } from 'src/store/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import styles from './styles.module.css';

export type UserEmailSubscription = {
  id: number;
  created_at: string;
  updated_at: string;
  last_email_sent?: string;
  allow_marketing_emails: boolean;
  notification_period: number;
}

export type FormUser = {
  id: string;
  brand: string;
  store?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  status?: string;
  is_superuser: boolean;
  is_brand_admin: boolean;
  memberStored?: string;
  storedManaged?: Option[];
  email_frequency?: string;
  subscribe_to_emails?: boolean;
  email_subscription?: UserEmailSubscription
};

export type ModalFormUserProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: FormUser;
  onSubmit: (values: FormUser) => void;
  // eslint-disable-next-line no-unused-vars
};

type StoresProps = {
  id: number;
  name: string;
};

export const ModalFormUser = ({ isOpen, onClose, defaultValues, onSubmit }: ModalFormUserProps) => {
  const [stores, setStores] = useState<StoresProps[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const { user } = useSelector((state: any) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
    setError,
    getValues,
    control,
  } = useForm<FormUser>({ defaultValues });

  const { title, subTitle, buttonLabel } = React.useMemo(() => {
    const hasDefaultValues = !!defaultValues;

    return {
      buttonLabel: hasDefaultValues ? 'Save' : 'Add',
      title: hasDefaultValues ? 'Edit User' : 'Add New User',
      subTitle: hasDefaultValues ? 'Edit brand main information' : 'Add new users to system',
    };
  }, [defaultValues]);

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  async function loadStores() {
    try {
      const response = await axiosJsonInstance.get(`/brand/${user.brand}`);

      setStores(response.data.stores);
    } catch (err: any) {
      toast.error(err.data.error[0]);
    }
  }

  useEffect(() => {
    loadStores();
  }, []);

  const onSubmitForm = handleSubmit(async (data) => {
    onSubmit(data);
  });

  function handleComparePassword(e: { target: { value: string } }) {
    const password = getValues('password');
    const confirmPassword = e.target.value;
    setError('password', { message: password !== confirmPassword ? 'Password does not match' : undefined });
  }

  return (
    <Modal
      hideCloseButton
      title={title}
      subTitle={subTitle}
      isOpen={isOpen}
      onClose={handleClose}
      className={cx('px-5 py-6 overflow-auto')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={onSubmitForm}>
        <FormField label="Stores">
          <Controller
            name="storedManaged"
            control={control}
            render={({ field }) => {
              return (
                <DropdownMulti
                  options={stores?.map((brand) => ({
                    value: String(brand.id),
                    label: brand.name,
                  }))}
                  {...field}
                />
              );
            }}
          />
        </FormField>
        <FormField label="First name" error={errors.first_name?.message}>
          <TextInput {...register('first_name')} icon="user" placeholder="e.g. John" />
        </FormField>
        <FormField label="Last name" error={errors.last_name?.message}>
          <TextInput {...register('last_name')} icon="user" placeholder="e.g. Gates" />
        </FormField>

        <FormField label="Username" error={errors.username?.message}>
          <TextInput {...register('username')} icon="user" placeholder="e.g. johngates" />
        </FormField>

        <FormField label="E-mail" error={errors.email?.message}>
          <TextInput {...register('email')} icon="user" placeholder="e.g. john@email.com" />
        </FormField>

        <FormField label="Password" error={errors.password?.message}>
          <div className="relative">
            <TextInput
              {...register('password')}
              icon="lock"
              placeholder="Password"
              type={!showPassword ? 'password' : 'text'}
            />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className={styles.showPassword}>
              {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        </FormField>
        <FormField label="Confirm Password" error={errors.password?.message}>
          <div className="relative">
            <TextInput
              {...register('confirmPassword')}
              icon="lock"
              placeholder="Confirm Password"
              type={!showConfirmPassword ? 'password' : 'text'}
              onBlur={(e) => handleComparePassword(e)}
            />
            <button
              type="button"
              onClick={() => setConfirmShowPassword(!showConfirmPassword)}
              className={styles.showPassword}
            >
              {!showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        </FormField>
        <FormField label="User status" error={errors.last_name?.message}>
          <div className="flex gap-8">
            <RadioButton {...register('status')} name="status" value="member" label="Member" />
            <RadioButton {...register('status')} name="status" value="brand_admin" label="Brand Admin" />
            <RadioButton {...register('status')} name="status" value="super_admin" label="Super User" />
          </div>
        </FormField>
        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={handleClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              {buttonLabel}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
