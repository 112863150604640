/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Typography } from 'src/components/Typography';

interface Params {
  startTime?: string;
  endTime?: string;
  stepMinutes?: number;
  setSelectedTime: (values: string) => void;
  selectedTime: string;
}

const TimeInput = ({
  startTime = '00:00',
  endTime = '23:30',
  stepMinutes = 30,
  setSelectedTime,
  selectedTime = '',
}: Params) => {
  const [listTime, setListTime] = useState<string[]>([]);

  useEffect(() => {
    let initialDateTime = moment()
      .hour(parseInt(startTime.split(':')[0], 10))
      .minute(parseInt(startTime.split(':')[1], 10));

    const endTimeDate = moment()
      .hour(parseInt(endTime.split(':')[0], 10))
      .minute(parseInt(endTime.split(':')[1], 10));

    const timeArray = [];
    timeArray.push(moment(initialDateTime).format('HH:mm'));
    do {
      initialDateTime = moment(initialDateTime).add(stepMinutes, 'm');
      timeArray.push(moment(initialDateTime).format('HH:mm'));
    } while (initialDateTime.format('HH:mm') !== endTimeDate.format('HH:mm'));
    setListTime(timeArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx('flex flex-col gap-3 items-center')}>
      <Typography variant="caption-2" color="neutral-800">
        Time
      </Typography>

      <div className="max-h-[9rem] flex flex-col gap-y-2 overflow-auto px-1 w-[70px]">
        {listTime.map((time) => (
          <Typography
            variant="caption-3"
            // tag="button"
            role="button"
            key={time}
            onClick={() => setSelectedTime(time)}
            className={cx('p-1 px-2 hover:bg-primary-light hover:text-white hover:rounded-full', 'datapicker-action', {
              'bg-primary text-white rounded-full ': selectedTime === time,
            })}
          >
            {time}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default TimeInput;
