import React from 'react';
import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { Illustration } from 'src/components/Illustration';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { Typography } from 'src/components/Typography';
import { useConfirm } from './useConfirm';

export const ConfirmModal = () => {
  const { title = '', subTitle, illustration, labelConfirm, isOpen = false, proceed, cancel } = useConfirm();

  React.useEffect(() => {
    const handleKeydown = (e: any) => {
      if (proceed && isOpen && e.key === 'Enter') {
        proceed();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [proceed, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={cancel} hideCloseButton>
      <div className="flex flex-col items-center p-10">
        {illustration && <Illustration name={illustration} className="mb-6" />}

        <Typography className="max-w-[250px] text-center mb-3" variant="standard-h3">
          {title}
        </Typography>

        {subTitle && (
          <Typography className="mb-6" variant="body-small">
            {subTitle}
          </Typography>
        )}

        <Row>
          <Col>
            <Button onClick={proceed} className={`${labelConfirm ? 'text-primary' : 'text-red'}`} variant="ghost" size="medium">
              {labelConfirm || 'Delete'}
            </Button>
          </Col>

          <Col>
            <Button onClick={cancel} size="medium">
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
