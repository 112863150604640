import React, { useEffect, useState } from 'react';
import { Col } from 'src/components/Col';
import { FiltersProps, Filters as PersonFilters } from 'src/components/Filters';
import Layout from 'src/components/layout';
import { Row } from 'src/components/Row';
import { RangeDatePicker } from 'src/components/datepicker';
import { AGE_FILTER, GENDER_FILTER } from 'src/constants/filters';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import {
  getIntervalByDate,
  getTimestampOf,
} from 'src/utils/helpers';
import { IStores } from 'src/interfaces/storeList.interface';
import { IDateTime } from 'src/interfaces/common.interface';
import { IStore } from 'src/interfaces/store.interface';
import moment from 'moment-timezone';
import DashboardIndicator from './components/DashboardIndicator';
import DashboardOutsideTraffic from './components/OutsideTraffic';
import DashboardInsideTraffic from './components/InsideTraffic';

const FILTERS: FiltersProps['filters'] = [GENDER_FILTER, AGE_FILTER];

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state: IDateTime) => state.dateTime);
  const { stores } = useSelector((state: IStores) => state.stores);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));
  const [storesIds, setStoresIds] = useState<number[]>([]);

  const intervalLabel = `${moment(startDate).format('DD/MM/YYYY HH:mm')} - ${moment(endDate).format(
    'DD/MM/YYYY HH:mm',
  )}`;

  useEffect(() => {
    if (stores) {
      const ids = stores?.map((store: IStore) => store.id) || [];
      setStoresIds(ids);
    }
  }, [stores]);

  useEffect(() => {
    dispatch(
      ActionTypes.getAllStores.request({
        start_date: startTimeTimestamp,
        end_date: endTimeTimestamp,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
      }),
    );
  }, [dispatch, startTimeTimestamp, endTimeTimestamp, ageFilter, genderFilter]);

  useEffect(() => {
    if (storesIds.length > 0) {
      dispatch(
        ActionTypes.getAllStoresGraphs.request({
          graphType: 'total_footfall',
          interval: getIntervalByDate(startTimeTimestamp, endTimeTimestamp),
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          stores: storesIds,
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
      dispatch(
        ActionTypes.getAllStoresAvgTimeGraphs.request({
          interval: getIntervalByDate(startTimeTimestamp, endTimeTimestamp),
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          stores: storesIds,
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
    }
  }, [dispatch, startTimeTimestamp, endTimeTimestamp, ageFilter, genderFilter, storesIds]);

  useEffect(() => {
    dispatch(
      ActionTypes.getAllStoresFootfallDodGraphs.request({
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        ageFilter: ageFilter || undefined,
        genderFilter: genderFilter || undefined,
      }),
    );
    dispatch(
      ActionTypes.getAllStoresAvgTimeDoDGraphs.request({
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        ageFilter: ageFilter || undefined,
        genderFilter: genderFilter || undefined,
      }),
    );
  }, [dispatch, startTimeTimestamp, endTimeTimestamp, ageFilter, genderFilter]);

  const Filters = (
    <div className="flex flex-row gap-5">
      <RangeDatePicker needTime />

      <PersonFilters
        title="Filters"
        filters={FILTERS}
        onApplyFilters={({ age, gender }) => {
          const ageFilter = age ? age.join(',') : undefined;
          const genderFilter = gender ? gender.join(',') : undefined;
          dispatch(ActionTypes.updateAge(ageFilter));
          dispatch(ActionTypes.updateGender(genderFilter));
        }}
        onClear={() => {
          dispatch(ActionTypes.updateAge(''));
          dispatch(ActionTypes.updateGender(''));
        }}
      />
    </div>
  );

  return (
    <Layout title="Store Analytics">
      <Row>
        <Col>{Filters}</Col>
      </Row>
      <DashboardIndicator />
      <DashboardOutsideTraffic intervalLabel={intervalLabel} />
      <DashboardInsideTraffic intervalLabel={intervalLabel} />
    </Layout>
  );
};

export default Dashboard;
