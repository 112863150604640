import { axiosJsonInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async postStore(data: any) {
    return axiosJsonInstance.post('store/', data);
  },
  async getAllStoresInfo() {
    return axiosJsonInstance.get('store/');
  },
  async getAllStores({ start_date, end_date, ageFilter, genderFilter }: any) {
    return axiosJsonInstance.get('store/', {
      params: {
        start_date,
        end_date,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },
  async getStore(storeId: any, startDate: any, endDate: any, ageFilter: any = '', genderFilter: any = '') {
    return axiosJsonInstance.get(`store/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },
  async getStoreBrand(brandId: any) {
    return axiosJsonInstance.get(`brand/${brandId}/`);
  },
  async updateStoreBrand(payload: any) {
    return axiosJsonInstance.patch(`store/${payload.storeId}/`, payload);
  },
  async getStoreTopViewPlots(
    storeId: number | string,
    startDate: number,
    endDate: number,
  ) {
    return axiosJsonInstance.get(`e_search/store_top_perspective/?store_id=${storeId}&start_date=${startDate}&end_date=${endDate}`);
  },
};
