import * as Action from './actions';

const initialState = {
  heatmap: {},
  loading: false,
  error: null,

  calendar_heatmap: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_HEATMAP: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_HEATMAP_SUCCESS: {
      return {
        ...state,
        heatmap: action.payload,
        loading: false,
      };
    }
    case Action.GET_HEATMAP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case Action.GET_CALENDAR_HEATMAP: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_SUCCESS: {
      return {
        ...state,
        calendar_heatmap: action.payload,
        loading: false,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
