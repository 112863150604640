/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useState } from 'react';
import cx from 'classnames';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import moment from 'moment-timezone';

import { Icon } from 'src/components/Icon';
import { Typography } from 'src/components/Typography';
import { Link } from 'src/components/Link';
import { If } from 'src/components/If';
import { Card } from 'src/components/Card';

import { useSelector } from 'react-redux';
import CustomActions from './customActions';
import TimeInput from './timeInput';

import 'react-datepicker/dist/react-datepicker.css';
import { getTimeFromDate } from './helper';

export interface RangeDatePickerProps {
  needTime?: boolean;
  className?: string;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  defaultOpened?: boolean;
}
interface Dates {
  start: Date | null;
  end: Date | null;
}

export const RangeDatePicker = ({
  className,
  needTime,
  initialStartDate = new Date(),
  initialEndDate = new Date(),
  defaultOpened = false,
}: RangeDatePickerProps) => {
  const { startDate: reduxStartDate, endDate: reduxEndDate } = useSelector((state: any) => state.dateTime);

  const [startDate, setStartDate] = useState<Date | null | any>(Date.parse(reduxStartDate));
  const [endDate, setEndDate] = useState<Date | null | any>(Date.parse(reduxEndDate));
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const [startTime, setStartTime] = useState(getTimeFromDate(reduxStartDate));
  const [endTime, setEndTime] = useState(getTimeFromDate(reduxEndDate));

  const handleChange = ({ start, end }: Dates) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleReset = (clickComeFromOutside = true) => {
    if (clickComeFromOutside) {
      handleChange({ start: new Date(reduxStartDate), end: new Date(reduxEndDate) });
      setStartTime(getTimeFromDate(reduxStartDate));
      setEndTime(getTimeFromDate(reduxEndDate));
      return;
    }

    handleChange({ start: initialStartDate, end: initialEndDate });
    setStartTime('');
    setEndTime('');
  };

  const displayValue = React.useMemo(() => {
    const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

    if (!startDate && !endDate) {
      const initialDate = localStorage.getItem('startDate') || startDate;
      const finallyDate = localStorage.getItem('endDate') || endDate;

      const startDateFormat = `${moment(new Date(Number(initialDate)))
        .hour(parseInt(startTime.split(':')[0], 10))
        .minute(parseInt(startTime.split(':')[1], 10))
        .format(FORMAT_DATE)}`;

      const endDateFormat = `${moment(new Date(Number(finallyDate)))
        .hour(parseInt(endTime.split(':')[0], 10))
        .minute(parseInt(endTime.split(':')[1], 10))
        .format(FORMAT_DATE)}`;

      const date = `${startDateFormat} - ${endDateFormat}`;

      return date;
    }

    const startDateFormat = `${moment(startDate)
      .hour(parseInt(startTime.split(':')[0], 10))
      .minute(parseInt(startTime.split(':')[1], 10))
      .format(FORMAT_DATE)}`;

    const endDateFormat = `${moment(endDate || new Date())
      .hour(parseInt(endTime.split(':')[0], 10))
      .minute(parseInt(endTime.split(':')[1], 10))
      .format(FORMAT_DATE)}`;

    const date = `${startDateFormat} - ${endDateFormat}`;

    return date;
  }, [startDate, endDate, startTime, endTime]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomInput = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ onClick }, ref) => {
      return (
        <button type="button" className="datapicker-container" onClick={onClick} ref={ref}>
          <Typography variant="small-button" color="neutral-900">
            {displayValue}
          </Typography>
        </button>
      );
    },
  );

  return (
    <Card className={cx('max-w-sm flex justify-center align-middle px-4 py-1 w-[350px]', className)}>
      <Icon icon="calendar" className="mt-1 mr-3" />

      <DatePicker
        open={isOpen}
        locale="en"
        // eslint-disable-next-line react/no-unstable-nested-components
        calendarContainer={({ children, className }: any) => {
          return (
            <Card className="" padding>
              <div className="flex justify-between mb-3">
                <Typography variant="standard-h5">Calendar</Typography>

                <Link onClick={() => handleReset(false)} role="button" className="no-underline">
                  Reset
                </Link>
              </div>
              <div className="flex items-end gap-1">
                <If condition={needTime}>
                  <TimeInput setSelectedTime={setStartTime} selectedTime={startTime} />
                </If>

                <CalendarContainer className={cx(className, 'border-0')}>
                  <div className="relative flex">{children}</div>
                </CalendarContainer>

                <If condition={needTime}>
                  <TimeInput setSelectedTime={setEndTime} selectedTime={endTime} />
                </If>
              </div>
              <div>
                <CustomActions
                  startDate={startDate}
                  endDate={endDate}
                  startTime={startTime}
                  endTime={endTime}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  setIsOpen={setIsOpen}
                />
              </div>
            </Card>
          );
        }}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        customInput={<CustomInput />}
        monthsShown={2}
        onCalendarClose={handleReset}
        shouldCloseOnSelect={false}
        selectsRange
        dateFormat="dd/MM/yyyy HH:mm"
        onClickOutside={() => setIsOpen(false)}
        onInputClick={() => setIsOpen(true)}
        onChange={(dates) => {
          const [start, end] = dates;
          handleChange({
            start,
            end,
          });
        }}
        maxDate={new Date()}
      />
    </Card>
  );
};

export default RangeDatePicker;
