import { axiosJsonInstance } from 'src/store/api';

export default {
  async postLogin(data: any) {
    return axiosJsonInstance.post('dj-rest-auth/login/', data);
  },
  async postLogout(data: any) {
    return axiosJsonInstance.post('dj-rest-auth/logout/', data);
  },
  async postRegisterUser(data: any) {
    return axiosJsonInstance.post('dj-rest-auth/registration/', data);
  },
  async postPasswordReset(data: any) {
    return axiosJsonInstance.post('password_reset/', {
      email: data,
    });
  },
  async postPasswordResetConfirm(data: any) {
    return axiosJsonInstance.post('password_reset/confirm/', data);
  },
  async postPasswordChange(data: any) {
    return axiosJsonInstance.post('dj-rest-auth/password/change/', data);
  },
  async getUser() {
    return axiosJsonInstance.get('user/');
  },
};
