import React from 'react';
import cx from 'classnames';

import { Card } from 'src/components/Card';
import { TIconsType } from 'src/components/Icon/constants';
import { Icon } from 'src/components/Icon';
import { If } from 'src/components/If';
import { Typography } from 'src/components/Typography';
import { convertIntervalDateDays } from 'src/utils/helpers';
import { useSelector } from 'react-redux';
import { Tooltip } from '../Tooltip';

export type CardIndicatorProps = {
  label: string;
  tooltip?: string;
  icon: TIconsType;
  percent?: string;
  value: string | number;
  bottom: {
    icon?: TIconsType;
    value?: string;
    description?: string;
  };
  className?: string;
  toggle?: any;
};

export const CardIndicator: React.FC<CardIndicatorProps> = ({
  label,
  tooltip,
  value,
  bottom,
  className,
  children,
  toggle,
  icon,
  percent,
}) => {
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);

  return (
    <Card className={cx(className)} padding>
      <div className="flex gap-2">
        <div className="">
          <div className="flex items-center gap-3">
            <Typography className="whitespace-nowrap" variant="caption-2" color="neutral-600">
              {label}
            </Typography>
            {tooltip && (
              <Tooltip tooltipPlacement="top" tooltipText={tooltip} width={300}>
                <Icon icon="circle-warning" color="neutral-400" />
              </Tooltip>
            )}
          </div>

          <Typography className="mt-2" variant="large-h1" color="neutral-800">
            {value}
          </Typography>

          <div className="flex items-center gap-2">
            <If condition={icon}>
              <Icon icon={icon} color="primary" />
            </If>

            <div className="text-base">
              <span className="">
                {`${percent}`}
                %
              </span>
              <span className="ml-1 text-neutral-400">{convertIntervalDateDays(startDate, endDate)}</span>
            </div>
          </div>
        </div>
        <div className="w-full">{children}</div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex items-center gap-1">
          {bottom.icon && <Icon icon={bottom.icon} />}

          <Typography variant="caption-2" color="neutral-800">
            {bottom.value}
          </Typography>

          <Typography variant="caption-2" color="neutral-400">
            {bottom.description}
          </Typography>
        </div>
        {toggle}
        {/* <Toggle label="Plot on chart" checked onChange={() => null} /> */}
      </div>
    </Card>
  );
};
