import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { history } from 'src/utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import * as ActionTypes from 'src/store/actions';
import { Button } from 'src/components/Button';
import { FormField } from 'src/components/FormField';
import { TextInput } from 'src/components/TextInput';
import AuthLayout from 'src/components/layout/auth';
import { Typography } from 'src/components/Typography';
import { Icon } from 'src/components/Icon';

import styles from './styles.module.css';

type Form = {
  email: string;
  password: string;
};

type AuthProps = {
  auth: {
    loading: boolean;
  };
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state: AuthProps) => state.auth.loading);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: Form) => {
    dispatch(ActionTypes.postLogin.request(data));
  };

  return (
    <AuthLayout
      title="Welcome, Login To Continue"
      subtitle="Use your email and password you created when you registered."
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-96 gap-2 m-auto py-5">
        <FormField label="Email" error={errors.email?.message}>
          <TextInput
            placeholder="e.g. name@mail.com"
            error={errors.email}
            {...register('email', {
              required: 'Email is required',
              minLength: {
                message: 'Min 6 characters',
                value: 6,
              },
            })}
          />
        </FormField>

        <FormField label="Password" error={errors.password?.message}>
          <div className="relative">
            <TextInput
              icon="lock"
              placeholder="**********"
              type={!showPassword ? 'password' : 'text'}
              error={errors.password}
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  message: 'Min 4 characters',
                  value: 4,
                },
              })}
              className="pr-6"
            />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className={styles.showPassword}>
              {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        </FormField>
        <div className={styles.remember}>
          <button
            type="button"
            onClick={() => history.push('/reset-password')}
            className="text-accent text-xs flex gap-3"
          >
            <Typography variant="input-label" color="accent">
              Forgot password?
            </Typography>
          </button>
        </div>
        <Button className="h-[50px] mt-5" type="submit" disabled={loading}>
          <Typography variant="standard-button" color="white">
            {!loading ? 'Login' : 'Logging...'}
          </Typography>
          {!loading && <Icon icon="left-to-right" color="white" size="sm" className="mt-1 ml-3" />}
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
