import React, { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { TextInput } from 'src/components/TextInput';

type ThresholdSecondsProps = {
  seconds: string;
};

type Props = {
  // eslint-disable-next-line no-unused-vars
  thresholdSeconds: string;
  setThresholdSeconds: (value: string) => void;
  onClosePopUpAnalysis: () => void;
  register: UseFormRegister<ThresholdSecondsProps>;
  handleSubmitThresholdSeconds: UseFormHandleSubmit<ThresholdSecondsProps>;
  setValue: UseFormSetValue<ThresholdSecondsProps>;
  // eslint-disable-next-line no-unused-vars
  fetchDwellAnalysisData: (thresholdSeconds: string) => void;
  getValues: UseFormGetValues<ThresholdSecondsProps>
};

const CameraPopUpAnalysisContent: React.FC<Props> = ({
  setThresholdSeconds,
  onClosePopUpAnalysis,
  register,
  handleSubmitThresholdSeconds,
  setValue,
  thresholdSeconds,
  fetchDwellAnalysisData,
}) => {
  const [isLoadingThreshouldSeconds, setIsLoadingThreshouldSeconds] = useState(false);

  const clearAllThresholdValues = () => {
    setValue('seconds', '');
    setThresholdSeconds('');
  };

  const onSubmitThresholdSeconds = handleSubmitThresholdSeconds(async (data) => {
    try {
      setIsLoadingThreshouldSeconds(true);
      setThresholdSeconds(data.seconds);
    } catch (err) {
      clearAllThresholdValues();
    } finally {
      setIsLoadingThreshouldSeconds(false);
    }
  });

  useEffect(() => {
    fetchDwellAnalysisData(thresholdSeconds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdSeconds]);

  return (
    <Card
      title="Threshold seconds"
      subTitle="Enter the minimum time spent in the area and click on the filter."
      className="min-w-[316px]"
      padding
    >
      <form onSubmit={onSubmitThresholdSeconds}>
        <div className="">
          <TextInput placeholder="Input seconds" autoFocus {...register('seconds')} type="number" />
          <div className="flex mt-4 gap-2">
            <Button onClick={onClosePopUpAnalysis} variant="ghost" fullWidth size="medium">
              Cancel
            </Button>
            <Button
              onClick={onClosePopUpAnalysis}
              fullWidth
              size="medium"
              type="submit"
              disabled={isLoadingThreshouldSeconds}
            >
              {isLoadingThreshouldSeconds ? 'Applying' : 'Apply'}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default CameraPopUpAnalysisContent;
