import React from 'react';

import cx from 'classnames';

import { MultiSelect } from 'react-multi-select-component';
import { Tag } from '../Tag';

import { Checkbox } from '../Checkbox';

export type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

// eslint-disable-next-line no-unused-vars
const customValueRenderer = (selected: Option[], _options: Option[], _onChange?: (selected: Option[]) => void) => {
  const isEmptyState = !selected?.length;

  if (isEmptyState) {
    return 'Select';
  }

  return (
    <div className="flex flex-wrap gap-2">
      {selected.map(({ label, value, disabled }) => (
        <Tag
          key={`${label}-${value}`}
          icon="close"
          onClickIcon={() => _onChange?.(selected.filter((item) => item.value !== value))}
          active={!disabled}
        >
          {label}
        </Tag>
      ))}
    </div>
  );
};

export type DropdownMultiProps = {
  className?: string;
  options: Option[];
  value?: Option[];
  // eslint-disable-next-line no-unused-vars
  onChange?: (selected: Option[]) => void;
};

type ItemRendererType = {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: () => void;
};

const ItemRenderer = ({ option, checked, disabled, onClick }: ItemRendererType) => (
  <Checkbox checked={checked} label={option.label} disabled={disabled} onChange={onClick} />
);

export const DropdownMulti = ({ className, options, value = [], ...props }: DropdownMultiProps) => {
  return (
    <MultiSelect
      className={cx(className)}
      options={options}
      value={value}
      disableSearch
      ClearSelectedIcon={null}
      ClearIcon={null}
      valueRenderer={(_, _options) => customValueRenderer(value, _options, props.onChange)}
      hasSelectAll={false}
      ItemRenderer={ItemRenderer}
      // onChange={onChange}
      {...props}
      labelledBy="Select"
    />
  );
};
