import { FilterItem } from 'src/components/Filters';

export const STORE_FILTERS: FilterItem[] = [
  {
    key: 'store',
    title: 'Stores',
    options: [
      {
        label: 'Store A-Z',
        value: 'a-z',
      },
    ],
    defaultValue: 'a-z',
  },
];
