import { useContext } from 'react';
import { AvailableIllustrations } from 'src/components/Illustration/constants';
import { ConfirmContext } from './ConfirmContextProvider';

export const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);

  const isConfirmed = (title: string, subTitle?: string, illustration?: AvailableIllustrations, labelConfirm?: string) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        title,
        subTitle,
        illustration,
        labelConfirm,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      },
    );
  };

  return {
    ...confirm,
    isConfirmed,
  };
};
