import { isEqual } from 'lodash';
import { isEmptyOrUndefined } from './isUndefined';

const removeEmptyOrNulls = (obj: any) => {
  if (!obj) return obj;

  Object.keys(obj).forEach((key: string) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '' || isEmptyOrUndefined(obj[key])) {
      delete obj[key];
    }
  });

  return obj;
};

export const objectsAreEqual = (obj1: any, obj2: any) => {
  // TO DISABLE CACHE, UNCOMMENT THE LINE BELOW AND ALL APIS WILL BE RE-CALLED
  // return false;

  const obj1WithoutEmptyOrNulls = removeEmptyOrNulls(obj1);
  const obj2WithoutEmptyOrNulls = removeEmptyOrNulls(obj2);

  if (isEqual(obj1WithoutEmptyOrNulls, obj2WithoutEmptyOrNulls)) {
    return true;
  }

  return false;
};
