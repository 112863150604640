import { all, spawn } from 'redux-saga/effects';
import stores from './modules/store/saga';
import products from './modules/product/saga';
import departments from './modules/department/saga';
import locations from './modules/location/saga';
import collections from './modules/collection/saga';
import auth from './modules/auth/saga';
import cameras from './modules/cameras/saga';
import graphs from './modules/graphs/saga';
import heatmap from './modules/heatmap/saga';
import tills from './modules/tills/saga';
import areas from './modules/areas/saga';
import brands from './modules/brands/saga';
import areasOfInterest from './modules/areasOfInterest/saga';
import user from './modules/user/saga';

/**
 * rootSaga combines all the sagas and returns them in a generator. spawn means to runs them async.
 */
export default function* rootSaga() {
  yield all([
    spawn(stores),
    spawn(products),
    spawn(departments),
    spawn(locations),
    spawn(collections),
    spawn(auth),
    spawn(cameras),
    spawn(graphs),
    spawn(heatmap),
    spawn(tills),
    spawn(areas),
    spawn(brands),
    spawn(areasOfInterest),
    spawn(user),
  ]);
}
