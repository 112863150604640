import { axiosJsonInstance } from 'src/store/api';

export default {
  async postProduct(data: any) {
    return axiosJsonInstance.post('products/', data);
  },

  async getStoreProducts(storeId: any, search: any, pageNumber: any, next: string, previous: string, more: any) {
    if (next || more) {
      return axiosJsonInstance.get(next);
    } if (previous) {
      return axiosJsonInstance.get(previous);
    }
    return axiosJsonInstance.get('products/', {
      params: {
        search,
        store_id: storeId,
        page_size: 10,
        page: pageNumber,
      },
    });
  },

  async getDepartmentProducts(departmentId: any, search: any, pageNumber: any, next: string, previous: string, more: any) {
    if (next || more) {
      return axiosJsonInstance.get(next);
    } if (previous) {
      return axiosJsonInstance.get(previous);
    }
    return axiosJsonInstance.get('products/', {
      params: {
        search,
        department_id: departmentId,
        page_size: 10,
        page: pageNumber,
      },
    });
  },

  async updateProduct(productId: any, data: any) {
    return axiosJsonInstance.put(`product/${productId}/`, data);
  },

  async getProduct(productId: any) {
    return axiosJsonInstance.get(`product/${productId}/`);
  },
};
