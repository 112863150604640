import React from 'react';

import { CSVLink } from 'react-csv';

type Props = {
  children: React.ReactNode;
  data: any[];
  headers?: Array<{
    label: string;
    key: string;
  }>;
  filename: string;
};

export const DownloadCSV: React.FC<Props> = ({ children, data, headers, filename, ...rest }) => {
  if (!data) {
    return <> </>;
  }

  return (
    <CSVLink headers={headers} filename={filename} data={data || ''} {...rest}>
      {children}
    </CSVLink>
  );
};
