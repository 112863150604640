import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icon';

export type LockProps = {
  isLocked?: boolean;
  children: React.ReactNode;
};

export const Lock = ({ isLocked, children }: LockProps) => {
  if (!isLocked) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div className={cx('flex-1 relative flex items-center justify-center')}>
      <Icon
        className={cx('absolute text-8xl z-10')}
        icon="lock-filled"
        color="white"
      />
      <div
        style={{ background: 'rgba(63, 64, 79, 0.04)' }}
        className={cx('flex-1 blur-md')}
      >
        {children}
      </div>
    </div>
  );
};
