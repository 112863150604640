import moment from 'moment';
import { axiosJsonInstance } from 'src/store/api';
import { notifyError } from 'src/utils/notification';

type GetLastAliveType = {
  data: {
    canary: {
      alive: string;
      last_alive: string;
    };
    sloane: {
      alive: string;
      last_alive: string;
    };
  };
};

export const errorResponse = {
  data: {
    canary: {
      alive: 'false',
      last_alive: '',
    },
    sloane: {
      alive: 'false',
      last_alive: '',
    },
  },
};

export const getLastAlive = async () => {
  const response = {
    data: {
      canary: {
        alive: 'false',
        last_alive: moment().subtract(30, 'minutes').unix().toString(),
      },
      sloane: {
        alive: 'true',
        last_alive: moment().subtract(30, 'minutes').unix().toString(),
      },
    },
  };

  return Promise.resolve(response);
  // Leo asked to fake this data, this is why it's commented.
  // const response = await axiosJsonInstance.get('/keep-alive/?keep_alive_key=devtools@trackin.tech');

  // try {
  //   if (response.data) {
  //     const parsed =
  //       response.data
  //         .replaceAll("'", '"')
  //         .replaceAll('True', '"true"')
  //         .replaceAll('False', '"false"');
  //     return Promise.resolve(JSON.parse(parsed) as GetLastAliveType);
  //   }

  //   notifyError('Error getting stores status');
  //   return Promise.reject(errorResponse);
  // } catch (e) {
  //   notifyError('Error reading stores status');
  //   return Promise.reject(errorResponse);
  // }
};
