import { axiosJsonInstance } from 'src/store/api';

export type PostBrandProps = {
  name: string;
  address: string;
};

export default {
  async getAllBrands() {
    return axiosJsonInstance.get('brands/');
  },
  async getBrandUsers(payload: any) {
    return axiosJsonInstance.get(`brand-users/?brand_id=${payload.brandId}`);
  },
  async postBrand(payload: PostBrandProps) {
    return axiosJsonInstance.post('brands/', payload);
  },
  async updateBrand(payload: any) {
    return axiosJsonInstance.patch(`brand/${payload.brandId}`, payload);
  },
  async postBrandUser(payload: any) {
    return axiosJsonInstance.post('users/', payload);
  },
  async updateBrandUser(payload: any) {
    return axiosJsonInstance.patch(`users/${payload.userId}/`, payload);
  },
  async deleteBrandUser(payload: any) {
    return axiosJsonInstance.delete(`/users/${payload.userId}/`);
  },
};
