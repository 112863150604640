/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'src/components/Breadcrumb';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Filters } from 'src/components/Filters';
import { Icon } from 'src/components/Icon';
import { If } from 'src/components/If';
import Layout from 'src/components/layout';
import { Row } from 'src/components/Row';
import { Table } from 'src/components/Table';
import { Typography } from 'src/components/Typography';
import { useModal } from 'src/hooks/useModal';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import { STORE_FILTERS } from 'src/pages/SuperAdmin/Common/constants';
import { Pagination } from 'src/components/Pagination';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tooltip } from 'src/components/Tooltip';
import { useSelector, useDispatch } from 'react-redux';

import * as ActionTypes from 'src/store/actions';
import { ModalFormStore, FormStore } from './ModalFormStore';

const BREADCRUMB_LIST: BreadcrumbItem[] = [
  {
    label: 'Dashboard',
    href: '/super-admin/admin-dashboard',
  },
  {
    label: 'Stores',
    href: '',
  },
];

type StoreProps = {
  id: number;
  cameras: [];
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  postcode: string;
  phone_number: string;
  es_store_id: string;
  coordinates: number;
  brand: number;
};

export const Stores: React.FC = () => {
  const { brandId } = useParams<{ brandId: string }>();

  const dispatch = useDispatch();
  const { storeBrand, storeBrand_loading } = useSelector((state: any) => state.stores);

  const { isConfirmed } = useConfirm();

  const [isOpenModalStore, onCloseModalStore, onOpenModalStore] = useModal(false);
  const [selectedStore, setSelectedStore] = React.useState<FormStore | undefined>();

  const handleCloseModalStore = () => {
    setSelectedStore(undefined);
    onCloseModalStore();
  };

  const handleSubmitStore = (values: FormStore) => {
    const body = {
      brand_id: brandId,
      name: values.store,
      address_1: values.address,
      address_2: values.address_2,
      city: values.city,
      postcode: values.postcode,
      phone_number: values.phone_number,
      // numberOfCameras: Number(values.numberOfCameras),
    };
    if (!selectedStore) {
      dispatch({
        type: ActionTypes.POST_STORE,
        payload: body,
      });
      handleCloseModalStore();
      return;
    }
    dispatch({
      type: ActionTypes.UPDATE_STORE_BRAND,
      payload: { ...body, storeId: values.storeId },
    });

    handleCloseModalStore();
  };

  const handleEditStore = (store: FormStore) => {
    setSelectedStore(store);
    onOpenModalStore();
  };

  const handleDelete = async (rowData: any) => {
    const willDelete = await isConfirmed(
      'Are You Sure You Want To Delete This Brand?',
      'You wont be able to recover this brand or its settings',
      'delete',
    );
  };

  function loadStores() {
    dispatch(
      ActionTypes.getStoreBrand.request({
        type: ActionTypes.GET_STORE_BRAND,
        brandId,
      }),
    );
  }

  useEffect(() => {
    loadStores();
  }, []);

  const breadcrumb = useMemo(() => {
    return <Breadcrumb list={BREADCRUMB_LIST} />;
  }, []);

  return (
    <Layout title="Apple">
      <If condition={isOpenModalStore}>
        <ModalFormStore
          isOpen={isOpenModalStore}
          onClose={handleCloseModalStore}
          defaultValues={selectedStore}
          onSubmit={handleSubmitStore}
        />
      </If>
      <Row>
        <Col>{breadcrumb}</Col>
      </Row>

      <Row>
        <Col>
          <Filters
            filters={STORE_FILTERS}
            title="Filters"
            onApplyFilters={(values) => console.log('values::', values)}
          />
        </Col>

        <Col>
          <div className="flex justify-end gap-4">
            <Button
              onClick={onOpenModalStore}
              variant="dropdown-button"
              iconProps={{
                icon: 'add',
                side: 'left',
                size: 'sm',
              }}
            >
              <Typography variant="small-button">Add New Store</Typography>
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="h-[calc(100% - 5.5rem)]">
        <Col className="h-auto">
          <Card
            title="Stores"
            subTitle="Stores of the brand"
            iconsGroup={(
              <div className="flex gap-2">
                <Tooltip tooltipText="Download" tooltipPlacement="top">
                  <Icon icon="download" className="text-xl" />
                </Tooltip>
              </div>
            )}
            padding
          >
            {storeBrand_loading ? (
              <div className="text-center">Loading...</div>
            ) : (
              <div className="flex flex-col">
                <Table
                  cols={[
                    {
                      field: 'store',
                      title: 'Store name',
                      order: true,
                    },
                    {
                      field: 'address',
                      title: 'Store Address',
                      order: true,
                    },
                    {
                      field: 'numberOfCameras',
                      title: 'No of cameras',
                      order: true,
                    },
                    {
                      field: 'actions',
                      title: 'Actions',
                      order: true,
                      alignment: 'end',
                      customCol: (rowData: any, rowIdx) => storeBrand &&
                        storeBrand?.length > 0 && (
                          <div className="flex gap-2 items-center justify-end py-2">
                            <Icon icon="edit" tag="button" onClick={() => handleEditStore({ ...rowData, rowIdx })} />
                            <Icon icon="delete" tag="button" onClick={() => handleDelete({ ...rowData, rowIdx })} />
                          </div>
                      ),
                    },
                  ]}
                  rows={
                    storeBrand?.length
                      ? storeBrand?.map((store: StoreProps) => ({
                        id: store.brand,
                        // brand: brand.name,
                        storeId: store.id,
                        store: store.name,
                        city: store.city,
                        address_2: store.address_2,
                        postcode: store.postcode,
                        phone_number: store.phone_number,
                        address: store.address_1,
                        numberOfCameras: store.cameras.length,
                      }))
                      : [
                        {
                          store: 'No Data',
                        },
                      ]
                  }
                />
                {/* <Pagination className="ml-auto mt-4" count={5} /> */}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
