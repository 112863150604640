import { createApiAction } from 'src/utils/helpers';

export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAILURE = 'GET_COLLECTION_FAILURE';

export const POST_COLLECTION = 'POST_COLLECTION';
export const POST_COLLECTION_SUCCESS = 'POST_COLLECTION_SUCCESS';
export const POST_COLLECTION_FAILURE = 'POST_COLLECTION_FAILURE';

export const getCollections = createApiAction(GET_COLLECTION);

export const postCollection = createApiAction(POST_COLLECTION); // dont use
