import { createApiAction } from 'src/utils/helpers';

export const POST_LOGIN = 'POST_LOGIN';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE';

export const POST_LOGOUT = 'POST_LOGOUT';
export const POST_LOGOUT_SUCCESS = 'POST_LOGOUT_SUCCESS';
export const POST_LOGOUT_FAILURE = 'POST_LOGOUT_FAILURE';

export const POST_REGISTER_USER = 'POST_REGISTER_USER';
export const POST_REGISTER_USER_SUCCESS = 'POST_REGISTER_USER_SUCCESS';
export const POST_REGISTER_USER_FAILURE = 'POST_REGISTER_USER_FAILURE';

export const POST_PASSWORD_RESET = 'POST_PASSWORD_RESET';
export const POST_PASSWORD_RESET_SUCCESS = 'POST_PASSWORD_RESET_SUCCESS';
export const POST_PASSWORD_RESET_FAILURE = 'POST_PASSWORD_RESET_FAILURE';

export const POST_PASSWORD_RESET_CONFIRM = 'POST_PASSWORD_RESET_CONFIRM';
export const POST_PASSWORD_RESET_CONFIRM_SUCCESS =
  'POST_PASSWORD_RESET_CONFIRM_SUCCESS';
export const POST_PASSWORD_RESET_CONFIRM_FAILURE =
  'POST_PASSWORD_RESET_CONFIRM_FAILURE';

export const POST_PASSWORD_CHANGE = 'POST_PASSWORD_CHANGE';
export const POST_PASSWORD_CHANGE_SUCCESS = 'POST_PASSWORD_CHANGE_SUCCESS';
export const POST_PASSWORD_CHANGE_FAILURE = 'POST_PASSWORD_CHANGE_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const postLogin = createApiAction(POST_LOGIN);
export const postLogout = createApiAction(POST_LOGOUT);
export const postRegisterUser = createApiAction(POST_REGISTER_USER);
export const postPasswordReset = createApiAction(POST_PASSWORD_RESET);
export const postPasswordResetConfirm = createApiAction(
  POST_PASSWORD_RESET_CONFIRM,
);
export const postPasswordChange = createApiAction(POST_PASSWORD_CHANGE);
export const getUser = createApiAction(GET_USER);
