export type IfProps = {
  condition: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export const If = ({ condition, children }: IfProps) => {
  if (condition) {
    return children;
  }
  return null;
};
