import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Table } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { IArea, IAreaTable } from 'src/interfaces/areas.interface';
import { ITill, ITillTable } from 'src/interfaces/tills.interface';
import { convertSecondsToHMS, numberFormat } from 'src/utils/helpers';

type Props = {
  intervalLabel: string;
};

const QueueAndTraffic: React.FC<Props> = ({ intervalLabel }) => {
  const { storeTills, storeTillsLoading } = useSelector((state: any) => state.tills);
  const { outsideTraffic: storeOutsideTraffic, outsideTrafficLoading } = useSelector((state: any) => state.areas);

  const [queueMonitoring, setQueueMonitoring] = useState<ITillTable>({
    cols: [],
    rows: [],
  });
  const [outsideTraffic, setOutsideTraffic] = useState<IAreaTable>({
    cols: [],
    rows: [],
  });

  const calculateDataTill = useCallback(() => {
    if (!storeTills || !storeTills.data || storeTills.data.length === 0) return [];

    const dataTill = storeTills.data.map((till: ITill) => ({
      id: till.id,
      till: till.name,
      averageTime: till.time_metrics?.average_time >= 1.0 ? convertSecondsToHMS(till.time_metrics?.average_time) : '-',
      noOfPeople: numberFormat(till.footfall_metrics) || '-',
      queueRate: `${(till.correl_queued_and_in_store * 100).toFixed(2)} %`,
    }));

    if (dataTill.length > 1) {
      dataTill.push({
        id: 'total',
        till: 'Total',
        averageTime: convertSecondsToHMS(storeTills.queue_monitoring_totals.total_average_time),
        noOfPeople: numberFormat(storeTills.queue_monitoring_totals.total_people),
        queueRate: `${(storeTills.queue_monitoring_totals.total_correl_queued_and_in_store * 100).toFixed(2)} %`,
        highlightedRow: true,
      });
    }

    return dataTill;
  }, [storeTills]);

  useEffect(() => {
    const dataTill = calculateDataTill();

    const newQueueMonitoring = {
      cols: [
        { title: 'Till', field: 'till', order: true },
        { title: 'Average Time', field: 'averageTime', order: true },
        { title: 'No. of people', field: 'noOfPeople', order: true },
        { title: 'Queue rate', field: 'queueRate', order: true },
      ],
      rows: dataTill,
    };

    const newOutsideTraffic = {
      cols: [
        { title: 'Camera', field: 'title', order: true },
        { title: 'No. of people', field: 'value', order: true },
      ],
      rows: storeOutsideTraffic.map((traffic: IArea) => ({
        title: traffic.camera.es_cam_id,
        value: numberFormat(traffic.footfall_metrics),
      })),
    };

    setQueueMonitoring(newQueueMonitoring);
    setOutsideTraffic(newOutsideTraffic);
  }, [calculateDataTill, storeOutsideTraffic]);

  if (storeTillsLoading || outsideTrafficLoading) {
    return (
      <div className="animate-pulse flex flex-row gap-3 mt-3">
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
        <div role="status" className="w-[50%] bg-white p-4 rounded-lg shadow flex flex-col gap-2">
          <div className="h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-24 w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      <Col size="6">
        <Card
          className="w-full h-full"
          title="Queue Monitoring"
          subTitle="Monitor how many people are in an area at any time."
          iconsGroup={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV
                filename={`Queue Monitoring - ${intervalLabel}`}
                data={queueMonitoring.rows}
                headers={queueMonitoring.cols.map((data: any) => {
                  return {
                    label: data.title,
                    key: data.field,
                  };
                })}
              >
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          }
          padding
        >
          <Table cols={queueMonitoring.cols} rows={queueMonitoring.rows} />
        </Card>
      </Col>
      <Col size="6">
        <Card
          className="w-full h-full"
          title="Outside Traffic"
          subTitle="Measures store outside traffic "
          iconsGroup={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV
                filename={`Outside Traffic - ${intervalLabel}`}
                data={outsideTraffic.rows}
                headers={outsideTraffic.cols.map((data: any) => {
                  return {
                    label: data.title,
                    key: data.field,
                  };
                })}
              >
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          }
          padding
        >
          <Table cols={outsideTraffic.cols} rows={outsideTraffic.rows} />
        </Card>
      </Col>
    </Row>
  );
};

export default QueueAndTraffic;
