const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

/**
 * This function extracts the time from a date and returns it as a string
 * @param date a javascript Date
 * @returns the string representation of the time in the date , ex: '01:00', '00:00', etc
 */
export const getTimeFromDate = (date: Date) => {
  return `${zeroPad(new Date(date).getHours(), 2)}:${zeroPad(new Date(date).getMinutes(), 2)}`;
};
