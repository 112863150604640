import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Tooltip } from 'src/components/Tooltip';
import { useSelector } from 'react-redux';
import { convertSecondsToHMS, getHoursMinutesAndSeconds, getSimpleMinutesAndSeconds } from 'src/utils/helpers';
import { ISummaryType } from 'src/interfaces/store.interface';
import { TIconsType } from 'src/components/Icon/constants';
import SummaryIndicators from '../../summaryIndicators';

type Props = {
  intervalLabel: string;
};

const SummaryReport: React.FC<Props> = ({ intervalLabel }) => {
  const { store, store_loading } = useSelector((state: any) => state.stores);
  const [summary, setSummary] = useState<ISummaryType[]>([]);

  const getSummaryInfo = useCallback(() => {
    const averageTime =
      (store.indoor_time_metrics?.average_time && getSimpleMinutesAndSeconds(store.indoor_time_metrics.average_time)) ||
      0;

    const longestTime =
      (store.indoor_time_metrics?.longest_visit && convertSecondsToHMS(store.indoor_time_metrics.longest_visit)) || 0;

    const busiestWeek = store ? store.indoor_busiest_week?.replace('Week starting', 'W/C') : '';
    const busiestDay = store ? store.indoor_busiest_day?.replace('The ', '') : '';

    return {
      averageTime,
      longestTime,
      busiestDay,
      busiestWeek,
    };
  }, [store]);

  useEffect(() => {
    const { averageTime, busiestDay, busiestWeek, longestTime } = getSummaryInfo();

    const newSummary = [
      {
        icon: 'meanVisitTime' as TIconsType,
        title: 'Avg. Time Visit',
        description: averageTime,
      },
      {
        icon: 'time' as TIconsType,
        title: 'Longest Visit',
        description: longestTime,
      },
      {
        icon: 'calendarBusy' as TIconsType,
        title: 'Busiest Month',
        description: store.indoor_busiest_month || 'Unknown',
      },
      {
        icon: 'calendarBusy' as TIconsType,
        title: 'Busiest Week',
        description: busiestWeek || 'Unknown',
      },
      {
        icon: 'calendarBusy' as TIconsType,
        title: 'Busiest Day',
        description: busiestDay || 'Unknown',
      },
      {
        icon: 'calendarBusy' as TIconsType,
        title: 'Busiest Hour',
        description: store.indoor_busiest_hour || 'Unknown',
      },
    ];

    setSummary(newSummary);
  }, [store, getSummaryInfo]);

  const summaryDownloadCSV = useMemo(() => {
    const summaryData = [] as any;
    summaryData.push(['metric', 'value']);
    if (store.indoor_time_metrics) {
      summary.map((item, i: number) => {
        if (i === 0) {
          summaryData.push([item.title, getHoursMinutesAndSeconds(store.indoor_time_metrics.average_time)]);
        } else if (i === 1) {
          summaryData.push([item.title, getHoursMinutesAndSeconds(store.indoor_time_metrics.longest_visit)]);
        } else {
          summaryData.push([item.title, item.description]);
        }
        return item;
      });
    }
    return summaryData;
  }, [store, summary]);

  if (store_loading) {
    return (
      <div className="delay-100 transition-all animate-pulse w-full bg-white p-4 rounded-lg mb-3">
        <div className="h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
        <div className="flex flex-row gap-5">
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
          <div className="h-10 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
        </div>
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <Card
          title="Summary Report"
          iconsGroup={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              <DownloadCSV filename={`Summary Report - ${intervalLabel}`} data={summaryDownloadCSV}>
                <Icon icon="download" className="text-xl" />
              </DownloadCSV>
            </Tooltip>
          }
          padding
        >
          <div className="flex justify-between ">
            {summary.map((item) => (
              <SummaryIndicators key={item.title} icon={item.icon} title={item.title} description={item.description} />
            ))}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SummaryReport;
