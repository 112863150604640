import * as Action from './actions';

const initialState = {
  storeTills: [],
  storeTillsLoading: false,
  storeTillsError: null,

  departmentTills: [],
  departmentTillsLoading: false,
  departmentTillsError: null,

  cameraTills: [],
  cameraTillsLoading: false,
  cameraTillsError: null,

  createTillsLoading: false,
  createTillsError: null,

  deleteTillLoading: true,
  deleteTillError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_STORE_TILLS: {
      return {
        ...state,
        storeTillsLoading: true,
      };
    }
    case Action.GET_STORE_TILLS_SUCCESS: {
      return {
        ...state,
        storeTills: action.payload,
        storeTillsLoading: false,
      };
    }
    case Action.GET_STORE_TILLS_FAILURE: {
      return {
        ...state,
        storeTillsLoading: false,
        storeTillsError: action.error,
      };
    }

    case Action.GET_DEPARTMENT_TILLS: {
      return {
        ...state,
        departmentTillsLoading: true,
      };
    }
    case Action.GET_DEPARTMENT_TILLS_SUCCESS: {
      return {
        ...state,
        departmentTills: action.payload,
        departmentTillsLoading: false,
      };
    }
    case Action.GET_DEPARTMENT_TILLS_FAILURE: {
      return {
        ...state,
        departmentTillsLoading: false,
        departmentTillsError: action.error,
      };
    }

    case Action.GET_CAMERA_TILLS: {
      return {
        ...state,
        cameraTillsLoading: true,
      };
    }
    case Action.GET_CAMERA_TILLS_SUCCESS: {
      return {
        ...state,
        cameraTills: action.payload,
        cameraTillsLoading: false,
      };
    }
    case Action.GET_CAMERA_TILLS_FAILURE: {
      return {
        ...state,
        cameraTillsLoading: false,
        cameraTillsError: action.error,
      };
    }

    case Action.POST_TILLS: {
      return {
        ...state,
        createTillsLoading: true,
      };
    }
    case Action.POST_TILLS_SUCCESS: {
      return {
        ...state,
        createTillsLoading: false,
      };
    }
    case Action.POST_TILLS_FAILURE: {
      return {
        ...state,
        createTillsLoading: false,
        createTillsError: action.error,
      };
    }

    case Action.DELETE_TILL: {
      return {
        ...state,
        // storeTills: state?.storeTills?.filter((till: any) => till.id !== action.payload.tillId),
        // departmentTills: state?.departmentTills?.filter((till: any) => till.id !== action.payload.tillId),
        // cameraTills: state?.cameraTills?.filter((till: any) => till.id !== action.payload.tillId),
        deleteTillLoading: true,
      };
    }
    case Action.DELETE_TILL_SUCCESS: {
      return {
        ...state,
        deleteTillLoading: false,
      };
    }
    case Action.DELETE_TILL_FAILURE: {
      return {
        ...state,
        deleteTillLoading: false,
        deleteTillError: action.error,
      };
    }

    default:
      return state;
  }
};
