/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Checkbox } from 'src/components/Checkbox';
import { Col } from 'src/components/Col';
import { Icon } from 'src/components/Icon';
import { PopUp } from 'src/components/PopUp';
import { Row } from 'src/components/Row';
import { Tag } from 'src/components/Tag';
import { DIRECTIONS } from 'src/constants/directions';
import { useModal } from 'src/hooks/useModal';
import { AiOutlineSave } from 'react-icons/ai';
import { uniqueId } from 'lodash';
import { IAreaOfInterest } from 'src/interfaces/department.interface';

type SelectableOptionsWithIconProps = {
  areas: any[];
  areasOfInterest: any;
  onSave?: (e: any) => void;
};

const SelectableOptionsWithIcon = ({ onSave, areas, areasOfInterest }: SelectableOptionsWithIconProps) => {
  const [isOpen, onClose, onOpen] = useModal();
  const [selectedAreas, setSelectedAreas] = useState<any>([]);

  const onClick = (e: any) => {
    if (e.target.checked) {
      setSelectedAreas([...selectedAreas, e.target.value]);
    } else {
      setSelectedAreas(selectedAreas.filter((area: any) => area !== e.target.value));
    }
  };

  return (
    <PopUp
      placement="top-end"
      element={
        <Card title="Selectable Areas" subTitle="Choose from selectable areas entry area" padding>
          <div>
            {areas.map((area: any) => {
              if (area.type === 'AI') {
                const isChecked = areasOfInterest.find((item: any) => item.id === area.id);
                return (
                  <Checkbox
                    key={area.id}
                    label={area.name}
                    defaultChecked={isChecked}
                    value={area.id}
                    onChange={onClick}
                  />
                );
              }
              return null;
            })}
            <Button
              iconProps={{
                icon: 'settings',
                color: 'white',
                side: 'left',
              }}
              className="mt-3 bg-green"
              onClick={() => {
                onSave?.(selectedAreas);
                onClose();
              }}
            >
              Save
            </Button>
          </div>
        </Card>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Icon icon="add" tag="button" color={isOpen ? 'primary' : undefined} onClick={onOpen} />
    </PopUp>
  );
};

type AreasOfInterestProps = {
  data: any[];
  areas: any[];
  onOpenAreaOfInterest: () => void;
  onDelete: (id: number, tempId: any) => void;
  onAddData: (data: any) => void;
  onSaveData: (data: any) => void;
};

export const AreasOfInterest = ({
  data = [],
  areas = [],
  onOpenAreaOfInterest,
  onDelete,
  onAddData,
  onSaveData,
}: AreasOfInterestProps) => {
  const [selectedEntranceArea, setSelectedEntranceArea] = useState(DIRECTIONS[1].value);
  const [selectedExitArea, setSelectedExitArea] = useState(DIRECTIONS[2].value);

  const handleSaveEntryArea = (id: any, ids: any) => {
    const aoi = data.filter((item: any) => item.tempId === id);
    const entryData = ids.map((id: any) => {
      return {
        id: parseInt(id, 10),
        name: areas.find((item: any) => item.id === parseInt(id, 10)).name,
      };
    });
    const newAoi = { ...aoi[0], entry_areas: entryData };
    onAddData(
      data.map((item: any) => {
        if (item.tempId === id) {
          return newAoi;
        }
        return item;
      }),
    );
  };

  const handleSaveExitArea = (id: any, ids: any) => {
    const aoi = data.filter((item: any) => item.tempId === id);
    const exitData = ids.map((id: any) => {
      return {
        id: parseInt(id, 10),
        name: areas.find((item: any) => item.id === parseInt(id, 10)).name,
      };
    });
    const newAoi = { ...aoi[0], exit_areas: exitData };
    onAddData(
      data.map((item: any) => {
        if (item.tempId === id) {
          return newAoi;
        }
        return item;
      }),
    );
  };

  const isDataFromDatabase = data.length > 0 && data.every((item: any) => item.id);

  return (
    <Row>
      <Col>
        <Card
          title="Areas of interest"
          subTitle="Entry and Exit Areas Adjustments"
          iconsGroup={
            !isDataFromDatabase && (
              <div className="flex gap-4 items-center">
                <Icon icon="add" tag="button" color="neutral-600" onClick={onOpenAreaOfInterest} />
                <AiOutlineSave className="mt-0.5 cursor-pointer" onClick={onSaveData} />
              </div>
            )
          }
          padding
        >
          {data.map((aoi: IAreaOfInterest) => {
            const { entry_areas = [], exit_areas = [] } = aoi;
            return (
              <Card
                key={aoi.tempId || uniqueId()}
                title={aoi.name}
                iconsGroup={
                  <Icon icon="delete" tag="button" color="neutral-600" onClick={() => onDelete(aoi.id, aoi.tempId)} />
                }
                className="pb-2 px-2 mb-4"
              >
                <Row>
                  <Col className="pl-0" size="6">
                    <Card
                      subTitle="Entry Areas"
                      iconsGroup={
                        !aoi.id && (
                          <SelectableOptionsWithIcon
                            onSave={(ids: any) => handleSaveEntryArea(aoi.tempId, ids)}
                            areas={areas}
                            areasOfInterest={entry_areas}
                          />
                        )
                      }
                      padding
                    >
                      {entry_areas.length > 0 &&
                        entry_areas.map((area: any) => (
                          <Tag
                            className="mb-2"
                            // dual={{
                            //   title: 'Directions',
                            //   selected: selectedEntranceArea,
                            //   onChange: (value) => setSelectedEntranceArea(value),
                            //   options: DIRECTIONS,
                            //   subTitle: 'Choose from selectable areas entry area',
                            // }}
                            key={area.id}
                          >
                            {areas.find((item: any) => item.id === area.id)?.name || '-'}
                          </Tag>
                        ))}
                    </Card>
                  </Col>

                  <Col className="pr-0" size="6">
                    <Card
                      subTitle="Exit Areas"
                      iconsGroup={
                        !aoi.id && (
                          <SelectableOptionsWithIcon
                            onSave={(ids: any) => handleSaveExitArea(aoi.tempId, ids)}
                            areas={areas}
                            areasOfInterest={exit_areas}
                          />
                        )
                      }
                      padding
                    >
                      {exit_areas.length > 0 &&
                        exit_areas.map((area: any) => (
                          <Tag
                            className="mb-2"
                            // dual={{
                            //   title: 'Directions',
                            //   selected: selectedExitArea,
                            //   onChange: (value) => setSelectedExitArea(value),
                            //   options: DIRECTIONS,
                            //   subTitle: 'Choose from selectable areas exit area',
                            // }}
                            key={area.id}
                          >
                            {/* {areas.find((item: any) => item.id === parseInt(area.id, 10)).name} */}
                            {areas.find((item: any) => item.id === area.id)?.name || '-'}
                          </Tag>
                        ))}
                    </Card>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </Card>
      </Col>
    </Row>
  );
};
