import * as Action from './actions';
import { lastWeekTime, todayLastTime } from '../../../utils/helpers';

const fromDate = localStorage.getItem('startDate');
const toDate = localStorage.getItem('endDate');

const initialState = {
  startDate: fromDate ? new Date(fromDate) : lastWeekTime,
  endDate: toDate ? new Date(toDate) : todayLastTime,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.UPDATE_START_DATE: {
      localStorage.setItem('startDate', action.payload);
      return {
        ...state,
        startDate: action.payload,
      };
    }
    case Action.UPDATE_END_DATE: {
      localStorage.setItem('endDate', action.payload);
      return {
        ...state,
        endDate: action.payload,
      };
    }

    default:
      return state;
  }
};
