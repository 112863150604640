export const colDef = [
  {
    title: '',
    field: 'store',
    order: false,
  },
  {
    title: 'Total Footfall',
    field: 'totalFootfall',
    order: false,
  },
  {
    title: 'Busiest Month',
    field: 'busiestMonth',
    order: false,
  },
  {
    title: 'Busiest Week',
    field: 'busiestWeek',
    order: false,
  },
  {
    title: 'Busiest Day',
    field: 'busiestDay',
    order: false,
  },
  {
    title: 'Busiest Hour',
    field: 'busiestHour',
    order: false,
  },
];

export const HIGHLIGHTED_ROW_NAME = 'highlightedRow';
