/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import cx from 'classnames';
import { PopUp } from 'src/components/PopUp';
import { Typography } from 'src/components/Typography';
import { Tag } from 'src/components/Tag';
import { Button } from 'src/components/Button';
import { useModal } from 'src/hooks/useModal';
import { Card } from 'src/components/Card';
import { Icon } from 'src/components/Icon';
import { groupBy } from 'src/utils/array';
import { useSelector } from 'react-redux';

type FilterOption = {
  label: string;
  value: string;
};

export type FilterItem = {
  title: string;
  key: string;
  options: FilterOption[];
  defaultValue?: string;
};

type FilterElementProps = {
  data: FilterItem;
  className?: string;
  selectedFilters: FilterOption[];
  // eslint-disable-next-line no-unused-vars
  onSelect: (filter: FilterOption, group: string) => void;
};

const FilterElement = ({ data, className, selectedFilters, onSelect }: FilterElementProps) => {
  return (
    <div className={cx(className)}>
      <Typography>{data.title}</Typography>
      <hr className="mt-2 mb-4" />

      <div className="flex gap-2 flex-wrap">
        {data.options.map((option) => (
          <Tag
            key={`option-${option.value}`}
            className="cursor-pointer"
            active={!!selectedFilters.find((item) => item.value === option.value)}
            title={option.label}
            onClick={() => onSelect(option, data.key)}
          >
            {option.label}
          </Tag>
        ))}
      </div>
    </div>
  );
};

type SelectedFilterState = {
  group: string;
} & FilterOption;

export type FiltersProps = {
  className?: string;
  filters: FilterItem[];
  title: string;
  // eslint-disable-next-line no-unused-vars
  onApplyFilters: (values: { [key: string]: string[] }) => void;
  onClear?: () => void;
  acceptMultipleOptions?: boolean;
};

export const Filters = ({
  className,
  filters = [],
  title,
  onApplyFilters,
  onClear,
  acceptMultipleOptions = true,
}: FiltersProps) => {
  const [isOpen, onClose] = useModal();
  const [openModal, setOpenModal] = useState(false);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);

  const handleFiltersFromStore = () => {
    const defaultSelected: SelectedFilterState[] = [];
    const ageArray = ageFilter ? ageFilter.split(',') : [];
    const genderArray = genderFilter ? genderFilter.split(',') : [];
    const filterArray = [...ageArray, ...genderArray];
    filterArray.forEach((keys: any) => {
      filters
        .forEach((filter) => {
          const item = filter.options.find((item) => item.value === keys);
          if (item) {
            defaultSelected.push({ ...item, group: filter.key });
          }
        });
    });
    return defaultSelected;
  };

  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilterState[]>(() => {
    return handleFiltersFromStore();
  });

  const selectedFiltersThin = React.useMemo(() => {
    const filtersGrouped = groupBy<SelectedFilterState>(selectedFilters, 'group');

    const result: { [key: string]: string[] } = {};

    Object.keys(filtersGrouped).forEach((key) => {
      const values = filtersGrouped[key].map((item) => item.value);

      result[key] = values;
    });

    return result;
  }, [selectedFilters]);

  const handleSelectFilter = (filterObj: FilterOption, group: string) => {
    setSelectedFilters((prev) => {
      if (acceptMultipleOptions) {
        const filterFound = prev.find((filter) => filter.group === group && filter.value === filterObj.value);

        if (filterFound) {
          return prev.filter((filter) => filter.value !== filterObj.value);
        }

        return prev.concat({ ...filterObj, group });
      }

      return prev.filter((filter) => filter.group !== group).concat({ ...filterObj, group });
    });
  };

  const handleRemoveFilter = ({ group, value }: SelectedFilterState) => {
    setSelectedFilters((prev) => {
      const newList = prev.filter((filter) => !(filter.group === group && filter.value === value));

      const newGroupedList = groupBy(newList, 'group');

      const result: { [key: string]: string[] } = {};

      Object.keys(newGroupedList).forEach((key) => {
        const values = newGroupedList[key].map((item) => item.value);

        result[key] = values;
      });

      onApplyFilters(result);

      return newList;
    });
  };

  const filterElements = (
    <Card className={cx('max-w-[372px] w-full min-w-[300px]')} padding>
      <div className="flex justify-between mb-8">
        <Typography>{title}</Typography>

        <Button
          variant="ghost"
          onClick={() => {
            setSelectedFilters([]);
            onClear?.();
            onClose();
          }}
        >
          Clear All
        </Button>
      </div>

      {filters.map((filter, idx, list) => (
        <FilterElement
          key={filter.title}
          data={filter}
          className={cx({ 'mb-8': idx < list.length - 1 })}
          selectedFilters={selectedFilters}
          onSelect={handleSelectFilter}
        />
      ))}

      <Button
        size="medium"
        className="mt-10"
        onClick={() => {
          onApplyFilters(selectedFiltersThin);
          setOpenModal(false);
        }}
      >
        Apply Filters
      </Button>
    </Card>
  );

  const handleRemoveLastTag = () => {
    if (!genderFilter && !ageFilter) {
      setSelectedFilters([]);
    } else if (ageFilter || genderFilter) {
      setSelectedFilters(handleFiltersFromStore());
    }
    setOpenModal(false);
  };

  return (
    <PopUp isOpen={openModal} onClose={handleRemoveLastTag} element={filterElements}>
      <Card
        className={cx(
          'flex gap-4 mb-0 px-4 py-1 border-2 border-transparent',
          {
            'border-b-primary border-solid': isOpen || !!selectedFilters.length,
          },
          className,
        )}
      >
        <button type="button" onClick={() => setOpenModal(true)} className={cx('flex items-center gap-2')}>
          <Icon icon="sortTool" />
          <Typography variant="small-button" color="neutral-900">
            {title}
          </Typography>
        </button>

        <div className="flex gap-4">
          {selectedFilters.map((filter) => {
            return (
              <Tag
                icon="close"
                onClickIcon={() => handleRemoveFilter(filter)}
                active
                key={`${filter.group}-${filter.value}`}
              >
                {filter.label}
              </Tag>
            );
          })}
        </div>
      </Card>
    </PopUp>
  );
};
