import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

export type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  children?: React.ReactNode;
};

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ className, label, children, ...props }, ref) => {
    const labelId = React.useRef(Math.random().toString());

    return (
      <label className="flex items-center gap-3" htmlFor={labelId.current}>
        <input
          ref={ref}
          id={labelId.current}
          className={cx(styles.radio, className)}
          {...props}
          type="radio"
        />
        <span className="text-neutral-900 text-base">{label || children}</span>
      </label>
    );
  },
);
