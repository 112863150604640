import { axiosJsonInstance, axiosBlobInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async getCameras(departmentId: any) {
    return axiosJsonInstance.get('e_search/cameras/', {
      params: {
        department_id: departmentId,
      },
    });
  },

  async updateCameras(cameras: any) {
    return axiosJsonInstance.put('camera-distribution/', cameras);
  },

  async getCameraImage(cameraId: any) {
    return axiosBlobInstance.get(`e_search/camera_image/${cameraId}/`);
  },

  async getCameraImageSnapshot(cameraId: any) {
    return axiosBlobInstance.get(`e_search/camera_image_snapshot/${cameraId}/`);
  },

  async getCamerasWithInfo(startDate: any, endDate: any, storeId: any) {
    return axiosJsonInstance.get('camera-info/', {
      params: {
        start_date: startDate, // july 1
        end_date: endDate, // October 7
        store_id: storeId,
      },
    });
  },

  async getCameraStats(startDate: any, endDate: any, cameraId: any, ageFilter: any, genderFilter: any) {
    return axiosJsonInstance.get(`camera/${cameraId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      },
    });
  },

  async getCameraPaths(startDate: any, endDate: any, cameraId: any) {
    return axiosJsonInstance.get(`/e_search/paths/${cameraId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  async getCamerasDistribution() {
    return axiosJsonInstance.get('camera-distribution/');
  },
};
