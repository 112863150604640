import React from 'react';
import cx from 'classnames';

import { ToggleSwitch } from 'react-dragswitch';

import 'react-dragswitch/dist/index.css';

const GREEN_COLOR = '#27AE60';
const NEUTRAL_200_COLOR = '#BFBFC4';

export type ToggleProps = {
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  handleColor?: string;
  focusShadow?: string;
  label?: string;
  justifyEnd?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Toggle = React.forwardRef<any, ToggleProps>(
  ({ className, label, justifyEnd, ...props }, ref) => (
    <div className={cx(
      'flex items-center gap-2',
      { 'justify-end': justifyEnd },
    )}
    >
      <span className="text-sm text-neutral-800">{label}</span>
      <ToggleSwitch
        ref={ref}
        className={cx(className)}
        onColor={GREEN_COLOR}
        offColor={NEUTRAL_200_COLOR}
        {...props}
      />
    </div>
  ),
);
