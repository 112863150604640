import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  children?: React.ReactNode;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, label, children, ...props }, ref) => {
    const labelId = React.useRef(Math.random().toString());

    return (
      <label className="flex items-center gap-3" htmlFor={labelId.current}>
        <input
          ref={ref}
          id={labelId.current}
          className={cx(
            styles.checkbox,
            'focus-within:outline outline-2 outline-offset-2',
            className,
          )}
          {...props}
          type="checkbox"
        />
        <span className="text-base text-neutral-900">{label || children}</span>
      </label>
    );
  },
);
