/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Card } from 'src/components/Card';
import { Icon } from 'src/components/Icon';
import Logo from 'src/assets/images/TrackIn_Logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import cx from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { history } from 'src/utils/history';

import styles from './styles.module.css';

const sortByStoreName = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const sortByCameraName = (a: any, b: any) => {
  if (a.es_cam_id < b.es_cam_id) {
    return -1;
  }
  if (a.es_cam_id > b.es_cam_id) {
    return 1;
  }
  return 0;
};

type IParamsProps = {
  id?: string;
  storeId?: string;
  cameraId?: string;
};

const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, storeId, cameraId } = useParams<IParamsProps>();
  const { storesInfo } = useSelector(({ stores }: any) => stores);
  const { is_superuser } = useSelector(({ auth }: any) => auth.user);
  const [menuData, setMenuData] = useState<any[]>();

  useEffect(() => {
    dispatch(
      ActionTypes.getAllStoresInfo.request({}),
    );
  }, [dispatch]);

  const handleClickMenu = (storeId: number, department?: number, cameraId?: number) => {
    if (cameraId && department) {
      history.push(`/camera/${storeId}/${department}/${cameraId}`);
    } else {
      history.push(`/store/${storeId}`);
    }
  };

  useEffect(() => {
    const menu = storesInfo.sort(sortByStoreName).map((store: any) => ({
      id: store.id,
      name: store.name,
      active:
        (Number(id) === store.id && location.pathname.indexOf('store') > 0) ||
        (location.pathname.indexOf('camera') > 0 && Number(storeId) === store.id),
      cameras: store.cameras.sort(sortByCameraName).map((camera: any) => ({
        id: camera.id,
        department: camera.department,
        es_cam_id: camera.es_cam_id,
        active: false,
      })),
    }));

    setMenuData(menu);
  }, [storesInfo, storeId, id]);

  return (
    <Card className="mr-2 min-w-[200px] h-full sticky top-0 left-0">
      <div
        className="w-full mb-5 pt-3 cursor-pointer"
        onClick={() => history.push(is_superuser ? '/super-admin/admin-dashboard' : '/')}
      >
        <img src={Logo} width="74" alt="TrackIn" className="mx-auto" />
      </div>
      <div
        className={cx('p-2 cursor-pointer', {
          'pl-0 border-l-4 border-primary bg-gray-100': location.pathname === '/dashboard',
        })}
        onClick={() => history.push('/dashboard')}
      >
        <Icon icon="dashboard" className="mt-1 mx-2" />
        Dashboard
      </div>
      {menuData &&
        menuData.map((store: any) => (
          <div
            key={store.id}
            className={`${styles.menuItem} ${cx('p-2 pointer', {
              'pl-0 bg-gray-100': store.active,
            })}`}
          >
            <div
              onClick={() => handleClickMenu(store.id)}
              className={`${cx('p-2 pb-0 pointer', {
                'border-l-4 border-primary': store.active,
              })}`}
            >
              <Icon icon="store" className="mt-1 mr-2" />
              {store.name}
              <Icon icon="arrow-down" className="mt-2 mr-2 float-right" />
            </div>
            <ul
              className={cx(
                `pointer ${store.active ? styles.activeSubmenu : ''}
                  location.pathname.indexOf('camera') > 0 && Number(storeId) === store.id ? styles.activeSubmenu : ''
                }`,
              )}
            >
              {store.cameras.map((camera: any) => (
                <div
                  key={`${store.id}-${camera.id}`}
                  className={`flex h-10 text-gray-600 ${Number(cameraId) === camera.id ? 'border-l-4 border-primary' : ''}`}
                  onClick={() => handleClickMenu(store.id, camera.department, camera.id)}
                >
                  <div className="ml-4 self-center">
                    <Icon icon="cameraVideo" />
                  </div>
                  {camera.es_cam_id.replace('cam', 'cam ')}
                </div>
              ))}
            </ul>
          </div>
        ))}
    </Card>
  );
};
export default SideMenu;
