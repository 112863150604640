import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* postProduct({ payload }: any) {
  try {
    const { data } = yield call(Api.postProduct, payload);
    yield put(ActionTypes.postProduct.success(data));
    notifyInfo('Product created!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postProduct.failure(e));
  }
}

function* postProductSaga() {
  yield takeLatest(ActionTypes.POST_PRODUCT, postProduct);
}

function* getStoreProducts({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreProducts,
      payload.storeId,
      payload.search,
      payload.pageNumber,
      payload.next,
      payload.previous,
      payload.more,
    );
    data.more = payload.more;
    yield put(ActionTypes.getStoreProducts.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreProducts.failure(e));
  }
}

function* getStoreProductsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_PRODUCTS, getStoreProducts);
}

function* getDepartmentProducts({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDepartmentProducts,
      payload.departmentId,
      payload.search,
      payload.pageNumber,
      payload.next,
      payload.previous,
      payload.more,
    );
    data.more = payload.more;
    yield put(ActionTypes.getDepartmentProducts.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getDepartmentProducts.failure(e));
  }
}

function* getDepartmentProductsSaga() {
  yield takeLatest(ActionTypes.GET_DEPARTMENT_PRODUCTS, getDepartmentProducts);
}

function* updateProduct({ payload }: any) {
  try {
    const { data } = yield call(
      Api.updateProduct,
      payload.productId,
      payload.data,
    );
    yield put(ActionTypes.updateProduct.success(data));
    notifyInfo('Product updated!');
    // history.push("dashboard");
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateProduct.failure(e));
  }
}

function* updateProductSaga() {
  yield takeLatest(ActionTypes.UPDATE_PRODUCT, updateProduct);
}

function* getProduct({ payload }: any) {
  try {
    const { data } = yield call(Api.getProduct, payload.productId);
    yield put(ActionTypes.getProduct.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getProduct.failure(e));
  }
}

function* getProductSaga() {
  yield takeLatest(ActionTypes.GET_PRODUCT, getProduct);
}

export default function* rootSaga() {
  yield all([
    fork(postProductSaga),
    fork(getStoreProductsSaga),
    fork(getDepartmentProductsSaga),
    fork(updateProductSaga),
    fork(getProductSaga),
  ]);
}
