import React, { createContext, useState } from 'react';

import { AvailableIllustrations } from 'src/components/Illustration/constants';

type State = {
  title: string;
  subTitle?: string;
  illustration?: AvailableIllustrations;
  labelConfirm?: string;
  isOpen: boolean;
  proceed: any;
  cancel: any;
};

export const ConfirmContext = createContext<[State, React.Dispatch<State>]>([
  {
    title: '',
    isOpen: false,
    proceed: null,
    cancel: null,
  },
  () => null,
]);

const ConfirmContextProvider: React.FC = ({ children }) => {
  const [confirm, setConfirm] = useState<State>({
    title: '',
    subTitle: '',
    labelConfirm: '',
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>;
};

export default ConfirmContextProvider;
