import { createApiAction } from 'src/utils/helpers';

export const POST_STORE = 'POST_STORE';
export const POST_STORE_SUCCESS = 'POST_STORE_SUCCESS';
export const POST_STORE_FAILURE = 'POST_STORE_FAILURE';

export const GET_ALL_STORES = 'GET_ALL_STORES';
export const GET_ALL_STORES_SUCCESS = 'GET_ALL_STORES_SUCCESS';
export const GET_ALL_STORES_FAILURE = 'GET_ALL_STORES_FAILURE';
export const GET_ALL_STORES_PAYLOAD = 'GET_ALL_STORES_PAYLOAD';

export const GET_STORE = 'GET_STORE';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';

export const GET_ALL_STORES_INFO = 'GET_ALL_STORES_INFO';
export const GET_ALL_STORES_INFO_SUCCESS = 'GET_ALL_STORES_INFO_SUCCESS';
export const GET_ALL_STORES_INFO_FAILURE = 'GET_ALL_STORES_INFO_FAILURE';

export const GET_STORE_TODAY = 'GET_STORE_TODAY';
export const GET_STORE_TODAY_SUCCESS = 'GET_STORE_TODAY_SUCCESS';
export const GET_STORE_TODAY_FAILURE = 'GET_STORE_TODAY_FAILURE';

export const GET_STORE_BRAND = 'GET_STORE_BRAND';
export const GET_STORE_BRAND_SUCCESS = 'GET_STORE_BRAND_SUCCESS';
export const GET_STORE_BRAND_FAILURE = 'GET_STORE_BRAND_FAILURE';

export const UPDATE_STORE_BRAND = 'UPDATE_STORE_BRAND';
export const UPDATE_STORE_BRAND_SUCCESS = 'UPDATE_STORE_BRAND_SUCCESS';
export const UPDATE_STORE_BRAND_FAILURE = 'UPDATE_STORE_BRAND_FAILURE';

export const GET_STORE_TOP_VIEW_PLOTS = 'GET_STORE_TOP_VIEW_PLOTS';
export const GET_STORE_TOP_VIEW_PLOTS_SUCCESS = 'GET_STORE_TOP_VIEW_PLOTS_SUCCESS';
export const GET_STORE_TOP_VIEW_PLOTS_FAILURE = 'GET_STORE_TOP_VIEW_PLOTS_FAILURE';

export const postStore = createApiAction(POST_STORE);
export const getAllStores = createApiAction(GET_ALL_STORES);
export const getAllStoresInfo = createApiAction(GET_ALL_STORES_INFO);
export const getStore = createApiAction(GET_STORE);
export const getStoreToday = createApiAction(GET_STORE_TODAY);
export const getStoreBrand = createApiAction(GET_STORE_BRAND);
export const updateStoreBrand = createApiAction(UPDATE_STORE_BRAND);
export const getStoreTopViewPlots = createApiAction(GET_STORE_TOP_VIEW_PLOTS);
