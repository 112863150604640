import React from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { useSelector } from 'react-redux';

export type FormStore = {
  storeId: number;
  brand: string;
  store?: string;
  address?: string;
  address_2: string;
  city: string;
  postcode: string;
  phone_number: string;
  numberOfCameras?: number;
};

export type ModalFormStoreProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: FormStore;
  onSubmit: (values: FormStore) => void;
};

export const ModalFormStore = ({ isOpen, onClose, defaultValues, onSubmit }: ModalFormStoreProps) => {
  const { storeBrandForm_loading } = useSelector((state: any) => state.stores);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
  } = useForm<FormStore>({
    defaultValues,
  });

  const { title, subTitle, buttonLabel } = React.useMemo(() => {
    const hasDefaultValues = !!defaultValues;

    return {
      buttonLabel: hasDefaultValues ? 'Save' : 'Add',
      title: hasDefaultValues ? 'Edit Store' : 'Add Store',
      subTitle: hasDefaultValues ? 'Edit store to the brand' : 'Add store to the brand',
    };
  }, [defaultValues]);

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  const onSubmitForm = handleSubmit(async (data) => {
    onSubmit(data);
  });

  return (
    <Modal
      hideCloseButton
      title={title}
      subTitle={subTitle}
      isOpen={isOpen}
      onClose={handleClose}
      className={cx('px-5 py-6')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={onSubmitForm}>
        <FormField label="Store" error={errors.store?.message}>
          <TextInput {...register('store')} icon="store" placeholder="e.g. Store" />
        </FormField>

        <FormField label="Address" error={errors.address?.message}>
          <TextInput {...register('address')} icon="location" placeholder="e.g. Main street 7" />
        </FormField>

        <FormField label="Complement" error={errors.address_2?.message}>
          <TextInput {...register('address_2')} icon="location" placeholder="e.g. Complement" />
        </FormField>

        <FormField label="City" error={errors.address?.message}>
          <TextInput {...register('city')} icon="location" placeholder="e.g. City" />
        </FormField>

        <FormField label="Postcode" error={errors.address?.message}>
          <TextInput {...register('postcode')} icon="location" placeholder="e.g. Postcode" />
        </FormField>

        <FormField label="Phone Number" error={errors.address?.message}>
          <TextInput {...register('phone_number')} icon="location" placeholder="e.g. Phone Number" />
        </FormField>

        <FormField label="No. of Cameras" error={errors.numberOfCameras?.message} className="hidden">
          <TextInput {...register('numberOfCameras')} type="number" icon="cameraVideo" placeholder="e.g. 10" />
        </FormField>

        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={handleClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={storeBrandForm_loading} size="medium" type="submit">
              {buttonLabel}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
