import * as Action from './actions';

const genderFilter = localStorage.getItem('genderFilter');
const ageFilter = localStorage.getItem('ageFilter');

const initialState = {
  genderFilter: genderFilter || '',
  ageFilter: ageFilter || '',
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.UPDATE_GENDER: {
      localStorage.setItem('genderFilter', action.payload);
      return {
        ...state,
        genderFilter: action.payload,
      };
    }
    case Action.UPDATE_AGE: {
      localStorage.setItem('ageFilter', action.payload);
      return {
        ...state,
        ageFilter: action.payload,
      };
    }
    default:
      return state;
  }
};
