import React from 'react';
import cx from 'classnames';

import ReactModal, { Props } from 'react-modal';
import { If } from '../If';
import { Typography } from '../Typography';
import { Icon } from '../Icon';

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'fit-content',
  },
};

type SizeType = 'sm' | 'md' | 'lg' | 'xl';

// eslint-disable-next-line no-unused-vars
const SIZES: { [key in SizeType]: number } = {
  sm: 300,
  md: 480,
  lg: 660,
  xl: 940,
};

export type ModalProps = Props & {
  isOpen: boolean;
  onClose: () => void;
  size?: SizeType;
  title?: string;
  subTitle?: string;
  headerItens?: React.ReactNode;
  hideCloseButton?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  size,
  className,
  title,
  subTitle,
  headerItens,
  hideCloseButton,
  ...rest
}) => {
  const sizeModal = React.useMemo(() => {
    return SIZES[size as SizeType];
  }, [size]);

  return (
    <ReactModal
      style={{ content: { ...customStyles.content, maxWidth: sizeModal } }}
      isOpen={isOpen}
      shouldCloseOnEsc
      onRequestClose={onClose}
      {...rest}
    >
      <div className={cx(className as string)}>
        <If condition={title || subTitle}>
          <div className="flex justify-between items-start mb-3">
            <div>
              <Typography variant="standard-h3" color="neutral-800">
                {title}
              </Typography>
              <Typography variant="caption-3" color="neutral-400">
                {subTitle}
              </Typography>
            </div>

            <div className="flex items-center gap-3">
              {headerItens}
              <If condition={!hideCloseButton}>
                <Icon icon="close" tag="button" onClick={onClose} />
              </If>
            </div>
          </div>
        </If>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  size: 'md',
} as ModalProps;
