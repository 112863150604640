import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import { getParametersAndPersistObject } from 'src/utils/persist';
import { isEmpty } from 'lodash';
import { objectsAreEqual } from 'src/utils/objectsAreEqual';
import * as ActionTypes from './actions';
import Api from './api';
import { mockResponse } from 'src/pages/StoreDetail/components/StoreTopViewPlot/mockResponse';

function* getAllStoresInfo() {
  try {
    const { data } = yield call(Api.getAllStoresInfo);
    yield put(ActionTypes.getAllStoresInfo.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStoresInfo.failure(e));
  }
}

function* getAllStoresInfoSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES_INFO, getAllStoresInfo);
}

function* getAllStores({ payload }: any) {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { stores, storesPayload } = persistObject.stores;

    if (
      stores &&
      !isEmpty(stores) &&
      objectsAreEqual(storesPayload, payload)
    ) {
      yield put(ActionTypes.getAllStores.success(stores));
      return;
    }

    const { data } = yield call(Api.getAllStores, payload);
    yield put(ActionTypes.getAllStores.success(data));
    yield put(ActionTypes.getAllStores.savePayload(payload));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStores.failure(e));
  }
}

function* getAllStoresSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES, getAllStores);
}

function* getStore({ payload }: any): any {
  try {
    const { data } = yield call(Api.getStore, payload.storeId, payload.startDate, payload.endDate, payload.ageFilter, payload.genderFilter);

    yield put(ActionTypes.getStore.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStore.failure(e));
  }
}

function* getStoreSaga() {
  yield takeLatest(ActionTypes.GET_STORE, getStore);
}

function* getStoreToday({ payload }: any) {
  try {
    const startDate = Math.trunc(new Date().setHours(0, 0, 0) / 1000);
    const endDate = Math.trunc(new Date().setHours(23, 59, 59) / 1000);

    const { data } = yield call(
      Api.getStore,
      payload.storeId,
      startDate,
      endDate,
    );

    yield put(ActionTypes.getStoreToday.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreToday.failure(e));
  }
}

function* getStoreTodaySaga() {
  yield takeLatest(ActionTypes.GET_STORE_TODAY, getStoreToday);
}

function* getStoreBrand({ payload }: any) {
  try {
    const { data } = yield call(Api.getStoreBrand, payload.brandId);
    yield put(ActionTypes.getStoreBrand.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreBrand.failure(e));
  }
}

function* getStoreBrandSaga() {
  yield takeLatest(ActionTypes.GET_STORE_BRAND, getStoreBrand);
}

function* postStore({ payload }: any) {
  try {
    const { data } = yield call(Api.postStore, payload);
    yield put(ActionTypes.postStore.success(data));
    notifyInfo('Store created!');
    // history.push('dashboard');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postStore.failure(e));
  }
}

function* postStoreSaga() {
  yield takeLatest(ActionTypes.POST_STORE, postStore);
}

function* updateStoreBrand({ payload }: any) {
  try {
    const { data } = yield call(Api.updateStoreBrand, payload);
    yield put(ActionTypes.updateStoreBrand.success(data));
    notifyInfo('Store updated!');
  } catch (e) {
    notifyError((e as string) || 'Error!');
    yield put(ActionTypes.updateStoreBrand.failure(e));
  }
}

function* updateStoreSaga() {
  yield takeLatest(ActionTypes.UPDATE_STORE_BRAND, updateStoreBrand);
}

function* getStoreTopViewPlots({ payload }: any) {
  try {
    const { storeId, startDate, endDate } = payload;
    const { data } = yield call(Api.getStoreTopViewPlots, storeId, startDate, endDate);
    yield put(ActionTypes.getStoreTopViewPlots.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreTopViewPlots.failure(e));
  }
}

function* getStoreTopViewPlotsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_TOP_VIEW_PLOTS, getStoreTopViewPlots);
}

export default function* rootSaga() {
  yield all([
    fork(getAllStoresInfoSaga),
    fork(getAllStoresSaga),
    fork(getStoreSaga),
    fork(getStoreTodaySaga),
    fork(getStoreBrandSaga),
    fork(postStoreSaga),
    fork(updateStoreSaga),
    fork(getStoreTopViewPlotsSaga),
  ]);
}
