import { createApiAction } from 'src/utils/helpers';

export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const GET_ALL_BRANDS_SUCCESS = 'GET_ALL_BRANDS_SUCCESS';
export const GET_ALL_BRANDS_FAILURE = 'GET_ALL_BRANDS_FAILURE';

export const POST_BRAND = 'POST_BRAND';
export const POST_BRAND_SUCCESS = 'POST_BRAND_SUCCESS';
export const POST_BRAND_FAILURE = 'POST_BRAND_FAILURE';

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE';

export const GET_BRAND_USERS = 'GET_BRAND_USERS';
export const GET_BRAND_USERS_SUCCESS = 'GET_BRAND_USERS_SUCCESS';
export const GET_BRAND_USERS_FAILURE = 'GET_BRAND_USERS_FAILURE';

export const POST_BRAND_USERS = 'POST_BRAND_USERS';
export const POST_BRAND_USERS_SUCCESS = 'POST_BRAND_USERS_SUCCESS';
export const POST_BRAND_USERS_FAILURE = 'POST_BRAND_USERS_FAILURE';

export const UPDATE_BRAND_USERS = 'UPDATE_BRAND_USERS';
export const UPDATE_BRAND_USERS_SUCCESS = 'UPDATE_BRAND_USERS_SUCCESS';
export const UPDATE_BRAND_USERS_FAILURE = 'UPDATE_BRAND_USERS_FAILURE';

export const DELETE_BRAND_USERS = 'DELETE_BRAND_USERS';
export const DELETE_BRAND_USERS_SUCCESS = 'DELETE_BRAND_USERS_SUCCESS';
export const DELETE_BRAND_USERS_FAILURE = 'DELETE_BRAND_USERS_FAILURE';

export const getAllBrands = createApiAction(GET_ALL_BRANDS);
export const getBrandUsers = createApiAction(GET_BRAND_USERS);
export const postBrand = createApiAction(POST_BRAND);
export const updateBrand = createApiAction(UPDATE_BRAND);

export const postBrandUser = createApiAction(POST_BRAND_USERS);
export const updateBrandUser = createApiAction(UPDATE_BRAND_USERS);
export const deleteBrandUser = createApiAction(DELETE_BRAND_USERS);
