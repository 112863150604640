import { useEffect, useRef, useState } from 'react';

export function useRefWidth() {
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect?.();
      setWidth(boundingRect.width);
    }
  }, [ref]);

  const detectSize = () => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect?.();
      setWidth(boundingRect.width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [width]);

  return { widthValue: width, widthRef: ref };
}
