import React from 'react';

import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
  tooltipText: string;
  tooltipPlacement: 'top' | 'bottom' | 'right' | 'left';
  width?: number;
};

export function Tooltip({ children, tooltipText, tooltipPlacement = 'top', width }: Props) {
  return (
    <div className={`${tooltipText && styles.tooltip}`}>
      {children}
      {tooltipText && (
        <span style={{ width }} className={`${styles.tooltiptext} ${styles[tooltipPlacement || 'top']}`}>{tooltipText}</span>
      )}
    </div>
  );
}
