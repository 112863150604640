import { Modal } from 'src/components/Modal';
import cx from 'classnames';
import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { RadioButton } from 'src/components/RadioButton';
import { Row } from 'src/components/Row';
import { useForm } from 'react-hook-form';
import { FormUser } from './ModalFormUser';
import { useDispatch } from 'react-redux';

import * as ActionTypes from 'src/store/actions';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  brandId: string;
  selectedUser?: FormUser;
}

export const ModalFormUserEmail = ({ handleClose, isOpen, brandId, selectedUser }: Props) => {
  const { email_subscription } = selectedUser ?? { email_subscription: null };
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm<{
    allow_marketing_emails: boolean;
    notification_period: string;
  }>({ defaultValues: { ...email_subscription, notification_period: email_subscription?.notification_period.toString() } });

  const allowEmails = watch('allow_marketing_emails');

  const submitForm = (values: any) => {
    const body = {
      user_id: selectedUser?.id,
      allow_marketing_emails: values.allow_marketing_emails,
      notification_period: values.notification_period,
    };

    if (email_subscription) {
      dispatch(
        ActionTypes.updateUserEmailPreferences.request(body),
      );
    } else {
      dispatch(
        ActionTypes.createUserEmailPreferences.request(body),
      );
    }

    dispatch(
      ActionTypes.getBrandUsers.request({
        brandId,
      }),
    );
  };

  return (
    <Modal
      hideCloseButton
      title="E-mail preferences"
      subTitle="Config the selected user email preferences"
      isOpen={isOpen}
      onClose={handleClose}
      className={cx('px-5 py-6 h-[300px] overflow-auto')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(submitForm)}>
        <FormField>
          <Checkbox {...register('allow_marketing_emails')} name="allow_marketing_emails">
            Subscribe to marketing e-mails
          </Checkbox>
        </FormField>
        <FormField label="E-mails frequency">
          <div className="flex gap-8">
            <RadioButton {...register('notification_period')} name="notification_period" value={7} label="Weekly" disabled={!allowEmails} />
            <RadioButton {...register('notification_period')} name="notification_period" value={30} label="Monthly" disabled={!allowEmails} />
          </div>
        </FormField>
        <Row className="mt-5">
          <Col>
            <Button size="medium" onClick={handleClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
