import React from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from 'src/components/Button';
import { Col } from 'src/components/Col';
import { FormField } from 'src/components/FormField';
import { Modal } from 'src/components/Modal';
import { Row } from 'src/components/Row';
import { TextInput } from 'src/components/TextInput';
import { Dropdown } from 'src/components/Dropdown';
import { COUNTRY_OPTIONS } from 'src/constants/countries';
import { toast } from 'react-toastify';
import { axiosJsonInstance } from 'src/store/api';
import { useDispatch } from 'react-redux';

import * as ActionTypes from 'src/store/actions';

export type FormBrand = {
  id: string;
  brand: string;
  address?: string;
  country?: string;
  contactsName?: string;
  contactsEmail?: string;
  contactsPhoneNumber?: string;
  staff?: string;
};

type BrandsProps = {
  id: number;
  name: string;
  address?: string;
  icon_url?: string;
  employees?: any[];
  stores?: any[];
  icon?: string;
  phone_number?: string;
};

export type ModalFormBrandProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: FormBrand;
  brands: BrandsProps[];
  setBrands: React.Dispatch<React.SetStateAction<BrandsProps[]>>;
  // eslint-disable-next-line no-unused-vars
};

export const ModalFormBrand = ({ isOpen, onClose, defaultValues, brands, setBrands }: ModalFormBrandProps) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
  } = useForm<FormBrand>({ defaultValues });

  const { title, subTitle, buttonLabel } = React.useMemo(() => {
    const hasDefaultValues = !!defaultValues;

    return {
      buttonLabel: hasDefaultValues ? 'Save' : 'Add',
      title: hasDefaultValues ? 'Edit Brand' : 'Add Brand',
      subTitle: hasDefaultValues ? 'Edit brand main information' : 'Enter brand info to add new brand',
    };
  }, [defaultValues]);

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (defaultValues) {
        dispatch({
          type: ActionTypes.UPDATE_BRAND,
          payload: {
            brandId: defaultValues?.id,
            name: data.brand,
            address: data.address,
          },
        });
      } else {
        dispatch({
          type: ActionTypes.POST_BRAND,
          payload: {
            name: data.brand,
            address: data.address,
          },
        });
      }
      onClose();
    } catch (err) {
      toast.error('Error to insert new brand');
    }
  });

  return (
    <Modal
      hideCloseButton
      title={title}
      subTitle={subTitle}
      isOpen={isOpen}
      onClose={handleClose}
      className={cx('px-5 py-6')}
      size="md"
    >
      <form className="flex flex-col gap-3" onSubmit={onSubmit}>
        <FormField label="Brand" error={errors.brand?.message}>
          <TextInput
            {...register('brand', { required: 'Brand field is required' })}
            icon="store"
            placeholder="e.g. Brand"
          />
        </FormField>

        <FormField label="Address" error={errors.address?.message}>
          <TextInput {...register('address')} icon="location" placeholder="e.g. Main street 7" />
        </FormField>

        <FormField label="Country" error={errors.country?.message}>
          <Dropdown icon="location" {...register('country')} options={COUNTRY_OPTIONS} placeholder="e.g. Germany" />
        </FormField>

        <FormField label="Contacts name" error={errors.contactsName?.message}>
          <TextInput {...register('contactsName')} icon="user" placeholder="e.g. John" />
        </FormField>

        <FormField label="Contacts email" error={errors.contactsEmail?.message}>
          <TextInput {...register('contactsEmail')} icon="mail" placeholder="e.g. john@email.com" />
        </FormField>

        <FormField label="Contacts phone number" error={errors.contactsPhoneNumber?.message}>
          <TextInput {...register('contactsPhoneNumber')} icon="phoneButton" placeholder="e.g. +34 123 123" />
        </FormField>

        <FormField label="Staff" error={errors.staff?.message}>
          <TextInput {...register('staff')} icon="user" placeholder="e.g. John Doe" />
        </FormField>

        <Row className="mt-5 items-center">
          <Col>
            <Button size="medium" onClick={handleClose} variant="ghost" color="red">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button disabled={!isDirty} size="medium" type="submit">
              {buttonLabel}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
