import { axiosJsonInstance } from 'src/store/api';

export default {
  async getLocations(query: any) {
    return axiosJsonInstance.get('locations/', { params: query });
  },

  async postLocation(data: any) {
    return axiosJsonInstance.post('locations/', data);
  },
};
