import * as Action from './actions';

const initialState = {
  interval: '1d',

  allStoresGraphs: [],
  allStoresGraphs_loading: false,
  allStoresGraphs_error: null,
  allStoresGraphsPayload: {},

  allStoresAvgTimeGraphs: [],
  allStoresAvgTimeGraphs_loading: false,
  allStoresAvgTimeGraphs_error: null,
  allStoresAvgTimeGraphsPayload: {},

  storeGraphs: {},
  storeGraphs_loading: false,
  storeGraphs_error: null,
  storeGraphsPayload: {},

  storeWeekGraphs: {},
  storeWeekGraphsLoading: false,
  storeWeekGraphsError: null,

  storeTimeSpentGraph: {
    data: [],
    categories: [],
  },
  storeTimeSpentGraphLoading: false,
  storeTimeSpentGraphError: null,

  allDepartmentGraphs: [],
  allDepartmentGraphs_loading: false,
  allDepartmentGraphs_error: null,

  allDepartmentsInStoreGraphs: [],
  allDepartmentsInStoreGraphs_loading: false,
  allDepartmentsInStoreGraphs_error: null,

  allCameraGraphs: [],
  allCameraGraphsLoading: false,
  allCameraGraphsError: null,

  allCamerasInStoreGraphs: [],
  allCamerasInStoreGraphsLoading: false,
  allCamerasInStoreGraphsError: null,

  weekCamerasInStoreGraphs: [],
  weekCamerasInStoreGraphsLoading: false,
  weekCamerasInStoreGraphsError: null,

  allStoresFootfallDoDGraphs: [],
  allStoresFootfallDoDGraphsLoading: false,
  allStoresFootfallDoDGraphsError: null,
  allStoresFootfallDoDGraphsPayload: {},

  allStoresAvgTimeDoDGraphs: [],
  allStoresAvgTimeDoDGraphsLoading: false,
  allStoresAvgTimeDoDGraphsError: null,
  allStoresAvgTimeDoDGraphsPayload: {},

  storeFootfallDoDGraphs: [],
  storeFootfallDoDGraphsLoading: false,
  storeFootfallDoDGraphsError: null,

  storeAvgTimeDoDGraphs: [],
  storeAvgTimeDoDGraphsLoading: false,
  storeAvgTimeDoDGraphsError: null,

  footfallDayOverDayGraphs: [],

  graphPerformance: {
    xaxis: {
      visible: true,
    },
    yaxis: {
      visible: false,
    },
  },
  graphCameraPerformance: {
    xaxis: {
      visible: true,
    },
    yaxis: {
      visible: false,
    },
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.TOGGLE_LINE_CAMERA_GRAPH_PERFORMANCE: {
      return {
        ...state,
        graphCameraPerformance: {
          ...state.graphCameraPerformance,
          [action.payload.axis]: {
            visible: action.payload.checked,
          },
        },
      };
    }
    case Action.TOGGLE_LINE_GRAPH_PERFORMANCE: {
      return {
        ...state,
        graphPerformance: {
          ...state.graphPerformance,
          [action.payload.axis]: {
            visible: !state.graphPerformance[action.payload.axis].visible,
          },
        },
      };
    }
    case Action.UPDATE_INTERVAL: {
      return {
        ...state,
        interval: action.payload,
      };
    }
    case Action.GET_ALL_STORES_GRAPHS: {
      return {
        ...state,
        allStoresGraphs_loading: true,
      };
    }
    case Action.GET_ALL_STORES_GRAPHS_SUCCESS: {
      return {
        ...state,
        allStoresGraphs: action.payload,
        allStoresGraphs_loading: false,
      };
    }
    case Action.GET_ALL_STORES_GRAPHS_FAILURE: {
      return {
        ...state,
        allStoresGraphs_loading: false,
        allStoresGraphs_error: action.error,
      };
    }
    case Action.GET_ALL_STORES_GRAPHS_PAYLOAD: {
      console.log('GET_ALL_STORES_GRAPHS_PAYLOAD');
      return {
        ...state,
        allStoresGraphsPayload: action.payload,
      };
    }

    case Action.GET_ALL_STORES_AVG_TIME_GRAPHS: {
      return {
        ...state,
        allStoresAvgTimeGraphs_loading: true,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_GRAPHS_SUCCESS: {
      return {
        ...state,
        allStoresAvgTimeGraphs: action.payload,
        allStoresAvgTimeGraphs_loading: false,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_GRAPHS_FAILURE: {
      return {
        ...state,
        allStoresAvgTimeGraphs_loading: false,
        allStoresAvgTimeGraphs_error: action.error,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_GRAPHS_PAYLOAD: {
      return {
        ...state,
        allStoresAvgTimeGraphsPayload: action.payload,
      };
    }

    case Action.GET_STORE_GRAPHS: {
      return {
        ...state,
        storeGraphs_loading: true,
      };
    }
    case Action.GET_STORE_GRAPHS_SUCCESS: {
      return {
        ...state,
        storeGraphs: { ...action.payload },
        storeGraphs_loading: false,
      };
    }
    case Action.GET_STORE_GRAPHS_FAILURE: {
      return {
        ...state,
        storeGraphs_loading: false,
        storeGraphs_error: action.error,
      };
    }
    case Action.GET_STORE_GRAPHS_PAYLOAD: {
      return {
        ...state,
        storeGraphsPayload: action.payload,
      };
    }

    case Action.GET_WEEK_STORE_GRAPHS: {
      return {
        ...state,
        storeWeekGraphsLoading: true,
      };
    }
    case Action.GET_WEEK_STORE_GRAPHS_SUCCESS: {
      return {
        ...state,
        storeWeekGraphs: { ...action.payload },
        storeWeekGraphsLoading: false,
      };
    }
    case Action.GET_WEEK_STORE_GRAPHS_FAILURE: {
      return {
        ...state,
        storeWeekGraphsLoading: false,
        storeWeekGraphsError: action.error,
      };
    }

    case Action.GET_STORE_TIME_SPENT_GRAPH: {
      return {
        ...state,
        storeTimeSpentGraphLoading: true,
      };
    }
    case Action.GET_STORE_TIME_SPENT_GRAPH_SUCCESS: {
      return {
        ...state,
        storeTimeSpentGraph: action.payload,
        storeTimeSpentGraphLoading: false,
      };
    }
    case Action.GET_STORE_TIME_SPENT_GRAPH_FAILURE: {
      return {
        ...state,
        storeTimeSpentGraphLoading: false,
        storeTimeSpentGraphError: action.error,
      };
    }

    case Action.GET_ALL_DEPARTMENT_GRAPHS: {
      return {
        ...state,
        allDepartmentGraphs_loading: true,
      };
    }
    case Action.GET_ALL_DEPARTMENT_GRAPHS_SUCCESS: {
      return {
        ...state,
        allDepartmentGraphs: action.payload,
        allDepartmentGraphs_loading: false,
      };
    }
    case Action.GET_ALL_DEPARTMENT_GRAPHS_FAILURE: {
      return {
        ...state,
        allDepartmentGraphs_loading: false,
        allDepartmentGraphs_error: action.error,
      };
    }

    case Action.GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS: {
      return {
        ...state,
        allDepartmentsInStoreGraphs_loading: true,
      };
    }
    case Action.GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_SUCCESS: {
      return {
        ...state,
        allDepartmentsInStoreGraphs: action.payload,
        allDepartmentsInStoreGraphs_loading: false,
      };
    }
    case Action.GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_FAILURE: {
      return {
        ...state,
        allDepartmentsInStoreGraphs_loading: false,
        allDepartmentsInStoreGraphs_error: action.error,
      };
    }

    case Action.GET_ALL_CAMERA_GRAPHS: {
      return {
        ...state,
        allCameraGraphsLoading: true,
      };
    }
    case Action.GET_ALL_CAMERA_GRAPHS_SUCCESS: {
      return {
        ...state,
        allCameraGraphs: action.payload,
        allCameraGraphsLoading: false,
      };
    }
    case Action.GET_ALL_CAMERA_GRAPHS_FAILURE: {
      return {
        ...state,
        allCameraGraphsLoading: false,
        allCameraGraphsError: action.error,
      };
    }

    case Action.GET_WEEK_CAMERA_GRAPHS: {
      return {
        ...state,
        weekCameraGraphsLoading: true,
      };
    }
    case Action.GET_WEEK_CAMERA_GRAPHS_SUCCESS: {
      return {
        ...state,
        weekCameraGraphs: action.payload,
        weekCameraGraphsLoading: false,
      };
    }
    case Action.GET_WEEK_CAMERA_GRAPHS_FAILURE: {
      return {
        ...state,
        weekCameraGraphsLoading: false,
        weekCameraGraphsError: action.error,
      };
    }

    case Action.GET_ALL_CAMERAS_IN_STORE_GRAPHS: {
      return {
        ...state,
        allCamerasInStoreGraphsLoading: true,
      };
    }
    case Action.GET_ALL_CAMERAS_IN_STORE_GRAPHS_SUCCESS: {
      return {
        ...state,
        allCamerasInStoreGraphs: action.payload,
        allCamerasInStoreGraphsLoading: false,
      };
    }
    case Action.GET_ALL_CAMERAS_IN_STORE_GRAPHS_FAILURE: {
      return {
        ...state,
        allCamerasInStoreGraphsLoading: false,
        allCamerasInStoreGraphsError: action.error,
      };
    }

    case Action.GET_ALL_STORES_FOOTFALL_DOD_GRAPHS: {
      return {
        ...state,
        allStoresFootfallDoDGraphsLoading: true,
      };
    }
    case Action.GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_SUCCESS: {
      return {
        ...state,
        allStoresFootfallDoDGraphs: action.payload,
        allStoresFootfallDoDGraphsLoading: false,
      };
    }
    case Action.GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_FAILURE: {
      return {
        ...state,
        allStoresFootfallDoDGraphsLoading: false,
        allStoresFootfallDoDGraphsError: action.error,
      };
    }
    case Action.GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_PAYLOAD: {
      return {
        ...state,
        allStoresFootfallDoDGraphsPayload: action.payload,
      };
    }

    case Action.GET_ALL_STORES_AVG_TIME_DOD_GRAPHS: {
      return {
        ...state,
        allStoresAvgTimeDoDGraphsLoading: true,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_SUCCESS: {
      return {
        ...state,
        allStoresAvgTimeDoDGraphs: action.payload,
        allStoresAvgTimeDoDGraphsLoading: false,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_FAILURE: {
      return {
        ...state,
        allStoresAvgTimeDoDGraphsLoading: false,
        allStoresAvgTimeDoDGraphsError: action.error,
      };
    }
    case Action.GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_PAYLOAD: {
      // console.log('salvou payload', action.payload);
      return {
        ...state,
        allStoresAvgTimeDoDGraphsPayload: action.payload,
      };
    }

    case Action.GET_STORE_FOOTFALL_DOD_GRAPHS: {
      return {
        ...state,
        storeFootfallDoDGraphsLoading: true,
      };
    }
    case Action.GET_STORE_FOOTFALL_DOD_GRAPHS_SUCCESS: {
      return {
        ...state,
        storeFootfallDoDGraphs: action.payload,
        storeFootfallDoDGraphsLoading: false,
      };
    }
    case Action.GET_STORE_FOOTFALL_DOD_GRAPHS_FAILURE: {
      return {
        ...state,
        storeFootfallDoDGraphsLoading: false,
        storeFootfallDoDGraphsError: action.error,
      };
    }

    case Action.GET_STORE_AVG_TIME_DOD_GRAPHS: {
      return {
        ...state,
        storeAvgTimeDoDGraphsLoading: true,
      };
    }
    case Action.GET_STORE_AVG_TIME_DOD_GRAPHS_SUCCESS: {
      return {
        ...state,
        storeAvgTimeDoDGraphs: action.payload,
        storeAvgTimeDoDGraphsLoading: false,
      };
    }
    case Action.GET_STORE_AVG_TIME_DOD_GRAPHS_FAILURE: {
      return {
        ...state,
        storeAvgTimeDoDGraphsLoading: false,
        storeAvgTimeDoDGraphsError: action.error,
      };
    }

    case Action.GET_DAY_OVER_DAY_GRAPHS_SUCCESS: {
      return {
        ...state,
        footfallDayOverDayGraphs: action.payload,
      };
    }

    default:
      return state;
  }
};
