/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import * as Action from './actions';

const initialState = {
  stores: [],
  loading: false,
  error: null,
  storesPayload: {},

  storesInfo: [],
  loadingStoresInfo: false,
  errorStoresInfo: null,

  store: {},
  store_loading: false,
  store_error: null,

  storeToday: {},
  storeTodayLoading: false,
  storeTodayError: null,

  storeBrand: [],
  storeBrand_loading: false,
  storeBrandForm_loading: false,
  storeBrand_error: null,
  storeBrandForm_error: null,

  topViewPlots: [],
  topViewPlotsLoading: false,
  topViewPlotsError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.POST_STORE: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.POST_STORE_SUCCESS: {
      const newStores = [
        ...state.storeBrand,
        {
          id: action.payload.id,
          brand: action.payload.brand.id,
          name: action.payload.name,
          address_1: action.payload.address_1,
          address_2: action.payload.address_2,
          city: action.payload.city,
          postcode: action.payload.postcode,
          phone_number: action.payload.phone_number,
          cameras: action.payload.cameras,
          es_store_id: action.payload.es_store_id,
          coordinates: action.payload.coordinates,
        },
      ];
      return {
        ...state,
        storeBrand: newStores,
        loading: false,
      };
    }
    case Action.POST_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.GET_ALL_STORES: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_ALL_STORES_SUCCESS: {
      return {
        ...state,
        stores: action.payload,
        loading: false,
      };
    }
    case Action.GET_ALL_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case Action.GET_ALL_STORES_PAYLOAD: {
      // console.log('GET_ALL_STORES_PAYLOAD');
      return {
        ...state,
        storesPayload: action.payload,
      };
    }

    case Action.GET_ALL_STORES_INFO: {
      return {
        ...state,
        loadingStoresInfo: true,
      };
    }
    case Action.GET_ALL_STORES_INFO_SUCCESS: {
      return {
        ...state,
        storesInfo: action.payload,
        loadingStoresInfo: false,
      };
    }
    case Action.GET_ALL_STORES_INFO_FAILURE: {
      return {
        ...state,
        loadingStoresInfo: false,
        errorStoresInfo: action.error,
      };
    }

    case Action.GET_STORE: {
      return {
        ...state,
        store: {},
        store_loading: true,
      };
    }
    case Action.GET_STORE_SUCCESS: {
      return {
        ...state,
        store: action.payload,
        store_loading: false,
      };
    }
    case Action.GET_STORE_FAILURE: {
      return {
        ...state,
        store_loading: false,
        store_error: action.error,
      };
    }

    case Action.GET_STORE_TODAY: {
      return {
        ...state,
        storeTodayLoading: true,
      };
    }
    case Action.GET_STORE_TODAY_SUCCESS: {
      return {
        ...state,
        storeToday: action.payload,
        storeTodayLoading: false,
      };
    }
    case Action.GET_STORE_TODAY_FAILURE: {
      return {
        ...state,
        storeTodayLoading: false,
        storeTodayError: action.error,
      };
    }

    case Action.GET_STORE_BRAND: {
      return {
        ...state,
        storeBrand_loading: true,
      };
    }
    case Action.GET_STORE_BRAND_SUCCESS: {
      return {
        ...state,
        storeBrand: action.payload.stores,
        storeBrand_loading: false,
      };
    }
    case Action.GET_STORE_BRAND_FAILURE: {
      return {
        ...state,
        storeBrand_loading: false,
        storeBrand_error: action.error,
      };
    }
    case Action.UPDATE_STORE_BRAND: {
      return {
        ...state,
        storeBrandForm_loading: true,
      };
    }
    case Action.UPDATE_STORE_BRAND_SUCCESS: {
      const { id } = action.payload;
      const newStores = state.storeBrand.map((store: any) =>
        store.id === id
          ? {
              ...store,
              brand_id: action.payload.brand.id,
              name: action.payload.name,
              address_1: action.payload.address_1,
              address_2: action.payload.address_2,
              city: action.payload.city,
              postcode: action.payload.postcode,
              phone_number: action.payload.phone_number,
              storeId: action.payload.storeId,
            }
          : store,
      );

      return {
        ...state,
        storeBrand: newStores,
        storeBrandForm_loading: false,
      };
    }
    case Action.UPDATE_STORE_BRAND_FAILURE: {
      return {
        ...state,
        storeBrandForm_loading: false,
        storeBrandForm_error: action.error,
      };
    }

    case Action.GET_STORE_TOP_VIEW_PLOTS: {
      return {
        ...state,
        topViewPlotsLoading: true,
      };
    }
    case Action.GET_STORE_TOP_VIEW_PLOTS_SUCCESS: {
      return {
        ...state,
        topViewPlots: action.payload,
        topViewPlotsLoading: false,
      };
    }
    case Action.GET_STORE_TOP_VIEW_PLOTS_FAILURE: {
      return {
        ...state,
        topViewPlotsLoading: false,
        topViewPlotsError: action.error,
      };
    }

    default:
      return state;
  }
};
