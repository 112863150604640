import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError, notifyInfo } from 'src/utils/notification';
import { getParametersAndPersistObject } from 'src/utils/persist';
import * as ActionTypes from './actions';
import Api from './api';

function* getCameras({ payload }: any) {
  try {
    const { data } = yield call(Api.getCameras, payload.departmentId);
    yield put(ActionTypes.getCameras.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameras.failure(e));
  }
}

function* getCamerasSaga() {
  yield takeLatest(ActionTypes.GET_CAMERAS, getCameras);
}

function* updateCameras({ payload }: any) {
  try {
    const { data } = yield call(Api.updateCameras, payload);
    yield put(ActionTypes.updateCameras.success(data));
    notifyInfo('Cameras updated!');
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.updateCameras.failure(e));
  }
}

function* updateCamerasSaga() {
  yield takeLatest(ActionTypes.UPDATE_CAMERAS, updateCameras);
}

function* getCameraImage({ payload }: any) {
  try {
    const { data } = yield call(Api.getCameraImage, payload.cameraId);
    const imageUrl = URL.createObjectURL(data);
    yield put(ActionTypes.getCameraImage.success(imageUrl));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameraImage.failure(e));
  }
}

function* getCameraImageSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_IMAGE, getCameraImage);
}

function* getCameraImageSnapshot({ payload }: any) {
  try {
    const { data } = yield call(Api.getCameraImageSnapshot, payload.cameraId);
    const imageUrl = URL.createObjectURL(data);
    yield put(ActionTypes.getCameraImageSnapshot.success(imageUrl));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameraImageSnapshot.failure(e));
  }
}

function* getCameraImageSnapshotSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_IMAGE_SNAPSHOT, getCameraImageSnapshot);
}

function* getCameraStats({ payload }: any) {
  try {
    // const { startDate, endDate, persistObject } = getParametersAndPersistObject();
    // const { cameraStats } = persistObject.cameras;

    // if (cameraStats &&
    //   payload.startDate === startDate &&
    //   payload.endDate === endDate &&
    //   Number(payload.cameraId) === cameraStats.id
    // ) {
    //   yield put(ActionTypes.getCameraStats.success(cameraStats));
    //   return;
    // }

    const { data } = yield call(
      Api.getCameraStats,
      payload.startDate,
      payload.endDate,
      payload.cameraId,
      payload.ageFilter,
      payload.genderFilter,
    );
    yield put(ActionTypes.getCameraStats.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameraStats.failure(e));
  }
}

function* getCameraStatsSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_STATS, getCameraStats);
}

function* getCameraStatsToday({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCameraStats,
      Math.trunc(new Date().setHours(0, 0, 0) / 1000),
      Math.trunc(new Date().setHours(23, 59, 59) / 1000),
      payload.cameraId,
      null,
      null,
    );
    yield put(ActionTypes.getCameraStatsToday.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameraStatsToday.failure(e));
  }
}

function* getCameraStatsTodaySaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_STATS, getCameraStatsToday);
}

function* getCamerasWithInfo({ payload }: any) {
  try {
    // const { startDate, endDate, storeId, persistObject } = getParametersAndPersistObject();
    // const { camerasWithInfo } = persistObject.cameras;

    // if (camerasWithInfo &&
    //   payload.startDate === startDate &&
    //   payload.endDate === endDate &&
    //   Number(payload.storeId) === storeId
    // ) {
    //   yield put(ActionTypes.getCamerasWithInfo.success(camerasWithInfo));
    //   return;
    // }

    const { data } = yield call(Api.getCamerasWithInfo, payload.startDate, payload.endDate, payload.storeId);
    yield put(ActionTypes.getCamerasWithInfo.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCamerasWithInfo.failure(e));
  }
}

function* getCamerasWithInfoSaga() {
  yield takeLatest(ActionTypes.GET_CAMERAS_WITH_INFO, getCamerasWithInfo);
}

function* getCameraPaths({ payload }: any) {
  try {
    // const { startDate, endDate, cameraId, persistObject } = getParametersAndPersistObject();
    // const { cameraPaths } = persistObject.cameras;

    // if (cameraPaths &&
    //   payload.startDate === startDate &&
    //   payload.endDate === endDate &&
    //   Number(payload.cameraId) === cameraId
    // ) {
    //   yield put(ActionTypes.getCameraPaths.success(cameraPaths));
    //   return;
    // }

    const { data } = yield call(Api.getCameraPaths, payload.startDate, payload.endDate, payload.cameraId);
    yield put(ActionTypes.getCameraPaths.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCameraPaths.failure(e));
  }
}

function* getCameraPathsSaga() {
  yield takeLatest(ActionTypes.GET_CAMERA_PATHS, getCameraPaths);
}

function* getCamerasDistribution() {
  try {
    const { data } = yield call(Api.getCamerasDistribution);
    yield put(ActionTypes.getCamerasDistribution.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCamerasDistribution.failure(e));
  }
}

function* getCamerasDistributionSaga() {
  yield takeLatest(ActionTypes.GET_CAMERAS_DISTRIBUTION, getCamerasDistribution);
}

export default function* rootSaga() {
  yield all([
    fork(getCamerasSaga),
    fork(updateCamerasSaga),
    fork(getCameraImageSaga),
    fork(getCameraImageSnapshotSaga),
    fork(getCameraStatsSaga),
    fork(getCameraStatsTodaySaga),
    fork(getCameraPathsSaga),
    fork(getCamerasWithInfoSaga),
    fork(getCamerasDistributionSaga),
  ]);
}
