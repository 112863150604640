import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError } from 'src/utils/notification';
import { getParametersAndPersistObject } from 'src/utils/persist';
import { isArray, isEmpty } from 'lodash';
import { objectsAreEqual } from 'src/utils/objectsAreEqual';
import * as ActionTypes from './actions';
import Api from './api';

function* getStoreGraphs({ payload }: any) {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { storeGraphs, storeGraphsPayload } = persistObject.graphs;

    if (
      storeGraphs &&
      !isEmpty(storeGraphs) &&
      objectsAreEqual(storeGraphsPayload, payload)
    ) {
      yield put(ActionTypes.getStoreGraphs.success(storeGraphs));
      return;
    }

    const { data } = yield call(
      Api.getStoreGraphs,
      payload.shopId,
      payload.startDate,
      payload.endDate,
      payload.interval,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getStoreGraphs.success(data));
    yield put(ActionTypes.getStoreGraphs.savePayload(payload));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreGraphs.failure(e));
  }
}

function* getStoreGraphsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_GRAPHS, getStoreGraphs);
}

function* getWeekStoreGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreGraphs,
      payload.shopId,
      payload.startDate,
      payload.endDate,
      '1w',
      payload.genderFilter,
      payload.ageFilter,
    );

    yield put(ActionTypes.getWeekStoreGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getWeekStoreGraphs.failure(e));
  }
}

function* getWeekStoreGraphsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_GRAPHS, getWeekStoreGraphs);
}

function* getStoreTimeSpentGraph({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreTimeSpentGraph,
      payload.shopId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getStoreTimeSpentGraph.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreTimeSpentGraph.failure(e));
  }
}

function* getStoreTimeSpentGraphSaga() {
  yield takeLatest(ActionTypes.GET_STORE_TIME_SPENT_GRAPH, getStoreTimeSpentGraph);
}

function* getAllStoresGraphs({ payload }: any): any {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { allStoresGraphs, allStoresGraphsPayload } = persistObject.graphs;

    if (
      allStoresGraphs &&
      !isEmpty(allStoresGraphs) &&
      objectsAreEqual(allStoresGraphsPayload, payload)
    ) {
      yield put(ActionTypes.getAllStoresGraphs.success(allStoresGraphs));
      return;
    }
    const { data } = yield call(
      Api.getAllStoresGraphs,
      payload.graphType,
      payload.interval,
      payload.startDate,
      payload.endDate,
      payload.stores,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllStoresGraphs.success(data));
    yield put(ActionTypes.getAllStoresGraphs.savePayload(payload));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStoresGraphs.failure(e));
  }
}

function* getAllStoresGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES_GRAPHS, getAllStoresGraphs);
}

function* getAllStoresAvgTimeGraphs({ payload }: any) {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { allStoresAvgTimeGraphs, allStoresAvgTimeGraphsPayload } = persistObject.graphs;

    if (
      allStoresAvgTimeGraphs &&
      !isArray(allStoresAvgTimeGraphs) &&
      !isEmpty(allStoresAvgTimeGraphs) &&
      objectsAreEqual(allStoresAvgTimeGraphsPayload, payload)
    ) {
      yield put(ActionTypes.getAllStoresAvgTimeGraphs.success(allStoresAvgTimeGraphs));
      return;
    }

    const { data } = yield call(
      Api.getAllStoresAvgTimeGraphs,
      payload.interval,
      payload.startDate,
      payload.endDate,
      payload.stores,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllStoresAvgTimeGraphs.success(data));
    yield put(ActionTypes.getAllStoresAvgTimeGraphs.savePayload(payload));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStoresAvgTimeGraphs.failure(e));
  }
}

function* getAllStoresAvgTimeGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES_GRAPHS, getAllStoresAvgTimeGraphs);
}

function* getAllDepartmentGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAllDepartmentGraphs,
      payload.departmentId,
      payload.interval,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllDepartmentGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllDepartmentGraphs.failure(e));
  }
}

function* getAllDepartmentGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_DEPARTMENT_GRAPHS, getAllDepartmentGraphs);
}

function* getAllDepartmentsInStoreGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAllDepartmentsInStoreGraphs,
      payload.storeId,
      payload.graphType,
      payload.interval,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllDepartmentsInStoreGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllDepartmentGraphs.failure(e));
  }
}

function* getAllDepartmentsInStoreGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS, getAllDepartmentsInStoreGraphs);
}

function* getAllCameraGraphs({ payload }: any) {
  try {
    // TODO: cache this
    // const { persistObject, cameraId, storeId, startDate, endDate, interval, genderFilter, ageFilter } = getParametersAndPersistObject();
    // const { allCameraGraphs } = persistObject.graphs;
    // const payloadGenderFilter = payload.genderFilter || '';
    // const payloadAgeFilter = payload.genderFilter || '';

    // if (
    //   allCameraGraphs &&
    //   payload.startDate === startDate &&
    //   payload.endDate === endDate &&
    //   payload.interval === interval &&
    //   Number(payload.storeId) === storeId &&
    //   Number(payload.cameraId) === cameraId &&
    //   payloadGenderFilter === genderFilter &&
    //   payloadAgeFilter === ageFilter
    // ) {
    //   yield put(ActionTypes.getAllCameraGraphs.success(allCameraGraphs));
    //   return;
    // }

    // console.log(isEqual(_(allCameraGraphs.payload).omit(_.isUndefined).omit(_.isNull), payload));

    // if (
    //   isEqual(_(allCameraGraphs.payload).omit(_.isUndefined).omit(_.isNull), payload)
    // ) {
    //   yield put(ActionTypes.getAllCameraGraphs.success(allCameraGraphs));
    //   return;
    // }

    const { data } = yield call(
      Api.getAllCameraGraphs,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.interval,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllCameraGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllCameraGraphs.failure(e));
  }
}

function* getAllCameraGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_CAMERA_GRAPHS, getAllCameraGraphs);
}

function* getWeekCameraGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAllCameraGraphs,
      payload.storeId,
      payload.departmentId,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.interval,
    );
    yield put(ActionTypes.getWeekCameraGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getWeekCameraGraphs.failure(e));
  }
}

function* getWeekCameraGraphsSaga() {
  yield takeLatest(ActionTypes.GET_WEEK_CAMERA_GRAPHS, getWeekCameraGraphs);
}

function* getAllCamerasInStoreGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getAllCamerasInStoreGraphs,
      payload.storeId,
      payload.graphType,
      payload.interval,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllCamerasInStoreGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllCamerasInStoreGraphs.failure(e));
  }
}

function* getAllCamerasInStoreGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_CAMERAS_IN_STORE_GRAPHS, getAllCamerasInStoreGraphs);
}

function* getAllStoresFootfallDodGraphs({ payload }: any) {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { allStoresFootfallDoDGraphsPayload, allStoresFootfallDoDGraphs } = persistObject.graphs;

    if (
      allStoresFootfallDoDGraphs &&
      !isEmpty(allStoresFootfallDoDGraphs) &&
      objectsAreEqual(allStoresFootfallDoDGraphsPayload, payload)
    ) {
      yield put(ActionTypes.getAllStoresFootfallDodGraphs.success(allStoresFootfallDoDGraphs));
      return;
    }
    const { data } = yield call(
      Api.getAllStoresFootfallDoDGraphs,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllStoresFootfallDodGraphs.success(data));
    yield put(ActionTypes.getAllStoresFootfallDodGraphs.savePayload(payload)); // saves payload to verify on next api call
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStoresFootfallDodGraphs.failure(e));
  }
}

function* getAllStoresFootfallDodGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES_FOOTFALL_DOD_GRAPHS, getAllStoresFootfallDodGraphs);
}

function* getDayOverDayGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDayOverDayGraphs,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
      payload.type,
    );
    yield put(ActionTypes.getDayOverDayGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getDayOverDayGraphs.failure(e));
  }
}

function* getDayOverDayGraphsSaga() {
  yield takeLatest(ActionTypes.GET_DAY_OVER_DAY_GRAPHS, getDayOverDayGraphs);
}

function* getAllStoresAvgTimeDoDGraphs({ payload }: any) {
  try {
    const { persistObject } = getParametersAndPersistObject();
    const { allStoresAvgTimeDoDGraphs, allStoresAvgTimeDoDGraphsPayload } = persistObject.graphs;

    if (
      allStoresAvgTimeDoDGraphs &&
      !isEmpty(allStoresAvgTimeDoDGraphs) &&
      objectsAreEqual(allStoresAvgTimeDoDGraphsPayload, payload)
    ) {
      yield put(ActionTypes.getAllStoresAvgTimeDoDGraphs.success(allStoresAvgTimeDoDGraphs));
      return;
    }
    const { data } = yield call(
      Api.getAllStoresAvgTimeDoDGraphs,
      payload.startDate,
      payload.endDate,
      payload.genderFilter,
      payload.ageFilter,
    );
    yield put(ActionTypes.getAllStoresAvgTimeDoDGraphs.success(data));
    yield put(ActionTypes.getAllStoresAvgTimeDoDGraphs.savePayload(payload)); // saves payload to verify on next api call
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getAllStoresAvgTimeDoDGraphs.failure(e));
  }
}

function* getAllStoresAvgTimeDoDGraphsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_STORES_AVG_TIME_DOD_GRAPHS, getAllStoresAvgTimeDoDGraphs);
}

function* getStoreFootfallDodGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreFootfallDoDGraphs,
      payload.storeId,
      payload.startDate,
      payload.endDate,
    );
    yield put(ActionTypes.getStoreFootfallDodGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreFootfallDodGraphs.failure(e));
  }
}

function* getStoreFootfallDodGraphsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_FOOTFALL_DOD_GRAPHS, getStoreFootfallDodGraphs);
}

function* getStoreAvgTimeDoDGraphs({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getStoreAvgTimeDoDGraphs,
      payload.storeId,
      payload.startDate,
      payload.endDate,
    );
    yield put(ActionTypes.getStoreAvgTimeDoDGraphs.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getStoreAvgTimeDoDGraphs.failure(e));
  }
}

function* getStoreAvgTimeDoDGraphsSaga() {
  yield takeLatest(ActionTypes.GET_STORE_AVG_TIME_DOD_GRAPHS, getStoreAvgTimeDoDGraphs);
}

export default function* rootSaga() {
  yield all([
    fork(getStoreGraphsSaga),
    fork(getWeekStoreGraphsSaga),
    fork(getStoreTimeSpentGraphSaga),
    fork(getAllStoresGraphsSaga),
    fork(getAllStoresAvgTimeGraphsSaga),
    fork(getAllDepartmentGraphsSaga),
    fork(getAllDepartmentsInStoreGraphsSaga),
    fork(getAllCameraGraphsSaga),
    fork(getWeekCameraGraphsSaga),
    fork(getAllCamerasInStoreGraphsSaga),
    fork(getAllStoresFootfallDodGraphsSaga),
    fork(getDayOverDayGraphsSaga),
    fork(getAllStoresAvgTimeDoDGraphsSaga),
    fork(getStoreFootfallDodGraphsSaga),
    fork(getStoreAvgTimeDoDGraphsSaga),
  ]);
}
