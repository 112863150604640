import React from 'react';
import cx from 'classnames';
import { ICONS } from './constants';
import { IconProps } from './Icon.model';

import '../../styles/icon-styles.global.css';

const pathRender = (icon: IconProps['icon']) => {
  try {
    return ICONS[icon].path;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Icon ${icon} path not found, be sure icon exists in ICONS constant`);

    return null;
  }
};

export const ComponentIcon = React.forwardRef<HTMLAnchorElement, IconProps>(
  ({ tag, icon, href, className, color, size, ...anchorProps }: IconProps, ref) => {
    const path = React.useMemo(() => pathRender(icon), [icon]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component = (href ? 'a' : tag) as any;
    const props = { ref, href, ...anchorProps };

    return (
      <Component
        type={tag === 'button' ? 'button' : undefined}
        className={cx(
          'track-in-icon',
          icon,
          {
            [`text-${color}`]: !!color,
            'text-sm': size === 'sm',
            'text-xl': size === 'md',
            'text-3xl': size === 'lg',
          },
          className,
        )}
        {...props}
      >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          {path}
        </svg>
      </Component>
    );
  },
);

ComponentIcon.displayName = 'Icon';

ComponentIcon.defaultProps = {
  tag: 'i',
  color: 'neutral-400',
};

export default ComponentIcon;
