import { createApiAction } from 'src/utils/helpers';

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';

export const GET_DEPARTMENT_STATS = 'GET_DEPARTMENT_STATS';
export const GET_DEPARTMENT_STATS_SUCCESS = 'GET_DEPARTMENT_STATS_SUCCESS';
export const GET_DEPARTMENT_STATS_FAILURE = 'GET_DEPARTMENT_STATS_FAILURE';

export const POST_DEPARTMENT = 'POST_DEPARTMENT';
export const POST_DEPARTMENT_SUCCESS = 'POST_DEPARTMENT_SUCCESS';
export const POST_DEPARTMENT_FAILURE = 'POST_DEPARTMENT_FAILURE';

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE';

export const GET_DEPARTMENT_FLOORPLAN = 'GET_DEPARTMENT_FLOORPLAN';
export const GET_DEPARTMENT_FLOORPLAN_SUCCESS =
  'GET_DEPARTMENT_FLOORPLAN_SUCCESS';
export const GET_DEPARTMENT_FLOORPLAN_FAILURE =
  'GET_DEPARTMENT_FLOORPLAN_FAILURE';

export const UPDATE_DEPARTMENT_FLOORPLAN = 'UPDATE_DEPARTMENT_FLOORPLAN';
export const UPDATE_DEPARTMENT_FLOORPLAN_SUCCESS =
  'UPDATE_DEPARTMENT_FLOORPLAN_SUCCESS';
export const UPDATE_DEPARTMENT_FLOORPLAN_FAILURE =
  'UPDATE_DEPARTMENT_FLOORPLAN_FAILURE';

export const getDepartments = createApiAction(GET_DEPARTMENTS);
export const getDepartmentStats = createApiAction(GET_DEPARTMENT_STATS);
export const postDepartment = createApiAction(POST_DEPARTMENT);
export const updateDepartment = createApiAction(UPDATE_DEPARTMENT);
export const getDepartmentFloorplan = createApiAction(GET_DEPARTMENT_FLOORPLAN);
export const updateDepartmentFloorplan = createApiAction(
  UPDATE_DEPARTMENT_FLOORPLAN,
);
