import * as Action from './actions';

const initialState = {
  collections: [],
  loading: false,
  error: null,
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case Action.GET_COLLECTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_COLLECTION_SUCCESS: {
      return {
        ...state,
        collections: action.payload,
        loading: false,
      };
    }
    case Action.GET_COLLECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.POST_COLLECTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.POST_COLLECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Action.POST_COLLECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};
