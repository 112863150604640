import { createApiAction } from 'src/utils/helpers';

export const UPDATE_INTERVAL = 'UPDATE_INTERVAL';

export const GET_ALL_STORES_GRAPHS = 'GET_ALL_STORES_GRAPHS';
export const GET_ALL_STORES_GRAPHS_SUCCESS = 'GET_ALL_STORES_GRAPHS_SUCCESS';
export const GET_ALL_STORES_GRAPHS_FAILURE = 'GET_ALL_STORES_GRAPHS_FAILURE';
export const GET_ALL_STORES_GRAPHS_PAYLOAD = 'GET_ALL_STORES_GRAPHS_PAYLOAD';

export const GET_ALL_STORES_AVG_TIME_GRAPHS = 'GET_ALL_STORES_AVG_TIME_GRAPHS';
export const GET_ALL_STORES_AVG_TIME_GRAPHS_SUCCESS = 'GET_ALL_STORES_AVG_TIME_GRAPHS_SUCCESS';
export const GET_ALL_STORES_AVG_TIME_GRAPHS_FAILURE = 'GET_ALL_STORES_AVG_TIME_GRAPHS_FAILURE';
export const GET_ALL_STORES_AVG_TIME_GRAPHS_PAYLOAD = 'GET_ALL_STORES_AVG_TIME_GRAPHS_PAYLOAD';

export const GET_STORE_GRAPHS = 'GET_STORE_GRAPHS';
export const GET_STORE_GRAPHS_SUCCESS = 'GET_STORE_GRAPHS_SUCCESS';
export const GET_STORE_GRAPHS_FAILURE = 'GET_STORE_GRAPHS_FAILURE';
export const GET_STORE_GRAPHS_PAYLOAD = 'GET_STORE_GRAPHS_PAYLOAD';

export const GET_WEEK_STORE_GRAPHS = 'GET_WEEK_STORE_GRAPHS';
export const GET_WEEK_STORE_GRAPHS_SUCCESS = 'GET_WEEK_STORE_GRAPHS_SUCCESS';
export const GET_WEEK_STORE_GRAPHS_FAILURE = 'GET_WEEK_STORE_GRAPHS_FAILURE';

export const GET_STORE_TIME_SPENT_GRAPH = 'GET_STORE_TIME_SPENT_GRAPH';
export const GET_STORE_TIME_SPENT_GRAPH_SUCCESS = 'GET_STORE_TIME_SPENT_GRAPH_SUCCESS';
export const GET_STORE_TIME_SPENT_GRAPH_FAILURE = 'GET_STORE_TIME_SPENT_GRAPH_FAILURE';

export const GET_ALL_DEPARTMENT_GRAPHS = 'GET_ALL_DEPARTMENT_GRAPHS';
export const GET_ALL_DEPARTMENT_GRAPHS_SUCCESS = 'GET_ALL_DEPARTMENT_GRAPHS_SUCCESS';
export const GET_ALL_DEPARTMENT_GRAPHS_FAILURE = 'GET_ALL_DEPARTMENT_GRAPHS_FAILURE';

export const GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS = 'GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS';
export const GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_SUCCESS = 'GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_SUCCESS';
export const GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_FAILURE = 'GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS_FAILURE';

export const GET_ALL_CAMERA_GRAPHS = 'GET_ALL_CAMERA_GRAPHS';
export const GET_ALL_CAMERA_GRAPHS_SUCCESS = 'GET_ALL_CAMERA_GRAPHS_SUCCESS';
export const GET_ALL_CAMERA_GRAPHS_FAILURE = 'GET_ALL_CAMERA_GRAPHS_FAILURE';

export const GET_WEEK_CAMERA_GRAPHS = 'GET_WEEK_CAMERA_GRAPHS';
export const GET_WEEK_CAMERA_GRAPHS_SUCCESS = 'GET_WEEK_CAMERA_GRAPHS_SUCCESS';
export const GET_WEEK_CAMERA_GRAPHS_FAILURE = 'GET_WEEK_CAMERA_GRAPHS_FAILURE';

export const GET_ALL_CAMERAS_IN_STORE_GRAPHS = 'GET_ALL_CAMERAS_IN_STORE_GRAPHS';
export const GET_ALL_CAMERAS_IN_STORE_GRAPHS_SUCCESS = 'GET_ALL_CAMERAS_IN_STORE_GRAPHS_SUCCESS';
export const GET_ALL_CAMERAS_IN_STORE_GRAPHS_FAILURE = 'GET_ALL_CAMERAS_IN_STORE_GRAPHS_FAILURE';

export const GET_ALL_STORES_FOOTFALL_DOD_GRAPHS = 'GET_ALL_STORES_FOOTFALL_DOD_GRAPHS';
export const GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_SUCCESS = 'GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_SUCCESS';
export const GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_FAILURE = 'GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_FAILURE';
export const GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_PAYLOAD = 'GET_ALL_STORES_FOOTFALL_DOD_GRAPHS_PAYLOAD';

export const GET_ALL_STORES_AVG_TIME_DOD_GRAPHS = 'GET_ALL_STORES_AVG_TIME_DOD_GRAPHS';
export const GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_SUCCESS = 'GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_SUCCESS';
export const GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_FAILURE = 'GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_FAILURE';
export const GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_PAYLOAD = 'GET_ALL_STORES_AVG_TIME_DOD_GRAPHS_PAYLOAD';

export const GET_STORE_FOOTFALL_DOD_GRAPHS = 'GET_STORE_FOOTFALL_DOD_GRAPHS';
export const GET_STORE_FOOTFALL_DOD_GRAPHS_SUCCESS = 'GET_STORE_FOOTFALL_DOD_GRAPHS_SUCCESS';
export const GET_STORE_FOOTFALL_DOD_GRAPHS_FAILURE = 'GET_STORE_FOOTFALL_DOD_GRAPHS_FAILURE';

export const GET_STORE_AVG_TIME_DOD_GRAPHS = 'GET_STORE_AVG_TIME_DOD_GRAPHS';
export const GET_STORE_AVG_TIME_DOD_GRAPHS_SUCCESS = 'GET_STORE_AVG_TIME_DOD_GRAPHS_SUCCESS';
export const GET_STORE_AVG_TIME_DOD_GRAPHS_FAILURE = 'GET_STORE_AVG_TIME_DOD_GRAPHS_FAILURE';

export const GET_DAY_OVER_DAY_GRAPHS = 'GET_DAY_OVER_DAY_GRAPHS';
export const GET_DAY_OVER_DAY_GRAPHS_SUCCESS = 'GET_DAY_OVER_DAY_GRAPHS_SUCCESS';
export const GET_DAY_OVER_DAY_GRAPHS_FAILURE = 'GET_DAY_OVER_DAY_GRAPHS_FAILURE';

export const TOGGLE_LINE_GRAPH_PERFORMANCE = 'TOGGLE_LINE_GRAPH_PERFORMANCE';
export const TOGGLE_LINE_CAMERA_GRAPH_PERFORMANCE = 'TOGGLE_LINE_CAMERA_GRAPH_PERFORMANCE';

export const updateInterval = createApiAction(UPDATE_INTERVAL);
export const getAllStoresGraphs = createApiAction(GET_ALL_STORES_GRAPHS);
export const getAllStoresAvgTimeGraphs = createApiAction(GET_ALL_STORES_AVG_TIME_GRAPHS);
export const getStoreGraphs = createApiAction(GET_STORE_GRAPHS);
export const getWeekStoreGraphs = createApiAction(GET_WEEK_STORE_GRAPHS);
export const getStoreTimeSpentGraph = createApiAction(GET_STORE_TIME_SPENT_GRAPH);
export const getAllDepartmentGraphs = createApiAction(GET_ALL_DEPARTMENT_GRAPHS);
export const getAllDepartmentsInStoreGraphs = createApiAction(GET_ALL_DEPARTMENTS_IN_STORE_GRAPHS);
export const getAllCameraGraphs = createApiAction(GET_ALL_CAMERA_GRAPHS);
export const getAllCamerasInStoreGraphs = createApiAction(GET_ALL_CAMERAS_IN_STORE_GRAPHS);
export const getWeekCameraGraphs = createApiAction(GET_WEEK_CAMERA_GRAPHS);
export const getAllStoresFootfallDodGraphs = createApiAction(GET_ALL_STORES_FOOTFALL_DOD_GRAPHS);
export const getAllStoresAvgTimeDoDGraphs = createApiAction(GET_ALL_STORES_AVG_TIME_DOD_GRAPHS);
export const getStoreFootfallDodGraphs = createApiAction(GET_STORE_FOOTFALL_DOD_GRAPHS);
export const getStoreAvgTimeDoDGraphs = createApiAction(GET_STORE_AVG_TIME_DOD_GRAPHS);
export const getDayOverDayGraphs = createApiAction(GET_DAY_OVER_DAY_GRAPHS);

export const toggleLineGraph = (data: { axis: string; checked: boolean }) => {
  return {
    type: TOGGLE_LINE_GRAPH_PERFORMANCE,
    payload: data,
  };
};

export const toggleLineCameraGraph = (data: { axis: string; checked: boolean }) => {
  return {
    type: TOGGLE_LINE_CAMERA_GRAPH_PERFORMANCE,
    payload: data,
  };
};
