export const modeBarButtonsToRemove = [
  'autoScale2d',
  'autoscale',
  'editInChartStudio',
  'editinchartstudio',
  'hoverCompareCartesian',
  'hovercompare',
  'lasso',
  'lasso2d',
  'orbitRotation',
  'orbitrotation',
  'pan',
  'pan2d',
  'pan3d',
  'reset',
  'resetCameraDefault3d',
  'resetCameraLastSave3d',
  'resetGeo',
  'resetSankeyGroup',
  'resetScale2d',
  'resetViewMapbox',
  'resetViews',
  'resetcameradefault',
  'resetcameralastsave',
  'resetsankeygroup',
  'resetscale',
  'resetview',
  'resetviews',
  'select',
  'select2d',
  'sendDataToCloud',
  'senddatatocloud',
  'tableRotation',
  'tablerotation',
  'toggleHover',
  'toggleSpikelines',
  'togglehover',
  'togglespikelines',
  'zoom',
  'zoom2d',
  'zoom3d',
  'zoomIn2d',
  'zoomInGeo',
  'zoomInMapbox',
  'zoomOut2d',
  'zoomOutGeo',
  'zoomOutMapbox',
  'zoomin',
  'zoomout',
];
