import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

// https://redux-saga.js.org/docs/api/#createsagamiddlewareoptions
const sagaMiddleware = createSagaMiddleware();

// this is needed for https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 }) || compose;

// https://redux.js.org/api/createstore
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

const persistor = persistStore(store);

// https://redux-saga.js.org/docs/api/#middlewarerunsaga-args
sagaMiddleware.run(rootSaga);

export { store, persistor };
