/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import * as Action from './actions';

const initialState = {
  brands: [],
  loading: false,
  error: null,

  brandUsers: [],
  brandUsersLoading: false,
  brandUsersError: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_ALL_BRANDS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case Action.GET_ALL_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: action.payload,
        loading: false,
      };
    }
    case Action.GET_ALL_BRANDS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case Action.GET_BRAND_USERS: {
      return {
        ...state,
        brandUsersLoading: true,
        brandUsersError: null,
      };
    }
    case Action.GET_BRAND_USERS_SUCCESS: {
      return {
        ...state,
        brandUsers: action.payload,
        brandUsersLoading: false,
      };
    }
    case Action.GET_BRAND_USERS_FAILURE: {
      return {
        ...state,
        brandUsersLoading: false,
        brandUsersError: action.error,
      };
    }
    case Action.POST_BRAND_SUCCESS: {
      return {
        ...state,
        brands: [...state.brands, action.payload],
      };
    }

    case Action.UPDATE_BRAND_SUCCESS: {
      const { id } = action.payload;
      const newBrands = state.brands.map((brand: any) =>
        brand.id === id
          ? {
              ...brand,
              ...action.payload,
            }
          : brand,
      );

      return {
        ...state,
        brands: newBrands,
        brandUsersLoading: false,
      };
    }
    case Action.POST_BRAND_USERS_SUCCESS: {
      return {
        ...state,
        brandUsers: [...state.brandUsers, action.payload],
        brandUsersLoading: false,
      };
    }
    case Action.POST_BRAND_USERS_FAILURE: {
      return {
        ...state,
        brandUsersLoading: false,
        brandUsersError: action.error,
      };
    }

    case Action.UPDATE_BRAND_USERS_SUCCESS: {
      const { id } = action.payload;
      const newUsers = state.brandUsers.map((user: any) =>
        user.id === id
          ? {
              ...user,
              ...action.payload,
            }
          : user,
      );

      return {
        ...state,
        brandUsers: newUsers,
        brandUsersLoading: false,
      };
    }
    case Action.DELETE_BRAND_USERS_SUCCESS: {
      const { userId } = action.payload;
      const newUsers = state.brandUsers.filter((user: any) => user.id !== userId);

      return {
        ...state,
        brandUsers: newUsers,
        brandUsersLoading: false,
      };
    }

    default:
      return state;
  }
};
