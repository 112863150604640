import { axiosJsonInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async getAllStoresGraphs(
    graphType: any,
    interval: any,
    startDate: any,
    endDate: any,
    stores: any,
    genderFilter: any,
    ageFilter: any,
  ) {
    return axiosJsonInstance.post(`graphs/shops/${graphType}/`, {
      interval,
      start_date: startDate,
      end_date: endDate,
      stores,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
    });
  },

  async getAllStoresAvgTimeGraphs(
    interval: any,
    startDate: any,
    endDate: any,
    stores: any,
    genderFilter: any,
    ageFilter: any,
  ) {
    return axiosJsonInstance.post('graphs/shops/average_time/', {
      interval,
      start_date: startDate,
      end_date: endDate,
      stores,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
    });
  },

  async getStoreGraphs(shopId: any, startDate: any, endDate: any, interval: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.post(`graphs/shop/${shopId}/`, {
      start_date: startDate,
      end_date: endDate,
      interval,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      graph_types: ['total_footfall', 'average_time', 'average_departments_visited', 'average_department_time'],
    });
  },

  async getStoreTimeSpentGraph(shopId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.post(`graphs/shop/time_spent/${shopId}/`, {
      start_date: startDate,
      end_date: endDate,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
    });
  },

  async getAllDepartmentGraphs(
    departmentId: any,
    interval: any,
    startDate: any,
    endDate: any,
    genderFilter: any,
    ageFilter: any,
  ) {
    return axiosJsonInstance.post(`graphs/department/${departmentId}/`, {
      interval,
      start_date: startDate, // 1599318759
      end_date: endDate, // 1602083559
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      graph_types: ['total_footfall', 'average_time'],
    });
  },

  async getAllDepartmentsInStoreGraphs(
    storeId: any,
    graphType: any,
    interval: any,
    startDate: any,
    endDate: any,
    genderFilter: any,
    ageFilter: any,
  ) {
    return axiosJsonInstance.post(`graphs/shop/${storeId}/departments/${graphType}/`, {
      interval,
      start_date: startDate,
      end_date: endDate,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
    });
  },

  async getAllCameraGraphs(
    storeId: any,
    departmentId: any,
    cameraId: any,
    startDate: any,
    endDate: any,
    interval: any,
    genderFilter?: any,
    ageFilter?: any,
  ) {
    return axiosJsonInstance.post(`graphs/camera/${cameraId}/`, {
      store_id: storeId,
      department_id: departmentId,
      camera_id: cameraId,
      start_date: startDate,
      end_date: endDate,
      interval,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      graph_types: ['total_footfall', 'average_time'],
    });
  },

  async getAllCamerasInStoreGraphs(
    storeId: any,
    graphType: any,
    interval: any,
    startDate: any,
    endDate: any,
    genderFilter: any,
    ageFilter: any,
  ) {
    return axiosJsonInstance.post(`graphs/shop/${storeId}/cameras/${graphType}/`, {
      interval,
      start_date: startDate,
      end_date: endDate,
      gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
      age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
    });
  },

  async getAllStoresFootfallDoDGraphs(startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('e_search/footfall/day_over_day/', {
      params: {
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getAllStoresAvgTimeDoDGraphs(startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('/graphs/shop/day_over_day_time_spent/', {
      params: {
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getStoreFootfallDoDGraphs(storeId: any, startDate: any, endDate: any) {
    return axiosJsonInstance.get(`e_search/footfall/day_over_day/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  async getStoreAvgTimeDoDGraphs(storeId: any, startDate: any, endDate: any) {
    return axiosJsonInstance.get(`/graphs/shop/day_over_day_time_spent/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  },

  async getDayOverDayGraphs(
    storeId: any,
    startDate: any,
    endDate: any,
    genderFilter: any,
    ageFilter: any,
    type = 'footfall',
  ) {
    return axiosJsonInstance.get(`e_search/${type}/day_over_day/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },
};
