import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'src/components/Typography';
import * as ActionTypes from 'src/store/actions';
import { getTimestampOf } from 'src/utils/helpers';
import { orderBy } from 'lodash';
import { RangeDatePicker } from 'src/components/datepicker';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Checkbox } from 'src/components/Checkbox';

interface Props {
  storeId: number;
  departmentId: number;
  cameraId: number;
  onClose: () => void;
}

const ArchivedAreasList: React.FC<Props> = ({ storeId, departmentId, cameraId, onClose }) => {
  const dispatch = useDispatch();
  const { archivedAreas, archivedAreasError, archivedAreasLoading } = useSelector((state: any) => state.areas);
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);

  const fetchData = useCallback(() => {
    dispatch(
      ActionTypes.getArchivedAreas.request({
        storeId,
        departmentId,
        cameraId,
        startDate: Math.trunc(getTimestampOf(new Date(startDate))),
        endDate: Math.trunc(getTimestampOf(new Date(endDate))),
      }),
    );
  }, [dispatch, storeId, departmentId, cameraId, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [storeId, cameraId, departmentId, startDate, endDate, dispatch, fetchData]);

  const onClick = (e: any) => {
    if (e.target.checked) {
      setSelectedAreas([...selectedAreas, Number(e.target.value)]);
    } else {
      setSelectedAreas(selectedAreas.filter((area: any) => area !== Number(e.target.value)));
    }
  };

  const unarchiveAreas = () => {
    const areasToUnarchive = archivedAreas.filter((archivedArea: any) => selectedAreas.includes(Number(archivedArea.id)));

    dispatch(ActionTypes.getUnArchivedAreas.request({ areas: areasToUnarchive }));
    onClose();
  };

  if (archivedAreasError && !archivedAreasLoading) {
    return (
      <div className="flex flex-col place-items-center items-center w-full text-center h-[300px]">
        {`Error: ${archivedAreasError}`}
        <br />
        <Button
          onClick={fetchData}
          variant="dropdown-button"
          size="medium"
          className="bg-primary-light text-neutral-800"
        >
          <Typography variant="large-button">Try again</Typography>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-around">
        <RangeDatePicker needTime initialStartDate={new Date(startDate)} initialEndDate={new Date(endDate)} />
      </div>

      <Card className="px-2 py-4 mt-5 h-[350px] overflow-y-auto">
        {archivedAreasLoading && <div className="text-center h-[300px]">Loading...</div>}

        {!archivedAreasLoading && (!archivedAreas || archivedAreas.length === 0) && (
          <div className="text-center h-[300px]">No areas archived</div>
        )}

        {!archivedAreasLoading && archivedAreas && archivedAreas.length > 0 && (
          <div className="flex pt-3 flex-col gap-5 items-stretch w-full">
            <table className="text-left w-full">
              <thead>
                <tr className="border-b-2 border-b-neutral-100">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th> </th>
                  <th>Area name</th>
                  <th>Created at</th>
                  <th>Archived by</th>
                  <th>Archived at</th>
                </tr>
              </thead>
              <tbody>
                {archivedAreas &&
                  archivedAreas.length &&
                  orderBy(archivedAreas, 'deleted_at', 'desc').map((area: any) => (
                    <tr
                      key={area.id}
                      className={cx('w-full transition-colors duration-100', {
                        'hover:bg-primary-super-light': true,
                        'bg-primary-super-light': selectedAreas.includes(area.id),
                      })}
                    >
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <td>
                        <Checkbox value={area.id} onChange={onClick} />
                      </td>
                      <td>
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('py-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          {area.name}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('py-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          {moment(area.created_at).format('MM/DD/YYYY HH:mm') ?? '-'}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('py-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          {area.archived_by}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="caption-2"
                          tag="td"
                          className={cx('py-2', {
                            'text-neutral-800': true,
                          })}
                        >
                          {moment(area.archived_at ?? area.deleted_at).format('MM/DD/YYYY HH:mm') ?? '-'}
                        </Typography>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </Card>

      <div className="flex flex-row justify-end my-3">
        <Button
          color="primary"
          size="medium"
          className="disabled:cursor-not-allowed hover:cursor-pointer"
          disabled={selectedAreas.length === 0}
          type="button"
          variant="solid"
          onClick={unarchiveAreas}
        >
          <Typography variant="caption-2" color="neutral">
            Bring selected areas back
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default ArchivedAreasList;
