import { axiosJsonInstance } from 'src/store/api';

export default {
  async getCollections() {
    return axiosJsonInstance.get('collections/');
  },

  async postCollection(data: any) {
    return axiosJsonInstance.post('collections/', data);
  },
};
