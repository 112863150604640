import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

export type RowProps = {
  className?: string;
};

export const Row: React.FC<RowProps> = ({ children, className, ...props }) => {
  return (
    <div className={cx('', styles.row, className)} {...props}>
      {children}
    </div>
  );
};
