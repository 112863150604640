import React from 'react';

import cx from 'classnames';

export type PaginationProps = {
  count: number;
  defaultPage?: number;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onChangePage?: (page: number) => void;
};

type PageButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const PageButton = React.memo(({ className, children, ...props }: PageButtonProps) => {
  return (
    <button
      className={cx(
        'h-6 px-2',
        'border border-neutral-100 rounded-md',
        'text-sm',
        'transition-colors duration-300',
        {
          'border-neutral-50 bg-neutral-50 text-neutral-100': props.disabled,
          'hover:border-primary': !props.disabled,
        },
        className,
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
});

export const Pagination = ({ count, defaultPage = 1, className, onChangePage }: PaginationProps) => {
  const [currentPage, setCurrentPage] = React.useState(defaultPage);

  const handleChangePage = React.useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      onChangePage?.(pageNumber);
    },
    [setCurrentPage, onChangePage],
  );

  React.useEffect(() => {
    if (defaultPage) {
      setCurrentPage(defaultPage);
    }
  }, [setCurrentPage, defaultPage]);

  const { isFirstPage, isLastPage } = React.useMemo(() => {
    return {
      isFirstPage: currentPage === 1,
      isLastPage: currentPage === count,
    };
  }, [currentPage, count]);

  const items = React.useMemo(() => {
    return Array.from({ length: count }).map((_, idx) => idx + 1);
  }, [count]);

  return (
    <div className={cx('flex gap-1 flex-wrap', className)}>
      <PageButton onClick={() => handleChangePage(currentPage - 1)} disabled={isFirstPage}>
        {'<'}
      </PageButton>

      {items.map((page) => {
        const isCurrentPage = currentPage === page;

        return (
          <PageButton
            key={Math.random() * 1000}
            className={cx({
              'border-primary text-primary-dark bg-primary-super-light': isCurrentPage,
              'text-neutral-600': !isCurrentPage,
            })}
            onClick={() => handleChangePage(page)}
          >
            {page}
          </PageButton>
        );
      })}

      <PageButton onClick={() => handleChangePage(currentPage + 1)} disabled={isLastPage}>
        {'>'}
      </PageButton>
    </div>
  );
};
