import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { notifyError } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

const asyncGetCollections = async (payload: any) => {
  return Api.getCollections();
};

function* getCollections({ payload }: any) {
  try {
    const { data } = yield call(asyncGetCollections, payload);
    yield put(ActionTypes.getCollections.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCollections.failure(e));
  }
}

function* getCollectionSaga() {
  yield takeLatest(ActionTypes.GET_COLLECTION, getCollections);
}

function* postCollection({ payload }: any) {
  try {
    const { data } = yield call(Api.postCollection, payload);
    yield put(ActionTypes.postCollection.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.postCollection.failure(e));
  }
}

function* postCollectionSaga() {
  yield takeLatest(ActionTypes.POST_COLLECTION, postCollection);
}

export default function* rootSaga() {
  yield all([fork(getCollectionSaga), fork(postCollectionSaga)]);
}
