import { createApiAction } from 'src/utils/helpers';

export const CREATE_USER_EMAIL_PREFERENCES = 'CREATE_USER_EMAIL_PREFERENCES';
export const CREATE_USER_EMAIL_PREFERENCES_SUCCESS = 'CREATE_USER_EMAIL_PREFERENCES_SUCCESS';
export const CREATE_USER_EMAIL_PREFERENCES_FAILURE = 'CREATE_USER_EMAIL_PREFERENCES_FAILURE';

export const UPDATE_USER_EMAIL_PREFERENCES = 'UPDATE_USER_EMAIL_PREFERENCES';
export const UPDATE_USER_EMAIL_PREFERENCES_SUCCESS = 'UPDATE_USER_EMAIL_PREFERENCESSUCCESS';
export const UPDATE_USER_EMAIL_PREFERENCES_FAILURE = 'UPDATE_USER_EMAIL_PREFERENCESFAILURE';

export const updateUserEmailPreferences = createApiAction(UPDATE_USER_EMAIL_PREFERENCES);
export const createUserEmailPreferences = createApiAction(CREATE_USER_EMAIL_PREFERENCES);
