import React from 'react';
import cx from 'classnames';

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, ...props }, ref) => {
    return (
      <a
        ref={ref}
        className={cx(
          'text-primary underline underline-offset-2',
          'transition-colors duration-200',
          'cursor-pointer hover:text-primary-dark',
          'focus-within:outline outline-neutral-800 outline-2 outline-offset-2',
          className,
        )}
        {...props}
      >
        {props.children}
      </a>
    );
  },
);
