import { axiosJsonInstance } from 'src/store/api';

export type EmailPreferences = {
  user_id: number;
  allow_marketing_emails: boolean;
  notification_period: number;
}

export default {
  async createUserEmailPreferences({ allow_marketing_emails, notification_period, user_id }: EmailPreferences) {
    const formData = new FormData();
    formData.append('user_id', user_id.toString());
    formData.append('allow_marketing_emails', allow_marketing_emails.toString());
    formData.append('notification_period', notification_period.toString());

    return axiosJsonInstance({
      method: 'POST',
      url: 'subscription-email/',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async updateUserEmailPreferences({ allow_marketing_emails, notification_period, user_id }: EmailPreferences) {
    const formData = new FormData();
    formData.append('user_id', user_id.toString());
    formData.append('allow_marketing_emails', allow_marketing_emails.toString());
    formData.append('notification_period', notification_period.toString());

    return axiosJsonInstance({
      method: 'PUT',
      url: 'subscription-email/',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
