import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className="w-full h-full bg-white opacity-75 z-50">
      <span className="top-1/2 my-0 mx-auto block w-0 h-0">
        <i className="fas fa-circle-notch fa-spin fa-5x text-primary" />
      </span>
    </div>
  );
};

export default Loader;
