import React, { useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Icon } from 'src/components/Icon';
import { Canvas } from 'src/components/PlotShapesFigure/Canvas';
import { Row } from 'src/components/Row';
import { Typography } from 'src/components/Typography';
import { useConfirm } from 'src/providers/Confirm/useConfirm';
import * as ActionTypes from 'src/store/actions';
import { loadImg } from 'src/utils/loadImage';

type Props = {
  departmentId: number;
}

const MOCK_IMAGE_FLOORPLANT_URL = 'https://i.imgur.com/mxf7y4Q.png';
const CANVAS_WIDTH = 750;
const CANVAS_HEIGHT = 500;

const CamerasDistribution: React.FC<Props> = ({ departmentId }) => {
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const [camerasToShow, setCamerasToShow] = useState<any[]>([]);
  const [camerasAnnotations, setCamerasAnnotations] = useState<any[]>([]);
  const [selectedCameraId, setSelectedCameraId] = useState<number>();

  const { cameras, camerasDistribution } = useSelector((state: any) => state.cameras);

  const generateCammeraAnnotation = (camera: any) => ({
    key: camera.camera,
    id: camera.camera,
    name: camera.camera_distribution.name,
    label: camera.camera_distribution.name,
    x: camera.camera_distribution.x,
    y: camera.camera_distribution.y,
    width: camera.camera_distribution.width,
    height: camera.camera_distribution.height,
    rotation: camera.camera_distribution.rotation,
  });

  useEffect(() => {
    const cameraIds = cameras.map((camera: any) => camera.id);

    if (camerasDistribution.length > 0) {
      const camerasToShow = camerasDistribution.filter((camera: any) => cameraIds.includes(camera.camera));
      setCamerasToShow(camerasToShow);
      setCamerasAnnotations(camerasToShow.map(generateCammeraAnnotation));
    }
  }, [cameras, camerasDistribution]);

  useEffect(() => {
    dispatch(ActionTypes.getCamerasDistribution.request(null));
  }, []);

  const onSaveEditCameras = async () => {
    const willSave = await isConfirmed(
      'Confirmation',
      'Save Cameras & Distribution Changes?',
      undefined,
      'Yes',
    );

    if (willSave) {
      const camerasToSaveObject = camerasAnnotations.map((camera: any) => ({
        camera: camera.id,
        camera_distribution: {
          name: camera.name,
          x: camera.x,
          y: camera.y,
          width: camera.width,
          height: camera.height,
          rotation: camera.rotation,
        },
      }));

      // At the moment, each camera has to be saved individually
      camerasToSaveObject.forEach((camera: any) => {
        dispatch(ActionTypes.updateCameras.request(camera));
      });
    }
  };

  useEffect(() => {
    if (camerasToShow.length > 0) {
      const mapCameras = async (cams: any[], deptId: any) => {
        const mappedCamerasPromises = cams.map(async (camera: any) => {
          const img = await loadImg(`${process.env.PUBLIC_URL}/images/top_view_cameras/${deptId}/${camera.camera}.png`);

          return {
            ...generateCammeraAnnotation(camera),
            fillPatternImage: img,
          };
        });

        return Promise.all(mappedCamerasPromises);
      };

      mapCameras(camerasToShow, departmentId)
        .then((mappedCameras: any) => setCamerasAnnotations(mappedCameras))
        .catch((err) => console.error(err));
    }
  }, [camerasToShow, cameras, departmentId]);

  return (
    <Row>
      <Col>
        <Card
          title="Cameras & distribution"
          subTitle="Place cameras in store floor plan"
          padding
          iconsGroup={(
            <div className="flex gap-5 items-center">
              <AiOutlineSave size={20} color="#6A6B76" className="mt-0.5 cursor-pointer" onClick={() => onSaveEditCameras()} />
            </div>
          )}
        >
          <Row>
            <Col>
              <Canvas
                annotations={camerasAnnotations}
                setAnnotations={setCamerasAnnotations}
                img={MOCK_IMAGE_FLOORPLANT_URL}
                elementName="Cameras Distibution"
                canvasWidth={CANVAS_WIDTH}
                canvasHeight={CANVAS_HEIGHT}
                imgOpacity={0.9}
                withDraggable
                allowRotation
                selectedElementId={selectedCameraId}
                setSelectedElementId={setSelectedCameraId}
              />
            </Col>
            <Col size="5">
              <div className="flex items-center justify-between mb-6">
                <Typography>Cameras</Typography>
              </div>

              <div className="flex flex-col gap-4 max-h-[476px] overflow-y-auto">
                {camerasToShow.map((camera: any) => {
                  return (
                    <button
                      key={camera.camera}
                      className="flex items-center justify-start gap-2 px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200"
                      onClick={() => setSelectedCameraId(camera.camera)}
                      type="button"
                    >
                      <Icon icon="cctv" />
                      <Typography>{camera.camera_distribution.name}</Typography>
                      <Typography className="text-sm text-gray-300">{`(id: ${camera.camera})`}</Typography>
                    </button>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CamerasDistribution;
